import TreeIcon from "@mui/icons-material/AccountTree";
import OutcomesIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import ClaimsIcon from "@mui/icons-material/Forum";
import ValueIcon from "@mui/icons-material/Money";
import PartiesIcon from "@mui/icons-material/People";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate, useSubmit } from "react-router-dom";
import { CaseContextProps, useCase } from "../context/CaseContext";
import { useCurrency } from "../context/CurrencyContext";
import { CaseTitleDialog } from "./CaseTitleDialog";
import { useAuth } from "./auth/ParseAuthContext";

function getEvOfCase(caseContext: CaseContextProps): number | undefined {
  const parties = caseContext.getPartyDescriptors();
  let clientMaxEv: number | undefined = undefined;
  let maxEv: number | undefined = undefined;
  const evMatrix = caseContext.getEvMatrix();
  evMatrix.getPartyIds().forEach((partyId) => {
    const evs = evMatrix.getOwnEvs(partyId);
    if (evs === undefined || evs.length === 0) return;
    const ev = evs[evs.length - 1].value;
    const isClient = parties[partyId]?.isClient;
    if (isClient && (clientMaxEv === undefined || ev > clientMaxEv))
      clientMaxEv = ev;
    if (maxEv === undefined || maxEv < ev) maxEv = ev;
  });
  return clientMaxEv !== undefined ? clientMaxEv : maxEv;
}

export const CaseOverview = () => {
  const caseContext = useCase();
  const { id, name, createdAt, updatedAt, claims, parties } =
    caseContext.getCase();

  const submit = useSubmit();
  const setTitle: (title: string) => Promise<void> = (title) =>
    Promise.resolve(submit({ title }, { method: "put" }));

  const evOfCase = useMemo(() => getEvOfCase(caseContext), [caseContext]);

  const currency = useCurrency();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const createShortcut = (
    content: string,
    subtitle: string,
    icon: JSX.Element,
    to: string
  ) => {
    return (
      <Card
        sx={{
          minWidth: 120,
          mb: theme.spacing(2),
          mr: theme.spacing(2),
        }}
        key={subtitle}
      >
        <CardActionArea onClick={() => navigate(to)}>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                mb={theme.spacing(1)}
                alignItems="center"
              >
                {icon}
                <Typography
                  justifyItems="center"
                  variant="overline"
                  sx={{ ml: theme.spacing(1) }}
                >
                  {subtitle}
                </Typography>
              </Box>
              <Typography variant="h5">{content}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const client = parties?.find((p) => p.client);
  const clientOutcome =
    client && caseContext.getOutcomes().find((o) => o.partyId === client.id);

  const [showTitleDialog, setShowTitleDialog] = useState(false);
  return (
    <Box>
      <Box display="flex">
        <Typography key="label" variant="h5" color="textPrimary" mr={1}>
          {name}
        </Typography>
        {setTitle !== undefined && (
          <IconButton
            size="small"
            onClick={() => setShowTitleDialog(!showTitleDialog)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      <Typography key="title" gutterBottom color="textSecondary">
        <Trans i18nKey="case.details.caseId" values={{ caseId: id }} />
      </Typography>
      {createdAt && (
        <Typography key="createdAt">
          <Trans
            i18nKey="case.details.createdAt"
            values={{ date: new Date(createdAt).toLocaleString() }}
          />
        </Typography>
      )}
      {updatedAt && (
        <Typography key="updatedAt">
          <Trans
            i18nKey="case.details.updatedAt"
            values={{ date: new Date(updatedAt).toLocaleString() }}
          />
        </Typography>
      )}
      <Box
        key="shortcuts-configuration"
        mb={theme.spacing(2)}
        mt={theme.spacing(4)}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        {createShortcut(
          (parties?.length || 0).toString(),
          t("case.details.section.configuration.parties"),
          <PartiesIcon color="action" />,
          "configuration/parties"
        )}
        {createShortcut(
          (claims?.length || 0).toString(),
          t("case.details.section.configuration.claims"),
          <ClaimsIcon color="action" />,
          "configuration/claims"
        )}
      </Box>
      <Box
        key="shortcuts-analytics"
        mb={theme.spacing(2)}
        mt={theme.spacing(2)}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        {clientOutcome &&
          createShortcut(
            (clientOutcome.judgments.length || 0).toString(),
            t("case.details.section.analytics.outcomes"),
            <OutcomesIcon color="action" />,
            "analytics/outcomes"
          )}
        {createShortcut(
          currency.format(evOfCase || 0),
          t("case.details.section.analytics.value"),
          <ValueIcon color="action" />,
          "analytics/value"
        )}
        {currentUser?.canViewDecisionTree &&
          createShortcut(
            "Case Graph",
            "Decision Tree",
            <TreeIcon color="action" />,
            "analytics/tree"
          )}
      </Box>
      <CaseTitleDialog
        initialTitle={name}
        onClose={() => setShowTitleDialog(false)}
        saveTitle={(newTitle) => setTitle(newTitle)}
        open={showTitleDialog}
      />
    </Box>
  );
};
