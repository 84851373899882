import { Alert, Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { PartyDescriptors } from "../../model/CaseModel";
import { TabPanel } from "../general/TabPanel";
import { TwoLiner } from "../general/TwoLiner";
import { byName, clientsFirst, sort } from "./AnalyticsUtility";
import { EvMatrix } from "./EvMatrix";
import { PartyValue, PerPartyBarChart } from "./PerPartyBarChart";

function initializeStageData(
  evMatrix: EvMatrix,
  parties: PartyDescriptors,
  sortedParties: string[]
): { stage: Stage; data: PartyValue[] }[] {
  const partiesWithEvs = evMatrix.getPartyIds();
  if (partiesWithEvs.length === 0) {
    return [];
  }

  const stages: Stage[] =
    evMatrix
      .getOwnEvs(partiesWithEvs[0])
      ?.map((valueOfStage) => valueOfStage.stage) || [];

  return stages.map((stage) => {
    const data: PartyValue[] = sortedParties.map((partyId) => {
      const party = parties[partyId];
      return {
        partyId,
        partyName: party.name,
        client: party.isClient || false,
        value:
          evMatrix.getOwnEvs(partyId)?.find((ev) => ev.stage === stage)
            ?.value || 0,
      };
    });
    return { stage, data };
  });
}

export const EvAnalytics = () => {
  const caseContext = useCase();
  const evMatrix = caseContext.getEvMatrix();
  const parties = caseContext.getPartyDescriptors();
  const currency = useCurrency();

  const [value, setValue] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // defines order in charts etc.
  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [parties]
  );

  const perStageData: { stage: Stage; data: PartyValue[] }[] = useMemo(
    () => initializeStageData(evMatrix, parties, sortedParties),
    [evMatrix, parties, sortedParties]
  );
  if (perStageData.length === 0)
    return (
      <Typography>
        <Trans i18nKey={"case.analytics.ev.noData"} />
      </Typography>
    );

  const [selectedParty, setSelectedParty] = useState(
    sortedParties.length > 0 ? sortedParties[0] : undefined
  );

  const evOfSelectedParty = selectedParty
    ? perStageData[value]?.data.find((p) => p.partyId === selectedParty)
        ?.value || 0
    : 0;

  return (
    <Box sx={{ userSelect: "none" }}>
      <Typography mb={2}>
        <Trans i18nKey={"case.analytics.ev.description"} />
      </Typography>
      {selectedParty && (
        <Stack direction="row" spacing={2} mb={2}>
          <TwoLiner
            primary={t(
              parties[selectedParty]?.isClient
                ? "case.parties.name.client"
                : "case.parties.name.noClient",
              { name: parties[selectedParty]?.name || selectedParty }
            )}
            secondary="Selected party"
          />
          <TwoLiner
            primary={currency.format(evOfSelectedParty)}
            secondary={"Value of case"}
          />
          {evOfSelectedParty < 0 && (
            <Alert severity="warning">
              <Trans
                i18nKey="case.analytics.ev.warning.negative"
                values={{ name: parties[selectedParty].name }}
              />
            </Alert>
          )}
        </Stack>
      )}

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="ev analytics"
        scrollButtons="auto"
        variant="scrollable"
      >
        {perStageData.map(({ stage }, index) => (
          <Tab
            key={"outcome_tab_" + index + "_" + stage}
            label={t("case.stages." + Stage[stage])}
            id={"stage_" + stage}
          />
        ))}
      </Tabs>

      {perStageData.map((stage, index) => {
        return (
          <TabPanel key={"tab_" + index} currentTabIndex={value} index={index}>
            <Box mt={2} sx={{ userSelect: "none" }}>
              <PerPartyBarChart
                data={stage.data}
                label={"Value of case"}
                onSelected={setSelectedParty}
                formatValue={currency.format}
                selected={selectedParty}
              />
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
};
