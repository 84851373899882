export interface Branch {
  getEmv(): number;
}

export class Decision implements Branch {
  constructor(
    public name: string,
    public node: TreeNode,
    public cost: number
  ) {}

  getEmv(): number {
    return this.node.getEmv() - this.cost;
  }
}

export class Chance implements Branch {
  constructor(
    public name: string,
    public probability: number,
    public node: TreeNode
  ) {}

  getEmv(): number {
    return this.node.getEmv() * this.probability;
  }
}

export interface TreeNode {
  getEmv(): number;
}
export interface SubGraph {
  title: string;
  trees: TreeNode[];
}

export class DecisionNode implements TreeNode {
  constructor(public name: string, public decisions: Decision[]) {}
  getEmv(): number {
    if (this.decisions.length < 1) {
      return 0;
    }
    let emv = this.decisions[0].getEmv();
    for (let index = 1; index < this.decisions.length; index++) {
      emv = Math.max(emv, this.decisions[index].getEmv());
    }
    return emv;
  }
}

export class ChanceNode implements TreeNode {
  constructor(public chances: Chance[]) {}
  getEmv(): number {
    let emv = 0;
    for (const branch of this.chances) {
      emv += branch.getEmv();
    }
    return emv;
  }
}

export class EndNode implements TreeNode {
  constructor(private monetaryValue: number) {}

  getEmv(): number {
    return this.monetaryValue;
  }
}
