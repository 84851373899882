import { Box, Tooltip, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { percentage } from "./Claims";

export const WinChanceVertical = ({
  chance = 1,
  onChanceChange,
  opponentName,
  limit = 1,
}: {
  chance?: number;
  onChanceChange: (newChance: number) => void;
  opponentName?: string;
  limit?: number;
}) => {
  const [sliderValue, setSliderValue] = useState(chance * 100);
  const maxSliderValue = limit * 100;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        userSelect: "none",
        mr: 1,
      }}
    >
      <Typography variant="caption">
        <Trans
          i18nKey="case.claims.winChance.valueLabel"
          values={{ winChance: percentage.format(sliderValue / 100) }}
        />
      </Typography>
      <Tooltip
        title={
          opponentName
            ? t("case.claims.winChance.tooltip", { defendant: opponentName })
            : t("case.claims.winChance.tooltipNoDefendant")
        }
      >
        <Slider
          color="primary"
          size="small"
          value={sliderValue}
          aria-label="Small"
          valueLabelFormat={(value) => percentage.format(value / 100)}
          valueLabelDisplay="off"
          onChangeCommitted={(ignored, value) => {
            if (typeof value !== "number") return;
            const normalized = Math.min(value, maxSliderValue);
            setSliderValue(normalized);
            onChanceChange(normalized / 100);
          }}
          onChange={(ignored, value) => {
            if (typeof value !== "number") return;
            const normalized = Math.min(value, maxSliderValue);
            setSliderValue(normalized);
          }}
          marks={limit !== 1 ? [{ value: limit * 100 }] : []}
        />
      </Tooltip>
    </Box>
  );
};
