import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { t } from "i18next";
import { MenuItem, ZoomOutButtonMenu } from "./ZoomOutButtonMenu";

export const EditAndDeleteMenu = ({
  openToTheLeft = false,
  onEdit,
  onDelete,
  additionalItems = [],
}: {
  openToTheLeft?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  additionalItems?: MenuItem[];
}) => {
  if (additionalItems.length === 0)
    return (
      <ZoomOutButtonMenu
        openToTheLeft={openToTheLeft}
        items={[
          {
            title: t("moreMenu.tip.edit"),
            icon: <EditIcon fontSize="inherit" />,
            onClick: onEdit,
          },
          {
            title: t("moreMenu.tip.delete"),
            icon: <DeleteIcon fontSize="inherit" />,
            onClick: onDelete,
          },
        ]}
      />
    );
  const items = additionalItems.concat([
    {
      title: t("moreMenu.tip.edit"),
      icon: <EditIcon fontSize="inherit" />,
      onClick: onEdit,
    },
    {
      title: t("moreMenu.tip.delete"),
      icon: <DeleteIcon fontSize="inherit" />,
      onClick: onDelete,
    },
  ]);
  return <ZoomOutButtonMenu openToTheLeft={openToTheLeft} items={items} />;
};
