import { IssueRelation } from "../../model/CaseModel";
import randomId from "../../utils/randomId";
import { IssueCallbacks, IssueEditorRows } from "./IssueEditorRows";
import { Issue, IssueGroup, Party, isGroup } from "./IssueModel";
import { IssuePointer, locate } from "./IssuePointer";

interface IssueRelationChangeContext {
  issue: Issue;
  group?: IssueGroup;
  lastInGroup: boolean;
  nextIssue?: Issue;
  nextGroup?: IssueGroup;
  issues: (Issue | IssueGroup)[];
}

function changeRelation(
  newRelation: IssueRelation,
  context: IssueRelationChangeContext
): boolean {
  const { issue, group, lastInGroup, nextIssue, nextGroup, issues } = context;
  // check if there is anything to switch at all?
  if (issue.relation === newRelation) return false;
  if (nextIssue === undefined && nextGroup === undefined) return false;

  if (group) {
    // issue is currently in a group
    if (lastInGroup) {
      // relation must be AND, otherwise the next element would be in group
      // we can only change relation to relation of group
      if (newRelation !== group.relation) return false;
      // but we need to check if next element can be added to issue's group
      // if next is a group with a different relation, we can't switch
      if (nextGroup) {
        if (nextGroup.relation !== newRelation) return false;
        // move elements of next group to current group
        const indexOfNext = issues.indexOf(nextGroup);
        if (indexOfNext < 0) return false;
        issue.relation = newRelation;
        nextGroup.subIssues.forEach((i) => group.subIssues.push(i));
        issues.splice(indexOfNext, 1);
      }
      // next is not group
      else {
        // change relation and add element to current group
        if (nextIssue === undefined) return false;
        const indexOfNext = issues.indexOf(nextIssue);
        if (indexOfNext < 0) return false;
        issue.relation = newRelation;
        group.subIssues.push(nextIssue);
        issues.splice(indexOfNext, 1);
      }
    } else {
      // issue is not last in group
      if (newRelation === IssueRelation.And) {
        // switch relation and split group between issue and next
        const indexOfIssueInGroup = group.subIssues.indexOf(issue);
        if (indexOfIssueInGroup < 0) return false;
        const indexOfGroup = issues.indexOf(group);
        if (indexOfGroup < 0) return false;
        issue.relation = newRelation;
        if (indexOfIssueInGroup === 0) {
          // issue is first in group. remove issue from group
          group.subIssues.shift();
          // move issue to issues as standalone
          issues.splice(indexOfGroup, 0, issue);
          // flatten group if it has only one element
          if (group.subIssues.length < 2) {
            issues.splice(indexOfGroup + 1, 1, ...group.subIssues);
          }
        } else {
          // split group at issue
          const newGroupIssues = group.subIssues.splice(
            indexOfIssueInGroup + 1
          );
          // if new group is only one issue, make it a standalone
          if (newGroupIssues.length < 2)
            issues.splice(indexOfGroup + 1, 0, ...newGroupIssues);
          else {
            const newGroup: IssueGroup = {
              id: randomId(),
              relation: group.relation,
              subIssues: newGroupIssues,
            };
            issues.splice(indexOfGroup + 1, 0, newGroup);
          }
        }
        // delete any remaining groups with only one element
      } else {
        // change relation of group
        group.relation = newRelation;
        for (let index = 0; index < group.subIssues.length - 1; index++) {
          group.subIssues[index].relation = newRelation;
        }
      }
    }
  }
  // issue is not in a group
  else {
    if (nextGroup) {
      // abort if new relation is incompatible with next group
      if (newRelation !== nextGroup.relation) return false;
      // add issue to next group
      const indexOfIssue = issues.indexOf(issue);
      if (indexOfIssue < 0) return false;
      issue.relation = newRelation;
      nextGroup.subIssues = [issue, ...nextGroup.subIssues];
      issues.splice(indexOfIssue, 1);
    } else {
      if (nextIssue === undefined) return false;
      // create a group with new relation and containing issue and next
      const indexOfIssue = issues.indexOf(issue);
      if (indexOfIssue < 0) return false;
      const indexOfNext = issues.indexOf(nextIssue);
      if (indexOfNext < 0) return false;
      const newGroup: IssueGroup = {
        id: randomId(),
        relation: newRelation,
        subIssues: [issue, nextIssue],
      };
      issue.relation = newRelation;
      issues[indexOfIssue] = newGroup;
      issues.splice(indexOfNext, 1);
    }
  }
  return true;
}

function onRelationSelected(
  issue: Issue,
  newRelation: IssueRelation,
  issues: (Issue | IssueGroup)[]
): boolean {
  if (issue.relation === newRelation) return false;
  const position = locate(issues, issue.id);
  if (position === undefined) return false;
  const next = position.next();
  if (next === undefined) return false;

  const group = position.getGroup();
  const lastInGroup =
    group !== undefined &&
    group.subIssues.findIndex((i) => i.id === issue.id) ===
      group.subIssues.length - 1;

  const nextGroup = next.getGroup();

  const context: IssueRelationChangeContext = {
    issue,
    group,
    lastInGroup,
    nextGroup,
    nextIssue: nextGroup === undefined ? next.get() : undefined,
    issues,
  };

  return changeRelation(newRelation, context);
}

function scaleXorWinChances(issues: Issue[], defendantId: string) {
  let totalWinChance = 0;

  issues.forEach((issue) => {
    const winChance = issue.winChanceAgainst[defendantId];
    if (winChance !== undefined) {
      totalWinChance += winChance;
    }
  });

  if (totalWinChance <= 1) return;
  const scale = 1 / totalWinChance;

  issues.forEach((issue) => {
    const winChance = issue.winChanceAgainst[defendantId];
    if (winChance !== undefined) {
      issue.winChanceAgainst[defendantId] = winChance * scale;
    }
  });
}

export const TwoLevelIssueHierarchyEditor = ({
  issues,
  defendants,
  onIssuesUpdate,
  onIssueEditorRequested,
}: {
  issues: (Issue | IssueGroup)[];
  defendants: Party[];
  onIssuesUpdate: (newIssues: (Issue | IssueGroup)[]) => void;
  onIssueEditorRequested: (issue: Issue) => void;
}) => {
  const onIssueUpdate = (updated: Issue) => {
    const issuePointer = locate(issues, updated.id);
    if (!issuePointer) {
      console.warn("Cannot update issue, no issue found with id", updated.id);
      return;
    }
    issuePointer.set(updated);
    onIssuesUpdate(issues.map((i) => (i.id === updated.id ? updated : i)));
  };

  const callbacks: IssueCallbacks = {
    onDelete: (issue) => {
      let newIssues: (Issue | IssueGroup)[] = JSON.parse(
        JSON.stringify(issues)
      );
      const issuePointer = locate(newIssues, issue.id);
      if (!issuePointer) return;
      const group = issuePointer.getGroup();
      if (group) {
        group.subIssues = group.subIssues.filter((i) => i.id !== issue.id);
        // check if group has more than 1 element left
        if (group.subIssues.length < 2)
          newIssues.splice(newIssues.indexOf(group), 1, ...group.subIssues);
      } else {
        newIssues = newIssues.filter((i) => i.id !== issue.id);
      }
      onIssuesUpdate(newIssues);
    },
    onIssueEditorRequested,
    onMoveUp: (issue) => {
      const issuesCopy = JSON.parse(JSON.stringify(issues));
      const issuePointer = locate(issuesCopy, issue.id);
      if (swap(issuePointer, issuePointer?.previous()))
        onIssuesUpdate(issuesCopy);
    },
    onMoveDown: (issue) => {
      const issuesCopy = JSON.parse(JSON.stringify(issues));
      const issuePointer = locate(issuesCopy, issue.id);
      if (swap(issuePointer, issuePointer?.next())) onIssuesUpdate(issuesCopy);
    },
    onRelationSelected: (issue, newRelation) => {
      const issuesCopy: (Issue | IssueGroup)[] = JSON.parse(
        JSON.stringify(issues)
      );
      const issueCopy = locate(issuesCopy, issue.id)?.get();
      if (issueCopy && onRelationSelected(issueCopy, newRelation, issuesCopy)) {
        if (newRelation === IssueRelation.Xor) {
          // create XOR group or moved an issue into one. scale win chances for all defendants
          issuesCopy.forEach((i) => {
            if (!isGroup(i) || i.relation !== IssueRelation.Xor) return;
            defendants.forEach((d) => scaleXorWinChances(i.subIssues, d.id));
          });
        }
        onIssuesUpdate(issuesCopy);
      }
    },
    onWinChanceUpdated: (issue, defendantId, newChance) => {
      const issueCopy = JSON.parse(JSON.stringify(issue));
      issueCopy.winChanceAgainst[defendantId] = newChance;
      onIssueUpdate(issueCopy);
    },
    onSwitchToGeneric: (issue) => {
      const issueCopy = JSON.parse(JSON.stringify(issue));
      issueCopy.generic = true;
      onIssueUpdate(issueCopy);
    },
    onSwitchToSpecific: (issue) => {
      const issueCopy: Issue = JSON.parse(JSON.stringify(issue));
      issueCopy.generic = false;
      onIssueUpdate(issueCopy);
    },
    onGenericWinChanceUpdated: (issue, newChance) => {
      const issueCopy = JSON.parse(JSON.stringify(issue));
      issueCopy.genericWinChance = newChance;
      onIssueUpdate(issueCopy);
    },
  };
  return (
    <IssueEditorRows
      issues={issues}
      defendants={defendants}
      callbacks={callbacks}
    />
  );
};

function swap(source?: IssuePointer, target?: IssuePointer) {
  if (source === undefined || target === undefined) return false;
  const i1 = source.get();
  if (i1 === undefined) {
    console.warn("Source issue not found", source);
    return false;
  }
  const i2 = target.get();
  if (i2 === undefined) {
    console.warn("Target issue not found", target);
    return false;
  }

  if (!target.set(i1) || !source.set(i2)) return false;
  const sourceRelation = i1.relation;
  i1.relation = i2.relation;
  i2.relation = sourceRelation;
  return true;
}
