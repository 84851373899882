import { useMemo } from "react";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { Currency, gbp } from "../../i18n/Currency";
import { getCostsTotal } from "../../model/CaseModelUtility";
import { Budget, Disbursements } from "../../model/CostModel";
import randomId from "../../utils/randomId";
import { EditableCostsPerStage } from "./EditableCostsPerStage";
import { CostsPerStage } from "./EditableCostsPerStageTable";

function convert(budget: Budget): CostsPerStage[] {
  const costsPerStage = new Map<Stage, CostsPerStage>();
  budget.costs.forEach((costs) => {
    const totals = getCostsTotal(costs);
    const costsOfStage = costsPerStage.get(costs.stage);
    if (costsOfStage === undefined) {
      costsPerStage.set(costs.stage, {
        stage: costs.stage,
        incurredFees: totals.incurredFees.toNumber(),
        incurredLawyerCosts: totals.incurredLawyerCosts.toNumber(),
        estimatedFees: totals.estimatedFees.toNumber(),
        estimatedLawyerCosts: totals.estimatedLawyerCosts.toNumber(),
      });
      return;
    }
  });
  return [...costsPerStage.values()];
}

const asDisbursements: (costs: CostsPerStage) => Disbursements = (costs) => ({
  id: randomId(),
  name: "",
  entries: [
    {
      amount: costs.incurredFees,
      incurred: true,
      courtFee: true,
      id: randomId(),
    },
    {
      amount: costs.incurredLawyerCosts,
      incurred: true,
      id: randomId(),
    },
    {
      amount: costs.estimatedFees,
      courtFee: true,
      id: randomId(),
    },
    {
      amount: costs.estimatedLawyerCosts,
      id: randomId(),
    },
  ],
});

export const EditableCostsPerStageBudgetAdapter = ({
  budget,
  otherBudgets = [],
  updateBudget,
  currency = gbp,
}: {
  budget: Budget;
  otherBudgets: Budget[];
  updateBudget: (newValue: Budget) => Promise<void>;
  currency?: Currency;
}) => {
  const costs = useMemo(() => convert(budget), [budget]);

  const updateCosts = (update: CostsPerStage[]) => {
    const newBudget: Budget = {
      partyId: budget.partyId,
      costs: update.map((costsOfStage) => ({
        id: randomId(),
        stage: costsOfStage.stage,
        name: Stage[costsOfStage.stage],
        hourlyCosts: [],
        disbursements: [asDisbursements(costsOfStage)],
      })),
    };
    return updateBudget(newBudget);
  };

  const getCosts = (partyId: string) => {
    const partyBudget = otherBudgets.find(
      (budget) => budget.partyId === partyId
    );
    if (partyBudget === undefined) {
      console.log("No budget found for party " + partyId);
      return;
    }
    console.log("Got budget copy request", partyId);
    return convert({ ...partyBudget, partyId: budget.partyId });
  };

  return (
    <EditableCostsPerStage
      costs={costs}
      currency={currency}
      updateCosts={updateCosts}
      partyId={budget.partyId}
      getCosts={getCosts}
    />
  );
};
