import IssueNode from "../IssueNode";
import {
  Case,
  IssueScenario,
  LiabilityIssue,
  Relation,
} from "../model/CaseConfiguration";

/**
 * Utility calculating the outcome of liability issues of a case for a given IssueScenario.
 */
export class LiabilityOutcome {
  private rootLiabilities: IssueNode[] = [];

  private readonly claimants: { [claimId: string]: string } = {};

  constructor(
    private c: Case,
    when: IssueScenario
  ) {
    // if an issue is won is determined primarily by "when"
    // if not configured in "when", win status is calculated
    const isWon: (issue: LiabilityIssue) => boolean = (issue) => {
      if (when.issuesWon?.includes(issue.id) === true) return true;
      if (when.issuesLost?.includes(issue.id) === true) return false;

      // assume win as default
      if (issue.subIssues === undefined || issue.subIssues.length === 0)
        return true;

      const relation = issue.relation ?? Relation.And;
      switch (relation) {
        case Relation.And:
          return issue.subIssues.every(isWon);
        case Relation.Or:
          return issue.subIssues.some(isWon);
        case Relation.Xor:
          return issue.subIssues.filter(isWon).length === 1;
      }
    };

    // create issue tree
    c.liabilityIssues?.forEach((rootIssue) => {
      this.claimants[rootIssue.id] = rootIssue.claimant;
      this.rootLiabilities.push(new IssueNode(rootIssue, isWon));
    });
  }

  getLiabilitiesWonBy(partyId: string): LiabilityIssue[] {
    const issuesWonByParty = new Set<LiabilityIssue>();

    this.rootLiabilities.forEach((rootLiability) => {
      const claimant = this.claimants[rootLiability.issue.id];

      if (partyId === claimant) {
        // party is claimant of liability
        rootLiability.accept((liability) => {
          if (liability.isWon())
            // liability is won for claimants
            issuesWonByParty.add(liability.issue);
        });
        return;
      }
      // party is not claimant, maybe defendant?
      rootLiability.accept((liability) => {
        if (liability.hasDefendant(partyId) && !liability.isWon())
          // party is defendant and liability is lost for claimants
          issuesWonByParty.add(liability.issue);
      });
    });
    return [...issuesWonByParty];
  }

  getLiabilitiesLostBy(partyId: string): LiabilityIssue[] {
    // party can be: defendant, claimant or neither
    const issuesLostByParty = new Set<LiabilityIssue>();

    this.rootLiabilities.forEach((rootLiability) => {
      const claimant = this.claimants[rootLiability.issue.id];
      if (partyId === claimant) {
        rootLiability.accept((liability) => {
          if (!liability.isWon())
            // party is claimant and issue is lost
            issuesLostByParty.add(liability.issue);
        });
        return;
      }
      // party is not claimant but maybe defendant?
      rootLiability.accept((liability) => {
        if (liability.hasDefendant(partyId) && liability.isWon())
          // party is defendant and claimant won the issue
          issuesLostByParty.add(liability.issue);
      });
    });
    return [...issuesLostByParty];
  }

  getLiability(issueId: string): IssueNode | undefined {
    for (let i = 0; i < this.rootLiabilities.length; i++) {
      const liability = this.rootLiabilities[i].find(
        (liability) => liability.issue.id === issueId
      );
      if (liability) return liability;
    }
  }

  isIssueWon(issueId: string): boolean {
    const issue = this.getLiability(issueId);
    return issue !== undefined && issue.isWon();
  }

  /**
   * Returns liability issues won in this outcome, where win means that the defendants lost.
   * @returns liabilities won in this outcome
   */
  getIssuesWon(): LiabilityIssue[] {
    const issuesWon: LiabilityIssue[] = [];
    this.rootLiabilities.forEach((rootLiability) => {
      rootLiability.accept((liability) => {
        if (liability.isWon()) {
          issuesWon.push(liability.issue);
        }
      });
    });
    return issuesWon;
  }

  /**
   * Returns liability issues lost in this outcome, where loss means that the defendants won.
   * @returns liabilities lost in this outcome
   */
  getIssuesLost(): LiabilityIssue[] {
    const issuesLost: LiabilityIssue[] = [];
    this.rootLiabilities.forEach((rootLiability) => {
      rootLiability.accept((liability) => {
        if (!liability.isWon()) {
          issuesLost.push(liability.issue);
        }
      });
    });
    return issuesLost;
  }

  isSetOff(issueId: string): boolean {
    return this.rootLiabilities.some((root) => {
      const issueNode = root.find((node) => node.issue.id === issueId);
      return issueNode && issueNode.isSetOff();
    });
  }
}
