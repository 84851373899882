import { ZERO } from "../big/bigUtil";
import randomId from "../utils/randomId";
import { ApportionmentCalculator } from "./ApportionmentCalculator";
import { RecoverySharesCalculator } from "./RecoverySharesCalculator";
import { Cost, Recovery, Share } from "./model/CaseConfiguration";
import { PaymentObligation } from "./payment/PaymentObligation";

export class RecoveryPaymentCalculator {
  private recoveryAggregator: RecoverySharesCalculator =
    new RecoverySharesCalculator();
  private costCalculator: ApportionmentCalculator;

  constructor(
    lawyersCostsApportionment: Share[],
    courtFeesApportionment: Share[]
  ) {
    this.costCalculator = new ApportionmentCalculator(
      lawyersCostsApportionment,
      courtFeesApportionment
    );
  }

  getRecoveryPayment(cost: Cost): PaymentObligation | undefined {
    // get what payers are liable for
    const apportionedCost = this.costCalculator.getApportionedAmounts(cost);
    if (Object.keys(apportionedCost).length === 0) {
      // nobody pays
      return;
    }
    // get possible recoveries
    const recoveryShares = this.recoveryAggregator.getRecoveryShares(
      cost.costId,
      cost.type
    );
    if (
      recoveryShares.maxRecovery.lte(0) ||
      Object.keys(recoveryShares.payerShares).length === 0
    ) {
      // nobody pays
      return;
    }

    // scale payable cost amounts to recovery shares
    const maxRecoveryAmount = recoveryShares.maxRecovery.mul(cost.amount);
    let totalPaid = ZERO;
    for (const payerId in apportionedCost) {
      const recoveryShare = recoveryShares.payerShares[payerId];

      if (recoveryShare === undefined || recoveryShare.lte(0)) {
        // payer does not pay
        apportionedCost[payerId] = ZERO;
      } else {
        // scale cost based on recovery share
        const amountPaidByPayer = apportionedCost[payerId].mul(recoveryShare);
        apportionedCost[payerId] = amountPaidByPayer;
        totalPaid = totalPaid.add(amountPaidByPayer);
      }
    }

    if (totalPaid.lte(0)) {
      // nobody pays
      return;
    }

    const sharePerPayer: Map<string, Big> = new Map();

    for (const payerId in apportionedCost) {
      const amountPaidByPayer = apportionedCost[payerId];
      if (amountPaidByPayer.gt(0)) {
        sharePerPayer.set(payerId, amountPaidByPayer.div(maxRecoveryAmount));
      }
    }

    return new PaymentObligation(
      randomId(),
      maxRecoveryAmount,
      sharePerPayer,
      cost.payerId
    );
  }

  addRecovery(recovery: Recovery) {
    // recoveries must have payers specified (based on the debtors of the recipient in this scenario)
    if (recovery.payers === undefined || recovery.payers.length === 0) {
      console.warn("Ignoring recovery without payers", recovery);
      return;
    }

    // check if recovery is for specific costs
    if (
      recovery.recoveredCosts !== undefined &&
      recovery.recoveredCosts.length > 0
    ) {
      this.recoveryAggregator.addSpecificRecovery(
        recovery.recoveredCosts,
        recovery.percentage,
        recovery.payers
      );
      return;
    }
    this.recoveryAggregator.addGeneralRecovery(
      recovery.type,
      recovery.percentage,
      recovery.payers
    );
  }
}
