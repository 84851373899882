import Collapsed from "@mui/icons-material/ChevronRight";
import Expanded from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";

export const TurningChevron = ({
  turn,
  onClick,
}: {
  turn?: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton onClick={() => onClick()}>
      {turn ? (
        <Expanded
          key="expanded-chevron"
          sx={{
            animation: "expand 0.3s linear",
            "@keyframes expand": {
              from: {
                transform: "rotate(-90deg)",
              },
              to: {
                transform: "rotate(0deg)",
              },
            },
          }}
        />
      ) : (
        <Collapsed
          key="collapsed-chevron"
          sx={
            turn !== undefined
              ? {
                  animation: "collapse 0.3s linear",
                  "@keyframes collapse": {
                    from: {
                      transform: "rotate(90deg)",
                    },
                    to: {
                      transform: "rotate(0deg)",
                    },
                  },
                }
              : // avoid animation at initial component load
                {}
          }
        />
      )}
    </IconButton>
  );
};
