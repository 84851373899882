import { Box, Typography } from "@mui/material";
import "chart.js/auto";
import { graphviz } from "d3-graphviz";
import { useEffect, useMemo } from "react";
import { useCase } from "../../context/CaseContext";
import { generateDotGraph } from "../../tree/graph/TreeGraphUtility";

let counter = 0;
const getId = () => `graph${counter++}`;

export const Graph = ({ dot }: { dot: string }) => {
  const id = useMemo(getId, []);

  useEffect(() => {
    graphviz(`#${id}`).renderDot(dot);
  }, [dot]);

  return <div id={id} />;
};

export const DecisionTree = () => {
  const caseContext = useCase();
  const decisionTrees = caseContext.getDecisionTrees();

  if (decisionTrees === undefined)
    return (
      <Box>
        <Typography>Decision tree not loaded</Typography>
      </Box>
    );
  const graph = generateDotGraph(decisionTrees, caseContext.getCase().name);

  return (
    <Box>
      <Graph dot={graph} />
    </Box>
  );
};
