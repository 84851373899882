import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CheckboxList = ({
  elements,
  onSelect,
  onUnselect,
  disabled = false,
}: {
  elements: {
    id: string;
    title: string;
    selected: boolean;
  }[];
  onSelect: (...ids: string[]) => void;
  onUnselect: (...ids: string[]) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "general.CheckboxList",
  });
  if (elements.length === 0) {
    return <Typography>{t("noElements")}</Typography>;
  }

  const createCheckbox = (element: {
    id: string;
    title: string;
    selected: boolean;
  }) => (
    <FormControlLabel
      key={element.id}
      label={element.title}
      disabled={disabled}
      control={
        <Checkbox
          key={element.id}
          checked={element.selected}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            event.target.checked ? onSelect(element.id) : onUnselect(element.id)
          }
        />
      }
    />
  );

  if (elements.length === 1) return createCheckbox(elements[0]);

  const numberOfCoveredClaims = elements.reduce(
    (previous, current) => (current.selected ? previous + 1 : previous),
    0
  );
  const allCovered = numberOfCoveredClaims === elements.length;
  const indeterminate = !allCovered && numberOfCoveredClaims > 0;

  return (
    <>
      <FormControlLabel
        label={t("all")}
        disabled={disabled}
        control={
          <Checkbox
            key="all"
            checked={allCovered}
            indeterminate={indeterminate}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              event.target.checked
                ? onSelect(...elements.map((e) => e.id))
                : onUnselect(...elements.map((e) => e.id))
            }
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {elements.map((element) => createCheckbox(element))}
      </Box>
    </>
  );
};

export default CheckboxList;
