import Big from "big.js";
import { getLiabilityProbabilities } from "../../../analytics/api/LocalAnalytics";
import { getPossibleScenarios } from "../../../analytics/liability/LiabilityScenarios";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { ZERO } from "../../../big/bigUtil";
import { Case, Configuration } from "../../../model/CaseModel";
import { getValueOfAssets } from "../../../model/CaseModelUtility";
import randomId from "../../../utils/randomId";
import { getCostSummary } from "../../costs/Budgeting";
import { getOverallWinChance } from "../../issues/IssueModel";
import { convertTo } from "../../issues/IssueModelConverter";

export function areEqual(c1: Configuration, c2: Configuration): boolean {
  // assets
  if (Object.keys(c1.assets).length !== Object.keys(c2.assets).length)
    return false;
  for (const defendantId in c1.assets) {
    if (c1.assets[defendantId] !== c2.assets[defendantId]) return false;
  }
  // costs
  if (Object.keys(c1.costs).length !== Object.keys(c2.costs).length)
    return false;
  for (const defendantId in c1.costs) {
    if (c1.costs[defendantId] !== c2.costs[defendantId]) return false;
  }

  if (Object.keys(c1.winChances).length !== Object.keys(c2.winChances).length)
    return false;
  for (const issueId in c1.winChances) {
    if (!areEqualWinChances(c1.winChances[issueId], c2.winChances[issueId]))
      return false;
  }

  return true;
}

export function areEqualWinChances(
  c1WinChance: {
    generic: boolean;
    genericWinChance: number;
    perDefendantWinChance: {
      [defendantId: string]: number;
    };
  },
  c2WinChance?: {
    generic: boolean;
    genericWinChance: number;
    perDefendantWinChance: {
      [defendantId: string]: number;
    };
  }
): boolean {
  if (c2WinChance === undefined) return false;
  if (c1WinChance.generic !== c2WinChance.generic) return false;
  if (c1WinChance.genericWinChance !== c2WinChance.genericWinChance)
    return false;
  for (const defendantId in c1WinChance.perDefendantWinChance) {
    if (
      c1WinChance.perDefendantWinChance[defendantId] !==
      c2WinChance.perDefendantWinChance[defendantId]
    )
      return false;
  }
  return true;
}

export function flattenClaims(c: Case): Case {
  if (c.claims === undefined || c.claims.length === 0) return c;
  const flattenedCase: Case = JSON.parse(JSON.stringify(c));

  const probabilities = getLiabilityProbabilities(flattenedCase);

  flattenedCase.claims?.forEach((claim) => {
    if (claim.issues === undefined || claim.issues.length === 0) return;

    const allIssuesAreGeneric = claim.issues.every(
      (issue) => issue.generic === true
    );
    if (allIssuesAreGeneric) {
      const scenarios = getPossibleScenarios(
        [{ id: claim.id, relation: claim.relation, subIssues: claim.issues }],
        (issueId) =>
          probabilities.find((p) => p.liabilityId === issueId)?.winChance ?? 1
      );
      let winChance = ZERO;
      scenarios.forEach((scenario) => {
        if (scenario.issuesWon?.includes(claim.id))
          winChance = winChance.add(scenario.probability);
      });
      claim.generic = true;
      claim.genericWinChance = winChance.toNumber();
      claim.issues = undefined;
      return;
    }

    // defendant-specific claim
    const issueModelClaim = convertTo(claim, flattenedCase.parties ?? []);
    const perDefendantWinChance: { [defendantId: string]: number } = {};
    claim.defendants?.forEach((defendant) => {
      const winChance = getOverallWinChance(
        issueModelClaim.issues,
        defendant.id
      );
      if (winChance !== undefined)
        perDefendantWinChance[defendant.id] = winChance.toNumber();
    });
    claim.generic = false;
    claim.perDefendantWinChance = perDefendantWinChance;
    claim.issues = undefined;
  });
  return flattenedCase;
}

export function extract(c: Case): Configuration {
  const configuration: Configuration = {
    id: c.name,
    assets: {},
    costs: {},
    winChances: {},
    timestamp: Date.now(),
  };
  c.budgets?.forEach(
    (budget) =>
      (configuration.costs[budget.partyId] = getCostSummary(budget).totalCosts)
  );
  c.parties?.forEach((party) => {
    configuration.assets[party.id] = getValueOfAssets(party) ?? null;
  });
  c.claims?.forEach((claim) => {
    const winChance: {
      generic: boolean;
      genericWinChance: number;
      perDefendantWinChance: {
        [defendantId: string]: number;
      };
    } = {
      generic: claim.generic === true,
      genericWinChance: claim.genericWinChance ?? 0,
      perDefendantWinChance: {},
    };
    for (const defendantId in claim.perDefendantWinChance) {
      winChance.perDefendantWinChance[defendantId] =
        claim.perDefendantWinChance[defendantId];
    }
    configuration.winChances[claim.id] = winChance;
  });

  return configuration;
}

/**
 * Modifies given case so the win chances of its claims, as well as values of assets and costs equal those in the given configuration.
 * If necessary, the costs and assets are scaled by a factor needed to meet the configuration's target.
 * If no assets/costs are defined in the case, new cost/asset entries are created.
 * If the configuration demands zero costs, all costs are removed from the case.
 * If the configuration demands unlimited assets, all assets are removed from the case.
 *
 * @param c case to apply the configuration to
 * @param configuration to apply
 */
export function apply(c: Case, configuration: Configuration) {
  // scale budgets
  c.budgets?.forEach((budget) => {
    const target = configuration.costs[budget.partyId];
    if (target === undefined || target < 0) return;

    const current = getCostSummary(budget).totalCosts;
    if (current === target) return;

    if (target === 0) {
      // delete all costs
      budget.costs = [];
      return;
    }

    if (current === 0) {
      // add cost with target value
      budget.costs = [
        {
          id: randomId(),
          name: "Simulated variant costs",
          stage: Stage.Trial,
          hourlyCosts: [],
          disbursements: [
            {
              id: randomId(),
              name: "Simulated variant cost",
              entries: [{ id: randomId(), amount: target }],
            },
          ],
        },
      ];
      return;
    }
    // neither current, nor target are 0. they are not equal and target is greater than zero
    const scalingFactor = new Big(target).div(current);
    budget.costs.forEach((cost) => {
      cost.disbursements.forEach((disbursement) => {
        disbursement.entries?.forEach(
          (entry) => (entry.amount = scalingFactor.mul(entry.amount).toNumber())
        );
      });
      cost.hourlyCosts.forEach((hourlyCost) => {
        hourlyCost.ratePerHour = scalingFactor
          .mul(hourlyCost.ratePerHour)
          .toNumber();
      });
    });
  });
  // scale assets
  c.parties?.forEach((party) => {
    const current = getValueOfAssets(party);
    const target = configuration.assets[party.id];
    if (current === target) return;
    if (target === null) {
      party.assets = undefined;
      return;
    }
    if (target < 0) {
      console.log("Cannot set negative asset value");
      return;
    }
    if (current === undefined) {
      // add virtual asset
      party.assets = [
        {
          id: randomId(),
          name: "Simulated variant asset",
          values: [{ value: target, weight: 1 }],
        },
      ];
      return;
    }
    // neither current, nor target are 0. they are not equal and target is greater than zero
    const scalingFactor = new Big(target).div(current);
    party.assets?.forEach((asset) => {
      asset.values.forEach(
        (value) => (value.value = scalingFactor.mul(value.value).toNumber())
      );
    });
  });
  // apply win chances
  c.claims?.forEach((claim) => {
    const winChance = configuration.winChances[claim.id];
    if (winChance === undefined) return;

    claim.generic = winChance.generic;
    claim.genericWinChance = winChance.genericWinChance;
    claim.perDefendantWinChance = { ...winChance.perDefendantWinChance };
  });
}
