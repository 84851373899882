import AddIcon from "@mui/icons-material/AddCircle";
import { Collapse, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState } from "react";
import { Currency, gbp } from "../i18n/Currency";
import { NamedValue, Party } from "../model/CaseModel";
import { getValueOfAssets } from "../model/CaseModelUtility";
import randomId from "../utils/randomId";
import { AssetEditorDialog } from "./AssetEditorDialog";
import { AssetList } from "./AssetList";
import { PartyCallbacks } from "./Parties";
import { PartyEditorDialog } from "./PartyEditorDialog";
import { EditAndDeleteMenu } from "./general/EditAndDeleteMenu";
import { TurningChevron } from "./general/TurningChevron";
import { YesNoDialog } from "./general/YesNoDialog";

export const PartyDetails = ({
  party,
  partyCallbacks,
  currency = gbp,
}: {
  party: Party;
  partyCallbacks: PartyCallbacks;
  currency?: Currency;
}) => {
  const [expanded, setExpanded] = useState<boolean | undefined>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newAsset, setNewAsset] = useState<NamedValue>();

  const valueOfAssets = getValueOfAssets(party);
  const assetValueLabel =
    valueOfAssets === undefined
      ? t("case.parties.assets.unlimited")
      : t("case.parties.assets.value", {
          value: currency.format(valueOfAssets),
        });
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        userSelect: "none",
      }}
      mb={2}
    >
      <TurningChevron onClick={() => setExpanded(!expanded)} turn={expanded} />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <Typography onClick={() => setExpanded(!expanded)} mr={1}>
            {t(
              party.client
                ? "case.parties.name.client"
                : "case.parties.name.noClient",
              { name: party.name }
            )}
          </Typography>

          <EditAndDeleteMenu
            onEdit={() => setEditDialogOpen(true)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
          <PartyEditorDialog
            party={party}
            open={editDialogOpen}
            onClose={(updatedParty) => {
              if (updatedParty && updatedParty.id === party.id) {
                if (updatedParty.name !== party.name) {
                  partyCallbacks.setName(party, updatedParty.name);
                }
                if (updatedParty.client !== party.client) {
                  if (updatedParty.client) partyCallbacks.setClient(party);
                  else partyCallbacks.setNonClient(party);
                }
              }
              setEditDialogOpen(false);
            }}
          />
          <YesNoDialog
            onYes={() => {
              partyCallbacks.delete(party);
              setDeleteDialogOpen(false);
            }}
            onNo={() => {
              setDeleteDialogOpen(false);
            }}
            open={deleteDialogOpen}
            title={t("case.parties.deleteDialog.title")}
            description={t("case.parties.deleteDialog.description", {
              partyName: party.name,
            })}
          />
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          onClick={() => setExpanded(!expanded)}
        >
          {assetValueLabel}
        </Typography>
        <Collapse in={expanded}>
          <Box>
            <AssetList
              assets={party.assets || []}
              currency={currency}
              onChange={(newAssets) =>
                partyCallbacks.setAssets(party, newAssets)
              }
            />
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                // create & add new asset to party
                const newAsset = {
                  id: randomId(),
                  name: "",
                  values: [{ value: 0, weight: 1 }],
                };
                setNewAsset(newAsset);
              }}
              title={`${t("case.parties.assets.tip.add")}`}
            >
              <AddIcon />
            </IconButton>
            {newAsset && (
              <AssetEditorDialog
                asset={newAsset}
                onClose={(updatedAsset) => {
                  if (updatedAsset) {
                    const updatedAssets = party.assets
                      ? [...party.assets, updatedAsset]
                      : [updatedAsset];
                    // send update upstairs
                    partyCallbacks.setAssets(party, updatedAssets);
                  }
                  setNewAsset(undefined);
                }}
                open={newAsset !== undefined}
              />
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
