import { Container } from "@mui/material";
import { useLoaderData, useNavigation } from "react-router";

import { CaseList } from "../components/CaseList";

import Loading from "../components/general/Loading";
import { Case } from "../model/CaseModel";

const GetCases = () => {
  const { cases } = useLoaderData() as { cases: Case[] };

  const navigation = useNavigation();

  if (navigation.state === "loading") {
    return <Loading />;
  }
  return (
    <Container maxWidth="md">
      <CaseList cases={cases} />
    </Container>
  );
};

export default GetCases;
