import { Box, Typography } from "@mui/material";
import "chart.js/auto";
import { t } from "i18next";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { Tabs } from "../general/Tabs";
import { byName, clientsFirst, sort } from "./AnalyticsUtility";
import { OutcomeRiskAnalysis } from "./OutcomeRiskAnalysis";

export const RiskAnalytics = () => {
  const currency = useCurrency();
  const caseContext = useCase();
  const parties = caseContext.getPartyDescriptors();
  const outcomes = caseContext.getOutcomes();

  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [parties]
  );

  const tabs: { label: string; tabComponent: React.ReactNode }[] =
    sortedParties.map((partyId) => {
      const party = parties[partyId];
      const name = party?.name || partyId;
      const outcome = outcomes.find((o) => o.partyId === partyId);
      return {
        label: t(
          party?.isClient
            ? "case.parties.name.client"
            : "case.parties.name.noClient",
          { name }
        ),
        tabComponent: outcome ? (
          <OutcomeRiskAnalysis
            outcome={outcome}
            parties={parties}
            currency={currency}
          />
        ) : (
          <Typography>
            <Trans i18nKey="case.analytics.risk.noOutcomes" values={{ name }} />
          </Typography>
        ),
      };
    });

  return (
    <Box sx={{ userSelect: "none" }}>
      <Tabs tabs={tabs} />
    </Box>
  );
};
