import {
  LiabilityIssue,
  QuantumIssue,
  Relation,
} from "./model/CaseConfiguration";

export default class IssueNode {
  private children: IssueNode[] = [];

  private defendants: string[];

  private won: boolean;

  private branchWon: boolean;

  constructor(
    public issue: LiabilityIssue,
    isWon: (issue: LiabilityIssue) => boolean,
    private parent?: IssueNode
  ) {
    this.won = isWon(issue);
    this.branchWon = this.won;

    issue.subIssues?.forEach((subIssue) =>
      this.children.push(new IssueNode(subIssue, isWon, this))
    );

    // gather defendants of issue
    const uniqueDefendants = new Set<string>();
    // issue has defendants -> use defendants in the parent as well
    // issue has no defendants -> check defendants of parent(s)
    this.issue.defendants?.forEach((defendant) =>
      uniqueDefendants.add(defendant)
    );
    this.children.forEach((child) =>
      child.defendants.forEach((defendant) => uniqueDefendants.add(defendant))
    );
    /**
     * add defendants defined in the parents' issues
     * do not use `parent.defendants` field, because:
     * - it would be wrong to include defendants of other sibling issues
     * - it would loop back to `this`
     * - may be not initialized yet
     */
    let nextParent = parent;
    while (nextParent) {
      this.branchWon = this.branchWon && nextParent.isWon();
      nextParent.issue.defendants?.forEach((defendant) =>
        uniqueDefendants.add(defendant)
      );
      nextParent = nextParent.parent;
    }

    this.defendants = [...uniqueDefendants];
  }

  /**
   * @returns true if this issue and all of its parent issues up to the root are won
   */
  isBranchWon() {
    return this.branchWon;
  }

  isWon() {
    return this.won;
  }

  isLostForDefendant(partyId: string): boolean {
    if (!this.hasDefendant(partyId)) return false;

    if (this.children.length < 1) {
      // no children, just check if claimant won = defendant lost
      return this.won;
    }
    // there are children, check liability
    if (
      this.issue.relation === Relation.Or ||
      this.issue.relation === Relation.Xor
    ) {
      // several liability
      // whether party won/lost should be determined by a sub-issue of defendant
      const subIssuesOfParty = this.children.filter((child) =>
        child.hasDefendant(partyId)
      );
      if (subIssuesOfParty.length < 1) {
        // no sub issues for party.
        console.warn(
          "Party '" +
            partyId +
            "' is defendant of several liability issue '" +
            this.issue.id +
            "' but no sub-issue for the defendant exists. Assuming win/lose status of '" +
            this.issue.id +
            "' for defendant"
        );
        // just check if claimant won = defendant lost
        return this.won;
      }
      return subIssuesOfParty.some((child) =>
        child.isLostForDefendant(partyId)
      );
    }
    // joint liability
    return this.won;
  }

  isWonForDefendant(partyId: string): boolean {
    if (!this.hasDefendant(partyId)) return false;

    if (this.children.length < 1) {
      // no children, just check if claimant lost = defendant won
      return !this.won;
    }
    // there are children, check liability
    if (
      this.issue.relation === Relation.Or ||
      this.issue.relation === Relation.Xor
    ) {
      // whether party won/lost should be determined by a sub-issue of defendant
      const subIssuesOfParty = this.children.filter((child) =>
        child.hasDefendant(partyId)
      );
      if (subIssuesOfParty.length < 1) {
        // no sub issues for party.
        console.warn(
          "Party '" +
            partyId +
            "' is defendant of several liability issue '" +
            this.issue.id +
            "' but no sub-issue for the defendant exists. Assuming win/lose status of '" +
            this.issue.id +
            "' for defendant"
        );
        // just check if claimant lost = defendant won
        return !this.won;
      }
      return subIssuesOfParty.some((child) => child.isWonForDefendant(partyId));
    }
    // joint liability
    return !this.won;
  }

  hasDefendant(partyId: string): boolean {
    return this.defendants.includes(partyId);
  }

  getDefendants(): string[] {
    return this.defendants;
  }

  getLiableDefendants(): string[] {
    return this.getDefendants().filter((defendantId) =>
      this.isLostForDefendant(defendantId)
    );
  }

  isLiabilityOf(quantum: QuantumIssue): boolean {
    if (quantum.liabilities.includes(this.issue.id)) return true;
    let nextParent = this.parent;
    while (nextParent) {
      if (quantum.liabilities.includes(nextParent.issue.id)) return true;
      nextParent = nextParent.parent;
    }
    return false;
  }

  accept(visit: (issue: IssueNode) => void) {
    visit(this);
    this.children.forEach((child) => child.accept(visit));
  }

  find(predicate: (node: IssueNode) => boolean): IssueNode | undefined {
    if (predicate(this)) return this;
    for (let i = 0; i < this.children.length; i++) {
      const found = this.children[i].find(predicate);
      if (found) return found;
    }
    return undefined;
  }

  isSetOff(): boolean {
    return this.issue.setOff === true || this.parent?.isSetOff() === true;
  }
}

export function findNodeByIssueId(
  roots: IssueNode[],
  issueId: string
): IssueNode | undefined {
  for (let i = 0; i < roots.length; i++) {
    const liabilityNode = roots[i].find((node) => node.issue.id === issueId);
    if (liabilityNode) return liabilityNode;
  }
  return undefined;
}
