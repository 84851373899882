import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Quantum } from "../../model/CaseModel";
import { YesNoDialog } from "../general/YesNoDialog";
import { Party } from "./IssueModel";
import { QuantumRow, QuantumRowCallbacks } from "./QuantumRow";

export interface QuantumEditorRowsProps {
  quantums: Quantum[];
  defendants: Party[];
  callbacks: QuantumTableCallbacks;
  claim: { title: string; ordinal: string };
  firstColSpan?: number;
}

export const QuantumEditorRows = ({
  quantums,
  defendants,
  callbacks,
  claim,
  firstColSpan = 2,
}: QuantumEditorRowsProps) => {
  const [quantumToDelete, setQuantumToDelete] = useState<Quantum | undefined>();

  const summaryColumn = defendants.length > 1;

  const quantumRowCallbacks: QuantumRowCallbacks = {
    onQuantumEditorRequested: callbacks.onQuantumEditorRequested,
    onLiabilityUpdate: (quantum, defendantId, newLiability) => {
      const newQuantum: Quantum = JSON.parse(JSON.stringify(quantum));
      if (newQuantum.liabilityShares === undefined)
        newQuantum.liabilityShares = {};
      newQuantum.liabilityShares[defendantId] = newLiability;
      callbacks.onQuantumUpdate(newQuantum);
    },
    onQuantumDelete: callbacks.onQuantumDelete,
  };
  return (
    <>
      <YesNoDialog
        onYes={() => {
          if (quantumToDelete !== undefined)
            callbacks.onQuantumDelete(quantumToDelete);
          setQuantumToDelete(undefined);
        }}
        onNo={() => setQuantumToDelete(undefined)}
        open={quantumToDelete !== undefined}
        title={t("case.claims.issues.quantums.deleteDialog.title")}
        description={t("case.claims.issues.quantums.deleteDialog.description", {
          ...quantumToDelete,
        })}
      />
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            colSpan={firstColSpan}
            rowSpan={summaryColumn ? 2 : 1}
          >
            <Typography>
              <b>Quantums</b>
            </Typography>
          </TableCell>
          {defendants.map((defendant, index) => (
            <TableCell
              align="center"
              key={"defendant_" + index}
              rowSpan={summaryColumn ? 2 : 1}
              sx={{ minWidth: 160 }}
            >
              <Typography>
                <b>{defendant.name}</b>
              </Typography>
            </TableCell>
          ))}
          {summaryColumn && (
            <TableCell align="center" key={"summary_total"} colSpan={2}>
              <Typography>
                <b>Total liability</b>
              </Typography>
            </TableCell>
          )}
        </TableRow>
        {summaryColumn && (
          <TableRow>
            <TableCell align="center" key={"summary_all"}>
              <Typography>
                <b>Most</b>
              </Typography>
            </TableCell>
            <TableCell align="center" key={"summary_any"}>
              <Typography>
                <b>Least</b>
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {quantums?.map((quantum) => (
          <QuantumRow
            key={"quantum_" + quantum.id}
            issueTitle={claim.title}
            issueOrdinal={claim.ordinal}
            quantum={quantum}
            defendants={defendants}
            callbacks={quantumRowCallbacks}
            indent={firstColSpan === 1}
            summaryColumn={summaryColumn}
          />
        ))}
      </TableBody>
    </>
  );
};

export interface QuantumTableCallbacks {
  onQuantumEditorRequested: (quantum?: Quantum) => void;
  onQuantumDelete: (quantum: Quantum) => void;
  onQuantumUpdate: (quantum: Quantum) => void;
}
