import { Info } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import DraftIcon from "@mui/icons-material/EditNote";
import OfferedIcon from "@mui/icons-material/MailLock";
import AcceptedIcon from "@mui/icons-material/MarkEmailRead";
import RejectedIcon from "@mui/icons-material/Unsubscribe";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { useCurrency } from "../../../context/CurrencyContext";
import { NamedEntity, OfferStatus } from "../../../model/CaseModel";

export interface Offer {
  id: string;
  // party offering
  offeror: NamedEntity;
  // receiving party
  offeree: NamedEntity;
  // amount to be paid
  amount: number;
  // if true, offeror pays, otherwise offeree pays
  offerorPays?: boolean;
  offerorCosts: number;
  offereeCosts: number;
  stage: Stage;
  status: OfferStatus;
  claims?: string[];
  quantums?: { title: string; value: number; apportioned: number }[];
}

function getStatusColor(status: OfferStatus, theme: Theme): string {
  switch (status) {
    case OfferStatus.Accepted:
      return theme.palette.success.main;
    case OfferStatus.Rejected:
      return theme.palette.error.main;
    case OfferStatus.Offered:
      return theme.palette.info.main;
    default:
      return theme.palette.text.secondary;
  }
}

function getIcon(status: OfferStatus): JSX.Element {
  switch (status) {
    case OfferStatus.Draft:
      return <DraftIcon fontSize="large" />;
    case OfferStatus.Offered:
      return <OfferedIcon fontSize="large" />;
    case OfferStatus.Accepted:
      return <AcceptedIcon fontSize="large" />;
    case OfferStatus.Rejected:
      return <RejectedIcon fontSize="large" />;
  }
}

const SettlementOfferCard = ({
  offer,
  onStatusChange,
  onDeleteRequested,
  onEditRequested,
  onInfoRequested,
}: {
  offer: Offer;
  onStatusChange?: (newStatus: OfferStatus) => void;
  onDeleteRequested?: () => void;
  onEditRequested?: () => void;
  onInfoRequested?: () => void;
}) => {
  const currency = useCurrency();
  const { t } = useTranslation(undefined, {
    keyPrefix: "case.analytics.settlement.offerDialog",
  });

  const theme = useTheme();

  const { offeror, offeree, amount, offerorPays, status, claims, quantums } =
    offer;

  const cardColor = getStatusColor(status, theme);

  return (
    <Card
      raised
      sx={{
        width: "fit-content",
        userSelect: "none",
        backgroundColor:
          status >= OfferStatus.Offered ? lighten(cardColor, 0.91) : undefined,
      }}
    >
      <CardHeader
        title={<span>Settlement offer by {offeror.name}</span>}
        subheader={
          (offerorPays ? offeror.name : offeree.name) +
          " to pay " +
          currency.format(amount)
        }
        action={
          <>
            {onInfoRequested && (
              <IconButton
                aria-label="Results"
                title="Results"
                onClick={onInfoRequested}
              >
                <Info />
              </IconButton>
            )}
            {onEditRequested && (
              <IconButton
                aria-label="Edit offer"
                title="Edit offer"
                onClick={onEditRequested}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        }
        avatar={
          <Typography color={cardColor} display="flex">
            {getIcon(status)}
          </Typography>
        }
        sx={{ textAlign: "justify" }}
      />
      <CardContent sx={{ textAlign: "justify", py: 0 }}>
        <Typography variant="overline">Settled claims</Typography>
        {claims && claims.length > 0 ? (
          <List key="claims" dense sx={{ py: 0 }}>
            {claims.map((claim, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemText primary={claim} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box pl={theme.spacing(2)}>
            <Typography variant="caption" color={theme.palette.error.main}>
              No settled claims configured
            </Typography>
          </Box>
        )}
        <Typography variant="overline">Settled quantums</Typography>
        {quantums && quantums.length > 0 ? (
          <List key="quantums" dense sx={{ py: 0 }}>
            {quantums.map((quantum, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemText
                  primary={quantum.title}
                  secondary={`${currency.format(quantum.apportioned)} of ${currency.format(quantum.value)}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box pl={theme.spacing(2)}>
            <Typography variant="caption" color={theme.palette.error.main}>
              No settled quantums configured
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions>
        <ToggleButtonGroup
          color="primary"
          exclusive
          aria-label="Platform"
          value={status}
          sx={{ justifyContent: "center" }}
          onChange={(_, status) => {
            if (typeof status !== "number") return;
            if (!onStatusChange) return;
            onStatusChange(status);
          }}
          disabled={!onStatusChange}
        >
          <ToggleButton value={OfferStatus.Draft}>
            {t("status.draft")}
          </ToggleButton>
          <ToggleButton value={OfferStatus.Offered} color="info">
            {t("status.offered")}
          </ToggleButton>
          <ToggleButton value={OfferStatus.Rejected} color="error">
            {t("status.rejected")}
          </ToggleButton>
          <ToggleButton value={OfferStatus.Accepted} color="success">
            {t("status.accepted")}
          </ToggleButton>
        </ToggleButtonGroup>
        {onDeleteRequested && (
          <IconButton
            aria-label="Delete offer"
            title="Delete offer"
            onClick={onDeleteRequested}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default SettlementOfferCard;
