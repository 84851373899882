import React, { useContext } from "react";
import { Currency, gbp } from "../i18n/Currency";

export interface CurrencyContextProps {
  currency: Currency;
}

export const CurrencyContext = React.createContext<Currency>(gbp);

// provides the case context based on case loaded by react-router's loader
export const CurrencyContextProvider = ({
  children,
  currency,
}: {
  children: JSX.Element | JSX.Element[];
  currency: Currency;
}) => {
  return (
    <CurrencyContext.Provider value={currency}>
      {children}
    </CurrencyContext.Provider>
  );
};

export function useCurrency() {
  return useContext(CurrencyContext);
}
