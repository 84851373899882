import { Alert, Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { Outcome } from "../../analytics/api/Analytics";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { PartyDescriptors } from "../../model/CaseModel";
import { TwoLiner } from "../general/TwoLiner";
import { byName, clientsFirst, sort } from "./AnalyticsUtility";
import { PartyValue, PerPartyBarChart } from "./PerPartyBarChart";

function initializeData(
  outcomes: Outcome[],
  parties: PartyDescriptors
): PartyValue[] {
  if (outcomes.length === 0) return [];

  const data: PartyValue[] = outcomes.map((outcome) => {
    const party = parties[outcome.partyId];
    return {
      partyId: outcome.partyId,
      partyName: party.name,
      client: party.isClient || false,
      value: outcome.netResult,
    };
  });
  return data;
}

export const NetResultAnalytics = () => {
  const currency = useCurrency();
  const caseContext = useCase();
  const parties = caseContext.getPartyDescriptors();
  const outcomes = caseContext.getOutcomes();

  // defines order in charts etc.
  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [parties]
  );

  const netResultData: PartyValue[] = useMemo(
    () => initializeData(outcomes, parties),
    [outcomes, parties]
  );
  if (netResultData.length === 0)
    return (
      <Typography>
        <Trans i18nKey={"case.analytics.netResult.noData"} />
      </Typography>
    );

  let numberOfJudgments = 0;
  outcomes.forEach(
    (outcome) =>
      (numberOfJudgments = Math.max(
        numberOfJudgments,
        outcome.judgments.length
      ))
  );

  const [selectedParty, setSelectedParty] = useState(
    sortedParties.length > 0 ? sortedParties[0] : undefined
  );

  const netResultOfSelectedParty = selectedParty
    ? netResultData.find((p) => p.partyId === selectedParty)?.value || 0
    : 0;

  return (
    <Box sx={{ userSelect: "none" }}>
      <Typography>
        <Trans
          i18nKey="case.analytics.netResult.description"
          values={{ count: numberOfJudgments }}
        />
      </Typography>
      {selectedParty && (
        <Stack direction="row" spacing={2} mb={2} mt={2}>
          <TwoLiner
            primary={currency.format(netResultOfSelectedParty)}
            secondary={parties[selectedParty].name}
          />
          {netResultOfSelectedParty < 0 && (
            <Alert severity="warning">
              <Trans
                i18nKey="case.analytics.netResult.warning.negative"
                values={{ name: parties[selectedParty].name }}
              />
            </Alert>
          )}
        </Stack>
      )}
      <Box mt={2} sx={{ userSelect: "none" }}>
        <PerPartyBarChart
          data={netResultData}
          label={t("case.analytics.netResult.label")}
          onSelected={setSelectedParty}
          formatValue={currency.format}
          selected={selectedParty}
        />
      </Box>
    </Box>
  );
};
