import Big from "big.js";
import { min, ZERO } from "../../big/bigUtil";
import { Decision, DecisionNode, EndNode, TreeNode } from "../../tree/tree";
import { currency } from "../model/CaseConfiguration";
import { Item, Payer, Payment, PaymentPlan } from "./Payment";

export class PossibleItemPayment {
  constructor(
    public itemId: string,
    public itemPrice: Big,
    public maxPayerAmount: Big,
    public payer: Payer
  ) {}

  asRootNode(): PaymentNode {
    return new PaymentNode(this.itemId, this.itemPrice, {
      payerId: this.payer.payerId,
      amount:
        this.payer.availableAmount !== undefined
          ? min(this.payer.availableAmount, this.maxPayerAmount)
          : this.maxPayerAmount,
    });
  }
}

export class PaymentNode {
  private children: PaymentNode[] = [];
  constructor(
    public readonly itemId: string,
    private readonly itemPrice: Big,
    public readonly payment: Payment,
    private readonly parent?: PaymentNode
  ) {}

  addChild(itemId: string, itemPrice: Big, payment: Payment): PaymentNode {
    const child = new PaymentNode(itemId, itemPrice, payment, this);
    this.children.push(child);
    return child;
  }

  getAmountSpentPerItem(): Map<string, Big> {
    const amountsPerItem = new Map([[this.itemId, this.payment.amount]]);

    let node: PaymentNode | undefined = this.parent;
    while (node !== undefined) {
      const amountAlreadySpent = amountsPerItem.get(node.itemId);
      amountsPerItem.set(
        node.itemId,
        amountAlreadySpent
          ? amountAlreadySpent.add(node.payment.amount)
          : node.payment.amount
      );
      node = node.parent;
    }
    return amountsPerItem;
  }

  description(allItems: Item[]): string {
    let description = "Paid amounts:";
    const amountsPerItem = this.getAmountSpentPerItem();
    allItems.forEach((item) => {
      const amountSpent = amountsPerItem.get(item.id);
      const itemPaidFully = amountSpent && amountSpent.gte(item.price);
      description += `<br/>${item.id}: ${currency.format(
        (amountSpent ? amountSpent : ZERO).toNumber()
      )} of ${currency.format(item.price.toNumber())} ${
        itemPaidFully ? "✔" : "✖"
      }`;
    });
    return description;
  }

  asTree(allItems: Item[]): TreeNode {
    if (this.children.length === 0) {
      let itemsCost = ZERO;
      allItems.forEach((item) => (itemsCost = itemsCost.add(item.price)));
      return new DecisionNode(this.description(allItems), [
        new Decision(
          `Total paid`,
          new EndNode(-itemsCost),
          // create balance 0 if all items were paid, otherwise set balance to missing amount
          this.getAmountSpent().mul(-2).add(itemsCost).toNumber()
        ),
      ]);
    }
    return new DecisionNode(
      this.description(allItems),
      this.children.map((child) => {
        return new Decision(
          `${child.payment.payerId} pays for ${child.itemId}`,
          child.asTree(allItems),
          child.payment.amount.toNumber()
        );
      })
    );
  }

  /**
   * Recursively grows this node by branching into a set of given optional payments. For each payment a new child is added to this node.
   * Each of the new children is then recursively grown with the remaining other optional payments.
   *
   * @param possiblePayments to expand as children nodes
   */
  grow(possiblePayments: PossibleItemPayment[]) {
    if (possiblePayments.length < 1) return;

    const applicablePayments = possiblePayments.filter((possiblePayment) => {
      // filter out payments for items that are already completely paid for
      const amountAlreadyPaidForItem = this.getAmountPaidFor(
        possiblePayment.itemId
      );
      const amountLeftToPay = possiblePayment.itemPrice.minus(
        amountAlreadyPaidForItem
      );
      // item already paid completely or is there anything left to pay?
      return amountLeftToPay.gt(0);
    });

    console.debug(
      `${applicablePayments.length} / ${possiblePayments.length} payments can be paid`
    );
    if (applicablePayments.length === 0) return;

    possiblePayments.forEach((possiblePayment) => {
      // filter out payments for items that are already completely paid for
      const amountAlreadyPaidForItem = this.getAmountPaidFor(
        possiblePayment.itemId
      );
      const amountLeftToPay = possiblePayment.itemPrice.minus(
        amountAlreadyPaidForItem
      );
      // item already paid completely or is there anything left to pay?
      if (amountLeftToPay.lte(0)) return;

      // filter out payments of payers with no more money
      const amountPayableByPayer = this.getAmountPayable(possiblePayment);
      if (amountPayableByPayer.lte(0)) {
        console.debug(
          `Payer '${possiblePayment.payer.payerId}' has no funds to pay ${possiblePayment.maxPayerAmount} for '${possiblePayment.itemId}'`
        );
        // payer has no more money
        return;
      }
      console.debug(
        `${amountAlreadyPaidForItem} of ${possiblePayment.itemPrice} already paid for ${possiblePayment.itemId}, ${amountPayableByPayer} payable by ${possiblePayment.payer.payerId}`
      );

      // create payment child node
      const child = this.addChild(
        possiblePayment.itemId,
        possiblePayment.itemPrice,
        {
          amount: min(amountLeftToPay, amountPayableByPayer),
          payerId: possiblePayment.payer.payerId,
        }
      );

      console.debug(
        `Payer '${possiblePayment.payer.payerId}' pays ${child.payment.amount} for '${possiblePayment.itemId}'`
      );
      // for each possibility create a sub-tree and grow it with the other possibilities
      child.grow(
        applicablePayments.filter((element) => element !== possiblePayment)
      );
    });
  }

  getAmountPayable(possiblePayment: PossibleItemPayment): Big {
    if (!possiblePayment.payer.availableAmount) {
      // payer has no asset limit, pay maximum allowed for item
      return possiblePayment.maxPayerAmount;
    }
    // check if payer still has money
    const amountAlreadySpentByPayer = this.getAmountSpentBy(
      possiblePayment.payer.payerId
    );
    const amountAvailable = possiblePayment.payer.availableAmount.minus(
      amountAlreadySpentByPayer
    );
    if (amountAvailable.lte(0)) {
      // payer has no more money
      return ZERO;
    }
    return min(amountAvailable, possiblePayment.maxPayerAmount);
  }

  getAmountPaidFor(itemId: string): Big {
    const amountInParents = this.parent
      ? this.parent.getAmountPaidFor(itemId)
      : ZERO;
    return this.itemId === itemId
      ? this.payment.amount.add(amountInParents)
      : amountInParents;
  }

  getAmountSpent(): Big {
    const amountInParents = this.parent ? this.parent.getAmountSpent() : ZERO;
    return this.payment.amount.add(amountInParents);
  }

  getAmountSpentBy(payerId: string): Big {
    const amountInParents = this.parent
      ? this.parent.getAmountSpentBy(payerId)
      : ZERO;
    return this.payment.payerId === payerId
      ? this.payment.amount.add(amountInParents)
      : amountInParents;
  }

  fillPaymentPlans(plans: PaymentPlan[]) {
    if (this.children.length === 0) {
      // we are a leaf, construct the plan
      const plan = new PaymentPlan();
      plan.add(this.itemId, this.itemPrice, this.payment);
      // populate the plan with payments from this leaf up to root
      let node: PaymentNode | undefined = this.parent;
      while (node !== undefined) {
        plan.add(node.itemId, node.itemPrice, node.payment);
        node = node.parent;
      }
      plans.push(plan);
      return;
    }
    // not leaf, go to leaf children
    this.children.forEach((child) => child.fillPaymentPlans(plans));
  }
}
