import PlusIcon from "@mui/icons-material/Add";
import UnlimitedIcon from "@mui/icons-material/AllInclusive";
import MinusIcon from "@mui/icons-material/Remove";
import ResetIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableContainer,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Big from "big.js";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { ZERO, max } from "../../../big/bigUtil";
import { useCase } from "../../../context/CaseContext";
import { useCurrency } from "../../../context/CurrencyContext";
import { BargainingData, Configuration } from "../../../model/CaseModel";
import { MonetaryDeltaThreeLiner } from "../../MonetaryDeltaThreeLiner";
import { CollapsibleBox } from "../../general/CollapsibleBox";
import { CurrencyTextField } from "../../general/CurrencyTextField";
import { TabPanel } from "../../general/TabPanel";
import { ClaimWinChanceEditor } from "../../issues/ClaimWinChanceEditor";
import { percentage } from "../../issues/Claims";
import { Claim } from "../../issues/IssueModel";
import { BargainingLineChart } from "./BargainingLineChart";
import { areEqualWinChances, extract, flattenClaims } from "./Configuration";

export const BargainingAnalytics = () => {
  const caseContext = useCase();
  const c = caseContext.getCase();
  const parties = caseContext.getPartyDescriptors();
  const baselineOutcomes = caseContext.getOutcomes();
  if (baselineOutcomes.length === 0) {
    return (
      <Typography>
        <Trans i18nKey={"case.analytics.settlement.noOutcomes"} />
      </Typography>
    );
  }

  const submit = useSubmit();
  const submitVariant = async (newVariant: Configuration) => {
    newVariant.timestamp = Date.now();
    await submit(
      {
        variant: JSON.stringify(newVariant),
      },
      { method: "POST" }
    );
  };

  // tab controls
  const [configurationTab, setConfigurationTab] = useState(0);
  const handleConfigurationTabChange = (
    _: React.SyntheticEvent,
    newValue: number
  ) => {
    setConfigurationTab(newValue);
  };
  const [partyTab, setPartyTab] = useState(0);
  const handlePartyTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setPartyTab(newValue);
  };

  const baseline = useMemo(() => extract(flattenClaims(c)), [c]);

  const bargainingConfiguration = c.variants?.bargaining;
  if (!bargainingConfiguration)
    return (
      <Typography>
        <Trans i18nKey="case.analytics.settlement.noData" />
      </Typography>
    );
  const bargainingResults = caseContext.getVariantResults(
    bargainingConfiguration.id
  );
  if (!bargainingResults)
    return (
      <Typography>
        <Trans i18nKey="case.analytics.settlement.noData" />
      </Typography>
    );

  const bargainingData: BargainingData[] =
    bargainingResults.getBargainingData() ?? [];

  const theme = useTheme();
  const currency = useCurrency();
  const isSmallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ userSelect: "none" }}>
      <Typography key="description" variant="body1" color="textSecondary">
        <Trans i18nKey="case.analytics.settlement.bargaining.description" />
      </Typography>
      <Tabs
        value={configurationTab}
        onChange={handleConfigurationTabChange}
        aria-label="variant configuration"
      >
        <Tab
          key={"configuration_tab_winChance"}
          label={t(
            "case.analytics.settlement.bargaining.configurationTabs.winChance"
          )}
          id={"configuration_tab_winChance"}
        />
        <Tab
          key={"configuration_tab_costs"}
          label={t(
            "case.analytics.settlement.bargaining.configurationTabs.costs"
          )}
          id={"configuration_tab_costs"}
        />
        <Tab
          key={"configuration_tab_assets"}
          label={t(
            "case.analytics.settlement.bargaining.configurationTabs.assets"
          )}
          id={"configuration_tab_assets"}
        />
      </Tabs>
      <TabPanel
        key={"configuration_tabPanel_winChance"}
        currentTabIndex={configurationTab}
        index={0}
      >
        {c.claims?.map((claim, index) => {
          const winChance = bargainingConfiguration.winChances[claim.id];
          const baselineWinChance = baseline.winChances[claim.id];
          const cardClaim: Claim = {
            id: claim.id,
            title: claim.title,
            // strip to id & name only
            claimant: { id: claim.claimant.id, name: claim.claimant.name },
            defendants:
              claim.defendants
                ?.filter((d) => c.parties?.some((p) => p.id === d.id))
                .map((d) => ({ id: d.id, name: d.name })) ?? [],
            issues: [],
            generic: winChance?.generic,
            genericWinChance: winChance?.genericWinChance,
            winChanceAgainst: winChance?.perDefendantWinChance,
            quantums: [],
          };
          return (
            <Paper elevation={3} key={index}>
              <CollapsibleBox title={index + 1 + ". Claim: " + claim.title}>
                <Typography variant="body2">
                  {t("case.claims.claimant", { ...claim.claimant })}
                </Typography>
                <Typography variant="body2">
                  <Trans
                    i18nKey="case.claims.defendants"
                    values={{
                      names: cardClaim.defendants
                        .map((d) => d.name)
                        .join(" & "),
                    }}
                    count={cardClaim.defendants.length}
                  />
                </Typography>

                <Box display="flex" maxWidth="fit-content" height="max-content">
                  <TableContainer>
                    <Table size="small" sx={{ overflow: "hidden" }}>
                      <ClaimWinChanceEditor
                        claim={cardClaim}
                        defendants={cardClaim.defendants}
                        key={bargainingConfiguration.timestamp}
                        callbacks={{
                          onGenericWinChanceUpdated: async (newChance) => {
                            const newVariant: Configuration = JSON.parse(
                              JSON.stringify(bargainingConfiguration)
                            );
                            newVariant.winChances[claim.id].genericWinChance =
                              newChance;
                            await submitVariant(newVariant);
                          },
                          onWinChanceUpdated: async (
                            defendantId,
                            newChance
                          ) => {
                            const newVariant: Configuration = JSON.parse(
                              JSON.stringify(bargainingConfiguration)
                            );
                            newVariant.winChances[
                              claim.id
                            ].perDefendantWinChance[defendantId] = newChance;
                            await submitVariant(newVariant);
                          },
                        }}
                        hideSummary
                      />
                    </Table>
                  </TableContainer>
                </Box>
                <Button
                  variant="text"
                  title={t(
                    "case.analytics.settlement.bargaining.buttons.reset.winChance.tooltip"
                  )}
                  startIcon={<ResetIcon />}
                  disabled={areEqualWinChances(winChance, baselineWinChance)}
                  onClick={async () => {
                    const copy: Configuration = JSON.parse(
                      JSON.stringify(bargainingConfiguration)
                    );
                    copy.winChances[claim.id] = JSON.parse(
                      JSON.stringify(baselineWinChance)
                    );
                    await submitVariant(copy);
                  }}
                >
                  <Trans i18nKey="case.analytics.settlement.bargaining.buttons.reset.winChance.label" />
                </Button>
              </CollapsibleBox>
            </Paper>
          );
        })}
      </TabPanel>
      <TabPanel
        key={"configuration_tabPanel_costs"}
        currentTabIndex={configurationTab}
        index={1}
      >
        <Paper elevation={3} sx={{ p: theme.spacing(2) }}>
          <Stack spacing={theme.spacing(2)}>
            {Object.keys(bargainingConfiguration.costs).map(
              (partyId, index) => {
                const cost = bargainingConfiguration.costs[partyId] ?? 0;
                const baseValue = new Big(baseline.costs[partyId] ?? 0);

                const stepPercentage = 0.1;
                const step = baseValue.mul(stepPercentage);

                const delta = baseValue.neg().add(cost).toNumber();
                const party = parties[partyId];
                const partyName = party?.name || partyId;
                return (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{
                      alignItems: "baseline",
                      userSelect: "none",
                      mt: 2,
                    }}
                    spacing={theme.spacing(isSmallDisplay ? 1 : 2)}
                  >
                    <Typography minWidth={theme.spacing(4)}>
                      {partyName}
                    </Typography>
                    {!isSmallDisplay && (
                      <Button
                        key="minus_button"
                        variant="outlined"
                        disabled={step.eq(0) || cost <= 0}
                        startIcon={<MinusIcon />}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.costs[partyId] = max(
                            step.neg().add(cost),
                            ZERO
                          ).toNumber();
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.minus.costs.tooltip",
                          {
                            name: parties[partyId].name,
                            step: currency.format(step.toNumber()),
                          }
                        )}
                      >
                        {percentage.format(stepPercentage)}
                      </Button>
                    )}
                    <CurrencyTextField
                      key={bargainingConfiguration.timestamp}
                      label={
                        delta === 0
                          ? t(
                              "case.analytics.settlement.bargaining.valueLabels.unchanged"
                            )
                          : delta > 0
                            ? `+${currency.format(delta)}`
                            : `${currency.format(delta)}`
                      }
                      currency={currency}
                      onValueChange={async (newValue) => {
                        const newConfiguration: Configuration = JSON.parse(
                          JSON.stringify(bargainingConfiguration)
                        );
                        newConfiguration.costs[partyId] = newValue;
                        await submitVariant(newConfiguration);
                      }}
                      value={cost}
                      sx={{ maxWidth: theme.spacing(20) }}
                    />
                    {!isSmallDisplay && (
                      <Button
                        key="plus_button"
                        startIcon={<PlusIcon />}
                        variant="outlined"
                        disabled={step.eq(0)}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.costs[partyId] = step
                            .add(cost)
                            .toNumber();
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.plus.costs.tooltip",
                          {
                            name: parties[partyId].name,
                            step: currency.format(step.toNumber()),
                          }
                        )}
                      >
                        {percentage.format(stepPercentage)}
                      </Button>
                    )}
                    {delta !== 0 && (
                      <IconButton
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.costs[partyId] =
                            baseValue.toNumber();
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.reset.costs.tooltip",
                          { name: parties[partyId].name }
                        )}
                      >
                        <ResetIcon />
                      </IconButton>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        </Paper>
      </TabPanel>
      <TabPanel
        key={"configuration_tabPanel_assets"}
        currentTabIndex={configurationTab}
        index={2}
      >
        <Paper elevation={3} sx={{ p: theme.spacing(2) }}>
          <Stack spacing={theme.spacing(2)}>
            {Object.keys(bargainingConfiguration.assets).map(
              (partyId, index) => {
                const baseValue = baseline.assets[partyId];
                const assetValue = bargainingConfiguration.assets[partyId];

                const changed = baseValue !== assetValue;
                const label = !changed
                  ? t(
                      "case.analytics.settlement.bargaining.valueLabels.unchanged"
                    )
                  : baseValue === null
                    ? t(
                        "case.analytics.settlement.bargaining.valueLabels.limited"
                      )
                    : assetValue === null
                      ? t(
                          "case.analytics.settlement.bargaining.valueLabels.unlimited"
                        )
                      : assetValue - baseValue > 0
                        ? `+${currency.format(assetValue - baseValue)}`
                        : `${currency.format(assetValue - baseValue)}`;
                const partyName = parties[partyId].name;

                const stepPercentage = 0.1;
                const step = new Big(baseline.assets[partyId] ?? 1000000).mul(
                  stepPercentage
                );

                return (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{
                      alignItems: "baseline",
                      userSelect: "none",
                      mt: 2,
                    }}
                    spacing={theme.spacing(isSmallDisplay ? 1 : 2)}
                  >
                    <Typography minWidth={theme.spacing(4)}>
                      {partyName}
                    </Typography>
                    {!isSmallDisplay && (
                      <Button
                        key="minus_button"
                        variant="outlined"
                        disabled={step.eq(0)}
                        startIcon={<MinusIcon />}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          const newValue =
                            assetValue === null
                              ? step.neg().add(baseValue ?? 0)
                              : step.neg().add(assetValue ?? 0);
                          newConfiguration.assets[partyId] = max(
                            newValue,
                            ZERO
                          ).toNumber();
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.minus.assets.tooltip",
                          {
                            name: parties[partyId].name,
                            step: currency.format(step.toNumber()),
                          }
                        )}
                      >
                        {percentage.format(stepPercentage)}
                      </Button>
                    )}
                    <CurrencyTextField
                      key={bargainingConfiguration.timestamp}
                      label={label}
                      currency={currency}
                      onValueChange={async (newValue) => {
                        const newConfiguration: Configuration = JSON.parse(
                          JSON.stringify(bargainingConfiguration)
                        );
                        newConfiguration.assets[partyId] = newValue;
                        await submitVariant(newConfiguration);
                      }}
                      value={assetValue ?? undefined}
                      sx={{ maxWidth: theme.spacing(20) }}
                    />
                    {!isSmallDisplay && (
                      <Button
                        key="plus_button"
                        variant="outlined"
                        startIcon={<PlusIcon />}
                        disabled={step.eq(0) || assetValue === undefined}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          const newValue =
                            assetValue === null
                              ? step.add(baseValue ?? 0)
                              : step.add(assetValue ?? 0);
                          newConfiguration.assets[partyId] =
                            newValue.toNumber();
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.plus.assets.tooltip",
                          {
                            name: parties[partyId].name,
                            step: currency.format(step.toNumber()),
                          }
                        )}
                      >
                        {percentage.format(stepPercentage)}
                      </Button>
                    )}
                    {isSmallDisplay ? (
                      <IconButton
                        key="unlimited_button"
                        disabled={step.eq(0)}
                        color={assetValue === null ? "primary" : "default"}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.assets[partyId] = null;
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.unlimited.assets.tooltip",
                          { name: parties[partyId].name }
                        )}
                      >
                        <UnlimitedIcon />
                      </IconButton>
                    ) : (
                      <Button
                        key="unlimited_button"
                        variant={assetValue === null ? "contained" : "outlined"}
                        startIcon={<UnlimitedIcon />}
                        disabled={step.eq(0)}
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.assets[partyId] = null;
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.unlimited.assets.tooltip",
                          { name: parties[partyId].name }
                        )}
                      >
                        {t(
                          "case.analytics.settlement.bargaining.buttons.unlimited.assets.label"
                        )}
                      </Button>
                    )}
                    {changed && (
                      <IconButton
                        onClick={async () => {
                          const newConfiguration: Configuration = JSON.parse(
                            JSON.stringify(bargainingConfiguration)
                          );
                          newConfiguration.assets[partyId] = baseValue;
                          await submitVariant(newConfiguration);
                        }}
                        title={t(
                          "case.analytics.settlement.bargaining.buttons.reset.assets.tooltip",
                          { name: parties[partyId].name }
                        )}
                      >
                        <ResetIcon />
                      </IconButton>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        </Paper>
      </TabPanel>
      <Button
        variant="text"
        title={t(
          "case.analytics.settlement.bargaining.buttons.reset.all.tooltip"
        )}
        startIcon={<ResetIcon />}
        onClick={async () =>
          await submitVariant(JSON.parse(JSON.stringify(baseline)))
        }
      >
        <Trans i18nKey="case.analytics.settlement.bargaining.buttons.reset.all.label" />
      </Button>
      <Divider sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }} />
      <Tabs
        value={partyTab}
        onChange={handlePartyTabChange}
        aria-label="settlements per party"
      >
        {bargainingData.map(({ partyId }, index) => {
          const party = parties[partyId];
          return (
            <Tab
              key={"settlement_tab_" + index}
              label={t(
                party?.isClient
                  ? "case.parties.name.client"
                  : "case.parties.name.noClient",
                { name: party.name }
              )}
              id={"settlement_party_" + index}
            />
          );
        })}
      </Tabs>

      {bargainingData.map(({ partyId, opponents }, index) => {
        const { name } = parties[partyId];
        return (
          <TabPanel
            key={"tab_" + index}
            currentTabIndex={partyTab}
            index={index}
          >
            <Typography sx={{ mb: theme.spacing(2) }}>
              <Trans
                i18nKey="case.analytics.settlement.description"
                values={{ name }}
              />
            </Typography>
            <Stack direction="column" gap={theme.spacing(2)}>
              {Object.keys(opponents).map((opponentId, index) => {
                const { baseline: partyRanges, opponentRanges } =
                  opponents[opponentId];
                const range = partyRanges.perStage[0].range;
                const range2 = opponentRanges.perStage[0].range;
                return (
                  <Paper elevation={4} key={index} sx={{ p: theme.spacing(2) }}>
                    <Typography variant="h6" color="textSecondary">
                      <Trans
                        i18nKey="case.analytics.settlement.titleSettlementChart"
                        values={{
                          name,
                          other: parties[opponentId]?.name || opponentId,
                        }}
                      />
                    </Typography>
                    <Box mt={theme.spacing(1)} mb={theme.spacing(2)}>
                      <Stack spacing={theme.spacing(4)} direction="row">
                        <MonetaryDeltaThreeLiner
                          label={t(
                            "case.analytics.settlement.bargaining.evOfParty",
                            { name }
                          )}
                          value={range2.partyEv}
                          delta={range2.partyEv - range.partyEv}
                        />
                        <MonetaryDeltaThreeLiner
                          label={t(
                            "case.analytics.settlement.bargaining.evOfParty",
                            { name: parties[opponentId]?.name || opponentId }
                          )}
                          value={-range2.opponentEv}
                          delta={-range2.opponentEv + range.opponentEv}
                        />
                        <MonetaryDeltaThreeLiner
                          label={t(
                            "case.analytics.settlement.bargaining.midpoint"
                          )}
                          value={range2.mid}
                          delta={range2.mid - range.mid}
                        />
                      </Stack>
                    </Box>
                    <BargainingLineChart
                      rangeOne={partyRanges}
                      rangeOneLabel={t(
                        "case.analytics.settlement.bargaining.chart.baseline"
                      )}
                      rangeTwo={opponentRanges || partyRanges}
                      rangeTwoLabel={t(
                        "case.analytics.settlement.bargaining.chart.variant"
                      )}
                      onClick={(x, y) => console.log(x, y)}
                    />
                  </Paper>
                );
              })}
            </Stack>
          </TabPanel>
        );
      })}
    </Box>
  );
};
