import { ChanceNode, DecisionNode, EndNode, SubGraph, TreeNode } from "../tree";

const currency: Intl.NumberFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 2,
});

const START = `digraph G {
  graph [ rankdir = "LR"];
  node [shape=box];
  labelloc="t";
`;

export function generateDotGraph(
  subGraphs: SubGraph[] | SubGraph,
  title: string
): string {
  const nodeIds = new Map();
  let subgraphIndex = 0;
  const graphs: SubGraph[] =
    subGraphs instanceof Array ? subGraphs : [subGraphs];
  return (
    START +
    `label="${title}";` +
    graphs
      .reverse()
      .map((caseGraph) => {
        return (
          `subgraph cluster_${subgraphIndex++}` +
          ` { label="${caseGraph.title}";` +
          caseGraph.trees
            .map(
              (tree) =>
                traverseNodes(tree, nodeIds, 0) +
                traverseBranches(tree, nodeIds, new Set())
            )
            .join(" ") +
          "}"
        );
      })
      .join(" ") +
    "}"
  );
}

function getNodeId(node: TreeNode, nodeIds: Map<TreeNode, string>): string {
  let nodeId: string | undefined = nodeIds.get(node);
  if (nodeId === undefined) {
    nodeId = `n${nodeIds.size}`;
    nodeIds.set(node, nodeId);
  }
  return nodeId;
}

function traverseNodes(
  node: TreeNode,
  nodeIds: Map<TreeNode, string>,
  balance: number
): string {
  const existingNodeId: string | undefined = nodeIds.get(node);
  // check if already visited
  if (existingNodeId) return "";

  // node not generated yet
  const nodeId = `n${nodeIds.size}`;
  nodeIds.set(node, nodeId);

  if (node instanceof EndNode)
    return `${nodeId}[shape=oval fillcolor=${
      balance < 0 ? '"#FF7266"' : '"#89D38A"'
    } style=filled label=<Net result<br/><b>${currency.format(balance)}</b>>];`;

  if (node instanceof ChanceNode) {
    let output = `\n${nodeId}[shape=hexagon label=<EV: ${currency.format(
      node.getEmv()
    )}<br/>Balance: ${currency.format(balance)}>];`;
    node.chances.forEach((chance) => {
      output += traverseNodes(chance.node, nodeIds, balance);
    });
    return output;
  }

  if (node instanceof DecisionNode) {
    let output = `\n${nodeId}[style=rounded label=<${
      node.name
    }<br/>EV: ${currency.format(node.getEmv())}<br/>Balance: ${currency.format(
      balance
    )}>];`;
    node.decisions.forEach((decision) => {
      output += traverseNodes(decision.node, nodeIds, balance - decision.cost);
    });
    return output;
  }

  return `${nodeId}[label="?"];`;
}

function traverseBranches(
  node: TreeNode,
  nodeIds: Map<TreeNode, string>,
  alreadyVisited: Set<string>
): string {
  const nodeId: string | undefined = nodeIds.get(node);
  // unknown node
  if (!nodeId) return "";
  // node already visited
  if (alreadyVisited.has(nodeId)) return "";
  // mark as visited
  alreadyVisited.add(nodeId);

  // no branches to traverse
  if (node instanceof EndNode) return "";

  if (node instanceof DecisionNode) {
    let output = "";
    node.decisions.forEach((decision) => {
      output += `\n${nodeId} -> ${getNodeId(decision.node, nodeIds)}[label=<${
        decision.name
      }${
        decision.cost !== 0 ? "<br/>" + currency.format(decision.cost) : ""
      }>];`;
      output += traverseBranches(decision.node, nodeIds, alreadyVisited);
    });
    return output;
  }
  if (node instanceof ChanceNode) {
    let output = "";
    node.chances.forEach((chance) => {
      output += `\n${nodeId} -> ${getNodeId(
        chance.node,
        nodeIds
      )}[label=<${percentage.format(chance.probability)}<br/>${chance.name}>];`;
      output += traverseBranches(chance.node, nodeIds, alreadyVisited);
    });
    return output;
  }
  return "";
}
