import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { NamedEntity } from "../../model/CaseModel";

export interface CopyRequest {
  readonly sourcePartyId: string;
  readonly scaling: number;
}

export interface CostCopyDialogProps {
  target: string;
  sources?: NamedEntity[];
  onClose: (copy?: CopyRequest) => void;
  open: boolean;
}

export const CostCopyDialog = ({
  target,
  sources = [],
  onClose,
  open,
}: CostCopyDialogProps) => {
  const [sourcePartyId, setSourcePartyId] = useState<string>();
  const handleSourceChange = (
    event: SelectChangeEvent<typeof sourcePartyId>
  ) => {
    const {
      target: { value },
    } = event;
    setSourcePartyId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value : undefined
    );
  };

  const canSubmit = sourcePartyId !== undefined;

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.budget.costCopyDialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          <Trans
            i18nKey="case.budget.costCopyDialog.description"
            values={{ target }}
          />
        </DialogContentText>
        <DialogContentText mb={2}>
          <Trans i18nKey="case.budget.costCopyDialog.source.description" />
        </DialogContentText>
        <FormControl
          key={"source-selection"}
          fullWidth
          sx={{ mt: 2, minWidth: 220 }}
          error={sourcePartyId === undefined}
        >
          <InputLabel id="source-select-label">
            <Trans i18nKey="case.budget.costCopyDialog.source.label" />
          </InputLabel>
          <Select
            disabled={sources.length === 0}
            label={`${t("case.budget.costCopyDialog.source.label")}`}
            onChange={handleSourceChange}
            labelId="source-select-label"
            value={sourcePartyId === undefined ? "" : sourcePartyId}
            renderValue={(selected) =>
              sources.find((p) => selected === p.id)?.name
            }
          >
            {sources.map((party) => (
              <MenuItem key={party.id} value={party.id}>
                <ListItemText primary={party.name} />
              </MenuItem>
            ))}
          </Select>
          {sourcePartyId === undefined && (
            <FormHelperText>
              {t("case.budget.costCopyDialog.source.empty")}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          <Trans i18nKey="case.budget.costCopyDialog.cancel" />
        </Button>
        <Button
          onClick={() => {
            if (sourcePartyId === undefined) {
              onClose();
              return;
            }
            onClose({ sourcePartyId, scaling:1 });
          }}
          variant="contained"
          disabled={!canSubmit}
        >
          <Trans i18nKey="case.budget.costCopyDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
