import { ONE, ZERO } from "../big/bigUtil";
import IssueNode, { findNodeByIssueId } from "./IssueNode";
import Scenario from "./Scenario";
import {
  Case,
  CostApportionment,
  IssueScenario,
  LiabilityIssue,
  Party,
  QuantumIssue,
  Share,
  currency,
} from "./model/CaseConfiguration";

export function getParty(c: Case, id: string): Party | undefined {
  return c.parties?.find((party) => party.id === id);
}

export function getLiabilityIssue(
  c: Case,
  id: string
): LiabilityIssue | undefined {
  const liabilityIssue = c.liabilityIssues?.find(
    (liabilityIssue) =>
      liabilityIssue.id === id ||
      liabilityIssue.subIssues?.find((issue) => issue.id === id) !== undefined
  );
  if (liabilityIssue === undefined) return undefined;
  if (liabilityIssue.id === id) return liabilityIssue;
  return liabilityIssue.subIssues?.find((issue) => issue.id === id);
}

export function getRootLiabilityIssue(
  c: Case,
  id: string
): LiabilityIssue | undefined {
  if (c.liabilityIssues === undefined || c.liabilityIssues.length < 1)
    return undefined;

  for (let index = 0; index < c.liabilityIssues.length; index++) {
    const rootIssue = c.liabilityIssues[index];
    // is it sub-issue of this root?
    if (getSubIssue(c.liabilityIssues[index], id) !== undefined)
      return rootIssue;
  }
  return undefined;
}

function getSubIssue(
  issue: LiabilityIssue,
  subIssueId: string
): LiabilityIssue | undefined {
  if (issue.id === subIssueId) return issue;
  if (issue.subIssues === undefined || issue.subIssues.length < 1)
    return undefined;
  for (let index = 0; index < issue.subIssues.length; index++) {
    const subIssue = getSubIssue(issue.subIssues[index], subIssueId);
    if (subIssue !== undefined) return subIssue;
  }
  return undefined;
}

function getClaimants(c: Case, liabilityIssueId: string): Party[] {
  const claimants: Party[] = [];

  c.quantumIssues?.forEach((quantumIssue) => {
    if (!quantumIssue.liabilities.includes(liabilityIssueId)) return;
    const party = getParty(c, quantumIssue.claimant);
    if (party) claimants.push(party);
  });
  return claimants;
}

// return quantum issues referencing a liability issue
export function getQuantumIssuesOfLiability(
  c: Case,
  liabilityIssueId: string
): QuantumIssue[] {
  const quantumIssues = c.quantumIssues?.filter((quantumIssue) =>
    quantumIssue.liabilities.includes(liabilityIssueId)
  );
  return quantumIssues || [];
}

export function isCounterClaim(
  quantumIssue: QuantumIssue,
  liabilityIssue: LiabilityIssue | undefined
): boolean {
  return areSame([quantumIssue.claimant], liabilityIssue?.defendants);
}

/**
 * Returns unique array of defendant IDs of a quantum. Detects counter-claims.
 *
 * @param c case
 * @param quantumIssueId identifier of issue to check
 * @returns party identifiers
 */
export function getDefendantIds(c: Case, quantumIssueId: string): string[] {
  const quantumIssue = c.quantumIssues?.find(
    (issue) => issue.id === quantumIssueId
  );
  if (quantumIssue === undefined) {
    console.warn("Unknown quantum issue " + quantumIssueId);
    return [];
  }

  // use IssueNode to assure defendants are resolved in layered several / joint liabilities
  const liabilityRoots =
    c.liabilityIssues?.map(
      (liabilityIssue) => new IssueNode(liabilityIssue, () => false)
    ) || [];

  const defendantIds = new Set<string>();
  quantumIssue.liabilities?.forEach((liabilityIssueId) => {
    const liabilityNode = findNodeByIssueId(liabilityRoots, liabilityIssueId);
    if (liabilityNode === undefined) {
      console.warn(
        `Unknown liability issue '${liabilityIssueId}' referenced in quantum issue '${quantumIssue}'`
      );
      return;
    }
    if (isCounterClaim(quantumIssue, liabilityNode.issue)) {
      // defendants of liability have a quantum => counter claim
      // return all other claimants of all other quantums associated with this liability issue
      getQuantumIssuesOfLiability(c, liabilityIssueId).forEach(
        (quantumIssue) => {
          if (!liabilityNode.getDefendants().includes(quantumIssue.claimant))
            defendantIds.add(quantumIssue.claimant);
        }
      );
      return;
    }

    liabilityNode.getDefendants().forEach((id) => defendantIds.add(id));
  });

  return [...defendantIds];
}

/**
 * Returns the total claim value per party of a case.
 *
 * @param c case
 * @returns party identifiers mapped to total value of all claims
 */
export function getMaximumClaimsPerParty(c: Case): Map<string, number> {
  const claims = new Map<string, number>();

  // each quantum issue may weigh in on each of the quantum's defendants
  c.quantumIssues?.forEach((quantumIssue) => {
    // add claim amount to each defendant of quantum issue
    getDefendantIds(c, quantumIssue.id).forEach((defendantId) => {
      const defendantClaim = claims.get(defendantId);
      if (defendantClaim === undefined) {
        claims.set(defendantId, quantumIssue.value);
      } else {
        claims.set(defendantId, defendantClaim + quantumIssue.value);
      }
    });
  });

  return claims;
}

export function describeCase(
  c: Case,
  newLine: (line: string) => void = (line) => console.debug(line)
): void {
  newLine("Case: " + c.title);
  if (c.claimType) newLine("Claim type: " + c.claimType);
  if (c.parties) {
    newLine("Parties:");
    c.parties.forEach((party) => newLine("\t" + party.name));
  }
  if (c.liabilityIssues) {
    newLine("Liability Issues:");
    c.liabilityIssues.forEach((issue) => {
      newLine(issue.title);
      issue.subIssues?.forEach((subIssue) => newLine("\t" + subIssue.title));
      const claimants = new Set(
        getClaimants(c, issue.id).map((claimant) => claimant.id)
      );
      const counterClaimants = new Set<string>();
      issue.defendants?.forEach((defendantId) => {
        if (claimants.delete(defendantId)) counterClaimants.add(defendantId);
      });
      newLine(
        "\t" +
          [...claimants].map((id) => getParty(c, id)?.name) +
          " against " +
          issue.defendants?.map((id) => getParty(c, id)?.name)
      );
      if (counterClaimants.size > 0) {
        newLine(
          "\tWith counterclaim by " +
            [...counterClaimants].map((id) => getParty(c, id)?.name)
        );
      }
    });
  }
  if (c.quantumIssues) {
    newLine("Quantum Issues:");
    c.quantumIssues.forEach((issue) => {
      newLine(issue.title);
      newLine(
        "\t" +
          getParty(c, issue.claimant)?.name +
          " claim " +
          currency.format(issue.value)
      );
      newLine(
        "\tbased on liability issues: " +
          issue.liabilities?.map((id) => getLiabilityIssue(c, id)?.title)
      );
      newLine("\n");
    });
  }
  getMaximumClaimsPerParty(c).forEach((value, key) =>
    newLine(
      "Maximum claim against " +
        getParty(c, key)?.name +
        " is " +
        currency.format(value)
    )
  );
}

export function removeParties(
  scenario: Scenario,
  partyIds: string[]
): Scenario {
  let newScenario = scenario;
  partyIds.forEach((partyId) => {
    newScenario = newScenario.removeParty(partyId);
  });
  return newScenario;
}

export function isCovered(
  c: Case,
  smaller: IssueScenario,
  larger: IssueScenario
): boolean {
  // get roots of larger
  const largerWon = getRootLiabilitiesWon(c, larger);
  const largerLost = getRootLiabilitiesLost(c, larger);

  // smaller cannot contradict any value in larger
  // check smaller's won issues
  const wonIssuesCompatible =
    smaller.issuesWon === undefined ||
    smaller.issuesWon.every((issueId) => {
      if (larger.issuesWon && larger.issuesWon.includes(issueId)) {
        // larger also specifies issue as won
        return true;
      }
      if (larger.issuesLost && larger.issuesLost.includes(issueId)) {
        // larger defines issue as lost, contradiction
        return false;
      }
      // larger does not specify the issue at all, check root issue
      const rootWonIssue = getRootLiabilityIssue(c, issueId);
      return rootWonIssue && largerWon.includes(rootWonIssue.id);
    });
  if (!wonIssuesCompatible) {
    return false;
  }

  // now check smaller's lost issues
  return (
    smaller.issuesLost === undefined ||
    smaller.issuesLost.every((issueId) => {
      if (larger.issuesLost && larger.issuesLost.includes(issueId)) {
        // larger also specifies issue as lost
        return true;
      }
      if (larger.issuesWon && larger.issuesWon.includes(issueId)) {
        // larger defines issue as won, contradiction
        return false;
      }
      // larger does not specify the issue at all, must check root issue
      const rootLostIssue = getRootLiabilityIssue(c, issueId);
      return rootLostIssue && largerLost.includes(rootLostIssue.id);
    })
  );
}

/**
 * Checks if two arrays contain same elements (although not necessarily in same order).
 *
 * @param array1 first array
 * @param array2 second array
 * @returns true if both arrays are undefined or include the same elements (regardless of order)
 */
export function areSame<t>(
  array1: t[] | undefined,
  array2: t[] | undefined,
  comparator?: (e1: t, e2: t) => boolean
): boolean {
  if (array1 === undefined) {
    return array2 === undefined;
  }
  if (array2 === undefined || array1.length != array2.length) {
    return false;
  }
  if (comparator) {
    return array1.every((element1) =>
      array2.some((element2) => comparator(element1, element2))
    );
  }
  return array1.every((element) => array2.includes(element));
}

export function getRootLiabilitiesLost(
  c: Case,
  outcome: IssueScenario | undefined
): string[] {
  if (outcome === undefined) return [];
  const lostRootLiabilities = new Set<string>();
  outcome.issuesLost?.map((lostIssue) => {
    const rootIssueId = getRootLiabilityIssue(c, lostIssue)?.id;
    if (rootIssueId !== undefined) lostRootLiabilities.add(rootIssueId);
  });
  return [...lostRootLiabilities];
}

export function getRootLiabilitiesWon(
  c: Case,
  outcome: IssueScenario | undefined
): string[] {
  if (outcome === undefined) return [];
  const wonRootLiabilities = new Set<string>();
  outcome.issuesWon?.map((wonIssue) => {
    const rootIssueId = getRootLiabilityIssue(c, wonIssue)?.id;
    if (rootIssueId !== undefined) wonRootLiabilities.add(rootIssueId);
  });
  // now remove any root issues lost in the outcome (due to lost sub-issues)
  getRootLiabilitiesLost(c, outcome).forEach((lostRootIssue) =>
    wonRootLiabilities.delete(lostRootIssue)
  );
  return [...wonRootLiabilities];
}

/**
 * Checks if two scenarios have the same won/lost issue IDs. Please note that this method does not check the semantical equality of a case outcome for both scenarios.
 * The comparison is based solely on the sameness (element order of the respective ID arrays does not matter) of the issue IDs of the won/lost issues.
 *
 * @param outcome1 first scenario to compare
 * @param outcome2 second scenario to compare
 * @returns true if both hold same won/lost issue IDs
 */
export function areSameScenarios(
  outcome1: IssueScenario,
  outcome2: IssueScenario
) {
  if (outcome1 === outcome2) {
    return true;
  }
  if (outcome1 === undefined || outcome2 === undefined) {
    return false;
  }
  return (
    areSame(outcome1.issuesWon, outcome2.issuesWon) &&
    areSame(outcome1.issuesLost, outcome2.issuesLost)
  );
}

export function getResultingApportionment(
  debtors: string[],
  configuration?: CostApportionment
): Share[] {
  if (
    configuration === undefined ||
    configuration.costApportionment.length === 0
  )
    // all debtors equally liable
    return debtors.map((partyId) => ({ partyId, percentage: 1 }));

  let total = ZERO;
  const configuredPerParty: { [partyId: string]: number } = {};
  configuration.costApportionment.forEach((share) => {
    total = total.add(share.percentage ?? 1);
    configuredPerParty[share.partyId] = share.percentage ?? 1;
  });

  const resultingShares = debtors.map((partyId) => ({
    partyId,
    percentage: configuredPerParty[partyId] ?? 0,
  }));

  if (total.lt(1)) {
    // add generic part to all shares
    const generic = total.lt(1) ? ONE.minus(total) : ZERO;
    resultingShares.forEach(
      (share) => (share.percentage = generic.add(share.percentage).toNumber())
    );
  }

  return resultingShares;
}
