import { Claim, Issue, NamedEntity, Quantum } from "../../model/CaseModel";

export function splitMultiDefendantClaims(
  claims: Claim[]
): SingleDefendantClaim[] {
  const splitClaims: SingleDefendantClaim[] = [];
  claims.forEach((claim) =>
    splitMultiDefendantClaim(claim).forEach((splitClaim) =>
      splitClaims.push(splitClaim)
    )
  );
  return splitClaims;
}

export interface SingleDefendantClaim extends Claim {
  groupClaimId?: string;
}

export function splitMultiDefendantClaim(claim: Claim): SingleDefendantClaim[] {
  // is it a multi-defendant claim?
  if (claim.defendants === undefined || claim.defendants.length < 2)
    return [claim];

  const defendantClaims: SingleDefendantClaim[] = [];

  for (let i = 0; i < claim.defendants.length; i++) {
    const defendant = claim.defendants[i];
    const winChance = claim.genericWinChance;
    const winChanceVDefendant = claim.perDefendantWinChance
      ? claim.perDefendantWinChance[defendant.id]
      : undefined;
    const isLeaf = claim.issues === undefined || claim.issues.length === 0;
    if (isLeaf && !claim.generic && winChanceVDefendant === undefined) {
      // neither generic nor defendant is involved
      break;
    }
    const winChanceAgainst =
      winChanceVDefendant !== undefined
        ? { [defendant.id]: winChanceVDefendant }
        : undefined;

    const defendantClaim: SingleDefendantClaim = {
      id: `${claim.id}_v_${defendant.id}`,
      groupClaimId: claim.id,
      title: `${claim.title} v ${defendant.name}`,
      claimant: claim.claimant,
      defendants: [{ id: defendant.id, name: defendant.name }],
      counterToClaim: claim.counterToClaim,
      relation: claim.relation,
      setOff: claim.setOff,
      perDefendantWinChance: winChanceAgainst,
      genericWinChance: winChance,
      generic: claim.generic,
      quantums: getQuantumsOfDefendant(defendant.id, claim.quantums),
      issues: splitIssues(defendant, claim.issues),
    };
    defendantClaims.push(defendantClaim);
  }

  return defendantClaims;
}

function splitIssues(
  defendant: NamedEntity,
  issues?: Issue[]
): Issue[] | undefined {
  if (issues === undefined) return;
  const defendantIssues: Issue[] = [];
  issues.forEach((issue) => {
    const winChance = issue.genericWinChance;
    const winChanceVDefendant = issue.perDefendantWinChance
      ? issue.perDefendantWinChance[defendant.id]
      : undefined;
    const isLeaf =
      issue.subIssues === undefined || issue.subIssues.length === 0;
    if (isLeaf && !issue.generic && winChanceVDefendant === undefined) {
      // neither generic nor defendant is involved
      return;
    }
    const winChanceAgainst =
      winChanceVDefendant !== undefined
        ? { [defendant.id]: winChanceVDefendant }
        : undefined;
    const issueVDefendant: Issue = {
      id: `${issue.id}_v_${defendant.id}`,
      title: `${issue.title} v ${defendant.name}`,
      defendants: [{ id: defendant.id, name: defendant.name }],
      relation: issue.relation,
      generic: issue.generic,
      perDefendantWinChance: winChanceAgainst,
      genericWinChance: winChance,
      quantums: getQuantumsOfDefendant(defendant.id, issue.quantums),
      subIssues: splitIssues(defendant, issue.subIssues),
    };
    defendantIssues.push(issueVDefendant);
  });
  return defendantIssues.length > 0 ? defendantIssues : undefined;
}

function getQuantumsOfDefendant(
  defendantId: string,
  quantums?: Quantum[]
): Quantum[] | undefined {
  const quantumsOfDefendant: Quantum[] = [];
  quantums?.forEach((quantum) => {
    const liablePercentage =
      quantum.liabilityShares !== undefined
        ? quantum.liabilityShares[defendantId]
        : 1;

    quantumsOfDefendant.push({
      // ID and name remain same, it is the same quantum
      // just make the defendant liable
      id: `${quantum.id}`,
      name: `${quantum.name}`,
      awardedShares: quantum.awardedShares,
      value: quantum.value,
      liabilityShares: { [defendantId]: liablePercentage },
    });
  });

  return quantumsOfDefendant.length > 0 ? quantumsOfDefendant : undefined;
}
