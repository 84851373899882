import React from "react";
import {
  NumericFormatProps,
  InputAttributes,
  NumericFormat,
} from "react-number-format";

export interface Currency {
  symbol: string;
  format: (value: number) => string;
  createNumericFormat: (
    min?: number,
    max?: number
  ) => React.ForwardRefExoticComponent<
    CustomProps & React.RefAttributes<NumericFormatProps<InputAttributes>>
  >;
}

const gbpFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const gbp: Currency = {
  symbol: "£",
  format: (value) => gbpFormat.format(value),
  createNumericFormat(min, max) {
    return createFormat(this.symbol, min, max);
  },
};

// live formatting for text input fields
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function createFormat(
  symbol: string,
  min?: number,
  max?: number
): React.ForwardRefExoticComponent<
  CustomProps & React.RefAttributes<NumericFormatProps<InputAttributes>>
> {
  return React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          valueIsNumericString
          prefix={symbol}
          allowLeadingZeros={false}
          decimalScale={2}
          min={min}
          max={max}
          isAllowed={(values) => {
            const { floatValue } = values;
            if (floatValue === undefined) return true;
            if (min !== undefined && floatValue < min) return false;
            if (max !== undefined && floatValue > max) return false;
            return true;
          }}
          fixedDecimalScale
        />
      );
    }
  );
}
