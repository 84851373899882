import { Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { Currency, gbp } from "../i18n/Currency";
import { TwoLiner } from "./general/TwoLiner";

export interface CostSummaryValues {
  totalCosts: number;
  incurredCosts: number;
  estimatedCosts: number;
  courtFees: number;
  lawyersCosts: number;
}

export const CostSummary = ({
  values,
  currency = gbp,
  onClick,
}: {
  values: CostSummaryValues;
  currency?: Currency;
  onClick?: () => void;
}) => {
  const theme = useTheme();
  const isSmallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack direction="row" spacing={3}>
      <TwoLiner
        key={"total_cost"}
        primary={t("case.costs.total")}
        secondary={currency.format(values.totalCosts)}
        onClick={onClick}
      />
      <TwoLiner
        key={"incurred_cost"}
        primary={t("case.costs.incurred")}
        secondary={currency.format(values.incurredCosts)}
        onClick={onClick}
      />
      <TwoLiner
        key={"estimated"}
        primary={t("case.costs.budgeted")}
        secondary={currency.format(values.estimatedCosts)}
        onClick={onClick}
      />
      {!isSmallDisplay && (
        <>
          <Divider orientation="vertical" flexItem variant="middle" />
          <TwoLiner
            key={"lawyers"}
            primary={t("case.costs.lawyers")}
            secondary={currency.format(values.lawyersCosts)}
            onClick={onClick}
          />
          <TwoLiner
            key={"fees"}
            primary={t("case.costs.courtFees")}
            secondary={currency.format(values.courtFees)}
            onClick={onClick}
          />
        </>
      )}
    </Stack>
  );
};
