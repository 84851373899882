import { Box, IconButton, Zoom } from "@mui/material";
import { useEffect, useState } from "react";

import MoreIcon from "@mui/icons-material/MoreVert";

export interface MenuItem {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
}

export const ZoomOutButtonMenu = ({
  openToTheLeft = false,
  items,
}: {
  openToTheLeft?: boolean;
  items: MenuItem[];
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // make sure the edit menu closes after some time
  useEffect(() => {
    if (menuOpen) {
      const closeEditMenu = setTimeout(() => setMenuOpen(false), 5000);
      return () => clearTimeout(closeEditMenu);
    }
  }, [menuOpen]);
  return (
    <Box sx={{ ml: openToTheLeft ? 2 : 0, mr: !openToTheLeft ? 2 : 0 }}>
      {!openToTheLeft && (
        <IconButton size="small" onClick={() => setMenuOpen(!menuOpen)}>
          <MoreIcon fontSize="inherit" />
        </IconButton>
      )}
      {items.map((item, index) => (
        <Zoom key={index} in={menuOpen}>
          <IconButton
            size="small"
            disabled={item.onClick === undefined}
            onClick={() => {
              setMenuOpen(false);
              if (item.onClick !== undefined) item.onClick();
            }}
            title={item.title}
          >
            {item.icon}
          </IconButton>
        </Zoom>
      ))}
      {openToTheLeft && (
        <IconButton size="small" onClick={() => setMenuOpen(!menuOpen)}>
          <MoreIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};
