import Big from "big.js";
import { Cost, CostType, Share } from "./model/CaseConfiguration";

class Apportionment {
  constructor(private readonly apportionment: Share[]) {}

  getMaxAmountPerPayer(amount: number): { [payerId: string]: Big } {
    const perPayerAmounts: { [payerId: string]: Big } = {};
    const amountBig = new Big(amount);
    for (const share of this.apportionment) {
      perPayerAmounts[share.partyId] = amountBig.mul(share.percentage ?? 1);
    }
    return perPayerAmounts;
  }
}

export class ApportionmentCalculator {
  private readonly lawyersCosts: Apportionment;
  private readonly courtFees: Apportionment;
  constructor(
    lawyersCostsApportionment: Share[],
    courtFeesApportionment: Share[]
  ) {
    this.lawyersCosts = new Apportionment(lawyersCostsApportionment);
    this.courtFees = new Apportionment(courtFeesApportionment);
  }

  getApportionedAmounts(cost: Cost): { [partyId: string]: Big } {
    const perPartyAmounts: { [payerId: string]: Big } = {};
    if (cost.apportionment && cost.apportionment.length) {
      // cost-specific apportionment is split equally amount parties
      const amountPerParty = new Big(cost.amount).div(
        cost.apportionment?.length
      );
      cost.apportionment.forEach((partyId) => {
        perPartyAmounts[partyId] = amountPerParty;
      });
      return perPartyAmounts;
    }

    // non-cost-specific apportionment
    switch (cost.type) {
      case CostType.LawyerCosts: {
        return this.lawyersCosts.getMaxAmountPerPayer(cost.amount);
      }
      case CostType.CourtFees: {
        return this.courtFees.getMaxAmountPerPayer(cost.amount);
      }
      default: {
        console.error("Cannot apportion unknown cost type", cost);
        return {};
      }
    }
  }
}
