import { ChartData, Point } from "chart.js";

/**
 * @param chartData chart data to check datasets of
 * @returns maximum absolute value of all datasets
 */
export function getMaxAbsoluteValue(
  chartData: ChartData<"line" | "bar", (number | Point)[]>
): number {
  let absMax = 0;
  chartData.datasets.forEach((dataset) =>
    dataset.data.forEach(
      (value) =>
        (absMax = Math.max(
          absMax,
          Math.abs(typeof value === "number" ? value : value.y)
        ))
    )
  );
  return absMax;
}
