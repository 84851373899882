import Big from "big.js";
import { ONE, ZERO } from "../../big/bigUtil";
import { IssueRelation, Quantum } from "../../model/CaseModel";

export interface Party {
  id: string;
  name: string;
}

export interface Issue {
  id: string;
  title: string;
  relation: IssueRelation;
  // if set, issue is a generic one and defendant win chances shall be discarded
  generic?: boolean;
  genericWinChance?: number;
  // only in effect when genericWinChance not set
  winChanceAgainst: { [defendantId: string]: number };
}

export interface IssueGroup {
  id: string;
  subIssues: Issue[];
  relation: IssueRelation;
}

export interface Claim {
  id: string;
  title: string;
  claimant: Party;
  defendants: Party[];
  issues: (Issue | IssueGroup)[];
  quantums?: Quantum[];
  // for claims without issues
  generic?: boolean;
  genericWinChance?: number;
  // only in effect when genericWinChance not set
  winChanceAgainst?: { [defendantId: string]: number };
}

export function isGroup(element: unknown | IssueGroup): element is IssueGroup {
  return (element as IssueGroup).subIssues !== undefined;
}

export function isIssue(element: Issue | IssueGroup): element is Issue {
  return (element as Issue).winChanceAgainst !== undefined;
}

export function getWinChance(
  issue: Issue | IssueGroup,
  defendantId: string
): Big | undefined {
  if (isIssue(issue)) {
    const winChance = issue.generic
      ? issue.genericWinChance ?? 0
      : issue.winChanceAgainst[defendantId];
    return winChance === undefined ? undefined : new Big(winChance);
  }

  // group
  const winChances = issue.subIssues
    .map((i) => getWinChance(i, defendantId))
    .filter((p) => p !== undefined)
    .map((p) => new Big(p ?? 0));
  switch (issue.relation) {
    case IssueRelation.And:
      return and(winChances);
    case IssueRelation.Or:
      return or(winChances);
    case IssueRelation.Xor:
      return xor(winChances);
  }
}

function getRelation(i: Issue | IssueGroup): IssueRelation {
  if (isIssue(i)) return i.relation;

  const { subIssues } = i;
  // last sub-issue of group determines relation to next element
  return subIssues.length
    ? subIssues[subIssues.length - 1].relation
    : IssueRelation.And;
}

export function getOverallWinChance(
  issues: (Issue | IssueGroup)[],
  defendantId: string
): Big | undefined {
  if (issues.length === 0) return ZERO;

  let pWin = getWinChance(issues[0], defendantId);
  let relation = getRelation(issues[0]);
  for (let index = 1; index < issues.length; index++) {
    const nextIssue = issues[index];
    switch (relation) {
      case IssueRelation.And:
        pWin = and([pWin, getWinChance(nextIssue, defendantId)]);
        break;
      case IssueRelation.Or:
        pWin = or([pWin, getWinChance(nextIssue, defendantId)]);
        break;
      case IssueRelation.Xor:
        pWin = xor([pWin, getWinChance(nextIssue, defendantId)]);
        break;
    }
    relation = getRelation(nextIssue);
  }
  return pWin;
}

export function getIssueWinChance(
  issue: {
    generic?: boolean;
    genericWinChance?: number;
    // only in effect when genericWinChance not set
    winChanceAgainst?: { [defendantId: string]: number };
  },
  defendants: Party[],
  vAll = false
): Big | undefined {
  if (defendants.length === 0) return undefined;
  if (issue.generic)
    return issue.genericWinChance !== undefined
      ? new Big(issue.genericWinChance)
      : ZERO;

  // get vAll/vAny for liable defendants
  const winChances: (Big | undefined)[] = [];
  defendants.forEach((d) => {
    const winChance =
      issue.winChanceAgainst === undefined
        ? undefined
        : issue.winChanceAgainst[d.id];
    if (winChance === undefined) return;
    winChances.push(new Big(winChance));
  });
  return vAll ? and(winChances) : or(winChances);
}

export function getIssueGroupWinChance(
  issueGroup: IssueGroup,
  defendants: Party[],
  vAll = false
): Big | undefined {
  if (defendants.length === 0) return undefined;

  // get vAll/vAny for liable defendants
  const winChances = issueGroup.subIssues.map((subIssue) => {
    const subIssueWinChance = getIssueWinChance(subIssue, defendants, vAll);
    return subIssueWinChance !== undefined
      ? new Big(subIssueWinChance)
      : undefined;
  });
  switch (issueGroup.relation) {
    case IssueRelation.And:
      return and(winChances);
    case IssueRelation.Or:
      return or(winChances);
    case IssueRelation.Xor:
      return xor(winChances);
  }
}

export function getIssuesWinChance(
  issues: (Claim | Issue | IssueGroup)[],
  defendants: Party[],
  vAll = false
): number | undefined {
  if (defendants.length === 0) return undefined;

  const winChances = issues.map((i) => {
    return isGroup(i)
      ? getIssueGroupWinChance(i, defendants, vAll)
      : getIssueWinChance(i, defendants, vAll);
  });

  return and(winChances)?.toNumber();
}

function or(probabilities: (Big | undefined)[]): Big | undefined {
  // calculate probability of loss
  let pLoss: Big | undefined;
  probabilities.forEach((p) => {
    if (p !== undefined)
      pLoss = pLoss === undefined ? ONE.minus(p) : ONE.minus(p).mul(pLoss);
  });
  // invert p(loss) to get p(win)
  return pLoss === undefined ? undefined : ONE.minus(pLoss);
}

function and(probabilities: (Big | undefined)[]): Big | undefined {
  if (probabilities.length < 1) return undefined;
  let pWin: Big | undefined;
  probabilities.forEach((p) => {
    if (p !== undefined) pWin = pWin === undefined ? p : pWin.mul(p);
  });
  return pWin;
}

function xor(probabilities: (Big | undefined)[]): Big | undefined {
  // sum of all possible wins
  let pWin: Big | undefined;
  probabilities.forEach((p) => {
    if (p !== undefined) pWin = pWin === undefined ? p : pWin.add(p);
  });
  return pWin === undefined ? undefined : pWin.gt(ONE) ? ONE : pWin;
}
