import Big from "big.js";
import { min, ONE, ZERO } from "../../big/bigUtil";
import { percentage } from "../model/CaseConfiguration";
import { getMandatoryPayments, Item } from "./Payment";

export interface Part {
  readonly price: Big;

  getShare(payerId: string): number;

  getNumberOfPayers(): number;

  isPayer(payerId: string): boolean;

  hasAnyPayer(): boolean;

  isOnlyPayer(payerId: string): boolean;

  /**
   * Simulates a situation in which given payer pays a given amount.
   * @param payerId id of the payer
   * @param amount offered to pay
   * @return paid part, or undefined if no payment was done
   */
  pay(payerId: string, amount: Big): Part | undefined;

  accept(payerShareVisitor: (payerId: string, share: Big) => void): void;
}

class SinglePayerPart implements Part {
  constructor(
    readonly price: Big,
    private payer: string
  ) {}

  accept(payerShareVisitor: (payerId: string, share: Big) => void): void {
    payerShareVisitor(this.payer, ONE);
  }

  hasAnyPayer(): boolean {
    return true;
  }

  getShare(payerId: string): number {
    return this.payer === payerId ? 1 : 0;
  }

  getNumberOfPayers(): number {
    return 1;
  }

  isPayer(payerId: string): boolean {
    return this.isOnlyPayer(payerId);
  }

  isOnlyPayer(payerId: string): boolean {
    return payerId === this.payer;
  }

  pay(payerId: string, amount: Big): Part | undefined {
    if (!this.isPayer(payerId)) return undefined;
    if (amount.lte(0)) return undefined;

    if (amount.gte(this.price)) return this;

    // partial payment
    return new SinglePayerPart(amount, payerId);
  }
  toString(): string {
    return `SinglePayerPart[price=${this.price.toNumber()},payer='${
      this.payer
    }']`;
  }
}

class UnpaidPart implements Part {
  constructor(readonly price: Big) {}
  accept(): void {
    // no payer shares to visit
  }
  getShare(): number {
    return 0;
  }
  getNumberOfPayers(): number {
    return 0;
  }
  isPayer(): boolean {
    return false;
  }
  isOnlyPayer(): boolean {
    return false;
  }
  pay(): undefined {
    return undefined;
  }
  hasAnyPayer(): boolean {
    return false;
  }
  toString(): string {
    return `UnpaidPart[price=${this.price.toNumber()}]`;
  }
}

class MultiPayerPart implements Part {
  constructor(
    readonly price: Big,
    private readonly maxSharePerPayer: Map<string, Big>
  ) {}
  accept(payerShareVisitor: (payerId: string, share: Big) => void): void {
    for (const [payer, maxShare] of this.maxSharePerPayer) {
      payerShareVisitor(payer, maxShare);
    }
  }

  isPayer(payerId: string) {
    return this.maxSharePerPayer.has(payerId);
  }
  isOnlyPayer(): boolean {
    return false;
  }
  getShare(payerId: string): number {
    const maxShare = this.maxSharePerPayer.get(payerId);
    return maxShare ? maxShare.toNumber() : 0;
  }

  getNumberOfPayers(): number {
    return this.maxSharePerPayer.size;
  }

  pay(payerId: string, amount: Big): Part | undefined {
    if (amount.lte(0)) return undefined;
    const maxShare = this.maxSharePerPayer.get(payerId);
    if (maxShare === undefined || maxShare.lte(0)) return undefined;

    const maxPayerAmount = this.price.mul(maxShare);
    const amountPaid = min(min(amount, maxPayerAmount), this.price);
    if (amountPaid.lte(0)) {
      // nothing to pay
      return undefined;
    }

    return new SinglePayerPart(amountPaid, payerId);
  }
  hasAnyPayer(): boolean {
    return true;
  }

  toString(): string {
    return `MultiPayerPart[price=${this.price.toNumber()},maxSharePerPayer='${[
      ...this.maxSharePerPayer,
    ]}']`;
  }
}

export function createPartsForItem(item: Item): Part[] {
  const maxPaymentPerPayer: Map<string, Big> = new Map();

  for (const [payerId, maxShare] of item.maxSharePerPayer) {
    if (maxShare.lte(0)) continue;
    maxPaymentPerPayer.set(payerId, min(maxShare, ONE).mul(item.price));
  }
  return createParts(new Big(item.price), maxPaymentPerPayer);
}

/**
 * Given a price and a table of payer-ID mapped to the maximum amount that the given payer can pay,
 * creates Parts that shall be paid for by the payers in order to pay the full price.
 * There are 3 types of parts:
 * - unpaid (nobody can pay for, e.g. if there are two payers, each able to pay only 30% of the price, then 40% remains unpaid)
 * - single-payer (only one payer can pay for, making them mandatory for that payer, e.g. when there are two payers, each paying 50%, there are two single-payer parts, one for each payer)
 * - multi-payer (more than one payer can pay for it, e.g. when there are 5 payers, each able to pay 50% of the price, there is no way of determining who will pay)
 *
 * @param price of the item
 * @param maxPaymentPerPayer table of payer-ID mapped to the maximum amount that the given payer can pay
 * @returns list of parts, in sum covering 100% of the price
 */
export function createParts(
  price: Big,
  maxPaymentPerPayer: Map<string, Big>
): Part[] {
  if (price.lte(0)) return [];
  const mandatoryPayments = getMandatoryPayments(price, maxPaymentPerPayer);

  console.debug(
    `Constructing parts for ${price} with maximum payments ${[
      ...maxPaymentPerPayer.entries(),
    ]} and mandatory payments ${[...mandatoryPayments.entries()]}`
  );

  const parts: Part[] = [];
  // construct parts
  let optionalPaymentsTotal = price;
  for (const [payerId, mandatoryPayment] of mandatoryPayments) {
    if (mandatoryPayment.lte(0)) continue;

    // store single-payer part for mandatory payment
    parts.push(new SinglePayerPart(mandatoryPayment, payerId));
    optionalPaymentsTotal = optionalPaymentsTotal.minus(mandatoryPayment);
  }

  if (optionalPaymentsTotal.lte(0)) {
    // mandatory payments cover whole item
    return parts;
  }

  // mandatory payments do not cover whole item
  const optionalPaymentsPerPayer: Map<string, Big> = new Map();
  let maxPaymentsTotal = ZERO;
  for (const [payerId, maxPayerPayment] of maxPaymentPerPayer) {
    maxPaymentsTotal = maxPaymentsTotal.plus(maxPayerPayment);

    // for each payer: their optionalPayerPayment = maxPayment - mandatoryPayment
    const mandatoryPayerPayment = mandatoryPayments.get(payerId);
    const optionalPayerPayment =
      mandatoryPayerPayment === undefined
        ? maxPayerPayment
        : maxPayerPayment.minus(mandatoryPayerPayment);

    if (optionalPayerPayment.lte(0)) {
      // mandatory payment equals maximum payment. Payer can't pay more
      continue;
    }

    // store remaining share of payer
    // also, cap the payments with the remaining total of item
    optionalPaymentsPerPayer.set(
      payerId,
      min(optionalPayerPayment, optionalPaymentsTotal)
    );
  }

  // check if item can be paid full (i.e. if sum of max payments covers price)
  let multiPayerPart = optionalPaymentsTotal;
  if (maxPaymentsTotal.lt(price)) {
    // 1 - maxSharesTotal
    const unpaidAmount = price.minus(maxPaymentsTotal);
    // there is unpaid part
    console.debug(
      `${percentage.format(
        unpaidAmount.div(price).toNumber()
      )} (${unpaidAmount.toNumber()}) has no payers`
    );
    parts.push(new UnpaidPart(unpaidAmount));

    // shared multi-payer part = price - mandatoryTotal - unpaidAmount
    multiPayerPart = optionalPaymentsTotal.minus(unpaidAmount);
  }

  // are there any payers capable of paying?
  if (optionalPaymentsPerPayer.size <= 0) return parts;
  // only one payer remaining, use single-payer part to simplify
  if (optionalPaymentsPerPayer.size === 1) {
    for (const [payerId, optionalPayerPayment] of optionalPaymentsPerPayer)
      parts.push(new SinglePayerPart(optionalPayerPayment, payerId));
    return parts;
  }

  // more than one payer remaining
  // scale remaining shares per payer to remaining part size
  for (const [payerId, optionalPayerPayment] of optionalPaymentsPerPayer) {
    optionalPaymentsPerPayer.set(
      payerId,
      optionalPayerPayment.div(multiPayerPart)
    );
  }
  parts.push(new MultiPayerPart(multiPayerPart, optionalPaymentsPerPayer));
  return parts;
}
