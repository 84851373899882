import Collapsed from "@mui/icons-material/ChevronRight";
import Expanded from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { PartyDescriptors } from "../../model/CaseModel";
import { getCostsPerStage } from "../../model/CaseModelUtility";
import { Budget, OptionalBig } from "../../model/CostModel";
import { CostSummary, CostSummaryValues } from "../CostSummary";
import { byName, clientsFirst, sort } from "../analytics/AnalyticsUtility";
import { CostsBarChart } from "../analytics/CostsChart";
import { Tabs } from "../general/Tabs";
import { EditableCostsPerStageBudgetAdapter } from "./EditableCostsPerStageBudgetAdapter";

export interface BudgetCallbacks {
  update(newValue: Budget): Promise<void>;
}

export interface CostSelectionCallbacks {
  onHourlyCostsSelected: (
    partyId: string,
    costsId: string,
    timeCostsId: string
  ) => void;
  onDisbursementSelected: (
    partyId: string,
    costsId: string,
    disbursementsId: string
  ) => void;
}

export const Budgeting = () => {
  const caseContext = useCase();
  const { budgets } = caseContext.getCase();
  const parties: PartyDescriptors = caseContext.getPartyDescriptors();

  const submit = useSubmit();
  const budgetCallbacks: BudgetCallbacks = {
    update: function (budget: Budget): Promise<void> {
      return Promise.resolve(
        submit({ budget: JSON.stringify(budget) }, { method: "put" })
      );
    },
  };

  return (
    <BudgetingComponent
      budgets={budgets || []}
      budgetCallbacks={budgetCallbacks}
      parties={parties}
    />
  );
};

export function getCostSummary(budget: Budget): CostSummaryValues {
  const total = new OptionalBig();
  const incurred = new OptionalBig();
  const estimated = new OptionalBig();
  const lawyers = new OptionalBig();
  const courtFees = new OptionalBig();
  budget.costs.forEach((c) => {
    c.disbursements
      .flatMap((disbursement) => disbursement.entries || [])
      .forEach((cost) => {
        total.addNumber(cost.amount);
        if (cost.incurred) incurred.addNumber(cost.amount);
        else estimated.addNumber(cost.amount);
        if (cost.courtFee) courtFees.addNumber(cost.amount);
        else lawyers.addNumber(cost.amount);
      });
    c.hourlyCosts.forEach((hourlyCosts) => {
      const totalHours = new OptionalBig();
      const incurredHours = new OptionalBig();
      const estimatedHours = new OptionalBig();
      hourlyCosts.entries?.forEach((cost) => {
        totalHours.addNumber(cost.hours);
        if (cost.incurred) incurredHours.addNumber(cost.hours);
        else estimatedHours.addNumber(cost.hours);
      });
      totalHours.mulNumber(hourlyCosts.ratePerHour);
      incurredHours.mulNumber(hourlyCosts.ratePerHour);
      estimatedHours.mulNumber(hourlyCosts.ratePerHour);

      total.add(totalHours);
      // all hourly costs are lawyers costs
      lawyers.add(totalHours);

      incurred.add(incurredHours);
      estimated.add(estimatedHours);
    });
  });
  const summary: CostSummaryValues = {
    totalCosts: total.or(0),
    estimatedCosts: estimated.or(0),
    incurredCosts: incurred.or(0),
    courtFees: courtFees.or(0),
    lawyersCosts: lawyers.or(0),
  };
  return summary;
}

export const BudgetingComponent = ({
  budgets,
  budgetCallbacks,
  parties,
}: {
  budgets: Budget[];
  budgetCallbacks: BudgetCallbacks;
  parties: PartyDescriptors;
}) => {
  const currency = useCurrency();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [parties]
  );

  const tabs: { label: string; tabComponent: React.ReactNode }[] =
    sortedParties.map((partyId, index) => {
      const party = parties[partyId];
      const name = party?.name || partyId;
      const budget = budgets.find((b) => b.partyId === partyId);
      return {
        label: t(
          party?.isClient
            ? "case.parties.name.client"
            : "case.parties.name.noClient",
          { name }
        ),
        tabComponent: budget ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <IconButton onClick={toggleExpanded}>
                {expanded ? (
                  <Expanded
                    key="expanded-chevron"
                    sx={{
                      animation: "expand 0.3s linear",
                      "@keyframes expand": {
                        from: {
                          transform: "rotate(-90deg)",
                        },
                        to: {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                ) : (
                  <Collapsed
                    key="collapsed-chevron"
                    sx={{
                      animation: "collapse 0.3s linear",
                      "@keyframes collapse": {
                        from: {
                          transform: "rotate(90deg)",
                        },
                        to: {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                )}
              </IconButton>
              <CostSummary
                values={getCostSummary(budget)}
                currency={currency}
                onClick={toggleExpanded}
              />
            </Box>
            <Collapse in={expanded}>
              <EditableCostsPerStageBudgetAdapter
                budget={budget}
                otherBudgets={budgets.filter(
                  (b) => b.partyId !== budget.partyId
                )}
                updateBudget={budgetCallbacks.update}
                currency={currency}
                key={"budget_" + index}
              />
            </Collapse>
            <Box mt={2}>
              <CostsBarChart
                data={getCostsPerStage(budget)}
                currency={currency}
              />
            </Box>
          </>
        ) : (
          <Typography>
            <Trans i18nKey="case.budget.noBudget" values={{ name }} />
          </Typography>
        ),
      };
    });

  return <Tabs tabs={tabs} />;
};
