import {
  Claim as CaseModelClaim,
  Issue as CaseModelIssue,
  IssueRelation,
  NamedEntity,
  Party,
  Quantum,
} from "../../model/CaseModel";

import { Claim, Issue, IssueGroup, isGroup } from "./IssueModel";

export function convertTo(claim: CaseModelClaim, parties: Party[]): Claim {
  // convert
  const generic =
    claim.generic ||
    (claim.perDefendantWinChance === undefined &&
      claim.genericWinChance !== undefined);

  const winChanceAgainst: { [defendantId: string]: number } = {
    ...claim.perDefendantWinChance,
  };
  if (claim.issues === undefined || claim.issues.length === 0) {
    // initialize win chance per defendant if there are no issues
    claim.defendants?.forEach((d) => {
      if (winChanceAgainst[d.id] === undefined) {
        winChanceAgainst[d.id] = 0;
      }
    });
  }

  return {
    id: claim.id,
    title: claim.title,
    // strip to id & name only
    claimant: { id: claim.claimant.id, name: claim.claimant.name },
    defendants:
      claim.defendants
        ?.filter((d) => parties?.some((p) => p.id === d.id))
        .map((d) => ({ id: d.id, name: d.name })) ?? [],
    issues: getIssues(claim),
    generic,
    genericWinChance: claim.genericWinChance,
    winChanceAgainst,
    quantums: getAllQuantums(claim),
  };
}

export function convertFrom(claim: Claim): CaseModelClaim {
  const issues: CaseModelIssue[] = claim.issues.map((issue) => {
    // strip of unneeded properties
    const defendants = claim.defendants.map((d) => ({
      id: d.id,
      name: d.name,
    }));
    return isGroup(issue)
      ? {
          id: issue.id,
          relation: issue.relation,
          title: "group",
          defendants,
          subIssues: issue.subIssues.map((subIssue) =>
            convertIssue(subIssue, defendants)
          ),
        }
      : convertIssue(issue, defendants);
  });
  return {
    id: claim.id,
    title: claim.title,
    // strip to id & name only
    claimant: { id: claim.claimant.id, name: claim.claimant.name },
    defendants: claim.defendants.map((d) => ({ id: d.id, name: d.name })),
    issues,
    quantums: claim.quantums,
    generic: claim.generic,
    genericWinChance: claim.genericWinChance,
    perDefendantWinChance: claim.winChanceAgainst,
  };
}

function convertLeafIssue(issue: CaseModelIssue): Issue {
  const generic =
    issue.generic ||
    (issue.perDefendantWinChance === undefined &&
      issue.genericWinChance !== undefined);
  return {
    id: issue.id,
    title: issue.title,
    relation: issue.relation ?? IssueRelation.And,
    generic,
    genericWinChance: issue.genericWinChance,
    winChanceAgainst: issue.perDefendantWinChance ?? {},
  };
}

function getIssues(claim: CaseModelClaim): (Issue | IssueGroup)[] {
  const issues: (Issue | IssueGroup)[] = [];
  claim.issues?.forEach((issue) => {
    if (issue.subIssues && issue.subIssues.length > 0) {
      const group: IssueGroup = {
        id: issue.id,
        relation: issue.relation ?? IssueRelation.Or,
        subIssues: issue.subIssues.map(convertLeafIssue),
      };
      issues.push(group);
    } else issues.push(convertLeafIssue(issue));
  });
  return issues;
}

function getAllQuantums(claim: CaseModelClaim): Quantum[] {
  const quantums = new Map<string, Quantum>();
  claim.issues?.forEach((issue) => fillQuantumsOfIssues(issue, quantums));
  claim.quantums?.forEach((quantum) => quantums.set(quantum.id, quantum));

  claim.quantums = Array.from(quantums.values());

  return claim.quantums;
}

function fillQuantumsOfIssues(
  issue: CaseModelIssue,
  quantums: Map<string, Quantum>
) {
  issue.quantums?.forEach((quantum) => quantums.set(quantum.id, quantum));
  issue.subIssues?.forEach((subIssue) =>
    fillQuantumsOfIssues(subIssue, quantums)
  );
  issue.quantums = undefined;
}

function convertIssue(
  issue: Issue,
  allDefendants: NamedEntity[]
): CaseModelIssue {
  const result: CaseModelIssue = {
    id: issue.id,
    title: issue.title,
    relation: issue.relation,
    generic: issue.generic,
    genericWinChance: issue.genericWinChance,
    perDefendantWinChance: issue.winChanceAgainst,
  };

  if (issue.generic) result.defendants = allDefendants;
  else if (issue.winChanceAgainst)
    result.defendants = allDefendants.filter(
      (d) => issue.winChanceAgainst[d.id] !== undefined
    );

  return result;
}
