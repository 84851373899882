import {
  Box,
  Collapse,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { TurningChevron } from "./TurningChevron";

const defaultSx: SxProps<Theme> = {
  alignItems: "start",
  userSelect: "none",
  mb: 2,
};

export const CollapsibleBox = ({
  title,
  children,
  sx,
  expand = false,
}: {
  title: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  expand?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const [expanded, setExpanded] = useState(expand);
  const theme = useTheme();
  const mergedSx = { ...defaultSx, ...sx };

  return (
    <Box sx={mergedSx}>
      <Box display="flex" flexDirection="row">
        <TurningChevron
          onClick={() => setExpanded(!expanded)}
          turn={expanded}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            userSelect: "none",
            minHeight: "40px",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          {typeof title === "string" ? <Typography>{title}</Typography> : title}
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Box pl="40px" pr={theme.spacing(1)} pb={theme.spacing(1)}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
