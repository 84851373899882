import Big from "big.js";
import { min, ONE } from "../../big/bigUtil";
import { Part } from "./Parts";
import { createManagedPayer } from "./PayerWithLimitedFunds";
import { Item, Payer, Payment, PaymentPlan } from "./Payment";
import { PaymentNode, PossibleItemPayment } from "./PaymentPlanner";
import { PlannedItem } from "./PlannedItem";

export class PaymentPool<I extends Item> {
  private readonly children: PaymentNode[] = [];
  private lastMandatoryPayment: PaymentNode | undefined;

  constructor(
    public readonly items: I[],
    readonly payers: Payer[]
  ) {
    // remove shares of unavailable payers
    let managedPayers = payers.map((payer) => createManagedPayer(payer));

    const toPlan = new Set(
      items.map((item) => {
        const maxSharePerPayer: Map<string, Big> = new Map();
        for (const [payerId, maxShare] of item.maxSharePerPayer) {
          if (maxShare.lte(0) || !managedPayers.find((p) => p.id === payerId))
            continue;

          maxSharePerPayer.set(payerId, min(maxShare, ONE));
        }
        return new PlannedItem(item.id, item.price, maxSharePerPayer);
      })
    );
    const done: PlannedItem[] = [];

    // loop through items as long as progress is made
    let anythingChanged;
    do {
      anythingChanged = false;
      // allocate funds in each payer for their maximum possible payments
      toPlan.forEach((item) => {
        managedPayers.forEach((payer) =>
          payer.allocate(item.id, item.amountPayablePerPayer.get(payer.id))
        );
      });

      toPlan.forEach((item) => {
        // while going over payers, check if there is anybody that can pay for item
        let itemHasAnyPayers = false;
        // try if each payer can pay
        managedPayers = managedPayers.filter((payer) => {
          console.debug(`Processing '${payer.id}' for '${item.id}'`);
          // For payers with less assets than some payment items - reduce the max share of the payers in respective items!
          if (item.updateAvailableAmount(payer)) {
            // item parts changed based on payer assets
            anythingChanged = true;
          }
          // check if payer pays for item at all
          if (!item.amountPayablePerPayer.has(payer.id)) {
            // payer is not paying for item
            return payer.hasFunds();
          }
          // item has at least the payer
          itemHasAnyPayers = true;

          // check if payer has an exclusive payment part in the item
          if (!payer.hasEnoughFunds() || !item.payForExclusivePartsOf(payer))
            return payer.hasFunds();

          // payer paid an exclusive part, thus item changed
          anythingChanged = true;
          // check if item is fully paid
          if (item.isPaid()) {
            console.debug(`'${item.id}' payment plan done`);
            done.push(item);
            toPlan.delete(item);
          }
          // remove payer if they have no more assets
          if (!payer.hasFunds()) {
            // payer ran out of assets. remove payer from all todo items
            console.debug(`Payer '${payer.id}' has no more assets`);
            toPlan.forEach((todoItem) => todoItem.removePayer(payer.id));
            return false;
          }
          // payer still has assets, keep in list
          return true;
        });
        // if there is nobody to pay for item, remove it from to-dos
        if (!itemHasAnyPayers) {
          console.debug(`No payer available for item '${item.id}'`);
          done.push(item);
          toPlan.delete(item);
        }
        // de-allocate done items
        done.forEach((item) =>
          managedPayers.forEach((payer) => payer.allocate(item.id, undefined))
        );
      });
    } while (anythingChanged && toPlan.size > 0 && managedPayers.length > 0);

    // start building payment tree
    // tree starts with all mandatory payments of the items
    done.forEach((item) => this.addMandatoryPayments(item));
    // partially planned items may already have mandatory payments
    toPlan.forEach((item) => this.addMandatoryPayments(item));

    // for the remaining optional payments, the algorithm must calculate all possible payment paths
    this.addOptionalPayments(toPlan);
  }

  // cache expensive variable
  private paymentPlans: PaymentPlan[] | undefined;
  getPaymentPlans(): PaymentPlan[] {
    // lazy initialization
    if (this.paymentPlans === undefined) {
      // generate all possible plans
      const plans: PaymentPlan[] = [];
      this.children.forEach((child) => child.fillPaymentPlans(plans));
      // filter out plans with identical payments
      if (plans.length <= 1) return plans;
      const uniquePlans: PaymentPlan[] = [plans[0]];
      for (let index = 1; index < plans.length; index++) {
        const plan = plans[index];
        if (!uniquePlans.some((uniquePlan) => uniquePlan.equals(plan))) {
          uniquePlans.push(plan);
        }
      }
      this.paymentPlans = uniquePlans;
    }
    return this.paymentPlans;
  }

  private addMandatoryPayments(item: PlannedItem) {
    asPayments(item.done).forEach((payment) => {
      if (this.lastMandatoryPayment === undefined) {
        this.lastMandatoryPayment = new PaymentNode(
          item.id,
          item.price,
          payment
        );
        this.children.push(this.lastMandatoryPayment);
      } else {
        this.lastMandatoryPayment = this.lastMandatoryPayment.addChild(
          item.id,
          item.price,
          payment
        );
      }
    });
  }

  // use original asset limits, because the algorithm traverses all payments to check remaining funds of each payer
  // i.e. reducing the limits by mandatory payments leads to errors, as those would then be calculated twice
  private addOptionalPayments(items: Set<PlannedItem>) {
    if (items.size < 1) return;

    let optionalPayments: PossibleItemPayment[] = [];
    items.forEach((item) => {
      optionalPayments = optionalPayments.concat(
        getOptionalPayments(item, (payerId) =>
          this.payers.find((payer) => payer.payerId === payerId)
        )
      );
    });
    if (this.lastMandatoryPayment) {
      console.debug(
        "Got mandatory payments, growing with " +
          optionalPayments.length +
          " optional payments"
      );
      this.lastMandatoryPayment.grow(optionalPayments);
    } else {
      // start branching using all possible payments, as paths of any sequence need to be explored
      optionalPayments.forEach((possiblePayment) => {
        // create payment child node
        const child = possiblePayment.asRootNode();
        this.children.push(child);
        // for each possibility create a sub-tree and grow it with the other possibilities
        child.grow(
          optionalPayments.filter((element) => element !== possiblePayment)
        );
      });
    }
  }
}

function getOptionalPayments(
  item: PlannedItem,
  getPayer: (payerId: string) => Payer | undefined
): PossibleItemPayment[] {
  const possiblePayments: PossibleItemPayment[] = [];
  asPayments([...item.todo]).forEach((payment) => {
    // available amount => unlimited assets
    const payableMaximum =
      getPayer(payment.payerId)?.availableAmount || item.price;
    if (payableMaximum.gt(0))
      possiblePayments.push(
        new PossibleItemPayment(item.id, item.price, payment.amount, {
          payerId: payment.payerId,
          availableAmount: payableMaximum,
        })
      );
  });
  return possiblePayments;
}

/**
 * Aggregates payments of given parts. If a payer paid for multiple parts, then only one, aggregate payment is generated for that payer.
 *
 * @param parts to generate the payments for
 * @returns list of payments, one per payer
 */
function asPayments(parts: Part[]): Payment[] {
  const paymentsPerPayer = new Map<string, Big>();
  parts.forEach((part) => {
    part.accept((payerId, share) => {
      const otherPaymentsTotal = paymentsPerPayer.get(payerId);
      paymentsPerPayer.set(
        payerId,
        otherPaymentsTotal === undefined
          ? share.mul(part.price)
          : share.mul(part.price).plus(otherPaymentsTotal)
      );
    });
  });

  const payments: Payment[] = [];
  for (const [payerId, amount] of paymentsPerPayer) {
    payments.push({
      payerId,
      amount,
    });
  }
  return payments;
}
