import { ChartData, ChartDataset } from "chart.js";
import { t } from "i18next";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import {
  PartyDescriptor,
  SettlementData,
  SettlementRangeOfStage,
} from "../../../model/CaseModel";

export interface SettlementChartData {
  settlingPartyId: string;
  settlingParty: PartyDescriptor;
  charts: { opponentId: string; data: ChartData<"line", number[]> }[];
}

function createDataArray(value: number, length: number): number[] {
  const data = new Array(length).fill(NaN);
  data[0] = value;
  data[data.length - 1] = value;
  return data;
}

export interface SettlementColors {
  evSettlingParty: string;
  evOtherParty: string;
  midpoints: string;
  netResult: string;
  worstEnforcement: string;
  bestEnforcement: string;
  bargainingRange: string;
}

type Segment = {
  partyEvs: number[];
  opponentEvs: number[];
  midpoints: number[];
  settlement?: number;
};

function getSegments(perStage: SettlementRangeOfStage[]): Segment[] {
  let currentSegment: Segment = {
    partyEvs: [],
    opponentEvs: [],
    midpoints: [],
  };
  const segments: Segment[] = [currentSegment];

  for (let stageIndex = 0; stageIndex < perStage.length; stageIndex++) {
    const stage = perStage[stageIndex];
    currentSegment.partyEvs.push(stage.range.partyEv);
    currentSegment.opponentEvs.push(stage.range.opponentEv);
    currentSegment.midpoints.push(stage.range.mid);
    if (stage.settlement) {
      // store settlement in segment
      currentSegment.settlement = stage.settlement.amount;
      // start new segment
      const newSegmentStart = stage.settlement.effect;
      currentSegment = {
        partyEvs: new Array(stageIndex).fill(NaN),
        opponentEvs: new Array(stageIndex).fill(NaN),
        midpoints: new Array(stageIndex).fill(NaN),
      };
      currentSegment.partyEvs.push(newSegmentStart.partyEv);
      currentSegment.opponentEvs.push(newSegmentStart.opponentEv);
      currentSegment.midpoints.push(newSegmentStart.mid);
      segments.push(currentSegment);
    }
  }
  return segments;
}

export function asChartData(
  { perStage, netResult, bestEnforcement, worstEnforcement }: SettlementData,
  partyName: string,
  opponentName: string,
  colors: SettlementColors
): ChartData<"line", number[]> {
  const labelEvOtherParty = t(
    "case.analytics.settlement.chart.labels.evOther",
    {
      settlingParty: partyName,
      otherParty: opponentName,
    }
  );
  const labelEvParty = t(
    "case.analytics.settlement.chart.labels.evSettlingParty",
    {
      settlingParty: partyName,
      otherParty: opponentName,
    }
  );
  const labelMidpoint = t("case.analytics.settlement.chart.labels.midpoint");

  const segments = getSegments(perStage);
  const datasets: ChartDataset<"line", number[]>[] = [];
  for (const segment of segments) {
    datasets.push(
      {
        label: labelEvParty,
        data: segment.partyEvs,
        borderColor: colors.evSettlingParty,
        backgroundColor: colors.evSettlingParty,
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
      },
      {
        label: labelEvOtherParty,
        data: segment.opponentEvs,
        fill: "-1",
        borderColor: colors.evOtherParty,
        backgroundColor: colors.bargainingRange,
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
      },
      {
        label: labelMidpoint,
        data: segment.midpoints,
        borderColor: colors.midpoints,
        backgroundColor: colors.midpoints,
        borderWidth: 2,
        borderDash: [8, 8],
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 1,
      }
    );
    if (segment.settlement !== undefined) {
      const settlement: number[] = new Array(segment.midpoints.length - 1).fill(
        NaN
      );
      settlement.push(segment.settlement);
      datasets.push({
        label: "Settlement",
        data: settlement,
        borderColor: colors.bestEnforcement,
        backgroundColor: colors.bestEnforcement,
        borderWidth: 8,
        borderDash: [8, 8],
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 1,
      });
    }
  }
  datasets.push({
    label: `${t("case.analytics.settlement.chart.labels.netResult")}`,
    data: createDataArray(netResult, perStage.length),
    borderColor: colors.netResult,
    backgroundColor: colors.netResult,
    borderWidth: 2,
    pointHoverRadius: 6,
    pointHitRadius: 8,
    spanGaps: true,
    order: 3,
    fill: {
      target: "start",
      above: colors.netResult + "20",
    },
  });

  const chart: ChartData<"line", number[]> = {
    labels: perStage.map(({ stage }) =>
      t("case.stages." + Stage[stage], Stage[stage])
    ),
    datasets,
  };
  if (worstEnforcement !== undefined)
    chart.datasets.push({
      label: `${t("case.analytics.settlement.chart.labels.worstEnforcement", {
        settlingParty: partyName,
      })}`,
      data: createDataArray(worstEnforcement, perStage.length),
      borderColor: colors.worstEnforcement,
      backgroundColor: colors.worstEnforcement + "20",
      borderWidth: 2,
      pointHoverRadius: 6,
      pointHitRadius: 8,
      spanGaps: true,
      order: 3,
    });
  if (bestEnforcement !== undefined)
    chart.datasets.push({
      label: `${t("case.analytics.settlement.chart.labels.bestEnforcement", {
        settlingParty: partyName,
      })}`,
      data: createDataArray(bestEnforcement, perStage.length),
      borderColor: colors.bestEnforcement,
      backgroundColor: colors.bestEnforcement,
      borderWidth: 2,
      pointHoverRadius: 6,
      pointHitRadius: 8,
      spanGaps: true,
      order: 3,
    });

  return chart;
}
