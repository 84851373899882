import Big from "big.js";
import { ZERO } from "../big/bigUtil";
import { ApportionedCostsAggregator } from "./ApportionedCostsUtility";
import Scenario from "./Scenario";
import { ApportionedCosts, total } from "./api/Analytics";
import { CostType, Stage } from "./model/CaseConfiguration";

export interface CostPerStage {
  [stage: string]: ApportionedCosts;
}

export interface CostSummary {
  incurred: CostPerStage;
  incurredTotal: Big;
  estimated: CostPerStage;
  estimatedTotal: Big;
}

export const ZERO_COSTS: ApportionedCosts = Object.freeze({
  generic: 0,
  perParty: {},
  total: 0,
});

export function getCostSummary(
  scenario: Scenario,
  partyId: string
): CostSummary {
  // accumulate costs per stage
  const incurredAggregators: { [stage: string]: ApportionedCostsAggregator } =
    {};
  const estimatedAggregators: { [stage: string]: ApportionedCostsAggregator } =
    {};

  let incurredTotal = ZERO;
  let estimatedTotal = ZERO;

  const lawyersCosts = scenario.getGlobalApportionment(
    CostType.LawyerCosts,
    partyId
  );
  const courtFees = scenario.getGlobalApportionment(
    CostType.CourtFees,
    partyId
  );

  scenario.getCostsPaidBy(partyId).forEach((cost) => {
    let costAggregators: { [stage: string]: ApportionedCostsAggregator };
    if (cost.incurred) {
      incurredTotal = incurredTotal.add(cost.amount);
      costAggregators = incurredAggregators;
    } else {
      estimatedTotal = estimatedTotal.add(cost.amount);
      costAggregators = estimatedAggregators;
    }
    // initialize aggregator if not present yet
    let costOfStage = costAggregators[Stage[cost.stage]];
    if (costOfStage === undefined) {
      costOfStage = new ApportionedCostsAggregator(
        lawyersCosts?.costApportionment,
        courtFees?.costApportionment
      );
      costAggregators[Stage[cost.stage]] = costOfStage;
    }

    // finally process cost
    costOfStage.add(cost);
  });

  const estimated: CostPerStage = {};
  Object.entries(estimatedAggregators).forEach(
    (aggregator) => (estimated[aggregator[0]] = aggregator[1].get())
  );
  const incurred: CostPerStage = {};
  Object.entries(incurredAggregators).forEach(
    (aggregator) => (incurred[aggregator[0]] = aggregator[1].get())
  );

  return {
    estimated,
    estimatedTotal,
    incurred,
    incurredTotal,
  };
}

export function add(
  a?: ApportionedCosts,
  b?: ApportionedCosts
): ApportionedCosts {
  if (a === undefined) return b === undefined ? ZERO_COSTS : { ...b };
  if (b === undefined) return { ...a };

  const perParty: { [partyId: string]: number } = { ...a.perParty };
  Object.entries(b.perParty || {}).forEach((perPartyB) => {
    const perPartyA = perParty[perPartyB[0]];
    if (perPartyA === undefined) perParty[perPartyB[0]] = perPartyB[1];
    else
      perParty[perPartyB[0]] = new Big(perPartyB[1]).add(perPartyA).toNumber();
  });
  const generic = new Big(a.generic).add(b.generic).toNumber();
  return {
    generic,
    perParty,
    total: total(generic, perParty),
  };
}
