import { Box, IconButton, Typography } from "@mui/material";
import { WeightedValueEditor } from "./WeightedValueEditor";
import AddIcon from "@mui/icons-material/AddCircle";
import { Currency, gbp } from "../i18n/Currency";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { WeightedValue } from "../model/WeightedValue";

export const WeightedValuesEditor = ({
  values,
  onChange,
  currency = gbp,
  min,
  max,
}: {
  values: WeightedValue[];
  onChange: (newValues: WeightedValue[]) => void;
  currency?: Currency;
  min?: number;
  max?: number;
}) => {
  let totalWeight = 0;
  let sum = 0;
  values.forEach((value) => {
    totalWeight += value.weight;
    sum += value.value * value.weight;
  });
  const showWeights = values.length > 1;
  return (
    <Box pt={2} pb={2}>
      {showWeights && totalWeight > 0 && (
        <>
          <Typography
            key="result-label"
            variant="caption"
            color="textSecondary"
          >
            <Trans i18nKey={"weightedValue.label.result"} />
          </Typography>
          <Typography key="result">
            {currency.format(sum / totalWeight)}
          </Typography>
        </>
      )}
      {values.map((value, index) => {
        const share = totalWeight > 0 ? value.weight / totalWeight : undefined;
        return (
          <WeightedValueEditor
            key={"weighted-value-" + index}
            {...value}
            weight={showWeights ? value.weight : undefined}
            currency={currency}
            share={share}
            onValueChange={(newValue) =>
              onChange(
                values.map((v) =>
                  v === value ? { ...value, value: newValue } : v
                )
              )
            }
            onWeightChange={(newWeight) =>
              onChange(
                values.map((v) =>
                  v === value ? { ...value, weight: newWeight } : v
                )
              )
            }
            onDelete={
              values.length > 1
                ? () => onChange(values.filter((v) => v !== value))
                : undefined
            }
            min={min}
            max={max}
          />
        );
      })}

      <IconButton
        onClick={() => onChange(values.concat({ value: 0, weight: 1 }))}
        title={`${t("weightedValue.tip.button.add")}`}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};
