import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// bundle i18n into app
import { createTheme } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { CurrencyContextProvider } from "./context/CurrencyContext";
import { gbp } from "./i18n/Currency";
import "./i18n/i18n";

/* const ftColors = {
  palette: {
    primary: { main: "#262A33" },
    secondary: { main: "#990F3D" },
    background: {
      paper: "#F2DFCE",
      default: "#EDEEEE",
    },
    text: { primary: "#000000" },
  },
  typography: {
    fontFamily: "Inter",
  },
}; */

/* Grzegorz
  primary: {
    main: "#031341",
  },
  secondary: {
    main: "#47c1ec",
  },
  background: {
    default: "#e0e0e0",
    paper: "#FFFCF9",
  },
*/

export const getTheme = () => {
  const theme = createTheme({
    palette: {
      primary: { main: "#081a38" },
      secondary: { main: "#ffe4c4" },
      background: {
        default: "#f4f4f4",
        paper: "#fafafa",
      },
    },
    typography: {
      fontFamily: "Inter",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 400,
        md: 800,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "rgba(0,0,0,.1)",
              minHeight: 24,
            },
          },
        },
      },
    },
  });
  return theme;
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={getTheme()}>
        <CurrencyContextProvider currency={gbp}>
          <App />
        </CurrencyContextProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
