import { Typography } from "@mui/material";

export const Copyright = () => (
  <Typography
    variant="body2"
    color="textSecondary"
    align="center"
    sx={{ userSelect: "none" }}
  >
    {"Copyright © "}
    {new Date().getFullYear()}
    {" Legal Tech Vault GmbH."}
  </Typography>
);
