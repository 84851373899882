import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";

const CaseCreator = () => {
  const [title, setTitle] = useState<string>("");
  const navigate = useNavigate();
  const titleEmpty = title.trim().length < 1;
  return (
    <Container maxWidth="md">
      <Typography variant="h5" color="textSecondary" gutterBottom>
        <Trans i18nKey="case.creator.label" />
      </Typography>
      <Form noValidate autoComplete="off" method="post">
        <TextField
          label={<Trans i18nKey="case.creator.title" />}
          variant="outlined"
          color="primary"
          autoFocus
          type="text"
          name="name"
          required
          fullWidth
          error={titleEmpty}
          onChange={(e) => setTitle(e.target.value)}
          helperText={
            titleEmpty && <Trans i18nKey="case.creator.titleHelperText" />
          }
        />
        <Box m={2} display="flex">
          <Box mr={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={titleEmpty}
            >
              <Trans i18nKey="case.creator.submit" />
            </Button>
          </Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate("/cases")}
          >
            <Trans i18nKey="case.creator.cancel" />
          </Button>
        </Box>
      </Form>
    </Container>
  );
};

export default CaseCreator;
