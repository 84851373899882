import Big from "big.js";
import { ZERO } from "../big/bigUtil";
import { ApportionedCosts, total } from "./api/Analytics";
import { Cost, CostType, Share } from "./model/CaseConfiguration";

export class ApportionedCostsAggregator {
  private generic: Big = ZERO;
  private perParty: { [partyId: string]: Big } = {};

  constructor(
    private readonly lawyersCosts?: Share[],
    private readonly courtFees?: Share[]
  ) {}

  add(cost: Cost) {
    if (cost.apportionment && cost.apportionment.length) {
      // cost-specific apportionment
      const costPerParty = new Big(cost.amount).div(cost.apportionment?.length);
      cost.apportionment.forEach((partyId) => {
        const costsTowardsParty = this.perParty[partyId];
        this.perParty[partyId] =
          costsTowardsParty === undefined
            ? costPerParty
            : costsTowardsParty.add(costPerParty);
      });
      return;
    }
    switch (cost.type) {
      case CostType.LawyerCosts: {
        this.apportion(cost.amount, this.lawyersCosts);
        return;
      }
      case CostType.CourtFees: {
        this.apportion(cost.amount, this.courtFees);
        return;
      }
    }
  }

  private apportion(amount: number, apportionment?: Share[]) {
    if (apportionment === undefined) {
      this.generic = this.generic.add(amount);
      return;
    }

    let sharesTotal = ZERO;
    apportionment.forEach((share) => {
      const costsTowardsParty = this.perParty[share.partyId];
      if (share.percentage === undefined) {
        // assume 100% if not specified otherwise
        this.perParty[share.partyId] =
          costsTowardsParty === undefined
            ? new Big(amount)
            : costsTowardsParty.add(amount);
      } else {
        // scale amount according to percentage
        this.perParty[share.partyId] =
          costsTowardsParty === undefined
            ? new Big(amount).mul(share.percentage)
            : costsTowardsParty.add(new Big(amount).mul(share.percentage));
        sharesTotal = sharesTotal.add(share.percentage);
      }
    });

    // generic=1-sharesTotal
    if (sharesTotal.lt(1)) {
      // add generic part (1-sharesTotal)*amount
      this.generic = this.generic.add(sharesTotal.neg().add(1).mul(amount));
    }
  }

  get(): ApportionedCosts {
    const perPartyNumbers: { [partyId: string]: number } = {};
    Object.entries(this.perParty).forEach(
      (costsPerParty) =>
        (perPartyNumbers[costsPerParty[0]] = costsPerParty[1].toNumber())
    );
    return {
      generic: this.generic.toNumber(),
      perParty: perPartyNumbers,
      total: total(this.generic.toNumber(), perPartyNumbers),
    };
  }
}
