import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { getStages } from "../../../analytics/model/British";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { useCase } from "../../../context/CaseContext";
import { SettlementImpact } from "../../../model/CaseModel";
import SettlementImpactInfoDialog from "./SettlementImpactInfoDialog";
import { Offer as CardOffer } from "./SettlementOfferCard";
import SettlementOfferDialog, { Offer } from "./SettlementOfferDialog";
import SettlementTimeline from "./SettlementTimeline";

const SettlementOffers = ({
  partyId,
  offers,
  stages = getStages(),
  save,
  remove,
  create,
}: {
  partyId: string;
  offers: Offer[];
  stages: Stage[];
  save: (offer: Offer) => void;
  remove: (offerId: string) => void;
  create: () => Offer;
}) => {
  const [removedOffer, setRemovedOffer] = useState<Offer>();
  const handleClose = (offerId?: string) => {
    if (offerId) remove(offerId);
    setRemovedOffer(undefined);
  };

  const caseContext = useCase();

  const [editedOffer, setEditedOffer] = useState<Offer | undefined>();
  const [infoOffer, setInfoOffer] = useState<
    { offer: Offer; impact: SettlementImpact } | undefined
  >();

  const timelineOffers: CardOffer[] = offers.map(
    ({
      id,
      payment,
      offeree,
      offeror,
      costs,
      stage,
      status,
      claims,
      quantums,
    }) => ({
      id: id,
      offeror: offeror,
      offeree: offeree,
      amount: payment.amount,
      offerorPays: !payment.offereePays,
      offereeCosts: costs.settlement.offeree,
      offerorCosts: costs.settlement.offeree,
      stage: stage,
      status: status,
      claims: claims.map((claim) => claim.title),
      quantums: quantums.map((quantum) => ({
        title: quantum.title,
        value: quantum.value,
        apportioned: quantum.apportioned ?? 0,
      })),
    })
  );
  return (
    <>
      <SettlementTimeline
        partyId={partyId}
        offers={timelineOffers}
        onDeleteRequested={(offerId) => {
          const offer = offers.find((offer) => offer.id === offerId);
          if (!offer) return;
          setRemovedOffer(offer);
        }}
        onEditRequested={(offerId) => {
          // ignore if an offer is already being edited
          if (editedOffer) return;

          // open editor
          setEditedOffer(offers.find((offer) => offer.id === offerId));
        }}
        onInfoRequested={(offerId) => {
          // ignore if an offer is already being edited
          if (infoOffer) return;

          // open info modal
          const offer = offers.find((offer) => offer.id === offerId);
          if (!offer) return;

          const impact = caseContext.getOfferImpact(offerId);
          if (!impact) return;
          setInfoOffer({ offer, impact });
        }}
        onStatusChange={(offerId, newStatus) => {
          const offer = offers.find((offer) => offer.id === offerId);
          if (!offer) return;
          const offerCopy: Offer = JSON.parse(JSON.stringify(offer));
          offerCopy.status = newStatus;
          save(offerCopy);
        }}
      />
      {editedOffer && (
        <SettlementOfferDialog
          initialOffer={editedOffer}
          open
          stages={stages}
          onClose={(editedOffer) => {
            setEditedOffer(undefined);
            if (!editedOffer)
              // edit cancelled
              return;
            save(editedOffer);
          }}
        />
      )}
      {infoOffer && (
        <SettlementImpactInfoDialog
          title={t("case.analytics.settlement.offerDialog.title", {
            offeror: infoOffer.offer.offeror.name,
          })}
          offer={infoOffer.offer}
          impact={infoOffer.impact}
          open
          onClose={() => setInfoOffer(undefined)}
        />
      )}
      <Box width="100%" display="flex" justifyContent="center">
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          onClick={() => setEditedOffer(create())}
        >
          <AddIcon />
          <Trans i18nKey="case.analytics.settlement.new" />
        </Fab>
      </Box>
      <Dialog
        open={removedOffer !== undefined}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans
            i18nKey="case.analytics.settlement.deleteOfferDialog.title"
            values={{
              id: removedOffer?.id,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans
              i18nKey="case.analytics.settlement.deleteOfferDialog.question"
              values={{
                offeror: removedOffer?.offeror.name,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            autoFocus
            startIcon={<DeleteIcon />}
            onClick={() => handleClose(removedOffer?.id)}
          >
            <Trans i18nKey="case.analytics.settlement.deleteOfferDialog.buttons.delete" />
          </Button>
          <Button
            onClick={() => handleClose()}
            color="primary"
            variant="outlined"
          >
            <Trans i18nKey="case.analytics.settlement.deleteOfferDialog.buttons.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettlementOffers;
