import Big from "big.js";
import { getCostSummary } from "../analytics/CostUtility";
import Scenario from "../analytics/Scenario";
import { generateOutcomes } from "../analytics/ScenarioOutcome";
import { ScenarioOutcomeForParty } from "../analytics/ScenarioOutcomeForParty";
import { worst } from "../analytics/api/Analytics";
import { LiabilityIssue, Stage } from "../analytics/model/CaseConfiguration";
import { ZERO } from "../big/bigUtil";
import {
  Chance,
  ChanceNode,
  Decision,
  DecisionNode,
  EndNode,
  SubGraph,
  TreeNode,
} from "./tree";

export function generateDecisionTrees(scenario: Scenario): SubGraph {
  return {
    title:
      "Simulation with parties: " +
      scenario
        .getParties()
        .map((party) => party.name)
        .sort(),
    // go reverse as in most cases this makes C go first in graph
    trees:
      scenario
        .getParties()
        .sort()
        .map((party) => generateDecisionTree(scenario, party.id)) || [],
  };
}

interface OutcomeNode {
  // all scenarios having the same payment outcome
  scenarios: ScenarioOutcomeForParty[];
  // node for the outcome
  node: TreeNode;
}

/**
 * Creates one branch per payment end node in order to produce a concise decision tree.
 * The advantage of this method is that the avoids branch explosions in case of many issue permutations leading to the same payment outcomes.
 * The disadvantage is that the labels of the branches no longer contain information about what issues were won/lost.
 * @param outcomeNode end node of the payment outcome
 * @param consumeBranch callback for the created branch
 * @returns branches leading to the end nodes
 */
function createOneBranchPerPaymentOutcome(
  outcomeNode: OutcomeNode,
  consumeBranch: (branch: Chance) => void,
  shortLabel = false
) {
  if (outcomeNode.scenarios.length === 1) {
    // with only one scenario, we can benefit from the elaborate description generated in the other method
    createOneBranchPerScenario(outcomeNode, consumeBranch);
    return;
  }
  let probability = 0;
  let elaborateLabel = "";
  outcomeNode.scenarios.forEach((scenario, index) => {
    probability += scenario.getProbability();
    if (index > 0 && index < outcomeNode.scenarios.length - 1)
      elaborateLabel += "<br/>OR ";
    elaborateLabel += describeOutcome(scenario);
  });

  // use getLabel instead of combinedLabel for shortened text
  consumeBranch(
    new Chance(
      shortLabel ? getLabel(outcomeNode) : elaborateLabel,
      probability,
      outcomeNode.node
    )
  );
}

function getLabel(outcome: OutcomeNode): string {
  const ev = outcome.node.getEmv();
  return ev > 0 ? "Win" : ev === 0 ? "Neutral" : "Lose";
}

/**
 * Creates one branch per issue scenario leading to a payment end node.
 * The advantage of this method is that each branch contains information about the won/lost liability issues.
 * The disadvantage is that this method leads to an explosion in the number of branches in cases of many permutations of liability issue outcomes leading to equal payment outcomes.
 * @param outcomeNode end node of the payment outcome
 * @param consumeBranch callback for the created branch
 * @returns branches leading to the end nodes
 */
function createOneBranchPerScenario(
  outcomeNode: OutcomeNode,
  consumeBranch: (branch: Chance) => void
) {
  outcomeNode.scenarios.forEach((scenario) =>
    consumeBranch(
      new Chance(
        describeOutcome(scenario),
        scenario.getProbability(),
        outcomeNode.node
      )
    )
  );
}

export function generateDecisionTree(
  scenario: Scenario,
  partyId: string
): TreeNode {
  const partyLabel = scenario.getParty(partyId)?.name || partyId;

  const costSummary = getCostSummary(scenario, partyId);

  // get all possible liability issue outcomes.
  // cache nodes to be able to re-use for outcomes with equal payments
  const positiveOutcomes: Map<ScenarioOutcomeForParty, OutcomeNode> = new Map();
  const negativeOutcomes: Map<number, OutcomeNode> = new Map();

  generateOutcomes(scenario).forEach((outcome) => {
    const outcomeForParty = new ScenarioOutcomeForParty(outcome, partyId);

    // check if an outcome with same payments already exists. If so, connect to node
    for (const [existingOutcome, cachedOutcome] of positiveOutcomes) {
      if (existingOutcome.hasEqualPayments(outcomeForParty)) {
        // great, re-use end-node for the same payment outcome (this greatly simplifies the graphs as well as skips repeated payment planning)
        cachedOutcome.scenarios.push(outcomeForParty);
        return;
      }
    }

    // no outcome with same payments encountered yet, create a TreeNode
    const judgementTotal = outcomeForParty.getJudgementTotal();

    if (judgementTotal.lt(0)) {
      // net negative
      // cap at party's assets limit
      // get worst enforcement result
      const worstEnforcement = worst(outcomeForParty.getEnforcementResults());
      if (worstEnforcement === undefined) {
        console.warn(
          "No enforcement result for outcome",
          partyLabel,
          outcome.when
        );
        return;
      }

      const assetLimit = outcomeForParty.getAssetLimitOfParty();
      const amountToPay = assetLimit
        ? Math.min(assetLimit.toNumber(), worstEnforcement.balance)
        : worstEnforcement.balance;

      // is there a worst case node for this amount already?
      const cachedOutcome = negativeOutcomes.get(amountToPay);
      if (cachedOutcome !== undefined) {
        // yes, there already is a node for this worst-case payment amount
        // add this outcome to the scenarios of the node
        cachedOutcome.scenarios.push(outcomeForParty);
        return;
      }
      // no node yet, create
      const enforcement = new DecisionNode(Stage[Stage.Enforcement], [
        new Decision("Worst case payment", new EndNode(0), -amountToPay),
      ]);
      // cache with current outcome
      negativeOutcomes.set(amountToPay, {
        node: enforcement,
        scenarios: [outcomeForParty],
      });
      return;
    }

    // net positive judgement, simulate payment
    const payment = outcomeForParty.getPaymentTree();

    const end = new DecisionNode("Enforcement?", [
      new Decision(
        "Yes",
        payment,
        costSummary.estimated[Stage[Stage.Enforcement]]?.total || 0
      ),
      new Decision("No", new EndNode(0), 0),
    ]);

    // cache first node for this outcome
    positiveOutcomes.set(outcomeForParty, {
      node: end,
      scenarios: [outcomeForParty],
    });
  });

  // put all end nodes together and create branches to them
  const endNodes = [...positiveOutcomes.values()].concat([
    ...negativeOutcomes.values(),
  ]);
  const issueOutcomeBranches: Chance[] = [];
  endNodes.forEach((endNode) =>
    createOneBranchPerPaymentOutcome(endNode, (branch) =>
      issueOutcomeBranches.push(branch)
    )
  );

  // trial is the starting point for all branches to the end nodes
  const trial = new ChanceNode(issueOutcomeBranches);

  // build decision tree for the trial stages
  // please note that, as above, the tree is built backwards
  let trialReached = false;
  let lastNode: TreeNode = trial;
  let nextStageCosts = ZERO;

  const caseStages = scenario.getCaseStages();
  for (let index = caseStages.length - 1; index >= 0; index--) {
    const stage = caseStages[index];
    if (trialReached) {
      lastNode = new DecisionNode(Stage[stage], [
        new Decision("Proceed", lastNode, nextStageCosts.toNumber()),
      ]);

      nextStageCosts = new Big(costSummary.estimated[Stage[stage]]?.total || 0);
      continue;
    }
    const costOfStage = costSummary.estimated[Stage[stage]];
    // as long as trial is not reached, accumulate costs (without enforcement)
    if (costOfStage && stage !== Stage.Enforcement)
      nextStageCosts = nextStageCosts.add(costOfStage.total);
    if (stage === Stage.Trial) trialReached = true;
  }

  lastNode = new DecisionNode(partyLabel, [
    new Decision("Proceed", lastNode, nextStageCosts.toNumber()),
  ]);

  if (costSummary.incurredTotal.gt(0)) {
    lastNode = new DecisionNode(`${partyLabel}'s Past`, [
      new Decision(
        "Incurred costs",
        lastNode,
        costSummary.incurredTotal.toNumber()
      ),
    ]);
  }

  return lastNode;
}

function describeWhen(
  issuesWon: LiabilityIssue[],
  issuesLost: LiabilityIssue[]
): string {
  let description = "";

  if (issuesWon.length > 0) {
    description += " Win";
    issuesWon.forEach((issue) => (description += ` '${issue.title}'`));
    if (issuesLost.length > 0) description += ". ";
  }
  if (issuesLost.length > 0) {
    description += "Lose";
    issuesLost.forEach((issue) => (description += ` '${issue.title}'`));
  }
  return description;
}

export function describeOutcome(outcome: ScenarioOutcomeForParty) {
  let description = `${outcome.getPartyName()}: `;

  const issuesWon = outcome.getLiabilitiesWonByParty();
  const issuesLost = outcome.getLiabilitiesLostByParty();
  description += describeWhen(issuesWon, issuesLost);

  let others = outcome.getLiabilitiesLostByOthers();
  if (others.length > 0) {
    description += "<br/>Others: Lose ";
    others.forEach((issue) => (description += ` '${issue.title}'`));
  }
  others = outcome.getLiabilitiesWonByOthers();
  if (others.length > 0) {
    description += "<br/>Others: Win ";
    others.forEach((issue) => (description += ` '${issue.title}'`));
  }
  return description;
}
