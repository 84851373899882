import { Box, SxProps, Theme, useTheme } from "@mui/material";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  currentTabIndex: number;
  sx?: SxProps<Theme>;
}
export function TabPanel(props: TabPanelProps) {
  const { children, currentTabIndex, index, sx } = props;

  const theme = useTheme();
  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {currentTabIndex === index && (
        <Box sx={{ py: theme.spacing(2), ...sx }}>{children}</Box>
      )}
    </div>
  );
}
