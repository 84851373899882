import Big from "big.js";

export const ZERO = new Big(0);
export const ONE = new Big(1);

export function min(b1: Big, b2: Big): Big {
  return b1.lte(b2) ? b1 : b2;
}
export function max(b1: Big, b2: Big): Big {
  return b1.gte(b2) ? b1 : b2;
}
