export default function combineCompareFns<T>(
  comparisons: ((a: T, b: T) => number)[]
): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    for (const compareFunction of comparisons) {
      const result = compareFunction(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}
