import Big from "big.js";
import { min, ZERO } from "../../big/bigUtil";
import { createParts, Part } from "./Parts";
import { ManagedPayer } from "./PayerWithLimitedFunds";

export class PlannedItem {
  done: Part[] = [];
  todo = new Set<Part>();
  paidTotal = ZERO;

  readonly amountPayablePerPayer: Map<string, Big> = new Map();
  readonly price;

  constructor(
    readonly id: string,
    itemPrice: Big,
    private readonly maxSharePerPayer: Map<string, Big>
  ) {
    if (itemPrice.lte(0))
      throw new Error(`Price must be greater than 0, not '${itemPrice}'`);
    this.price = new Big(itemPrice);

    for (const [payerId, maxShare] of maxSharePerPayer) {
      if (maxShare.lte(0)) continue;

      this.amountPayablePerPayer.set(payerId, maxShare.mul(this.price));
    }
    this.rePlan();
  }

  private remainingToPay(): Big {
    return this.price.minus(this.paidTotal);
  }

  rePlan() {
    // calculate share of remaining available spending for unpaid part
    const remainingToPay = this.remainingToPay();
    if (remainingToPay.lte(0)) {
      // nothing left to pay
      console.debug(`Nothing more to pay in '${this.id}'`);
      this.todo.clear();
      return;
    }

    console.debug(
      `Re-planning remaining ${remainingToPay} of '${
        this.id
      }' with maximum payable amounts ${[
        ...this.amountPayablePerPayer.entries(),
      ]}`
    );
    this.todo.clear();

    const remainingParts = createParts(
      remainingToPay,
      this.amountPayablePerPayer
    );
    remainingParts.forEach((part) => {
      if (part.hasAnyPayer())
        // there is a payer
        this.todo.add(part);
      // no one can pay this
      else {
        console.debug(
          `No payer for newly planned part '${part}' of '${this.id}'`
        );
        this.done.push(part);
      }
    });
    console.debug(
      `Planned '${this.id}', ${this.todo.size} parts to pay: ${[...this.todo]}`
    );
  }

  private pay(part: Part, payerId: string, amount: Big): Big {
    if (amount.lte(0)) {
      console.warn(
        `Ignoring pay ${amount} request for '${part}' by '${payerId}'`
      );
      return ZERO;
    }
    if (this.isPaid()) {
      console.warn(
        `Ignoring pay ${amount} request for '${part}' by '${payerId}', item '${this.id}' already paid`
      );
      return ZERO;
    }
    if (this.done.includes(part)) {
      console.warn(
        `Ignoring pay ${amount} request for '${part}' by '${payerId}', part already paid`
      );
      return ZERO;
    }
    if (!this.todo.has(part)) {
      console.warn(
        `Ignoring pay ${amount} request for '${part}' by '${payerId}'. Not part of item '${this.id}'`
      );
      return ZERO;
    }

    const amountPayableByPayer = this.amountPayablePerPayer.get(payerId);
    if (amountPayableByPayer === undefined) {
      console.warn(
        `Ignoring pay ${amount} request for '${part}', payer '${payerId}' has no remaining shares in '${this.id}'`
      );
      return ZERO;
    }

    // check how much payer can pay of item
    const availableAmount = min(amountPayableByPayer, amount);

    // attempt payment
    console.debug(
      `Payment attempt for '${part}' of '${this.id}' by payer '${payerId}' with ${availableAmount} available`
    );
    const result = part.pay(payerId, availableAmount);
    if (result === undefined) {
      console.debug(
        `Payment of ${availableAmount} for '${part}' of '${this.id}' by payer '${payerId}' failed`
      );
      return ZERO;
    }

    // non-zero payment was made
    this.done.push(result);
    this.paidTotal = this.paidTotal.plus(result.price);
    console.debug(
      `'${payerId}' paid ${result.price} for '${part}' of '${
        this.id
      }'. Total paid is ${this.paidTotal.toNumber()} of ${this.price.toNumber()}`
    );

    const newAmountPayable = amountPayableByPayer.minus(result.price);
    this.amountPayablePerPayer.set(
      payerId,
      newAmountPayable.lte(0) ? ZERO : newAmountPayable
    );

    if (!this.isPaid()) {
      this.rePlan();
    }
    return result.price;
  }

  isPaid(): boolean {
    return this.price.lte(this.paidTotal);
  }

  payAll(payerId: string): boolean {
    let paidAnything = false;
    this.todo.forEach((part) => {
      if (this.pay(part, payerId, part.price)) paidAnything = true;
    });
    return paidAnything;
  }

  /**
   * Re-plans the remaining ToDos without a given payer.
   *
   * @param payer no longer available
   */
  removePayer(payerId: string): boolean {
    if (!this.amountPayablePerPayer.delete(payerId)) return false;

    console.debug(
      `Removing payer '${payerId}' from '${
        this.id
      }'. All payers were: ${Array.from(this.maxSharePerPayer.keys())}`
    );

    this.rePlan();

    return true;
  }

  /**
   * Checks if payer has enough assets to pay for yet unpaid parts of this item. If not, a replanning of this item is performed. If payer has too little assets, optional payments are reduced. If payer has too little assets to pay mandatory parts, the missing amount is transformed into unpaid parts.
   * @param payer to check
   * @returns true if this item's parts needed to be replanned, otherwise false
   */
  updateAvailableAmount(payer: ManagedPayer): boolean {
    console.debug(
      `Checking if payer '${payer.id}' has enough assets to pay remaining parts in '${this.id}'`
    );
    const amountPayableByPayer = this.amountPayablePerPayer.get(payer.id);
    if (amountPayableByPayer === undefined) {
      console.debug(
        `Ignoring update of payer '${payer.id}', who has no remaining shares in '${this.id}'`
      );
      return false;
    }

    const amountAvailable = payer.getAvailableAmount(this.remainingToPay());
    if (amountAvailable.lte(0)) {
      console.debug(`Payer '${payer.id}' has no more assets`);
      return this.removePayer(payer.id);
    }

    if (amountAvailable.gte(amountPayableByPayer)) {
      // payer has enough assets available to pay for their parts
      console.debug(
        `Payer '${payer.id}' has enough assets to pay ${amountPayableByPayer} for remaining parts in '${this.id}'`
      );
      return false;
    }

    console.debug(
      `Payer '${payer.id}' has ${amountAvailable} left, which is less than ${amountPayableByPayer} for their share in '${this.id}', replanning`
    );
    this.amountPayablePerPayer.set(payer.id, amountAvailable);
    this.rePlan();
    return true;
  }

  payForExclusivePartsOf(payer: ManagedPayer): boolean {
    if (!this.amountPayablePerPayer.has(payer.id)) {
      console.info(`'${payer.id}' has no remaining shares in '${this.id}'`);
      return false;
    }

    let paidAnything = false;
    console.debug(
      `Checking what '${payer.id}' can pay of '${[...this.todo]}' of '${
        this.id
      }'`
    );
    this.todo.forEach((part) => {
      console.debug(
        `Checking if '${payer.id}' can pay '${part}' of '${this.id}'`
      );
      // only pay for exclusive parts
      if (!part.isOnlyPayer(payer.id)) return;
      if (!payer.hasFunds()) {
        console.info(
          `'${payer.id}' has no funds to pay '${part}' of '${this.id}'`
        );
        return;
      }

      const paidAmount = this.pay(
        part,
        payer.id,
        payer.getAvailableAmount(this.remainingToPay())
      );
      if (paidAmount.lte(0)) {
        console.debug(
          `Payer '${payer.id}' cannot pay for ${part} of '${this.id}'`
        );
        return;
      }
      paidAnything = true;
      payer.reduceAvailableAmountBy(paidAmount);
      console.debug(
        `'${payer.id}' paid ${paidAmount.toNumber()} for '${this.id}'`
      );
    });
    // did payer pay anything?
    return paidAnything;
  }

  isDone() {
    return this.todo.size === 0;
  }
}
