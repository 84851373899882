import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useCurrency } from "../../context/CurrencyContext";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "../issues/Claims";

export interface OutcomeCardProps {
  judgementValue: number;
  netResult: number;
  probability: number;
  numberOfJudgments: number;
  enforcement?: { worstAmount: number; costs: number };
}

export const OutcomeCard = ({
  judgementValue,
  netResult,
  probability,
  numberOfJudgments,
  enforcement,
}: OutcomeCardProps) => {
  const theme = useTheme();
  const currency = useCurrency();
  const nonEnforceable = (enforcement?.worstAmount ?? 0) - judgementValue;

  return (
    <Card
      sx={{
        maxWidth: 384,
        m: 1,
        userSelect: "none",
        borderTop: "solid 4px",
        borderTopColor:
          netResult < 0
            ? theme.palette.error.dark
            : netResult > 0
            ? theme.palette.success.main
            : "background.default",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={4}
    >
      <CardHeader
        title={
          <Tooltip
            title={<Trans i18nKey="case.analytics.outcomes.tooltip.value" />}
          >
            <Typography>{currency.format(judgementValue)}</Typography>
          </Tooltip>
        }
        subheader={"Probability: " + percentage.format(probability)}
        sx={{ pb: 0 }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexGrow: 1,
          "&:last-child": {
            padding: 0,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Trans
              i18nKey="case.analytics.outcomes.judgments.number"
              count={numberOfJudgments}
              values={{ numberOfJudgments }}
            />
            {enforcement && enforcement.worstAmount > 0 && (
              <Stack direction="row" spacing={2} mt={2} mb={1}>
                <TwoLiner
                  primary={currency.format(enforcement.worstAmount)}
                  secondary={t("case.analytics.outcomes.enforcement.value")}
                />
                <TwoLiner
                  primary={currency.format(enforcement.costs)}
                  secondary={t("case.analytics.outcomes.enforcement.costs")}
                />
              </Stack>
            )}
            {nonEnforceable != 0 && (
              <Alert
                severity={nonEnforceable < 0 ? "warning" : "error"}
                sx={{ borderRadius: theme.spacing(1), mt: theme.spacing(1) }}
              >
                <Trans
                  i18nKey={
                    nonEnforceable < 0
                      ? "case.analytics.outcomes.warning.enforcementValue"
                      : "case.analytics.outcomes.warning.debt"
                  }
                  values={{ value: currency.format(-nonEnforceable) }}
                />
              </Alert>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardContent
        sx={{
          display: "flex",
          "&:last-child": {
            padding: 0,
          },
        }}
      >
        <Box
          width="100%"
          paddingLeft={theme.spacing(2)}
          paddingRight={theme.spacing(2)}
          sx={{
            backgroundColor:
              netResult < 0
                ? theme.palette.error.main
                : netResult > 0
                ? theme.palette.success.main
                : theme.palette.text.secondary,
          }}
        >
          <Typography variant="overline" color={theme.palette.background.paper}>
            <Trans
              i18nKey="case.analytics.outcomes.netResult"
              values={{ amount: currency.format(netResult) }}
            />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
