import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography,
  lighten,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Stage, percentage } from "../../../analytics/model/CaseConfiguration";
import { useCurrency } from "../../../context/CurrencyContext";
import { NamedEntity, OfferStatus } from "../../../model/CaseModel";

import CheckboxList from "../../general/CheckboxList";
import { CurrencyTextField } from "../../general/CurrencyTextField";
import { TabPanel } from "../../general/TabPanel";
import { TwoLiner } from "../../general/TwoLiner";
import CostApportionmentAccordion from "./CostApportionmentAccordion";

export interface Offer {
  id: string;
  // party proposing offer (could be receiving or paying)
  offeror: NamedEntity;
  // party receiving offer (not necessarily the money, could be payer)
  offeree: NamedEntity;
  payment: { amount: number; offereePays: boolean };
  stage: Stage;
  status: OfferStatus;
  costs: {
    settlement: {
      offeror: number;
      offeree: number;
      apportioned?: number;
    };
    // receiving party can apportion the settlement sum towards money they spent fighting payer
    towardsPayer: {
      // incurred costs estimated in tool
      incurred: number;
      // amount apportioned
      apportioned?: number;
    };
    // receiving party can apportion the settlement sum towards their other costs of the case
    other: {
      // incurred costs (without the costs towards payer)
      incurred: number;
      // apportioned
      apportioned?: number;
    };
  };
  claims: {
    id: string;
    title: string;
    settledByOffer: boolean;
    quantumIds: string[];
  }[];
  quantums: {
    id: string;
    title: string;
    value: number;
    apportioned?: number;
  }[];
}

export interface SettlementOfferDialogProps {
  initialOffer: Offer;
  stages: Stage[];
  // if positive, offeror pays offeree
  open: boolean;
  onClose: (offer?: Offer) => void;
}

enum PaymentDirection {
  OfferorPays,
  OffereePays,
}

const SettlementOfferDialog = ({
  initialOffer,
  open,
  onClose,
  stages,
}: SettlementOfferDialogProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [offer, setOffer] = useState(initialOffer);
  const [direction, setDirection] = useState(
    offer.payment.offereePays
      ? PaymentDirection.OffereePays
      : PaymentDirection.OfferorPays
  );

  // quantums covered by the offer
  const idsOfCoveredQuantums = offer.quantums
    .filter((q) =>
      offer.claims.some((c) => c.quantumIds.includes(q.id) && c.settledByOffer)
    )
    .map((q) => q.id);

  const close = () => onClose();
  const submit = () => onClose(offer);
  const { t } = useTranslation(undefined, {
    keyPrefix: "case.analytics.settlement.offerDialog",
  });

  const currency = useCurrency();
  const theme = useTheme();
  const isNarrowDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const minWidth = isNarrowDisplay
    ? theme.breakpoints.values.sm
    : theme.breakpoints.values.md;

  const dividerColor = lighten(theme.palette.text.secondary, 1);

  const apportionedTotal =
    (offer.costs.settlement.apportioned ?? 0) +
    (offer.costs.towardsPayer.apportioned ?? 0) +
    (offer.costs.other.apportioned ?? 0) +
    offer.quantums
      .map((q) => q.apportioned ?? 0)
      .reduce((prev, current) => prev + current, 0);
  const amountRemaining = offer.payment.amount - apportionedTotal;

  const apportionmentError =
    amountRemaining > 0
      ? `${currency.format(amountRemaining)} not apportioned`
      : amountRemaining < 0
        ? `Apportionment exceeds offer by ${currency.format(-amountRemaining)}`
        : undefined;
  const claimError = !offer.claims.some((c) => c.settledByOffer)
    ? t("error.noClaimSelected")
    : undefined;
  const error = claimError ?? apportionmentError;

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        userSelect: "none",
      }}
      maxWidth="lg"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title", { offeror: offer.offeror.name })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            width: theme.spacing(5),
            height: theme.spacing(5),
            mx: theme.spacing(2),
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Tabs
        sx={{ pl: theme.spacing(3) }}
        value={value}
        onChange={handleChange}
        aria-label="settlement offer configuration"
      >
        <Tab label={t("tabs.payment")} id={"payment"} key="tab_payment" />
        <Tab label={t("tabs.claims")} id={"claims"} key="tab_claims" />
        <Tab
          label={t("tabs.apportionment")}
          id={"apportionment"}
          key="tab_apportionment"
        />
      </Tabs>
      <TabPanel
        currentTabIndex={value}
        index={0}
        key={"tabpanel_payment"}
        sx={{ py: 0, minWidth }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            height: 385,
          }}
        >
          <Typography variant="button">
            {t("sections.payment.offer.title")}
          </Typography>
          <Stack
            direction={isNarrowDisplay ? "column" : "row"}
            gap={theme.spacing(1)}
            justifyContent="space-between"
          >
            <DialogContentText>
              {t("offeror", { name: offer.offeror.name })}
            </DialogContentText>
            <DialogContentText>
              {t("offeree", { name: offer.offeree.name })}
            </DialogContentText>
          </Stack>
          <FormControl
            key={"direction-selection"}
            id="direction-selection"
            fullWidth
            sx={{ minWidth: 220, mt: theme.spacing(1) }}
          >
            <InputLabel>{t("direction.label")}</InputLabel>
            <Select
              value={direction}
              label={t("direction.label")}
              onChange={(e: SelectChangeEvent<number>) => {
                const value = e.target.value;
                if (typeof value !== "number" || value === direction) return;
                setDirection(value);
                const payment = { ...offer.payment };
                payment.offereePays = value === PaymentDirection.OffereePays;
                setOffer({
                  ...offer,
                  payment,
                });
              }}
              labelId="direction-select-label"
              id="direction-select-label"
              disabled={offer.status !== OfferStatus.Draft}
            >
              <MenuItem key="offeree_pays" value={PaymentDirection.OffereePays}>
                {t("direction.offereePays", {
                  offeror: offer.offeror.name,
                  offeree: offer.offeree.name,
                })}
              </MenuItem>
              <MenuItem key="offeror_pays" value={PaymentDirection.OfferorPays}>
                {t("direction.offerorPays", {
                  offeror: offer.offeror.name,
                  offeree: offer.offeree.name,
                })}
              </MenuItem>
            </Select>
          </FormControl>

          <Stack
            direction={isNarrowDisplay ? "column" : "row"}
            gap={theme.spacing(2)}
            alignItems="end"
          >
            <FormControl
              key={"amount-section"}
              fullWidth
              sx={{ mt: 1, minWidth: 220 }}
              required
            >
              <CurrencyTextField
                currency={currency}
                label={`${t("amount")}`}
                onValueChange={(newAmount) => {
                  const payment = { ...offer.payment };
                  payment.amount = newAmount;
                  setOffer({ ...offer, payment });
                }}
                required
                variant="outlined"
                min={0}
                value={offer.payment.amount}
                disabled={offer.status !== OfferStatus.Draft}
              />
            </FormControl>
            <FormControl
              key={"stage-selection"}
              fullWidth
              sx={{ mt: 2, minWidth: 220 }}
            >
              <InputLabel id="stage-select-label">{t("stage")}</InputLabel>
              <Select
                value={offer.stage}
                label={t("stage")}
                onChange={(e: SelectChangeEvent<number>) => {
                  const value = e.target.value;
                  if (typeof value !== "number") return;

                  const newOffer: Offer = { ...offer, stage: value };
                  setOffer(newOffer);
                }}
                labelId="stage-select-label"
                id="stage-select-label"
                disabled={offer.status !== OfferStatus.Draft}
              >
                {stages.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    <Trans i18nKey={`case.stages.${Stage[stage]}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="button">
            {t("sections.payment.costs.title")}
          </Typography>
          <Stack
            direction={isNarrowDisplay ? "column" : "row"}
            gap={theme.spacing(2)}
            alignItems="end"
          >
            <FormControl
              key={"costs-offeror"}
              fullWidth
              sx={{ mt: 1, minWidth: 220 }}
              required
            >
              <CurrencyTextField
                currency={currency}
                label={`${t("costs.offeror", { name: offer.offeror.name })}`}
                onValueChange={(costs) => {
                  const newOffer: Offer = JSON.parse(JSON.stringify(offer));
                  newOffer.costs.settlement.offeror = costs;
                  setOffer(newOffer);
                }}
                required
                variant="outlined"
                min={0}
                value={offer.costs.settlement.offeror}
                disabled={offer.status !== OfferStatus.Draft}
              />
            </FormControl>
            <FormControl
              key={"costs-offeree"}
              fullWidth
              sx={{ mt: 1, minWidth: 220 }}
              required
            >
              <CurrencyTextField
                currency={currency}
                label={`${t("costs.offeree", { name: offer.offeree.name })}`}
                onValueChange={(costs) => {
                  const newOffer: Offer = JSON.parse(JSON.stringify(offer));
                  newOffer.costs.settlement.offeree = costs;
                  setOffer(newOffer);
                }}
                required
                variant="outlined"
                min={0}
                value={offer.costs.settlement.offeree}
                disabled={offer.status !== OfferStatus.Draft}
              />
            </FormControl>
          </Stack>
        </DialogContent>
      </TabPanel>

      <TabPanel
        currentTabIndex={value}
        index={1}
        key={"tabpanel_claims"}
        sx={{ py: 0, minWidth }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            height: 385,
          }}
        >
          <Typography variant="button">{t("sections.claims.title")}</Typography>
          <FormControl
            required
            error={claimError !== undefined}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">
              {t("sections.claims.legend")}
            </FormLabel>
            <FormGroup>
              <CheckboxList
                elements={offer.claims.map(
                  ({ id, title, settledByOffer: coveredByOffer }) => ({
                    id,
                    title,
                    selected: coveredByOffer,
                  })
                )}
                onSelect={(...claimIds) => {
                  const newOffer: Offer = JSON.parse(JSON.stringify(offer));
                  newOffer.claims.forEach((claim) => {
                    if (claimIds.includes(claim.id))
                      claim.settledByOffer = true;
                  });
                  setOffer(newOffer);
                }}
                onUnselect={(...claimIds) => {
                  const newOffer: Offer = JSON.parse(JSON.stringify(offer));
                  newOffer.claims.forEach((claim) => {
                    if (claimIds.includes(claim.id))
                      claim.settledByOffer = false;
                  });
                  setOffer(newOffer);
                }}
                disabled={offer.status !== OfferStatus.Draft}
              />
            </FormGroup>
            {claimError && <FormHelperText>{claimError}</FormHelperText>}
          </FormControl>
        </DialogContent>
      </TabPanel>

      <TabPanel
        currentTabIndex={value}
        index={2}
        key={"tabpanel_apportionment"}
        sx={{ py: 0, minWidth, display: "flex", flexDirection: "row" }}
      >
        <DialogContent
          sx={{
            height: 385,
            pt: theme.spacing(1),
          }}
        >
          <Typography variant="overline">
            {t("sections.apportionment.costs.title")}
          </Typography>
          <CostApportionmentAccordion
            key="settlement_costs"
            disableGutters
            elevation={2}
            title={
              direction === PaymentDirection.OffereePays
                ? t("costs.offeror", { name: offer.offeror.name })
                : t("costs.offeree", { name: offer.offeree.name })
            }
            secondaryTitle={currency.format(
              offer.costs.settlement.apportioned ?? 0
            )}
            costLabel="Apportionment"
            // value={direction === PaymentDirection.OffereePays ? offer.costs.settlement.offeror}
            value={offer.costs.settlement.apportioned ?? 0}
            suggestedValue={{
              label: "Full costs",
              value:
                direction === PaymentDirection.OffereePays
                  ? offer.costs.settlement.offeror
                  : offer.costs.settlement.offeree,
            }}
            max={Math.min(
              direction === PaymentDirection.OffereePays
                ? offer.costs.settlement.offeror
                : offer.costs.settlement.offeree,
              amountRemaining
            )}
            onValueChange={(newValue) => {
              const newOffer: Offer = JSON.parse(JSON.stringify(offer));
              newOffer.costs.settlement.apportioned = newValue;
              setOffer(newOffer);
            }}
            disabled={offer.status !== OfferStatus.Draft}
          />
          <Divider sx={{ borderColor: dividerColor }} />
          <CostApportionmentAccordion
            key="costs_towards_opponent"
            disableGutters
            elevation={2}
            title={t("costs.towardsPayer", {
              opponent:
                direction === PaymentDirection.OfferorPays
                  ? offer.offeror.name
                  : offer.offeree.name,
            })}
            costLabel="Apportionment"
            secondaryTitle={currency.format(
              offer.costs.towardsPayer.apportioned ?? 0
            )}
            value={offer.costs.towardsPayer.apportioned ?? 0}
            description={
              "Sum of all incurred costs apportioned towards the party"
            }
            suggestedValue={{
              label: "Incurred costs",
              value: offer.costs.towardsPayer.incurred,
            }}
            onValueChange={(newValue) => {
              const newOffer: Offer = JSON.parse(JSON.stringify(offer));
              newOffer.costs.towardsPayer.apportioned = newValue;
              setOffer(newOffer);
            }}
            disabled={offer.status !== OfferStatus.Draft}
          />
          <Divider sx={{ borderColor: dividerColor }} />
          <CostApportionmentAccordion
            key="costs_towards_others"
            disableGutters
            elevation={2}
            title={t("costs.other")}
            costLabel="Apportionment"
            secondaryTitle={currency.format(offer.costs.other.apportioned ?? 0)}
            value={offer.costs.other.apportioned ?? 0}
            suggestedValue={{
              label: "Other incurred costs",
              value: offer.costs.other.incurred,
            }}
            onValueChange={(newValue) => {
              const newOffer: Offer = JSON.parse(JSON.stringify(offer));
              newOffer.costs.other.apportioned = newValue;
              setOffer(newOffer);
            }}
            max={amountRemaining + (offer.costs.other.apportioned ?? 0)}
            disabled={offer.status !== OfferStatus.Draft}
          />
          <Typography variant="overline">
            {t("sections.apportionment.quantums.title")}
          </Typography>
          {offer.quantums.map((quantum, index) => (
            <Fragment key={index}>
              <CostApportionmentAccordion
                key={index}
                disabled={
                  !idsOfCoveredQuantums.includes(quantum.id) ||
                  offer.status !== OfferStatus.Draft
                }
                disableGutters
                elevation={2}
                title={quantum.title}
                costLabel="Apportionment"
                value={quantum.apportioned ?? 0}
                suggestedValue={{
                  label: "Possible maximum",
                  value: Math.min(quantum.value, offer.payment.amount),
                }}
                onValueChange={(newValue) => {
                  const newOffer: Offer = JSON.parse(JSON.stringify(offer));
                  const newQuantum = newOffer.quantums.find(
                    (q) => q.id === quantum.id
                  );
                  if (!newQuantum) return;
                  newQuantum.apportioned = newValue;
                  setOffer(newOffer);
                }}
                max={Math.min(quantum.value, offer.payment.amount)}
                secondaryTitle={
                  !idsOfCoveredQuantums.includes(quantum.id)
                    ? "Not covered by offer"
                    : `${percentage.format(quantum.value === 0 ? 1 : (quantum.apportioned ?? 0) / quantum.value)} of ${currency.format(quantum.value)}`
                }
              />
              {index < offer.quantums.length - 1 && (
                <Divider sx={{ borderColor: dividerColor }} />
              )}
            </Fragment>
          ))}
        </DialogContent>
        <Stack
          direction="column"
          gap={theme.spacing(1)}
          px={theme.spacing(3)}
          pt={theme.spacing(2)}
          mb={theme.spacing(1)}
        >
          <TwoLiner
            key="amount"
            primary={currency.format(offer.payment.amount)}
            secondary={"Settlement amount"}
          />
          <TwoLiner
            key="apportioned"
            primary={currency.format(apportionedTotal)}
            primaryColor={
              amountRemaining < 0
                ? theme.palette.error.main
                : amountRemaining === 0
                  ? theme.palette.success.main
                  : theme.palette.text.primary
            }
            secondaryColor={
              amountRemaining < 0
                ? theme.palette.error.main
                : amountRemaining === 0
                  ? theme.palette.success.main
                  : theme.palette.text.primary
            }
            secondary={
              amountRemaining === 0 ? "100% apportioned" : "Apportioned"
            }
          />
          {amountRemaining !== 0 && (
            <TwoLiner
              key="remaining"
              primary={currency.format(Math.abs(amountRemaining))}
              primaryColor={theme.palette.error.main}
              secondary={amountRemaining > 0 ? "Remaining" : "Exceeding"}
              secondaryColor={theme.palette.error.main}
            />
          )}
        </Stack>
      </TabPanel>
      <Divider />
      {error ? (
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Typography
            color={theme.palette.error.main}
            variant="body2"
            ml={theme.spacing(2)}
          >
            {error}
          </Typography>

          <Stack direction="row" gap={theme.spacing(1)}>
            <Button key="cancel" onClick={close}>
              {t("cancel")}
            </Button>
            <Button
              key="submit"
              onClick={submit}
              variant="contained"
              autoFocus
              disabled
            >
              {t("submit")}
            </Button>
          </Stack>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button key="cancel" onClick={close}>
            {t("cancel")}
          </Button>
          <Button
            key="submit"
            onClick={submit}
            variant="contained"
            autoFocus
            disabled={error !== undefined}
          >
            {t("submit")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SettlementOfferDialog;
