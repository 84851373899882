import { currency, percentage } from "../analytics/model/CaseConfiguration";
import { Payer, PaymentPlan } from "../analytics/payment/Payment";
import { PaymentObligation } from "../analytics/payment/PaymentObligation";
import { PaymentPool } from "../analytics/payment/PaymentPool";
import { ZERO } from "../big/bigUtil";
import { Decision, DecisionNode, EndNode, TreeNode } from "./tree";

export function createPaymentTree(
  paymentPool: PaymentPool<PaymentObligation>,
  party: Payer,
  names: { [id: string]: string }
): TreeNode {
  const plans = paymentPool.getPaymentPlans();

  // go through items in their order
  // for each item, use same payer order

  // tree is built backwards, so start with end nodes
  const lastBranches = new Map<PaymentPlan, Decision>();
  plans.forEach((plan) => {
    const summary = new DecisionNode(
      getPlanDescription(
        plan,
        paymentPool.payers,
        paymentPool.items,
        party,
        names
      ),
      [new Decision("Balance check", new EndNode(0), 0)]
    );
    lastBranches.set(plan, new Decision("Summary", summary, 0));
  });

  // loop in reverse order
  for (let index = paymentPool.items.length - 1; index >= 0; index--) {
    const item = paymentPool.items[index];
    for (let [plan, lastDecision] of lastBranches) {
      const itemPayments = plan.itemPayments.get(item.id);

      // in each plan, create nodes for payer (either paying or not)
      paymentPool.payers.forEach((payer) => {
        const amountPaid = itemPayments?.getAmountPaidBy(payer.payerId);
        if (amountPaid === undefined) {
          return;
        }
        const nextDecisionName = `${payer.name || payer.payerId} pays`;

        const node = new DecisionNode(
          `${payer.name || payer.payerId} paid ${percentage.format(
            amountPaid.div(item.price).toNumber()
          )} to ${names[item.recipientId]}`,
          [lastDecision]
        );
        const costForParty =
          payer.payerId === party.payerId
            ? amountPaid
            : item.recipientId === party.payerId
              ? amountPaid.neg()
              : ZERO;
        lastDecision = new Decision(
          nextDecisionName,
          node,
          costForParty.toNumber()
        );
      });
      lastBranches.set(plan, lastDecision);
    }
  }

  const decisions: Decision[] = [];
  for (const [, lastDecision] of lastBranches) {
    decisions.push(lastDecision);
  }

  return new DecisionNode(
    getRootDescription(paymentPool, party, names),
    decisions
  );
}

function getRootDescription(
  paymentPool: PaymentPool<PaymentObligation>,
  party: Payer,
  names: { [id: string]: string }
): string {
  let description = "Items to pay for:";
  description += `<br/>(from ${party.name || party.payerId}'s perspective)`;
  paymentPool.items.forEach((item) => {
    description += `<br/><br/>${currency.format(item.price.toNumber())} to ${
      names[item.recipientId]
    }`;

    description += `<br/>Payable by:`;
    paymentPool.payers.forEach((payer) => {
      const share = item.maxSharePerPayer.get(payer.payerId);
      if (share === undefined || share.lte(0)) return;
      description += `<br/>\t${
        payer.name || payer.payerId
      }: ${percentage.format(share.toNumber())}`;
    });
  });
  description += "<br/><br/>Payer assets:";
  paymentPool.payers
    .sort((p1, p2) => p1.payerId.localeCompare(p2.payerId))
    .forEach(
      (payer) =>
        (description += `<br/>${payer.name || payer.payerId}: ${
          payer.availableAmount
            ? currency.format(payer.availableAmount.toNumber())
            : "unlimited"
        }`)
    );
  description += "<br/>";
  return description;
}

function getPlanDescription(
  plan: PaymentPlan,
  payers: Payer[],
  items: PaymentObligation[],
  party: Payer,
  names: { [id: string]: string }
): string {
  let description = plan.isCompleteFor(items.map((item) => item.id))
    ? "Payment complete ✔"
    : "Payment incomplete ✖";
  [...items].sort().forEach((item) => {
    const payment = plan.itemPayments.get(item.id);
    if (!payment) {
      description += `<br/>${currency.format(item.price.toNumber())} for ${
        names[item.recipientId]
      } unpaid ✖`;
    } else {
      if (!payment.isComplete()) {
        description += `<br/>${currency.format(
          payment.getAmountUnpaid().toNumber()
        )} for ${names[item.recipientId]} unpaid ✖`;
      } else {
        description += `<br/>${currency.format(
          payment.itemPrice.toNumber()
        )} for ${names[item.recipientId]} ✔`;
      }
    }
  });

  description += "<br/>";
  const priceTotal = plan.getTotalPrice();
  payers
    .sort((p1, p2) => p1.payerId.localeCompare(p2.payerId))
    .forEach((payer) => {
      const amountPaidByPayer = plan.getAmountPaidBy(payer.payerId);
      description += `<br/>${
        payer.name || payer.payerId
      } paid ${currency.format(amountPaidByPayer)} (${percentage.format(
        amountPaidByPayer / priceTotal
      )})`;
    });

  description += "<br/>";
  description += `<br/>${party.name || party.payerId}'s perspective:`;
  return description;
}
