import AddIcon from "@mui/icons-material/AddBox";
import { Button, useTheme } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../../context/CaseContext";
import { Claim } from "../../model/CaseModel";
import randomId from "../../utils/randomId";
import { ClaimCallbacks as CardClaimCallbacks, ClaimCard } from "./ClaimCard";
import { ClaimEditorDialog } from "./ClaimEditorDialog";
import { Claim as CardClaim } from "./IssueModel";
import { convertFrom, convertTo } from "./IssueModelConverter";

export interface ClaimCallbacks {
  add: (claim: Claim) => void;
  update: (claim: Claim) => void;
  delete: (claim: Claim) => void;
}

export const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 2,
});

interface QuantumNames {
  [quantumId: string]: string;
}
interface QuantumNamesPerClaimant {
  // quantum id:name mappings, per claimant
  [claimantId: string]: QuantumNames;
}

function getAvailableQuantums(
  claim: CardClaim,
  allQuantumNames: QuantumNamesPerClaimant
): QuantumNames {
  const quantumIdsOfClaim = claim.quantums?.map((q) => q.id) ?? [];

  const quantumsOfClaimant = allQuantumNames[claim.claimant.id];

  const availableQuantums: QuantumNames = {};
  // only allow quantum names that are not yet defined in claim
  for (const quantumId in quantumsOfClaimant) {
    if (!quantumIdsOfClaim.includes(quantumId))
      availableQuantums[quantumId] = quantumsOfClaimant[quantumId];
  }
  return availableQuantums;
}

function getAllQuantumNames(claims?: Claim[]): QuantumNamesPerClaimant {
  if (claims === undefined || claims.length === 0) return {};

  const allQuantumNames: QuantumNamesPerClaimant = {};
  claims.forEach((claim) => {
    const quantumsOfClaimant: QuantumNames =
      allQuantumNames[claim.claimant.id] ?? {};
    allQuantumNames[claim.claimant.id] = quantumsOfClaimant;

    claim.quantums?.forEach(
      (quantum) => (quantumsOfClaimant[quantum.id] = quantum.name)
    );
  });
  return allQuantumNames;
}

export const Claims = () => {
  const caseContext = useCase();
  const { claims, parties } = caseContext.getCase();

  const [newClaimEditorOpen, setNewClaimEditorOpen] = useState(false);
  const claimCanBeCreated = parties && parties.length > 0;

  const submit = useSubmit();
  const claimCallbacks: ClaimCallbacks = {
    add: function (claim: Claim): void {
      submit({ claim: JSON.stringify(claim) }, { method: "post" });
    },
    update: function (claim: Claim): void {
      submit({ claim: JSON.stringify(claim) }, { method: "put" });
    },
    delete: function (claim: Claim): void {
      submit({ claimId: claim.id }, { method: "delete" });
    },
  };

  const allQuantumNames = useMemo(() => getAllQuantumNames(claims), [claims]);

  // convert claims to format accepted by the card
  const cardClaims: CardClaim[] = useMemo(
    () =>
      claims ? claims.map((claim) => convertTo(claim, parties ?? [])) : [],
    [claims, parties]
  );

  const callbacks: CardClaimCallbacks = {
    onClaimDelete: (claim) => claimCallbacks.delete(convertFrom(claim)),
    onClaimUpdate: (claim) => claimCallbacks.update(convertFrom(claim)),
  };

  const theme = useTheme();

  return (
    <>
      {cardClaims?.map((claim, index) => (
        <ClaimCard
          claim={claim}
          callbacks={callbacks}
          ordinal={`${index + 1}.`}
          key={"claim_" + index}
          parties={parties || []}
          availableQuantumNames={getAvailableQuantums(claim, allQuantumNames)}
        />
      ))}
      <Button
        disabled={!claimCanBeCreated}
        onClick={() => {
          // open claim editor
          setNewClaimEditorOpen(true);
        }}
        title={`${t("case.claims.tip.add")}`}
        startIcon={<AddIcon />}
        variant="text"
        sx={{ mb: theme.spacing(1) }}
      >
        <Trans i18nKey="case.claims.add" />
      </Button>
      {newClaimEditorOpen && (
        <ClaimEditorDialog
          onClose={(claim?) => {
            setNewClaimEditorOpen(false);
            if (claim) claimCallbacks.add({ ...claim, id: randomId() });
          }}
          open={newClaimEditorOpen}
          parties={parties}
        />
      )}
    </>
  );
};
