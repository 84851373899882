import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { Claim, NamedEntity } from "../model/CaseModel";

export interface IssueEditorProps {
  // parent
  parentClaim: Claim;
  title?: string;
  defendants?: NamedEntity[];
  parties?: NamedEntity[];
  generic?: boolean;
  // is dialog open?
  open: boolean;
  onClose: (claimTemplate?: {
    title: string;
    defendants: NamedEntity[];
    generic?: boolean;
  }) => void;
}

function isValidClaimTitle(claimTitle?: string): boolean {
  return claimTitle !== undefined && claimTitle.trim().length > 0;
}

export const IssueEditorDialog = ({
  parentClaim,
  open,
  onClose,
  title: initialTitle = "",
  generic: initialGeneric,
  defendants = [],
  parties = [],
}: IssueEditorProps) => {
  const [title, setTitle] = useState(initialTitle);
  const [generic, setGeneric] = useState(initialGeneric);
  const [defendantIds, setDefendantIds] = useState<string[]>(
    defendants.map((defendant) => defendant.id)
  );

  const [defendantsAsInParent, setDefendantsAsInParent] = useState(
    parentClaim.defendants?.find((d) => !defendantIds.includes(d.id)) ===
      undefined
  );

  const canSubmit = isValidClaimTitle(title);

  const availableDefendants = parties.filter(
    (party) => party.id !== parentClaim.claimant.id
  );

  const handleDefendantsChange = (
    event: SelectChangeEvent<typeof defendantIds>
  ) => {
    const {
      target: { value },
    } = event;
    setDefendantIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const close = () => onClose();
  const submit = () => {
    if (defendantsAsInParent) {
      onClose({ title, defendants: parentClaim.defendants || [], generic });
      return;
    }
    const defendants = parties.filter((party) =>
      defendantIds.includes(party.id)
    );
    onClose({ title, defendants, generic });
  };

  // translate once, use twice
  const defendantsLabel = t(
    parties.length === 0
      ? "case.claims.issueDialog.label.noParties"
      : "case.claims.issueDialog.label.defendants"
  );

  return (
    <Dialog
      sx={{ userSelect: "none" }}
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans
          i18nKey="case.claims.issueDialog.title"
          values={{ parentClaim: parentClaim.title }}
        />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="button">
          <Trans i18nKey="case.claims.issueDialog.sections.title.label" />
        </Typography>
        <DialogContentText mt={1} mb={1}>
          <Trans i18nKey="case.claims.issueDialog.sections.title.description" />
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label={`${t("case.claims.issueDialog.tip.title")}`}
          type="text"
          fullWidth
          required
          variant="outlined"
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
          }}
          error={!isValidClaimTitle(title)}
          helperText={
            !isValidClaimTitle(title)
              ? t("case.claims.issueDialog.helperText.missingTitle")
              : undefined
          }
        />
      </DialogContent>
      <Divider />
      <DialogContent>
        <Typography variant="button">
          <Trans i18nKey="case.claims.issueDialog.sections.genericIssue.label" />
        </Typography>
        <DialogContentText mt={1}>
          <Trans
            i18nKey="case.claims.issueDialog.sections.genericIssue.description"
            values={{ claimant: parentClaim.claimant.name }}
          />
        </DialogContentText>
        <FormControlLabel
          title={`${t("case.claims.issueDialog.tip.genericIssue")}`}
          control={
            <Switch
              checked={generic === true}
              onChange={(e) => setGeneric(e.target.checked)}
            />
          }
          label={`${t("case.claims.issueDialog.genericIssue")}`}
          labelPlacement="end"
        />
      </DialogContent>
      <Divider />
      <DialogContent>
        <Typography variant="button">
          <Trans i18nKey="case.claims.issueDialog.sections.defendants.label" />
        </Typography>
        <DialogContentText mt={1}>
          <Trans
            i18nKey="case.claims.issueDialog.sections.defendants.description"
            values={{ claimant: parentClaim.claimant.name }}
          />
        </DialogContentText>
        <FormControlLabel
          title={`${t("case.claims.issueDialog.tip.parentDefendants")}`}
          control={
            <Switch
              checked={defendantsAsInParent === true}
              onChange={(e) => setDefendantsAsInParent(e.target.checked)}
            />
          }
          disabled={generic}
          label={`${t("case.claims.issueDialog.parentDefendants")}`}
          labelPlacement="end"
        />
        <Collapse in={defendantsAsInParent !== true}>
          <FormControl
            key={"defendants-selection"}
            fullWidth
            sx={{ mt: 2, minWidth: 220 }}
            disabled={
              generic ||
              defendantsAsInParent ||
              availableDefendants.length === 0
            }
          >
            <InputLabel id="defendants-select-label">
              {defendantsLabel}
            </InputLabel>
            <Select
              multiple
              value={defendantIds}
              label={defendantsLabel}
              onChange={handleDefendantsChange}
              labelId="defendants-select-label"
              renderValue={(selected) =>
                parties
                  .filter((p) => selected.includes(p.id))
                  .map((p) => p.name)
                  .sort()
                  .join(", ")
              }
            >
              {availableDefendants
                .sort((p1, p2) => p1.name.localeCompare(p2.name))
                .map((party) => (
                  <MenuItem key={party.id} value={party.id}>
                    <Checkbox checked={defendantIds.includes(party.id)} />
                    <ListItemText primary={party.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Collapse>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={close}>
          <Trans i18nKey="case.claims.issueDialog.cancel" />
        </Button>
        <Button onClick={submit} variant="contained" disabled={!canSubmit}>
          <Trans i18nKey="case.claims.issueDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
