import React from "react";
import { User } from "./User";

export interface AuthContextProps {
  currentUser: User | undefined;
  login: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextProps>({
  currentUser: undefined,
  login: async () => {
    throw new Error("Not initialized");
  },
  logout: async () => {
    throw new Error("Not initialized");
  },
});
