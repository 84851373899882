import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "react-router-dom";
import { Case } from "../model/CaseModel";
import { Copyright } from "./Copyright";

export const CaseList = ({ cases = [] }: { cases?: Case[] }) => {
  const navigate = useNavigate();
  const [removedCase, setRemovedCase] = React.useState<Case>();

  const handleRemoveClick = (c: Case) => {
    setRemovedCase(c);
  };

  const handleClose = () => {
    setRemovedCase(undefined);
  };

  return (
    <Box mt={2}>
      <Box mt={2}>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          <Trans i18nKey="case.list.label" />
        </Typography>
      </Box>
      {cases.map((c) => (
        <Box key={c.id} mt={2}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {c.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                <Trans
                  i18nKey="case.list.card.caseId"
                  values={{ caseId: c.id }}
                />
              </Typography>
              {c.parties && c.parties.length > 0 && (
                <Typography
                  key="claimants"
                  variant="body2"
                  component="p"
                  gutterBottom
                >
                  <Trans
                    i18nKey="case.list.card.parties"
                    values={{
                      claimants: c.parties
                        .map((party) => party.name)
                        .join(", "),
                    }}
                  />
                </Typography>
              )}
              {c.updatedAt && (
                <Typography key="updatedAt" variant="body2" component="p">
                  <Trans
                    i18nKey="case.list.card.updatedAt"
                    values={{
                      date: new Date(c.updatedAt).toLocaleString(),
                    }}
                  />
                </Typography>
              )}
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                userSelect: "none",
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => navigate("/cases/" + c.id)}
              >
                <Trans i18nKey="case.list.card.open" />
              </Button>
              <Tooltip title={<Trans i18nKey="case.list.card.delete" />}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleRemoveClick(c)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Box>
      ))}
      <Dialog
        open={removedCase !== undefined}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans
            i18nKey="case.list.deleteDialog.title"
            values={{
              id: removedCase?.id,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans
              i18nKey="case.list.deleteDialog.question"
              values={{
                name: removedCase?.name,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <Form method="post" action={`/cases/${removedCase?.id}/delete`}>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              autoFocus
              startIcon={<DeleteIcon />}
              type="submit"
            >
              <Trans i18nKey="case.list.deleteDialog.delete" />
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              <Trans i18nKey="case.list.deleteDialog.cancel" />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Box mt={2} mb={2}>
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          onClick={() => navigate("/addCase")}
        >
          <AddIcon />
          <Trans i18nKey="case.list.new" />
        </Fab>
      </Box>
      <Copyright />
    </Box>
  );
};
