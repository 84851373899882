import { Box, CssBaseline } from "@mui/material";
import { RouterProvider, redirect } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import GetCases from "./api/GetCases";
import Home from "./api/Home";
import {
  ParseCaseApiProvider,
  useCaseApi,
} from "./api/case/parse/ParseCaseClient";
import CaseCreator from "./components/CaseCreator";
import { CaseOverview } from "./components/CaseOverview";
import { CasePage } from "./components/CasePage";
import ErrorPage from "./components/ErrorPage";
import { Parties } from "./components/Parties";
import { CaseOutcomes } from "./components/analytics/CaseOutcomes";
import { DecisionTree } from "./components/analytics/DecisionTree";
import { EvAnalytics } from "./components/analytics/EvAnalytics";
import { NetResultAnalytics } from "./components/analytics/NetResultAnalytics";
import { RevenueAnalytics } from "./components/analytics/RevenueAnalytics";
import { RiskAnalytics } from "./components/analytics/RiskAnalytics";
import { SensitivityAnalysis } from "./components/analytics/SensitivityAnalysis";
import { ValueAtRiskAnalytics } from "./components/analytics/ValueAtRiskAnalytics";
import { BargainingAnalytics } from "./components/analytics/settlement/BargainingAnalytics";
import { SettlementAnalytics } from "./components/analytics/settlement/SettlementAnalytics";
import { LoggedInPage } from "./components/auth/LoggedInPage";
import { ParseAuthProvider, useAuth } from "./components/auth/ParseAuthContext";
import SignIn from "./components/auth/SignIn";
import { Budgeting } from "./components/costs/Budgeting";
import { CostApportionments } from "./components/costs/CostApportionments";
import { CostRecoveries } from "./components/costs/CostRecoveries";
import { LawFirmFees } from "./components/costs/LawFirmFees";
import { Claims } from "./components/issues/Claims";
import { PortfolioPage } from "./components/portfolio/PortfolioPage";
import { CaseContextProvider } from "./context/CaseContext";
import {
  Claim,
  Configuration,
  CostStructure,
  Party,
  SettlementOffer,
} from "./model/CaseModel";
import { Budget } from "./model/CostModel";
import randomId from "./utils/randomId";

function AuthCheck() {
  // check login
  const { currentUser, login } = useAuth();

  return currentUser === undefined ? (
    <SignIn login={login} />
  ) : (
    <ParseCaseApiProvider>
      <AppRoutes />
    </ParseCaseApiProvider>
  );
}

function getString(formData: FormData, key: string): string {
  const value = formData.get(key);
  if (typeof value !== "string") {
    throw `Expected '${key}' value '${value}' to be a string`;
  }
  return value;
}

function AppRoutes() {
  const caseApi = useCaseApi();
  const { currentUser } = useAuth();

  // use CaseApi for loaders & actions in the routes
  async function casesLoader() {
    const cases = await caseApi.queryAllCases();
    return { cases };
  }

  async function caseLoader({ params }: { params: { caseId?: string } }) {
    if (params.caseId === undefined) return;
    const c = await caseApi.queryCase(params.caseId);
    return { c };
  }

  async function addCase({ request }: { request: Request }) {
    const formData = await request.formData();
    const caseName = getString(formData, "name");
    const caseId = randomId();
    return await caseApi.addCase({ id: caseId, name: caseName }).then((c) => {
      // id may get rewritten by server
      return redirect(`/cases/${c.id}`);
    });
  }

  async function deleteCase({ params }: { params: { caseId?: string } }) {
    if (params.caseId === undefined) throw "Case ID missing";
    await caseApi.removeCase(params.caseId);
    return redirect(`/cases`);
  }

  async function setTitle({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    const title: string = getString(formData, "title");
    return caseApi.setTitle(params.caseId, title);
  }

  async function updateParty({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT") {
      const update: Party = JSON.parse(getString(formData, "party"));
      return caseApi.updateParty(params.caseId, update);
    }
    if (request.method === "POST") {
      if (formData.has("party"))
        return caseApi.addParty(
          params.caseId,
          JSON.parse(getString(formData, "party"))
        );
      return caseApi.addParty(params.caseId, {
        id: getString(formData, "partyId"),
        name: getString(formData, "name"),
        client: formData.get("client") === "true",
      });
    }
    if (request.method === "DELETE")
      return caseApi.removeParty(params.caseId, getString(formData, "partyId"));
    console.warn("Unknown request method", request);
  }

  async function updateClaims({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT") {
      const claim: Claim = JSON.parse(getString(formData, "claim"));
      return caseApi.updateClaim(params.caseId, claim);
    }
    if (request.method === "POST") {
      const claim: Claim = JSON.parse(getString(formData, "claim"));
      return caseApi.addClaim(params.caseId, claim);
    }
    if (request.method === "DELETE")
      return caseApi.removeClaim(params.caseId, getString(formData, "claimId"));
    console.warn("Unknown request method", request);
  }

  async function updateSettlementOffers({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT") {
      const offer: SettlementOffer = JSON.parse(getString(formData, "offer"));
      return caseApi.updateOffer(params.caseId, offer);
    }
    if (request.method === "POST") {
      const offer: SettlementOffer = JSON.parse(getString(formData, "offer"));
      return caseApi.addOffer(params.caseId, offer);
    }
    if (request.method === "DELETE")
      return caseApi.removeOffer(params.caseId, getString(formData, "offerId"));
    console.warn("Unknown request method", request);
  }

  async function updateBudgets({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT") {
      const budget: Budget = JSON.parse(getString(formData, "budget"));
      return caseApi.updateBudget(params.caseId, budget);
    }
    console.warn("Unsupported request method", request);
  }

  async function updateCostStructure({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT") {
      const costStructure: CostStructure = JSON.parse(
        getString(formData, "costStructure")
      );
      return caseApi.updateCostStructure(params.caseId, costStructure);
    }
    console.warn("Unsupported request method", request);
  }

  async function setBargainingVariant({
    request,
    params,
  }: {
    request: Request;
    params: { caseId?: string };
  }) {
    if (params.caseId === undefined) throw "Case ID missing";
    const formData = await request.formData();
    if (request.method === "PUT" || request.method === "POST") {
      const variant: Configuration = JSON.parse(getString(formData, "variant"));
      await caseApi.setBargainingVariant(params.caseId, variant);
      return "ok";
    }
    console.warn("Unsupported request method", request);
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoggedInPage />,
      children: [
        {
          path: "/addCase",
          element: <CaseCreator />,
          action: addCase,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/portfolio",
          element: <PortfolioPage />,
          loader: casesLoader,
        },
        {
          path: "/cases",
          element: <GetCases />,
          loader: casesLoader,
        },
        {
          path: "/cases/:caseId/",
          element: (
            <CaseContextProvider>
              <CasePage />
            </CaseContextProvider>
          ),
          loader: caseLoader,
          children: [
            {
              index: true,
              element: <CaseOverview />,
              action: setTitle,
            },
            {
              path: "delete",
              action: deleteCase,
            },
            {
              path: "configuration",
              children: [
                {
                  path: "parties",
                  element: <Parties />,
                  action: updateParty,
                },
                {
                  path: "claims",
                  element: <Claims />,
                  action: updateClaims,
                },
                {
                  path: "costs",
                  element: <Budgeting />,
                  action: updateBudgets,
                },
                {
                  path: "apportionments",
                  element: <CostApportionments />,
                  action: updateParty,
                },
                {
                  path: "costStructure",
                  element: <LawFirmFees />,
                  action: updateCostStructure,
                },
                {
                  path: "recoveries",
                  element: <CostRecoveries />,
                  action: updateParty,
                },
              ],
            },
            {
              path: "analytics",
              children: [
                {
                  path: "value",
                  element: <EvAnalytics />,
                },
                {
                  path: "netResults",
                  element: <NetResultAnalytics />,
                },
                {
                  path: "risk",
                  element: <RiskAnalytics />,
                },
                {
                  path: "settlement",
                  element: <SettlementAnalytics />,
                  action: updateSettlementOffers,
                },
                {
                  path: "bargaining",
                  element: <BargainingAnalytics />,
                  action: setBargainingVariant,
                },
                {
                  path: "sensitivity",
                  element: <SensitivityAnalysis />,
                },
                {
                  path: "revenue",
                  element: <RevenueAnalytics />,
                },
                {
                  path: "outcomes",
                  element: <CaseOutcomes />,
                },
                {
                  path: "tree",
                  element: currentUser?.canViewDecisionTree && <DecisionTree />,
                },
                {
                  path: "var",
                  element: <ValueAtRiskAnalytics />,
                },
              ],
            },
          ],
        },
      ],
      errorElement: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <Box>
      <CssBaseline />
      <ParseAuthProvider>
        <AuthCheck />
      </ParseAuthProvider>
    </Box>
  );
}

export default App;
