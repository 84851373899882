import { TableCell, Typography } from "@mui/material";

import { Trans } from "react-i18next";
import { Party } from "./IssueModel";
import { WinChanceVertical } from "./WinChanceVertical";

export const WinChanceCell = ({
  defendant,
  winChance,
  onWinChanceUpdated,
  limit = 1,
}: {
  defendant: Party;
  winChance?: number;
  onWinChanceUpdated: (defendantId: string, newChance: number) => void;
  limit?: number;
}) => {
  return (
    <TableCell align="center" sx={{ borderBottom: "none", minWidth: 160 }}>
      {winChance === undefined ? (
        <Typography align="center" variant="caption">
          <Trans i18nKey="case.claims.winChance.notInvolved" />
        </Typography>
      ) : (
        <WinChanceVertical
          chance={winChance}
          onChanceChange={(newChance) =>
            onWinChanceUpdated(defendant.id, newChance)
          }
          opponentName={defendant.name}
          limit={limit}
        />
      )}
    </TableCell>
  );
};
