import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { NamedEntity } from "../../model/CaseModel";

export interface ClaimEditorProps {
  title?: string;
  claimant?: NamedEntity;
  defendants?: NamedEntity[];
  setOff?: boolean;
  open: boolean;
  onClose: (claimTemplate?: {
    title: string;
    claimant: NamedEntity;
    defendants: NamedEntity[];
  }) => void;
  parties?: NamedEntity[];
}

function isClaimTitleValid(claimTitle?: string): boolean {
  return claimTitle !== undefined && claimTitle.trim().length > 0;
}
function isClaimantValid(claimant?: NamedEntity): boolean {
  return claimant !== undefined;
}

export const ClaimEditorDialog = ({
  open,
  onClose,
  title: initialTitle = "",
  claimant: initialClaimant,
  defendants = [],
  parties = [],
}: ClaimEditorProps) => {
  const [title, setTitle] = useState(initialTitle);
  const [claimant, setClaimant] = useState(
    initialClaimant || (parties.length > 0 ? parties[0] : undefined)
  );
  const [defendantIds, setDefendantIds] = useState<string[]>(
    defendants.map((defendant) => defendant.id)
  );

  const areDefendantIdsValid = (defendantIds?: string[]): boolean =>
    defendantIds !== undefined &&
    !defendantIds.find((id) => id === claimant?.id);

  const canSubmit =
    isClaimTitleValid(title) &&
    isClaimantValid(claimant) &&
    areDefendantIdsValid(defendantIds);

  const handleDefendantsChange = (
    event: SelectChangeEvent<typeof defendantIds>
  ) => {
    const {
      target: { value },
    } = event;
    setDefendantIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const close = () => onClose();
  const submit = () => {
    if (claimant === undefined) {
      onClose();
      return;
    }
    const defendants = parties.filter((party) =>
      defendantIds.includes(party.id)
    );
    onClose({ title, claimant, defendants });
  };

  // translate once, use twice
  const claimantLabel = t(
    parties.length === 0
      ? "case.claims.editDialog.label.noParties"
      : "case.claims.editDialog.label.claimant"
  );
  const defendantsLabel = t(
    parties.length === 0
      ? "case.claims.editDialog.label.noParties"
      : "case.claims.editDialog.label.defendants"
  );

  const sortedParties = [...parties].sort((p1, p2) =>
    p1.name.localeCompare(p2.name)
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.claims.editDialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={1}>
          <Trans i18nKey="case.claims.editDialog.description" />
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label={`${t("case.claims.editDialog.tip.title")}`}
          type="text"
          fullWidth
          required
          variant="outlined"
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
          }}
          error={!isClaimTitleValid(title)}
          helperText={
            !isClaimTitleValid(title)
              ? t("case.claims.editDialog.helperText.missingTitle")
              : undefined
          }
        />
        <FormControl
          key={"claimant-selection"}
          fullWidth
          sx={{ mt: 2, minWidth: 220 }}
          required
          error={!isClaimantValid(claimant)}
        >
          <InputLabel id="claimant-select-label">{claimantLabel}</InputLabel>
          <Select
            labelId="claimant-select-label"
            value={claimant?.id || ""}
            label={claimantLabel}
            onChange={(e: SelectChangeEvent) =>
              setClaimant(parties.find((party) => party.id === e.target.value))
            }
          >
            {sortedParties.map((party) => (
              <MenuItem key={party.id} value={party.id}>
                {party.name}
              </MenuItem>
            ))}
          </Select>
          {!isClaimantValid(claimant) && (
            <FormHelperText>
              {t("case.claims.editDialog.helperText.missingClaimant")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          key={"defendants-selection"}
          fullWidth
          sx={{ mt: 2, minWidth: 220 }}
          error={!areDefendantIdsValid(defendantIds)}
        >
          <InputLabel id="defendants-select-label">
            {defendantsLabel}
          </InputLabel>
          <Select
            multiple
            value={defendantIds}
            label={defendantsLabel}
            onChange={handleDefendantsChange}
            labelId="defendants-select-label"
            renderValue={(selected) =>
              sortedParties
                .filter((p) => selected.includes(p.id))
                .map((p) => p.name)
                .join(", ")
            }
          >
            {sortedParties.map((party) => (
              <MenuItem key={party.id} value={party.id}>
                <Checkbox checked={defendantIds.includes(party.id)} />
                <ListItemText
                  primary={party.name}
                  secondary={
                    party.id === claimant?.id
                      ? t("case.claims.editDialog.label.claimant")
                      : undefined
                  }
                />
              </MenuItem>
            ))}
          </Select>
          {!areDefendantIdsValid(defendantIds) && (
            <FormHelperText>
              {t("case.claims.editDialog.helperText.wrongDefendants")}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <Trans i18nKey="case.claims.editDialog.cancel" />
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          disabled={!canSubmit}
          autoFocus
        >
          <Trans i18nKey="case.claims.editDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
