import { useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { Bar } from "react-chartjs-2";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { Currency, gbp } from "../../i18n/Currency";
import { backgroundColor, borderColor } from "./RevenueColors";

export type StageRevenue = {
  stage: Stage;
  indirectCosts: number;
  directCosts: number;
  margin: number;
};

export const RevenueBarChart = ({
  data,
  currency = gbp,
}: {
  data: StageRevenue[];
  currency?: Currency;
}) => {
  const theme = useTheme();

  // get all clients into the chart
  // then, get defendants in, sorted by EV
  const chartData: ChartData<"bar", number[]> = {
    labels: data.map((stageCosts) =>
      t("case.stages." + Stage[stageCosts.stage])
    ),
    datasets: [
      {
        label: t("case.feeStructure.indirectCosts"),
        data: data.map((stageCosts) => stageCosts.indirectCosts),
        backgroundColor: backgroundColor("indirectCosts", theme),
        borderColor: borderColor("indirectCosts", theme),
        borderWidth: 2,
      },
      {
        label: t("case.feeStructure.directCosts"),
        data: data.map((stageCosts) => stageCosts.directCosts),
        backgroundColor: backgroundColor("directCosts", theme),
        borderColor: borderColor("directCosts", theme),
        borderWidth: 2,
      },
      {
        label: t("case.feeStructure.margin"),
        data: data.map((stageCosts) => stageCosts.margin),
        backgroundColor: backgroundColor("margin", theme),
        borderColor: borderColor("margin", theme),
        borderWidth: 2,
      },
    ],
  };

  const font = {
    family: theme.typography.fontFamily,
  };
  return (
    <Bar
      data={chartData}
      options={{
        interaction: {
          mode: "index",
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            bodyFont: font,
            titleFont: font,
            footerFont: font,
            callbacks: {
              footer: function (items) {
                let total = 0;
                items.forEach((item) => {
                  const dataset = chartData.datasets[item.datasetIndex];
                  const value = dataset.data[item.dataIndex];
                  total += value;
                });
                return `Total: ${currency.format(total)}`;
              },
              label: (item) =>
                `${item.dataset.label}: ${currency.format(item.parsed.y)}`,
            },
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return typeof value === "number"
                  ? currency.format(value)
                  : value;
              },
              font,
            },
            grid: {
              // make 0 line thicker
              lineWidth: (context) => (context.tick.value == 0 ? 2 : 1),
            },
            stacked: true,
          },
          x: {
            ticks: {
              font,
            },
            grid: {
              color: () => undefined,
            },
            stacked: true,
          },
        },
      }}
    />
  );
};
