import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { useCase } from "../../../context/CaseContext";
import { useCurrency } from "../../../context/CurrencyContext";
import { Claim, OfferStatus, SettlementOffer } from "../../../model/CaseModel";
import { percentage } from "../../issues/Claims";
import { ClaimMatrix } from "../ClaimMatrix";

interface ClaimSettlementStatus {
  claimId: string;
  claimTitle: string;
  quantums: {
    quantumId: string;
    title: string;
    amount: number;
  }[];
  opponents: {
    [partyId: string]: {
      status?: OfferStatus;
      quantums: {
        [quantumId: string]: {
          // how much is opponent liable for
          quantumLiability: number;
          // how much of settlement sum has been apportioned towards this quantum
          quantumApportioned?: number;
        };
      };
    };
  };
}

function getSettlementStatus(
  claims: Claim[],
  offers: SettlementOffer[],
  claimMatrix: ClaimMatrix,
  partyId: string
): ClaimSettlementStatus[] {
  const getLiabilityOf = (
    partyId: string,
    claimId: string,
    quantumId: string
  ) => {
    const claim = claims.find((claim) => claim.id === claimId);
    if (!claim || claim.claimant.id === partyId) return 0;
    const quantum = claim.quantums?.find((q) => q.id === quantumId);
    if (!quantum || !quantum.liabilityShares) return 0;
    return quantum.liabilityShares[partyId] ?? 0;
  };

  const claimsStatus: ClaimSettlementStatus[] = [];
  claims.forEach(({ id, title, claimant, defendants, quantums }) => {
    // filter out claims the party is not involved in
    if (
      claimant.id !== partyId &&
      !defendants?.some((defendant) => defendant.id === partyId)
    )
      return;
    const claimStatus: ClaimSettlementStatus = {
      claimId: id,
      claimTitle: title,
      opponents: {},
      quantums: (quantums ?? []).map((q) => ({
        quantumId: q.id,
        title: q.name,
        amount: q.value,
      })),
    };
    // fill status of opponents
    claimMatrix.getOpponentsInClaim(partyId, id).forEach((opponentId) => {
      // initialize empty status (meaning no settlement offer)
      claimStatus.opponents[opponentId] = { quantums: {} };
    });

    claimsStatus.push(claimStatus);
  });

  for (const offer of offers) {
    offer.settledClaims.forEach((settledClaimId) => {
      const claimStatus = claimsStatus.find(
        (claimStatus) => claimStatus.claimId === settledClaimId
      );
      if (!claimStatus) {
        console.error(
          `Missing claim '${settledClaimId}' settled in offer ${offer}`
        );
        return;
      }
      // update status
      const opponentId =
        offer.offerorId === partyId ? offer.offereeId : offer.offerorId;

      const quantums: {
        [quantumId: string]: {
          // how much is opponent liable for
          quantumLiability: number;
          // how much of settlement sum has been apportioned towards this quantum
          quantumApportioned?: number;
        };
      } = {};
      for (const quantum of offer.settledQuantums) {
        quantums[quantum.id] = {
          quantumLiability: getLiabilityOf(
            opponentId,
            settledClaimId,
            quantum.id
          ),
          quantumApportioned: quantum.apportioned,
        };
      }

      const lastStatus = claimStatus.opponents[opponentId];
      // no status initialized for opponentId means it is not an opponent in the claim
      if (!lastStatus) return;

      // compare status and only update if offer has higher status
      if (lastStatus.status && offer.status <= lastStatus.status) return;
      lastStatus.status = offer.status;
      lastStatus.quantums = quantums;
    });
  }

  return claimsStatus;
}

const SettlementSummary = ({ partyId }: { partyId: string }) => {
  const theme = useTheme();
  const caseContext = useCase();

  const currency = useCurrency();
  const c = caseContext.getCase();

  const offers = (c.settlements ?? []).filter(
    ({ offerorId, offereeId }) => offerorId === partyId || offereeId === partyId
  );

  const claimStatus = getSettlementStatus(
    c.claims ?? [],
    offers,
    caseContext.getClaimMatrix(),
    partyId
  );

  const parties = caseContext.getPartyDescriptors();
  const opponents = parties[partyId].opponents;

  const accepted = offers.filter(
    ({ status }) => status === OfferStatus.Accepted
  );

  return (
    <TableContainer>
      <Table
        key="offers"
        size="small"
        aria-label="sticky table"
        sx={{ maxWidth: "fit-content" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="center">
              <b>Number of offers</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Offers total</TableCell>
            <TableCell align="right">{offers.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Rejected</TableCell>
            <TableCell align="right">
              {offers.filter((o) => o.status === OfferStatus.Rejected).length}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Accepted</TableCell>
            <TableCell align="right">{accepted.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Drafts</TableCell>
            <TableCell align="right">
              {offers.filter((o) => o.status === OfferStatus.Draft).length}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Pending</TableCell>
            <TableCell align="right">
              {offers.filter((o) => o.status === OfferStatus.Offered).length}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table
        key="claims"
        size="small"
        aria-label="sticky table"
        sx={{ mt: theme.spacing(2), maxWidth: "fit-content" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <b>Claim</b>
            </TableCell>
            {opponents.map((opponentId) => {
              const opponentName = parties[opponentId].name ?? opponentId;
              return (
                <TableCell align="center">
                  <b>{opponentName}</b>
                </TableCell>
              );
            })}
            <TableCell align="center">
              <b>Settled</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claimStatus.map((claim) => {
            const hasQuantums = claim.quantums.length > 0;
            const sx = hasQuantums ? { borderBottom: "none" } : undefined;
            const claimOpponents = Object.keys(claim.opponents).length;
            const claimOpponentsSettled = Object.values(claim.opponents).filter(
              ({ status }) => status === OfferStatus.Accepted
            ).length;
            return (
              <>
                <TableRow>
                  <TableCell align="left" sx={sx}>
                    {claim.claimTitle}
                  </TableCell>
                  {opponents.map((opponentId) => {
                    const opponentStatus = claim.opponents[opponentId];
                    if (!opponentStatus)
                      return (
                        <TableCell
                          align="center"
                          sx={{
                            ...sx,
                            backgroundColor: theme.palette.action.hover,
                          }}
                        />
                      );
                    return (
                      <TableCell align="center" sx={sx}>
                        {opponentStatus.status !== undefined
                          ? OfferStatus[opponentStatus.status]
                          : "No offer"}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" sx={sx}>
                    <b>{`${claimOpponentsSettled} of ${claimOpponents} parties`}</b>
                  </TableCell>
                </TableRow>
                {claim.quantums.map(({ quantumId, title, amount }, index) => {
                  const isLastQuantum = index + 1 === claim.quantums.length;
                  const sx = !isLastQuantum
                    ? { borderBottom: "none" }
                    : undefined;

                  let settledAmount = 0;
                  Object.values(claim.opponents)
                    .filter(({ status }) => status === OfferStatus.Accepted)
                    .forEach(({ status, quantums }) => {
                      if (status !== OfferStatus.Accepted) return;
                      const quantumStatus = quantums[quantumId];
                      if (quantumStatus)
                        settledAmount += quantumStatus.quantumApportioned ?? 0;
                    });
                  return (
                    <TableRow>
                      <TableCell align="left" sx={sx}>
                        <Typography
                          variant="caption"
                          pl={2}
                        >{`Apportionment of ${title} (${currency.format(amount)})`}</Typography>
                      </TableCell>
                      {opponents.map((opponentId) => {
                        const opponentStatus = claim.opponents[opponentId];
                        if (!opponentStatus)
                          return (
                            <TableCell
                              align="center"
                              sx={{
                                ...sx,
                                backgroundColor: theme.palette.action.hover,
                              }}
                            />
                          );
                        const quantumStatus =
                          opponentStatus.quantums[quantumId];
                        if (!quantumStatus)
                          return (
                            <TableCell align="center" sx={sx}>
                              --
                            </TableCell>
                          );
                        return (
                          <TableCell align="center" sx={sx}>
                            {currency.format(
                              quantumStatus.quantumApportioned ?? 0
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center" sx={sx}>
                        <b>
                          {`${currency.format(settledAmount)} (${percentage.format(amount === 0 ? 0 : settledAmount / amount)})`}
                        </b>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            );
          })}
          <TableRow>
            <TableCell align="left">
              <b>Settled</b>
            </TableCell>
            {opponents.map(([opponentId]) => {
              let claimsVOpponent = 0;
              let claimsVOpponentSettled = 0;
              claimStatus.forEach((claim) => {
                const opponentStatus = claim.opponents[opponentId];
                if (!opponentStatus) return;
                claimsVOpponent++;
                if (opponentStatus.status === OfferStatus.Accepted)
                  claimsVOpponentSettled++;
              });
              return (
                <TableCell align="center">
                  <b>{`${claimsVOpponentSettled} of ${claimsVOpponent} claims (${percentage.format(claimsVOpponent === 0 ? 0 : claimsVOpponentSettled / claimsVOpponent)})`}</b>
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettlementSummary;
