import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { Party } from "../model/CaseModel";
import randomId from "../utils/randomId";

export const PartyEditorDialog = ({
  party,
  open,
  onClose,
}: {
  party: Party;
  open: boolean;
  onClose: (party?: Party) => void;
}) => {
  const [name, setName] = useState(party.name);
  const [client, setClient] = useState(party.client);

  const isNameEmpty = name === undefined || name.trim().length === 0;

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.parties.editDialog.title" />
      </DialogTitle>
      <DialogContent sx={{ minWidth: 420 }}>
        <DialogContentText mb={1}>
          <Trans i18nKey="case.parties.editDialog.description" />
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label={`${t("case.parties.editDialog.tip.name")}`}
          type="text"
          name="name"
          fullWidth
          variant="outlined"
          value={name}
          // pass name update
          onChange={(e) => setName(e.target.value)}
          error={isNameEmpty}
          helperText={
            isNameEmpty
              ? t("case.parties.editDialog.helperText.emptyName")
              : undefined
          }
        />
        <FormControlLabel
          title={`${t("case.parties.editDialog.tip.client")}`}
          control={
            <Switch
              checked={client === true}
              onChange={(e) => setClient(e.target.checked)}
              name="client"
            />
          }
          label={`${t(
            client
              ? "case.parties.editDialog.client.yes"
              : "case.parties.editDialog.client.no"
          )}`}
          labelPlacement="end"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          <Trans i18nKey="case.parties.editDialog.cancel" />
        </Button>
        <Button
          disabled={isNameEmpty}
          onClick={() => {
            onClose({ id: party ? party.id : randomId(), name, client });
          }}
          type="submit"
          variant="contained"
        >
          <Trans i18nKey="case.parties.editDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
