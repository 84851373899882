import { Stage } from "../analytics/model/CaseConfiguration";
import { Party } from "../model/CaseModel";
import { Budget, Costs, Disbursements, HourlyCosts } from "../model/CostModel";
import randomId from "../utils/randomId";

const precedentWorks: { key: string; stage: Stage }[] = [
  { key: "preAction", stage: Stage.PreAction },
  { key: "issueStatementsOfCase", stage: Stage.IssueStatementsOfCase },
  { key: "cmc", stage: Stage.CMC },
  { key: "disclosure", stage: Stage.Disclosure },
  { key: "witnessStatements", stage: Stage.WitnessStatements },
  { key: "expertReports", stage: Stage.ExpertReports },
  { key: "ptr", stage: Stage.PTR },
  { key: "trialPreparation", stage: Stage.TrialPreparation },
  { key: "trial", stage: Stage.Trial },
  { key: "adr", stage: Stage.Trial },
  { key: "contingentA", stage: Stage.Trial },
  { key: "contingentB", stage: Stage.Trial },
];

const postTrialWorks: { key: string; stage: Stage }[] = [
  { key: "enforcement", stage: Stage.Enforcement },
  { key: "appeals", stage: Stage.Appeals },
];

const timeCostEntries = ["Grade 1", "Grade 2", "Grade 3", "Grade 4"];
const disbursementEntries = [
  "Expert Fees",
  "Expert Disbursements",
  "Leading counsel fees",
  "Junior counsel fees",
  "Court fees",
  "Other disbursements",
];

function createCosts(precedentWork: { key: string; stage: Stage }): Costs {
  const hourlyCosts: HourlyCosts[] = [];
  for (let index = 0; index < timeCostEntries.length; index++) {
    const cost: HourlyCosts = {
      id: randomId(),
      name: timeCostEntries[index],
      ratePerHour: 0,
    };
    hourlyCosts.push(cost);
  }

  const disbursements: Disbursements[] = [];
  for (let index = 0; index < disbursementEntries.length; index++) {
    const cost: Disbursements = {
      id: randomId(),
      name: disbursementEntries[index],
    };
    disbursements.push(cost);
  }

  return {
    id: randomId(),
    name: precedentWork.key,
    stage: precedentWork.stage,
    disbursements,
    hourlyCosts,
  };
}

export function initializeBudget(party: Party): Budget {
  console.debug(`Initializing budget for party '${party.name}'`);

  const costs: Costs[] = [];
  const allWorks = precedentWorks.concat(postTrialWorks);
  allWorks.forEach((work) => costs.push(createCosts(work)));

  // create budget
  return {
    partyId: party.id,
    costs,
  };
}
