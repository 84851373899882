import { Theme, useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { useMemo } from "react";

import { Bar } from "react-chartjs-2";
import { Currency, gbp } from "../../i18n/Currency";
import { percentage } from "../issues/Claims";
import { getMaxAbsoluteValue } from "./ChartsUtility";

export interface TornadoLine {
  // label of the changed variable
  variable: string;
  // % change of variable
  percent: number;
  // base value observed in the tornado
  base: number;
  // delta from base value after increasing the variable by +percent
  plus: number;
  // delta from base value after decreasing the variable by -percent
  minus: number;
}

interface TornadoChartData {
  chartData: ChartData<"bar", number[]>;
  minusLabels: (string | string[])[];
  plusLabels: (string | string[])[];
  tooltips: string[];
}

function initializeTornado(
  observedVariable: string,
  lines: TornadoLine[],
  theme: Theme,
  currency: Currency
): TornadoChartData {
  const tornado: TornadoChartData = {
    chartData: {
      labels: [],
      datasets: [],
    },
    minusLabels: [],
    plusLabels: [],
    tooltips: [],
  };

  lines.forEach((line, index) => {
    // to create a tornado, the chart is flipped on the side. X becomes vertical, Y horizontal
    // each tornado line is a category on the X axis (vertical in the tornado)
    // each dataset must provide one value per category
    // however, we use a trick - each plus and minus value of the tornado has its own a dataset
    // this seemed to be the only way to provide per value tooltips (without building own tooltip)
    // thus #datasets = 2* #lines
    const plus: number[] = new Array(lines.length).fill(NaN);
    plus[index] = line.plus;
    const minus: number[] = new Array(lines.length).fill(NaN);
    minus[index] = line.minus;

    // dataset for "plus" value
    tornado.chartData.datasets.push({
      data: plus,
      backgroundColor:
        line.plus >= 0
          ? theme.palette.success.main + "75"
          : theme.palette.error.main + "75",
      borderColor:
        line.plus >= 0 ? theme.palette.success.dark : theme.palette.error.dark,
      borderWidth: 2,
      label: `+${percentage.format(line.percent)} ${line.variable}`,
    });
    // create tooltip based on the effect of "plus" value on the observed variable
    if (line.plus > 0)
      tornado.tooltips.push(
        t("tornado.tooltip.increase", {
          variable: observedVariable,
          delta: currency.format(line.plus),
          value: currency.format(line.base + line.plus),
          percent: percentage.format(Math.abs(line.plus / line.base)),
        })
      );
    else if (line.plus < 0)
      tornado.tooltips.push(
        t("tornado.tooltip.decrease", {
          variable: observedVariable,
          delta: currency.format(line.plus),
          value: currency.format(line.base - line.plus),
          percent: percentage.format(Math.abs(line.plus / line.base)),
        })
      );

    // dataset for "minus" value
    tornado.chartData.datasets.push({
      data: minus,
      backgroundColor:
        line.minus < 0
          ? theme.palette.error.main + "75"
          : theme.palette.success.main + "75",
      borderColor:
        line.minus < 0 ? theme.palette.error.dark : theme.palette.success.dark,
      borderWidth: 2,
      label: `-${percentage.format(line.percent)} ${line.variable}`,
    });
    // create tooltip based on the effect of "minus" value on the observed variable
    if (line.minus > 0)
      tornado.tooltips.push(
        t("tornado.tooltip.increase", {
          variable: observedVariable,
          delta: currency.format(line.minus),
          value: currency.format(line.base + line.minus),
          percent: percentage.format(Math.abs(line.minus / line.base)),
        })
      );
    else if (line.minus < 0)
      tornado.tooltips.push(
        t("tornado.tooltip.decrease", {
          variable: observedVariable,
          delta: currency.format(line.minus),
          value: currency.format(line.base - line.minus),
          percent: percentage.format(Math.abs(line.minus / line.base)),
        })
      );

    // pre-generate labels for ticks on left and right side
    // some "plus" changes have negative impact on the of observed variable
    // some "negative" changes have positive impact on the of observed variable
    // ticks on left explain what lead to the decrease of the variable, ticks on the right the increase
    // thus, depending on what the change caused, the +/- labels are either left or right
    if (line.minus <= 0) {
      tornado.minusLabels[index] = [
        `-${percentage.format(line.percent)}`,
        line.variable,
      ];
      tornado.plusLabels[index] = [
        `+${percentage.format(line.percent)}`,
        line.variable,
      ];
    } else {
      tornado.minusLabels[index] = [
        `+${percentage.format(line.percent)}`,
        line.variable,
      ];
      tornado.plusLabels[index] = [
        `-${percentage.format(line.percent)}`,
        line.variable,
      ];
    }
    tornado.chartData.labels?.push(line.variable);
  });
  return tornado;
}

export const TornadoChart = ({
  observedVariable,
  lines,
  currency = gbp,
}: {
  observedVariable: string;
  lines: TornadoLine[];
  currency?: Currency;
}) => {
  const theme = useTheme();

  const tornado = useMemo(
    () => initializeTornado(observedVariable, lines, theme, currency),
    [observedVariable, lines, currency, theme]
  );

  const maxChartValue = getMaxAbsoluteValue(tornado.chartData);

  return (
    <Bar
      data={tornado.chartData}
      options={{
        interaction: {
          mode: "point",
          intersect: false,
        },
        indexAxis: "y",
        plugins: {
          legend: { display: false },
          tooltip: {
            mode: "point",
            intersect: false,
            position: "average",
            callbacks: {
              title: (items) => {
                if (items.length === 1) {
                  return items[0].dataset.label;
                }
                items.map((item) => item.dataset.label);
              },
              label: (item) => {
                return tornado.tooltips[item.datasetIndex];
              },
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            titleFont: {
              family: theme.typography.fontFamily,
            },
          },
        },
        scales: {
          y: {
            min: -1.1 * maxChartValue,
            max: 1.1 * maxChartValue,
            stacked: true,
            ticks: {
              callback: function (value) {
                if (typeof value === "number")
                  return tornado.minusLabels[value];
                return value;
              },
              font: {
                family: theme.typography.fontFamily,
              },
            },
          },
          x: {
            grid: {
              // make 0 line thicker
              lineWidth: (context) =>
                context.tick && context.tick.value == 0 ? 2 : 1,
            },
            ticks: {
              callback: function (value) {
                if (typeof value === "number") return currency.format(value);
                return value;
              },
              font: {
                family: theme.typography.fontFamily,
              },
            },
            stacked: true,
            position: "left",
          },
          // right side axis
          x1: {
            type: "category",
            display: true,
            position: "right",
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            min: 0,
            max: lines.length - 1,
            ticks: {
              callback: function (value) {
                if (typeof value === "number") return tornado.plusLabels[value];
                return value;
              },
              font: {
                family: theme.typography.fontFamily,
              },
            },
          },
        },
      }}
    />
  );
};
