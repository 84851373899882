import { Box, Tab, Tabs, Typography } from "@mui/material";
import "chart.js/auto";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { TabPanel } from "../general/TabPanel";

import { Trans } from "react-i18next";
import { Outcome } from "../../analytics/api/Analytics";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { CaseResults, useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { WinChanceMutation } from "../../model/CaseModel";
import { byName, clientsFirst, sort } from "./AnalyticsUtility";
import { TornadoChart, TornadoLine } from "./TornadoChart";

function getEvAtEnforcement(
  partyId: string,
  outcomes?: Outcome[]
): number | undefined {
  const outcome = outcomes?.find((outcome) => outcome.partyId === partyId);
  return outcome?.stageResults.find(
    (result) => result.stage === Stage.Enforcement
  )?.ev;
}

const maxVarianceFirst: (line1: TornadoLine, line2: TornadoLine) => number = (
  line1,
  line2
) =>
  Math.abs(line2.plus) +
  Math.abs(line2.minus) -
  (Math.abs(line1.plus) + Math.abs(line1.minus));

export const SensitivityAnalysis = () => {
  const currency = useCurrency();
  const caseContext = useCase();
  const c = caseContext.getCase();
  const parties = caseContext.getPartyDescriptors();

  const winChanceVariants = useMemo(() => {
    const variants: {
      mutation: WinChanceMutation;
      plus?: CaseResults;
      minus?: CaseResults;
    }[] = [];
    c.variants?.sensitivity.winChance.map((mutation) => {
      const mutationResults: (typeof variants)[0] = { mutation };
      if (mutation.minusVariantId) {
        const results = caseContext.getVariantResults(mutation.minusVariantId);
        if (results) mutationResults.minus = results;
      }
      if (mutation.plusVariantId) {
        const results = caseContext.getVariantResults(mutation.plusVariantId);
        if (results) mutationResults.plus = results;
      }
      variants.push(mutationResults);
    });
    return variants;
  }, [caseContext]);

  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [c]
  );
  if (sortedParties.length === 0) {
    return <Typography>No parties defined in case</Typography>;
  }
  const [value, setValue] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (winChanceVariants.length === 0) {
    return <Typography>No outcome variants available</Typography>;
  }

  return (
    <Box sx={{ userSelect: "none" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="sensitivity analysis"
      >
        {sortedParties.map((partyId, index) => {
          const party = parties[partyId];
          return (
            <Tab
              key={"sensitivity_tab_" + index + "_" + partyId}
              label={t(
                party?.isClient
                  ? "case.parties.name.client"
                  : "case.parties.name.noClient",
                { name: party?.name || partyId }
              )}
              id={"party_" + partyId}
            />
          );
        })}
      </Tabs>

      {sortedParties.map((partyId, index) => {
        const plusDataset: { label: string; data: number[] } = {
          label: "plus",
          data: [],
        };
        const minusDataset: { label: string; data: number[] } = {
          label: "minus",
          data: [],
        };
        const labels: string[] = [];

        // compare EV of party in variants vs. original
        const lines: TornadoLine[] = [];
        winChanceVariants.forEach(({ mutation, plus, minus }, index) => {
          // get EVs
          const base = getEvAtEnforcement(partyId, caseContext.getOutcomes());
          if (base === undefined) return;

          const evPlus = plus
            ? getEvAtEnforcement(partyId, plus.getOutcomes())
            : undefined;
          if (evPlus === undefined) return;

          const evMinus = minus
            ? getEvAtEnforcement(partyId, minus.getOutcomes())
            : undefined;
          if (evMinus === undefined) return;

          const evPlusDelta = evPlus - base;
          const evMinusDelta = evMinus - base;
          if (evPlusDelta === 0 && evMinusDelta === 0) return;
          plusDataset.data[index] = evPlusDelta;
          minusDataset.data[index] = evMinusDelta;
          const label = `Win chance for '${mutation.issueLabel}'`;
          labels[index] = label;

          lines.push({
            base,
            minus: evMinusDelta,
            plus: evPlusDelta,
            percent: mutation.delta,
            variable: label,
          });
        });

        lines.sort(maxVarianceFirst);

        return (
          <TabPanel key={"tab_" + index} currentTabIndex={value} index={index}>
            <Typography>
              <Trans
                i18nKey="case.analytics.sensitivity.description"
                values={{ name: parties[partyId].name }}
              />
            </Typography>
            <TornadoChart
              lines={lines}
              currency={currency}
              observedVariable={t("case.analytics.sensitivity.variables.ev")}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};
