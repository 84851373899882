import Big from "big.js";
import { ONE } from "../../big/bigUtil";
import {
  Case,
  Claim,
  Issue,
  IssueRelation,
  OfferStatus,
  RecoveryType,
  SettlementOffer,
  getAwardedValue,
} from "../../model/CaseModel";
import {
  getDisbursementTotals,
  getHourlyCostsTotals,
  getValueOfAssets,
} from "../../model/CaseModelUtility";
import { generateDecisionTrees } from "../../tree/DecisionTreeUtility";
import { SubGraph } from "../../tree/tree";
import { CostSummary, getCostSummary } from "../CostUtility";
import Scenario from "../Scenario";
import { generateOutcomes } from "../ScenarioOutcome";
import { getStages } from "../model/British";
import {
  AssetLimit,
  Case as CaseConfigurationCase,
  Claim as CaseConfigurationClaim,
  CostApportionment as CaseConfigurationCostApportionment,
  CaseVariant,
  Cost,
  CostGroups,
  CostType,
  IssueConfiguration,
  LiabilityIssue,
  Party,
  QuantumAmount,
  QuantumIssue,
  Recovery,
  Relation,
  Stage,
} from "../model/CaseConfiguration";
import { Analytics, Outcome } from "./Analytics";
import { getOutcome } from "./CaseConfigurationBasedAnalytics";
import {
  SingleDefendantClaim,
  splitMultiDefendantClaims,
} from "./CasePreprocessor";

export class LocalAnalytics implements Analytics {
  private scenario: Scenario;

  private outcomes: Outcome[] = [];

  constructor(c: Case, stages: Stage[] = getStages()) {
    // split multi-defendant claims
    const newCase: Case = JSON.parse(JSON.stringify(c));
    const acceptedSettlements = (newCase.settlements ?? []).filter(
      (settlement) => settlement.status === OfferStatus.Accepted
    );
    if (newCase.claims) {
      newCase.claims = removeSettledClaims(
        splitMultiDefendantClaims(newCase.claims),
        acceptedSettlements
      );
      reduceSettledQuantums(newCase.claims, acceptedSettlements);
    }
    this.scenario = new Scenario(getCase(newCase), getVariant(newCase, stages));

    // run expensive outcome generation only once
    // outcomes also cache their payment plans
    const scenarioOutcomes = generateOutcomes(this.scenario);
    for (const { id } of this.scenario.getParties()) {
      const outcome = getOutcome(this.scenario, scenarioOutcomes, id);
      let settlementBalance = 0;
      acceptedSettlements.forEach((settlement) => {
        if (settlement.offerorId === id) {
          // party is offeror
          settlementBalance += settlement.payment.offereePays
            ? settlement.payment.amount
            : -settlement.payment.amount;
        }
        if (settlement.offereeId === id) {
          // party is offeree
          settlementBalance += settlement.payment.offereePays
            ? -settlement.payment.amount
            : settlement.payment.amount;
          return;
        }
      });
      outcome.netResult += settlementBalance;
      this.outcomes.push(outcome);
    }
  }

  getOutcomes(): Outcome[] {
    return this.outcomes;
  }

  getOutcomeOf(partyId: string): Outcome | undefined {
    return this.getOutcomes().find((o) => o.partyId === partyId);
  }

  getDecisionTrees(): SubGraph {
    return generateDecisionTrees(this.scenario);
  }

  getCostSummary(partyId: string): CostSummary {
    return getCostSummary(this.scenario, partyId);
  }
}

function removeSettledClaims(
  claims: SingleDefendantClaim[],
  acceptedSettlements: SettlementOffer[]
): Claim[] {
  if (claims.length === 0) return [];
  if (acceptedSettlements.length === 0) return claims;

  const isSettled = (
    claim: SingleDefendantClaim,
    settlements: SettlementOffer[]
  ) => {
    return settlements.some(({ offerorId, offereeId, settledClaims }) => {
      if (claim.groupClaimId === undefined)
        return settledClaims.includes(claim.id);

      if (!settledClaims.includes(claim.groupClaimId)) return false;

      // multi-defendant version of the claim is settled, check if offeror and offeree match the split single-defendant claim
      if (
        claim.claimant.id === offereeId &&
        claim.defendants?.some((d) => d.id === offerorId)
      )
        return true;
      if (
        claim.claimant.id === offerorId &&
        claim.defendants?.some((d) => d.id === offereeId)
      )
        return true;
      // no match, single-defendant claim is for other defendant
      return false;
    });
  };

  // remove settled claims
  return claims.filter((claim) => !isSettled(claim, acceptedSettlements));
}

function reduceSettledQuantums(
  claims: Claim[],
  acceptedSettlements: SettlementOffer[]
) {
  if (claims.length === 0) return;
  if (acceptedSettlements.length === 0) return;

  // reduce amount of remaining quantums by apportioned settlement amounts
  const settledQuantumAmounts: { [quantumId: string]: number } = {};
  for (const settlement of acceptedSettlements) {
    settlement.settledQuantums.forEach(({ id, apportioned }) => {
      if (apportioned !== undefined && apportioned > 0) {
        if (settledQuantumAmounts[id] === undefined)
          settledQuantumAmounts[id] = apportioned;
        else settledQuantumAmounts[id] += apportioned;
      }
    });
  }
  claims
    .flatMap((claim) => claim.quantums ?? [])
    .forEach((quantum) => {
      const settledAmount = settledQuantumAmounts[quantum.id];
      if (settledAmount > 0) quantum.value -= settledAmount;
    });
}

const getCase: (clientCase: Case) => CaseConfigurationCase = (clientCase) => ({
  id: clientCase.id,
  title: clientCase.name,
  parties: getParties(clientCase),
  liabilityIssues: clientCase.claims?.map(getClaim) ?? [],
  quantumIssues: getQuantums(clientCase),
});

function getQuantums(clientCase: Case): QuantumIssue[] {
  const quantums = new Map<string, QuantumIssue>();
  clientCase.claims?.forEach((claim) => {
    fillQuantums(claim, claim.claimant.id, quantums);
    claim.issues?.forEach((issue) => {
      fillQuantums(issue, claim.claimant.id, quantums);
    });
  });
  return Array.from(quantums.values());
}

function fillQuantums(
  issue: Issue,
  claimant: string,
  quantums: Map<string, QuantumIssue>
) {
  issue.quantums?.forEach((clientQuantum) => {
    const claim = getAwardedValue(clientQuantum);
    let convertedQuantum = quantums.get(clientQuantum.id);
    if (convertedQuantum === undefined) {
      convertedQuantum = {
        id: clientQuantum.id,
        title: clientQuantum.name,
        value: claim,
        claimant: claimant,
        liabilities: [issue.id],
      };
      quantums.set(clientQuantum.id, convertedQuantum);
      return;
    }
    if (!convertedQuantum.liabilities.includes(issue.id))
      convertedQuantum.liabilities.push(issue.id);
    if (convertedQuantum.value < claim) convertedQuantum.value = claim;
  });
  issue.subIssues?.forEach((subIssue) =>
    fillQuantums(subIssue, claimant, quantums)
  );
}

function convertRelation(relation?: IssueRelation): Relation | undefined {
  if (relation === undefined) return undefined;
  switch (relation) {
    case IssueRelation.And:
      return Relation.And;
    case IssueRelation.Or:
      return Relation.Or;
    case IssueRelation.Xor:
      return Relation.Xor;
  }
  console.error("Unknown issue relation: " + relation);
}

function getClaim(claim: Claim): CaseConfigurationClaim {
  const issue: CaseConfigurationClaim = {
    id: claim.id,
    title: claim.title,
    claimant: claim.claimant.id,
    relation: convertRelation(claim.relation),
    defendants: claim.defendants?.map((defendant) => defendant.id),
  };

  if (claim.issues !== undefined && claim.issues.length > 0) {
    const liabilitySubIssues: LiabilityIssue[] = [];
    claim.issues.forEach((subIssue) =>
      fillLiabilities(subIssue, liabilitySubIssues)
    );
    issue.subIssues = liabilitySubIssues;
  }

  return issue;
}

function fillLiabilities(issue: Issue, liabilityIssues: LiabilityIssue[]) {
  const liabilityIssue: LiabilityIssue = {
    id: issue.id,
    title: issue.title,
    relation: convertRelation(issue.relation),
    defendants: issue.defendants?.map((defendant) => defendant.id),
  };

  if (issue.subIssues !== undefined && issue.subIssues.length > 0) {
    liabilityIssue.subIssues = [];
    for (let i = 0; i < issue.subIssues.length; i++)
      fillLiabilities(issue.subIssues[i], liabilityIssue.subIssues);
  }
  liabilityIssues.push(liabilityIssue);
}

function getParties(clientCase: Case): Party[] {
  return clientCase.parties?.map((p) => ({ id: p.id, name: p.name })) || [];
}

function getVariant(clientCase: Case, stages: Stage[]): CaseVariant {
  const variant: CaseVariant = {
    stages: getStages(),
    assetLimits: getAssetLimits(clientCase),
    liabilityWinChances: getLiabilityProbabilities(clientCase),
    quantumAmounts: getQuantumAmounts(clientCase),
    costs: getCosts(clientCase, stages),
    costRecoveries: getCostRecoveries(clientCase),
    costApportionments: getCostApportionments(clientCase),
  };

  return variant;
}

function getAssetLimits(clientCase: Case): AssetLimit[] {
  const assetLimits: AssetLimit[] = [];
  clientCase.parties?.forEach((party) => {
    const value = getValueOfAssets(party);
    // there is an asset limit (0 would mean no limit)
    if (value !== undefined)
      assetLimits.push({ partyId: party.id, amount: { value } });
  });
  return assetLimits;
}

function fillWinChances(issue: Issue, winChances: Map<string, number>) {
  if (issue.subIssues === undefined || issue.subIssues.length === 0) {
    // got leaf
    const winChance = getWinChanceOfLeaf(issue);
    const currentWinChance = winChances.get(issue.id);
    if (currentWinChance === undefined || winChance > currentWinChance)
      winChances.set(issue.id, winChance);
    return;
  }
  // traverse children
  issue.subIssues.forEach((subIssue) => fillWinChances(subIssue, winChances));
}

function getWinChanceOfLeaf(issue: Issue): number {
  if (issue.generic) return issue.genericWinChance || 0;

  // if there are no defendants, assume generic issue with 100% win chance
  if (issue.defendants === undefined || issue.defendants.length === 0) return 1;

  // calculate win chance based on defendants
  // if no win-chances are defined per defendant, assume 100% win chance
  if (issue.perDefendantWinChance === undefined) return 1;

  let winChance = ONE;
  for (let i = 0; i < issue.defendants.length; i++) {
    const defendantId = issue.defendants[i].id;
    const winChanceVDefendant = issue.perDefendantWinChance[defendantId];
    if (winChanceVDefendant !== undefined)
      winChance = winChance.mul(winChanceVDefendant);
  }
  return winChance.toNumber();
}

function getQuantumAmounts(clientCase: Case): QuantumAmount[] {
  const amounts: QuantumAmount[] = [];
  clientCase.claims?.forEach((claim) => {
    fillQuantumAmounts(claim, amounts);
    claim.issues?.forEach((issue) => fillQuantumAmounts(issue, amounts));
  });
  return amounts;
}

function fillQuantumAmounts(issue: Issue, amounts: QuantumAmount[]) {
  issue.quantums?.forEach((quantum) => {
    // use award estimate to calculate awarded quantum amounts
    const amount = getAwardedValue(quantum);
    const quantumAmount: QuantumAmount = {
      quantumId: quantum.id,
      amount,
      when: { issuesWon: [issue.id] },
    };

    // check if per-defendant liability is set
    if (quantum.liabilityShares) {
      const amountBig = new Big(amount);
      quantumAmount.perDefendant = {};
      for (const defendantId in quantum.liabilityShares) {
        quantumAmount.perDefendant[defendantId] = amountBig
          .mul(quantum.liabilityShares[defendantId] ?? 0)
          .toNumber();
      }
    }
    amounts.push(quantumAmount);
  });
  issue.subIssues?.forEach((subIssue) => fillQuantumAmounts(subIssue, amounts));
}

function getOpponentsPerStage(
  clientCase: Case,
  stages: Stage[]
): {
  partiesInvolvedPerStage: Map<Stage, string[]>;
  opponents: Map<string, string[]>;
} {
  // store parties involved in case but not settled
  const partiesNotFullySettled = new Set<string>();
  // store who is fighting whom
  const opponents = new Map<string, string[]>();
  const registerOpponents = (party1: string, party2: string) => {
    // register party2 as opponent of party1
    const opponentsOfParty1 = opponents.get(party1);
    if (opponentsOfParty1 === undefined) opponents.set(party1, [party2]);
    else if (!opponentsOfParty1.includes(party2))
      opponentsOfParty1.push(party2);

    // register party1 as opponent of party2
    const opponentsOfParty2 = opponents.get(party2);
    if (opponentsOfParty2 === undefined) opponents.set(party2, [party1]);
    else if (!opponentsOfParty2.includes(party1))
      opponentsOfParty2.push(party1);
  };

  // anybody involved in a claim is not fully settled
  // also use claims to store opponents
  clientCase.claims?.forEach((claim) => {
    partiesNotFullySettled.add(claim.claimant.id);
    claim.defendants?.forEach((defendant) => {
      partiesNotFullySettled.add(defendant.id);
      registerOpponents(claim.claimant.id, defendant.id);
    });
  });

  // if a party settles completely (has no more claims), store at which stage they settled
  const partiesSettled = new Map<string, Stage>();
  clientCase.settlements?.forEach(({ offerorId, offereeId, stage }) => {
    registerOpponents(offerorId, offereeId);
    if (!partiesNotFullySettled.has(offerorId)) {
      // offeror has no more claims, they fully settled
      const latestSettlementStage = partiesSettled.get(offerorId);
      if (
        !latestSettlementStage ||
        stages.indexOf(stage) > stages.indexOf(latestSettlementStage)
      ) {
        partiesSettled.set(offerorId, stage);
      }
    }
    if (!partiesNotFullySettled.has(offereeId)) {
      // offeree has no more claims, they fully settled
      const latestSettlementStage = partiesSettled.get(offereeId);
      if (
        !latestSettlementStage ||
        stages.indexOf(stage) > stages.indexOf(latestSettlementStage)
      ) {
        partiesSettled.set(offereeId, stage);
      }
    }
  });

  // calculate parties involved in each stage
  const partiesInvolvedPerStage = new Map<Stage, string[]>();
  for (const stage of stages) {
    const partiesInvolvedInStage = [...partiesNotFullySettled];
    for (const [partyId, settlementStage] of partiesSettled) {
      partiesInvolvedInStage.push(partyId);
      if (stage === settlementStage) partiesSettled.delete(partyId);
    }
    partiesInvolvedPerStage.set(stage, partiesInvolvedInStage);
  }
  return { opponents, partiesInvolvedPerStage };
}

function getCosts(clientCase: Case, stages: Stage[]): Cost[] {
  // take settlements into account
  // if there is a party with no claims, check if they are settled and if so, when
  // apportion all costs based on their stage and what parties were involved in that stage

  const { opponents, partiesInvolvedPerStage } = getOpponentsPerStage(
    clientCase,
    stages
  );

  const costs: Cost[] = [];
  const lastStage = stages[stages.length - 1];
  clientCase.budgets?.forEach((budgetPerParty) => {
    // get opponents of party in order to apportion each cost
    const opponentsOfParty = opponents.get(budgetPerParty.partyId) ?? [];
    // cost estimates only apply to remaining, not-settled opponents
    const opponentsNotFullySettled = opponentsOfParty.filter((opponentId) =>
      (partiesInvolvedPerStage.get(lastStage) ?? []).includes(opponentId)
    );
    budgetPerParty.costs.forEach((costsOfStage) => {
      // apportion costs only to opponents that are still involved at stage of the cost
      const opponentsAtStage = opponentsOfParty.filter((opponentId) =>
        (partiesInvolvedPerStage.get(costsOfStage.stage) ?? []).includes(
          opponentId
        )
      );
      const apportionment =
        opponentsAtStage.length === opponentsOfParty.length
          ? undefined
          : opponentsAtStage;

      costsOfStage.disbursements.forEach((disbursementsOfStage) => {
        const totals = getDisbursementTotals(disbursementsOfStage);
        if (totals.incurredFees.gt(0)) {
          costs.push({
            amount: totals.incurredFees.toNumber(),
            costId: disbursementsOfStage.id + "_incurred_fees",
            payerId: budgetPerParty.partyId,
            type: CostType.CourtFees,
            incurred: true,
            stage: costsOfStage.stage,
            title: disbursementsOfStage.name,
            apportionment,
          });
        }
        if (totals.incurredLawyerCosts.gt(0)) {
          costs.push({
            amount: totals.incurredLawyerCosts.toNumber(),
            costId: disbursementsOfStage.id + "_incurred",
            payerId: budgetPerParty.partyId,
            type: CostType.LawyerCosts,
            incurred: true,
            stage: costsOfStage.stage,
            title: disbursementsOfStage.name,
            apportionment,
          });
        }
        // ignore estimates if there are no opponents left
        if (totals.estimatedFees.gt(0) && opponentsNotFullySettled.length > 0) {
          costs.push({
            amount: totals.estimatedFees.toNumber(),
            costId: disbursementsOfStage.id + "_estimated_fees",
            payerId: budgetPerParty.partyId,
            type: CostType.CourtFees,
            stage: costsOfStage.stage,
            title: disbursementsOfStage.name,
            apportionment: opponentsNotFullySettled,
          });
        }
        // ignore estimates if there are no opponents left
        if (
          totals.estimatedLawyerCosts.gt(0) &&
          opponentsNotFullySettled.length > 0
        ) {
          costs.push({
            amount: totals.estimatedLawyerCosts.toNumber(),
            costId: disbursementsOfStage.id + "_estimated",
            payerId: budgetPerParty.partyId,
            type: CostType.LawyerCosts,
            stage: costsOfStage.stage,
            title: disbursementsOfStage.name,
            apportionment: opponentsNotFullySettled,
          });
        }
      });
      costsOfStage.hourlyCosts.forEach((hourlyCostsOfStage) => {
        const totals = getHourlyCostsTotals(hourlyCostsOfStage);
        if (totals.getIncurred().gt(0)) {
          costs.push({
            amount: totals.getIncurred().toNumber(),
            costId: hourlyCostsOfStage.id + "_incurred",
            payerId: budgetPerParty.partyId,
            type: CostType.LawyerCosts,
            incurred: true,
            stage: costsOfStage.stage,
            title: hourlyCostsOfStage.name,
            apportionment,
          });
        }
        // ignore estimates if there are no opponents left
        if (
          totals.getEstimated().gt(0) &&
          opponentsNotFullySettled.length > 0
        ) {
          costs.push({
            amount: totals.getEstimated().toNumber(),
            costId: hourlyCostsOfStage.id + "_estimated",
            payerId: budgetPerParty.partyId,
            type: CostType.LawyerCosts,
            stage: costsOfStage.stage,
            title: hourlyCostsOfStage.name,
            apportionment: opponentsNotFullySettled,
          });
        }
      });
    });
  });
  const getPartyName = (partyId: string) =>
    clientCase.parties?.find((p) => p.id === partyId)?.name ?? partyId;
  clientCase.settlements?.forEach((settlement) => {
    const { id, offerorId, offereeId, stage } = settlement;
    if (settlement.costs.settlement.offeror > 0)
      costs.push({
        costId: `${id}_${offerorId}`,
        title: `Costs of ${getPartyName(offerorId)} to settle with ${getPartyName(offereeId)}`,
        amount: settlement.costs.settlement.offeror,
        payerId: offerorId,
        apportionment: [offereeId],
        incurred: true,
        type: CostType.LawyerCosts,
        stage,
      });
    if (settlement.costs.settlement.offeree > 0)
      costs.push({
        costId: `${id}_${offereeId}`,
        title: `Costs of ${getPartyName(offereeId)} to settle with ${getPartyName(offerorId)}`,
        amount: settlement.costs.settlement.offeree,
        payerId: offereeId,
        apportionment: [offerorId],
        incurred: true,
        type: CostType.LawyerCosts,
        stage,
      });
  });
  return costs;
}

function getCostRecoveries(clientCase: Case): Recovery[] {
  const recoveries: Recovery[] = [];
  clientCase.parties?.forEach((party) => {
    party.recoveries?.forEach((recovery) => {
      const type =
        recovery.type === RecoveryType.CourtFees
          ? CostGroups.CourtFees
          : CostGroups.LawyerCosts;
      recoveries.push({
        recipient: party.id,
        type,
        percentage: recovery.percentage,
      });
    });
  }) || [];
  return recoveries;
}

function getCostApportionments(
  clientCase: Case
): CaseConfigurationCostApportionment[] {
  const apportionments: CaseConfigurationCostApportionment[] = [];
  clientCase.parties?.forEach((party) => {
    const apportionment: CaseConfigurationCostApportionment = {
      payerId: party.id,
      costApportionment: [],
    };
    if (party.apportionment === undefined) return;
    Object.entries(party.apportionment.perParty).forEach((entry) => {
      apportionment.costApportionment.push({
        partyId: entry[0],
        percentage: entry[1],
      });
    });
    apportionments.push(apportionment);
  }) ?? [];
  return apportionments;
}

export function getLiabilityProbabilities(
  clientCase: Case
): IssueConfiguration[] {
  const probabilities: IssueConfiguration[] = [];
  clientCase.claims?.forEach((claim) => {
    // children probabilities?
    if (claim.issues !== undefined && claim.issues.length > 0) {
      const winChances = new Map<string, number>();
      claim.issues.forEach((issue) => fillWinChances(issue, winChances));
      for (const [liabilityId, winChance] of winChances)
        probabilities.push({ liabilityId, winChance });
    }
    // no children, only claim
    else
      probabilities.push({
        liabilityId: claim.id,
        winChance: getWinChanceOfLeaf(claim),
      });
  });
  return probabilities;
}
