import { add, getCostSummary, ZERO_COSTS } from "../CostUtility";
import { Stage } from "../model/CaseConfiguration";
import Scenario from "../Scenario";
import { ScenarioOutcome } from "../ScenarioOutcome";
import { ScenarioOutcomeForParty } from "../ScenarioOutcomeForParty";
import { WeighedAverage } from "../Value";
import { getCost, Judgement, Outcome, StageValues, worst } from "./Analytics";

export function getOutcome(
  scenario: Scenario,
  outcomes: ScenarioOutcome[],
  partyId: string
): Outcome {
  const judgments: Judgement[] = [];
  const enforcementValueAvg = new WeighedAverage();
  const enforcementCostsAvg = new WeighedAverage();
  outcomes
    .map((outcome) => new ScenarioOutcomeForParty(outcome, partyId))
    .forEach((outcomeForParty) => {
      const value = outcomeForParty.getJudgementTotal().toNumber();

      const enforcementVariants = outcomeForParty.getEnforcementResults();
      // assume worst case for EV calculations
      const enforcement = worst(enforcementVariants);
      if (enforcement === undefined) {
        // judgement has no enforcement scenario for party.
        // still in order for the average to work, we need to accumulate the judgement's probability
        enforcementValueAvg.add({
          value: 0,
          probability: outcomeForParty.getProbability(),
        });
      } else {
        enforcementValueAvg.add({
          value: enforcement.balance,
          probability: outcomeForParty.getProbability(),
        });
        // only compound enforcement costs if there is anything to enforce
        if (enforcement.balance > 0)
          enforcementCostsAvg.add({
            value: getCost(enforcement),
            probability: outcomeForParty.getProbability(),
          });
      }

      const judgement: Judgement = {
        issuesWon:
          outcomeForParty
            .getLiabilitiesWonByParty()
            .map((liability) => liability.id)
            .sort() ?? [],
        issuesLost:
          outcomeForParty
            .getLiabilitiesLostByParty()
            .map((liability) => liability.id)
            .sort() ?? [],
        probability: outcomeForParty.getProbability(),
        value,
        enforcementVariants,
        quantumsAwarded: {},
        quantumsToPay: {},
        recoveriesAwarded: outcomeForParty
          .getValueOfRecoveriesAwarded()
          .toNumber(),
        recoveriesToPay: outcomeForParty.getValueOfRecoveriesToPay().toNumber(),
      };
      outcomeForParty
        .getQuantumsAwarded()
        .forEach(
          (payment) =>
            (judgement.quantumsAwarded[payment.id] = payment.price.toNumber())
        );
      outcomeForParty
        .getQuantumsToPay()
        .forEach(
          (payment) =>
            (judgement.quantumsToPay[payment.id] = payment.price.toNumber())
        );

      judgments.push(judgement);
    });

  const costSummary = getCostSummary(scenario, partyId);

  const estimatedTrialCosts = add(
    costSummary.estimated[Stage[Stage.Trial]],
    costSummary.estimated[Stage[Stage.Appeals]]
  );
  const incurredTrialCosts = add(
    costSummary.incurred[Stage[Stage.Trial]],
    costSummary.incurred[Stage[Stage.Appeals]]
  );
  const trial: StageValues = {
    stage: Stage.Trial,
    estimated: estimatedTrialCosts,
    incurred: incurredTrialCosts,
    ev: enforcementValueAvg.getOr(0) - estimatedTrialCosts.total,
    netResult: 0,
  };

  // build stage chain
  // use all stages apart of trial & enforcement, which are considered last
  const stagesToTrial: StageValues[] = [];
  scenario.getCaseStages().forEach((stage) => {
    if (stage >= Stage.Trial) return;
    const stageResult: StageValues = {
      estimated: costSummary.estimated[Stage[stage]] || ZERO_COSTS,
      incurred: costSummary.incurred[Stage[stage]] || ZERO_COSTS,
      netResult: 0,
      ev: 0,
      stage,
    };
    stagesToTrial.push(stageResult);
  });
  if (stagesToTrial.length === 0) {
    console.warn("No stage other than trial and enforcement found");
    const stageResult: StageValues = {
      estimated:
        costSummary.estimated[Stage[Stage.TrialPreparation]] || ZERO_COSTS,
      incurred:
        costSummary.incurred[Stage[Stage.TrialPreparation]] || ZERO_COSTS,
      netResult: 0,
      ev: 0,
      stage: Stage.TrialPreparation,
    };
    stagesToTrial.push(stageResult);
  }
  stagesToTrial.push(trial);

  // fill outcomes with net results values
  let previousStage: StageValues | undefined;
  stagesToTrial.forEach((currentStage) => {
    currentStage.netResult =
      previousStage === undefined
        ? -currentStage.incurred.total
        : previousStage.netResult -
          previousStage.estimated.total -
          currentStage.incurred.total;
    previousStage = currentStage;
  });

  // now traverse back from trial and calculate the EV
  let lastStage = trial;
  for (let index = stagesToTrial.length - 2; index >= 0; index--) {
    const stage = stagesToTrial[index];
    stage.ev = lastStage.ev - stage.estimated.total;
    lastStage = stage;
  }

  const incurredEnforcementCosts =
    costSummary.incurred[Stage[Stage.Enforcement]] || ZERO_COSTS;
  const enforcement: StageValues = {
    ev: enforcementValueAvg.getOr(0),
    estimated: costSummary.estimated[Stage[Stage.Enforcement]] || ZERO_COSTS,
    incurred: incurredEnforcementCosts,
    netResult:
      trial.netResult - trial.estimated.total - incurredEnforcementCosts.total,
    stage: Stage.Enforcement,
  };
  stagesToTrial.push(enforcement);

  // outcome's net result after enforcement
  const netResult =
    enforcement.netResult + enforcement.ev - enforcement.estimated.total;

  return { partyId, judgments, stageResults: stagesToTrial, netResult };
}
