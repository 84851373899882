import { Home } from "@mui/icons-material";
import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { UserAvatar } from "./UserAvatar";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbs: JSX.Element[] = [
    <Home
      key="home"
      fontSize="medium"
      sx={{ mt: 0.5, cursor: "pointer" }}
      onClick={() => navigate("/")}
    />,
  ];
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment.trim().length > 0);

  let href = "";
  for (let i = 0; i < pathSegments.length; i++) {
    const pathSegment = pathSegments[i];
    href += `/${pathSegment}`;

    // skip configuration & analytics path segments of cases
    if (
      href.startsWith("/cases/") &&
      (pathSegment === "configuration" || pathSegment === "analytics")
    )
      continue;

    const target = href;
    breadcrumbs.push(
      <Typography key={`separator_${i}`} variant="h6">
        {"/"}
      </Typography>
    );
    breadcrumbs.push(
      <Typography
        key={pathSegment}
        onClick={() => navigate(target)}
        variant="overline"
        sx={{ cursor: "pointer" }}
      >
        {pathSegment}
      </Typography>
    );
  }

  return (
    <AppBar
      position="sticky"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Stack flexGrow={1} gap={2} direction="row" alignItems="center">
          {breadcrumbs}
        </Stack>
        <UserAvatar />
      </Toolbar>
    </AppBar>
  );
};

export const LoggedInPage = () => {
  return (
    <Box>
      <NavBar />
      <Outlet />
    </Box>
  );
};
