import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { useCurrency } from "../../../context/CurrencyContext";

import { useTranslation } from "react-i18next";
import { OfferImpact } from "../../../model/CaseModel";
import { Offer } from "./SettlementOfferDialog";

const OfferSummary = ({
  offer,
  offeree,
  offeror,
}: {
  offer: Offer;
  offeror: OfferImpact;
  offeree: OfferImpact;
}) => {
  const currency = useCurrency();
  const theme = useTheme();

  const offerorAmount = offer.payment.offereePays
    ? offer.payment.amount
    : -offer.payment.amount;
  const offereeAmount = offer.payment.offereePays
    ? -offer.payment.amount
    : offer.payment.amount;

  const floor = offer.payment.offereePays
    ? offeror.evAtStageOfOffer.ofParty
    : offeree.evAtStageOfOffer.ofParty;
  const deltaToFloor = offer.payment.amount - floor;

  const midpoint = Math.abs(offeror.evAtStageOfOffer.mid);
  const deltaToMidpoint = offer.payment.amount - midpoint;

  const ceiling = offer.payment.offereePays
    ? offeror.evAtStageOfOffer.ofOpponent
    : offeree.evAtStageOfOffer.ofOpponent;
  const deltaToCeiling = offer.payment.amount - ceiling;

  const offerorNetBalance =
    offeror.netResult.settled - offeror.netResult.baseline;
  const offereeNetBalance =
    offeree.netResult.settled - offeree.netResult.baseline;

  const offerorBalance = offerorAmount - offer.costs.settlement.offeror;
  const offereeBalance = offereeAmount - offer.costs.settlement.offeree;

  const { t } = useTranslation(undefined, {
    keyPrefix: "case.analytics.settlement.offerSummary",
  });
  return (
    <Box>
      <TableContainer>
        <Table
          key="perPartyResults"
          size="small"
          aria-label="sticky table"
          sx={{ maxWidth: "fit-content" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell align="center">
                <b>{t("baseData.offeror", { name: offer.offeror.name })}</b>
              </TableCell>
              <TableCell align="center">
                <b>{t("baseData.offeree", { name: offer.offeree.name })}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{t("baseData.amount")}</TableCell>
              <TableCell align="right">
                {currency.format(offerorAmount)}
              </TableCell>
              <TableCell align="right">
                {currency.format(offereeAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">{t("baseData.costs")}</TableCell>
              <TableCell align="right">
                {currency.format(-offer.costs.settlement.offeror)}
              </TableCell>
              <TableCell align="right">
                {currency.format(-offer.costs.settlement.offeree)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">{t("baseData.balance")}</TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  color={
                    offerorBalance >= 0
                      ? theme.palette.success.dark
                      : theme.palette.error.dark
                  }
                >
                  <b>{currency.format(offerorBalance)}</b>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  color={
                    offereeBalance >= 0
                      ? theme.palette.success.dark
                      : theme.palette.error.dark
                  }
                >
                  <b>{currency.format(offereeBalance)}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table
          key="summary"
          size="small"
          aria-label="sticky table"
          sx={{ mt: theme.spacing(2), maxWidth: "fit-content" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>
                  {t("ev.offer", {
                    amount: currency.format(offerorAmount),
                  })}
                </b>
              </TableCell>
              <TableCell align="center">
                <b>
                  {t("ev.floor", {
                    name: offer.payment.offereePays
                      ? offer.offeree.name
                      : offer.offeror.name,
                  })}
                </b>
              </TableCell>
              <TableCell align="center">
                <b>{t("ev.mid")}</b>
              </TableCell>
              <TableCell align="center">
                <b>
                  {t("ev.ceiling", {
                    name: offer.payment.offereePays
                      ? offer.offeree.name
                      : offer.offeror.name,
                  })}
                </b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{t("ev.withoutOffer")}</TableCell>
              <TableCell align="right">{currency.format(floor)}</TableCell>
              <TableCell align="right">{currency.format(midpoint)}</TableCell>
              <TableCell align="right">{currency.format(ceiling)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <b>{t("ev.delta")}</b>
              </TableCell>
              <TableCell align="right">
                <b>{`${deltaToFloor > 0 ? "+" : ""}${currency.format(deltaToFloor)}`}</b>
              </TableCell>
              <TableCell align="right">
                <b>{`${deltaToMidpoint > 0 ? "+" : ""}${currency.format(deltaToMidpoint)}`}</b>
              </TableCell>
              <TableCell align="right">
                <b>{`${deltaToCeiling > 0 ? "+" : ""}${currency.format(deltaToCeiling)}`}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table
          key="netResult"
          size="small"
          aria-label="sticky table"
          sx={{ mt: theme.spacing(2), maxWidth: "fit-content" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell align="center">
                <b>{t("netResult.offeror", { name: offer.offeror.name })}</b>
              </TableCell>
              <TableCell align="center">
                <b>{t("netResult.offeree", { name: offer.offeror.name })}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{t("netResult.withOffer")}</TableCell>
              <TableCell align="right">
                {currency.format(offeror.netResult.settled)}
              </TableCell>
              <TableCell align="right">
                {currency.format(offeree.netResult.settled)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                {t("netResult.withoutOffer")}
              </TableCell>
              <TableCell align="right">
                {currency.format(offeror.netResult.baseline)}
              </TableCell>
              <TableCell align="right">
                {currency.format(offeree.netResult.baseline)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <b>{t("netResult.delta")}</b>
              </TableCell>
              <TableCell align="right">
                <b>
                  {`${offerorNetBalance > 0 ? "+" : ""}${currency.format(offerorNetBalance)}`}
                </b>
              </TableCell>
              <TableCell align="right">
                <b>{`${offereeNetBalance > 0 ? "+" : ""}${currency.format(offereeNetBalance)}`}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OfferSummary;
