import Big from "big.js";
import { SubGraph } from "../../tree/tree";
import { Stage } from "../model/CaseConfiguration";

export interface Analytics {
  getOutcomes(): Outcome[];

  getOutcomeOf(partyId: string): Outcome | undefined;

  getDecisionTrees(): SubGraph;
}

export interface Outcome {
  readonly partyId: string;
  readonly stageResults: StageValues[];
  readonly judgments: Judgement[];
  // post enforcement net result of this outcome
  netResult: number;
}

export function worst(
  enforcements: EnforcementPlan[]
): EnforcementPlan | undefined {
  if (enforcements.length === 0) return;
  return enforcements.reduce((previous, current) =>
    current.balance < previous.balance ? current : previous
  );
}

export function best(
  enforcements: EnforcementPlan[]
): EnforcementPlan | undefined {
  if (enforcements.length === 0) return;
  return enforcements.reduce((previous, current) =>
    current.balance > previous.balance ? current : previous
  );
}

export interface EnforcementPayment {
  value: number;
  // payment-specific cost, apportioned to payer
  cost: number;
}

export interface EnforcementPlan {
  // derived sum of payments
  readonly value: number;
  // =value-cost, derived
  readonly balance: number;
  // generic cost of this plan, independent of payer
  readonly baseCost: number;
  readonly payments: { [partyId: string]: EnforcementPayment };
}

export function getCost(enforcementPlan: EnforcementPlan): number {
  let total = new Big(enforcementPlan.baseCost);
  Object.values(enforcementPlan.payments).forEach(
    (payment) => (total = total.add(payment.cost))
  );
  return total.toNumber();
}

export interface Judgement {
  readonly issuesWon: string[];
  readonly issuesLost: string[];
  // value of the judgement decided by the court
  readonly value: number;
  // probability of the judgement [0..1]
  readonly probability: number;
  // what was awarded
  readonly quantumsToPay: { [quantumId: string]: number };
  readonly quantumsAwarded: { [quantumId: string]: number };
  readonly recoveriesToPay: number;
  readonly recoveriesAwarded: number;

  // enforcement results
  readonly enforcementVariants: EnforcementPlan[];
}

export interface ApportionedCosts {
  readonly generic: number;
  readonly perParty: { [partyId: string]: number };
  readonly total: number;
}

export function total(
  generic: number,
  perParty: { [partyId: string]: number }
): number {
  let total = new Big(generic);
  Object.values(perParty).forEach((cost) => (total = total.add(cost)));
  return total.toNumber();
}

export interface StageValues {
  readonly stage: Stage;
  ev: number;
  netResult: number;
  readonly incurred: ApportionedCosts;
  readonly estimated: ApportionedCosts;
}
