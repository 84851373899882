import Big from "big.js";
import { ZERO, min } from "../big/bigUtil";

/**
 * Aggregates quantum values for a set of liable defendants.
 */
export class QuantumLiabilities {
  private maxAmount: Big;
  private readonly maxPerDefendant: { [defendantId: string]: Big } = {};
  constructor(
    // initial maximum amount for the quantum
    amount: number,
    // defendants to process/return quantum values for
    private readonly liableDefendants: string[]
  ) {
    this.maxAmount = new Big(amount);
  }

  /**
   * @param newMax new maximum amount to increase to. Ignored if a higher maximum is already configured
   */
  increaseMaxAmount(newMax: number) {
    if (this.maxAmount.lt(newMax)) this.maxAmount = new Big(newMax);
  }

  /**
   *
   * @param perDefendant maximum quantum amount liabilities per-defendant. If a defendant already has a higher amount configured, the new value is ignored. Amounts are automatically capped at current general maximum amount configured for the quantum
   */
  increaseDefendantMaxAmounts(perDefendant: { [defendantId: string]: number }) {
    for (const defendantId in perDefendant) {
      if (!this.liableDefendants.includes(defendantId)) return;
      const newMax = perDefendant[defendantId];
      const currentMax = this.maxPerDefendant[defendantId];

      // do we have a value already or is current max lower than in quantum?
      if (currentMax === undefined || currentMax.lt(newMax))
        this.maxPerDefendant[defendantId] = new Big(newMax);
    }
  }

  /**
   * @returns IDs (only) of liable defendants mapped to the quantum amount that they are liable for. If no amount was configured for a defendant, configured maximum amount is mapped.
   */
  perDefendant(): { [defendantId: string]: Big } {
    const perDefendant: { [defendantId: string]: Big } = {
      ...this.maxPerDefendant,
    };
    this.liableDefendants.forEach((liableDefendantId) => {
      if (perDefendant[liableDefendantId] === undefined)
        perDefendant[liableDefendantId] = this.maxAmount;
    });
    return perDefendant;
  }

  /**
   * @param defendantId if undefined, general maximum amount of quantum is returned. if defined, the maximum of the defendant is returned. if defendant is not liable for the quantum, 0 is returned
   * @returns max quantum amount
   */
  max(defendantId?: string): Big {
    if (defendantId === undefined) return this.maxAmount;
    // check if defendant is liable?
    if (!this.liableDefendants.includes(defendantId)) return ZERO;

    // defendant is liable
    // return either configured max or general
    const defendantMax = this.maxPerDefendant[defendantId];
    if (defendantMax === undefined) return this.maxAmount;

    // make sure to cap defendant's amount at max
    return min(defendantMax, this.maxAmount);
  }
}
