import { Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../../context/CaseContext";
import { CostApportionment, PartyDescriptors } from "../../model/CaseModel";
import { TabPanel } from "../general/TabPanel";
import { CostApportionmentsOfParty } from "./CostApportionmentsOfParty";

export interface CostApportionmentCallbacks {
  update(partyId: string, newValue: CostApportionment): Promise<void>;
}

export type CostApportionmentOfParty = {
  readonly partyId: string;
  partyName: string;
  apportionment?: CostApportionment;
  opponents: string[];
};

export const CostApportionments = () => {
  const caseContext = useCase();
  const parties = caseContext.getPartyDescriptors();

  const submit = useSubmit();
  const apportionmentCallbacks: CostApportionmentCallbacks = {
    update: function (
      partyId: string,
      apportionment: CostApportionment
    ): Promise<void> {
      const party = caseContext
        .getCase()
        .parties?.find((p) => p.id === partyId);
      if (party === undefined) {
        console.warn(
          "Cannot update cost apportionment, no party exists with ID " + partyId
        );
        return Promise.resolve();
      }
      return Promise.resolve(
        submit(
          { party: JSON.stringify({ ...party, apportionment }) },
          { method: "put" }
        )
      );
    },
  };

  const apportionments: CostApportionmentOfParty[] =
    caseContext.getCase().parties?.map((party) => ({
      partyId: party.id,
      partyName: party.name,
      apportionment: party.apportionment,
      opponents: [],
    })) || [];

  // calculate who needs apportionment v. whom
  const partiesWithMultipleOpponents: CostApportionmentOfParty[] = [];

  const getOpponents = (partyId: string) =>
    caseContext.getPartyDescriptors()[partyId]?.opponents ?? [];
  apportionments.forEach((apportionment) => {
    const opponents = new Set(getOpponents(apportionment.partyId));
    // don't forget existing apportionments
    Object.keys(apportionment.apportionment?.perParty || {}).forEach(
      (opponent) => opponents.add(opponent)
    );
    if (opponents.size > 1)
      partiesWithMultipleOpponents.push({
        ...apportionment,
        opponents: [...opponents],
      });
  });

  return (
    <CostApportionmentsComponent
      apportionments={partiesWithMultipleOpponents}
      apportionmentCallbacks={apportionmentCallbacks}
      parties={parties}
    />
  );
};

export const CostApportionmentsComponent = ({
  apportionments,
  apportionmentCallbacks,
  parties,
}: {
  apportionments: CostApportionmentOfParty[];
  apportionmentCallbacks: CostApportionmentCallbacks;
  parties: PartyDescriptors;
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (ignored: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (apportionments.length === 0) {
    return (
      <Typography>
        <Trans
          i18nKey={"case.apportionments.description"}
          values={{ count: 0 }}
        />
      </Typography>
    );
  }
  return (
    <>
      <Typography>
        <Trans
          i18nKey={"case.apportionments.description"}
          values={{ count: apportionments.length }}
        />
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="cost apportionments"
      >
        {apportionments.map((apportionment, index) => (
          <Tab
            label={apportionment.partyName}
            id={"party_" + apportionment.partyId}
            key={"apportionments_tab_" + index}
          />
        ))}
      </Tabs>
      {apportionments.map((apportionment, index) => (
        <TabPanel
          currentTabIndex={value}
          index={index}
          key={"recoveries_tab_panel_" + index}
        >
          <CostApportionmentsOfParty
            apportionment={apportionment}
            opponents={apportionment.opponents}
            parties={parties}
            update={(newValue) =>
              apportionmentCallbacks.update(apportionment.partyId, newValue)
            }
            key={"apportionment_" + index}
          />
        </TabPanel>
      ))}
    </>
  );
};
