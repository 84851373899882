import Big from "big.js";
import { ONE, ZERO } from "../big/bigUtil";
import { createPaymentTree } from "../tree/PaymentTreeUtility";
import { TreeNode } from "../tree/tree";
import { EnforcementCosts } from "./EnforcementCosts";
import { Quantum } from "./Quantum";
import { ScenarioOutcome } from "./ScenarioOutcome";
import { EnforcementPlan } from "./api/Analytics";
import { Cost, LiabilityIssue, Party } from "./model/CaseConfiguration";
import { PaymentObligation, merge } from "./payment/PaymentObligation";

export class ScenarioOutcomeForParty {
  private issuesWon: LiabilityIssue[];
  private issuesLost: LiabilityIssue[];
  recoveriesAwarded: PaymentObligation[];
  recoveriesToPay: PaymentObligation[];
  private quantumsWon: Quantum[];
  private quantumsLost: Quantum[];
  private readonly enforcementCosts;

  constructor(
    private readonly outcome: ScenarioOutcome,
    public readonly partyId: string
  ) {
    this.enforcementCosts = new EnforcementCosts(outcome, partyId);

    this.quantumsWon = outcome.getQuantumsWonBy(partyId);
    this.quantumsLost = outcome.getQuantumsLostBy(partyId);

    this.recoveriesAwarded = outcome.getRecoveriesPaidTo(partyId);
    this.recoveriesToPay = [];
    outcome.getRecoveriesPaidBy(partyId).forEach((payment) => {
      // only consider portion of party to pay and assume nobody else pays.
      // this excludes payment paths not including party. In other words,
      // assume the worst case for the party (nobody else is covering the costs)
      const amountPaidByParty = payment.getMaximumPaidBy(partyId);
      if (amountPaidByParty.lte(0)) return;
      this.recoveriesToPay.push(
        new PaymentObligation(
          payment.id,
          amountPaidByParty,
          new Map([[partyId, ONE]]),
          payment.recipientId
        )
      );
    });

    this.issuesWon = outcome.getLiabilitiesWonBy(partyId);
    this.issuesLost = outcome.getLiabilitiesLostBy(partyId);
  }

  getProbability(): number {
    return this.outcome.when.probability.toNumber();
  }

  getPartyName(): string {
    return this.outcome.getParty(this.partyId)?.name || this.partyId;
  }

  /**
   * @returns payments involving this party (either paying or receiving)
   */
  private getPartyPayments(): PaymentObligation[] {
    const payments: PaymentObligation[] = [];
    // gather recoveries awarded to party in this outcome
    this.recoveriesAwarded.forEach((recovery) => merge(payments, recovery));

    // generate recovery items that party needs to pay
    this.recoveriesToPay.forEach((recovery) => merge(payments, recovery));

    // gather quantums awarded to party
    this.getQuantumsAwarded().forEach((quantum) => merge(payments, quantum));

    // gather quantums paid by party
    this.getQuantumsToPay().forEach((quantum) => merge(payments, quantum));
    return payments;
  }

  hasEqualPayments(other: ScenarioOutcomeForParty): boolean {
    const allPayments = this.getPartyPayments();
    const otherPayments = other.getPartyPayments();
    if (allPayments.length !== otherPayments.length) return false;
    for (let i = 0; i < otherPayments.length; i++) {
      const otherPayment = otherPayments[i];
      if (!allPayments.some((payment) => payment.equals(otherPayment)))
        return false;
    }
    return true;
  }

  getLiabilitiesWonByParty(): LiabilityIssue[] {
    return [...this.issuesWon];
  }

  getLiabilitiesLostByParty(): LiabilityIssue[] {
    return [...this.issuesLost];
  }

  getLiabilitiesWonByOthers(): LiabilityIssue[] {
    return this.outcome
      .getLiabilitiesWon()
      .filter(
        (liability) =>
          !this.issuesWon.includes(liability) &&
          !this.issuesLost.includes(liability)
      );
  }

  getLiabilitiesLostByOthers(): LiabilityIssue[] {
    return this.outcome
      .getLiabilitiesLost()
      .filter(
        (liability) =>
          !this.issuesWon.includes(liability) &&
          !this.issuesLost.includes(liability)
      );
  }

  getValueOfQuantumsAwarded(): Big {
    let totalQuantums = ZERO;
    this.quantumsWon.forEach((quantum) => {
      totalQuantums = totalQuantums.add(quantum.getAmount());
    });
    return totalQuantums;
  }

  getCost(costId: string): Cost | undefined {
    return this.outcome.getCost(costId);
  }

  getValueOfQuantumsToPay(): Big {
    let totalQuantums = ZERO;
    this.quantumsLost.forEach((quantum) => {
      totalQuantums = totalQuantums.minus(quantum.getAmount());
    });
    return totalQuantums;
  }

  getQuantumsToPay(): PaymentObligation[] {
    const items: PaymentObligation[] = [];
    // start EV calculation with quantums
    // add awarded quantums
    this.quantumsLost.forEach((quantum) => {
      const maxSharePerPayer = new Map<string, Big>();
      maxSharePerPayer.set(this.partyId, ONE);
      items.push(
        new PaymentObligation(
          quantum.getQuantumId(),
          quantum.getAmount(),
          maxSharePerPayer,
          quantum.getClaimant()
        )
      );
    });
    return items;
  }

  getPaymentTree(): TreeNode {
    const names: { [id: string]: string } = {};
    this.outcome.getParties().forEach((p) => (names[p.id] = p.name));
    return createPaymentTree(
      this.outcome.getPaymentPool(),
      this.outcome.getAsPayer(this.partyId),
      names
    );
  }

  // lazy initialization
  private enforcementResults: EnforcementPlan[] | undefined;

  /**
   * @returns enforcement results sorted from worst to best by their balance (and then number of payments)
   */
  getEnforcementResults(): EnforcementPlan[] {
    if (this.enforcementResults === undefined) {
      this.enforcementResults = this.enforcementCosts.getEnforcements(
        this.outcome.getPaymentPool()
      );
    }
    return this.enforcementResults;
  }

  getQuantumsAwarded(): PaymentObligation[] {
    const items: PaymentObligation[] = [];
    this.quantumsWon.forEach((quantum) => {
      const sharePerDefendant = new Map<string, Big>();

      const defendantLiabilities = quantum.getLiableDefendants();
      for (const defendantId in defendantLiabilities) {
        sharePerDefendant.set(defendantId, defendantLiabilities[defendantId]);
      }

      items.push(
        new PaymentObligation(
          quantum.getQuantumId(),
          quantum.getAmount(),
          sharePerDefendant,
          this.partyId
        )
      );
    });
    return items;
  }

  getParty(partyId: string): Party | undefined {
    return this.outcome.getParty(partyId);
  }

  getValueOfRecoveriesAwarded(): Big {
    // sum awarded recoveries
    let sum = ZERO;
    this.recoveriesAwarded.forEach(
      (recovery) => (sum = sum.add(recovery.maximumPaid))
    );
    return sum;
  }

  getValueOfRecoveriesToPay(): Big {
    let recoveryToPayTotal = ZERO;
    this.recoveriesToPay.forEach(
      // sum recoveries to pay
      (recovery) => {
        // minus because paying means negative value
        recoveryToPayTotal = recoveryToPayTotal.minus(
          recovery.getMaximumPaidBy(this.partyId)
        );
      }
    );
    return recoveryToPayTotal;
  }

  /**
   * Returns the net balance of the judgement.
   *
   * @returns total value balance of the judgement, including quantums awarded and to pay, as well as recoveries awarded and to pay
   */
  getJudgementTotal(): Big {
    return this.getValueOfQuantumsAwarded()
      .add(this.getValueOfQuantumsToPay())
      .add(this.getValueOfRecoveriesAwarded())
      .add(this.getValueOfRecoveriesToPay());
  }

  /**
   * @returns value of party's assets or undefined if no assets are
   */
  getAssetLimitOfParty(): Big | undefined {
    return this.outcome.getAssetLimitOfParty(this.partyId);
  }
}
