import Big from "big.js";

import { ZERO } from "../big/bigUtil";
import { LikelyValue } from "./model/CaseConfiguration";

/**
 * Builds an average of multiple LikelyValues. If you only need an average of one value, use avg(value)
 * @see avg
 */
export class WeighedAverage {
  private average = ZERO;
  private totalWeight = ZERO;

  /**
   * Adds another value to the average. The value is traversed in terms of its 'or' children.
   * @param value to add to the weighed average
   */
  add(value: LikelyValue) {
    const probability = p(value);
    this.average = this.average.add(new Big(value.value).mul(probability));
    this.totalWeight = this.totalWeight.add(probability);
    if (value.or) this.add(value.or);
  }

  /**
   * @returns current average or undefined if no value has been added
   */
  get = () =>
    this.totalWeight.lte(0) ? undefined : this.average.div(this.totalWeight);

  getOr = (defaultValue: number): number =>
    this.get()?.toNumber() || defaultValue;

  getOrBig = (defaultValue: Big): Big => this.get() ?? defaultValue;

  getWeight = () => this.totalWeight;
}

/**
 * Traverses a likely value and returns its average. If you need the average of multiple values, use WeighedAverage.
 *
 * @param value to return the average of
 * @returns average of the value and it's 'or' children chain
 * @see WeighedAverage
 */
export function avg(value: LikelyValue): number {
  if (value.or === undefined) return p(value) * value.value;
  return p(value) * value.value + avg(value.or);
}

const p = (value: LikelyValue) =>
  value.probability === undefined ? 1 : value.probability;

/**
 * Traverses a likely value chain and returns its probability sum.
 * @param value to return the probability sum of
 * @returns sum of probabilities of this value and its 'or' children
 */
export function probability(value: LikelyValue): number {
  let probabilitySum = p(value);
  if (value.or) probabilitySum += probability(value.or);
  return probabilitySum;
}
