import Big from "big.js";
import { min, ZERO } from "../../big/bigUtil";

export interface Payer {
  readonly payerId: string;
  readonly name?: string;
  availableAmount?: Big;
}

/**
 * Represents an item with a price. The item can be paid by a set of payers.
 * The maximum amount a payer is allowed to pay for an item is expressed as a share (0..1) of the item's price.
 */
export interface Item {
  readonly id: string;
  readonly price: Big;
  readonly maxSharePerPayer: Map<string, Big>;
}

/**
 * @param price of the item to pay for
 * @param maxAmountPerPayer maximum amount payable per payer
 * @returns minimum payments to be paid by each payer of the item, i.e. mandatory payments of shares exclusive to the payers, that cannot be paid by others, possibly empty
 */
export function getMandatoryPayments(
  price: Big,
  maxAmountPerPayer: Map<string, Big>
): Map<string, Big> {
  const mandatoryPayments: Map<string, Big> = new Map();

  // check who is paying anything
  let sumOfPossiblePayments = ZERO;
  let numberOfPayers = 0;
  for (const [, maxPayment] of maxAmountPerPayer) {
    if (maxPayment.gt(0)) {
      numberOfPayers++;
      sumOfPossiblePayments = sumOfPossiblePayments.plus(maxPayment);
    }
  }
  // nobody is paying
  if (numberOfPayers === 0) return mandatoryPayments;

  // if there is no more than one payer or all payers together pay for total of 100% or less => everybody must pay their max share
  if (numberOfPayers < 2 || sumOfPossiblePayments.lte(price)) {
    // everybody must pay their maximum share
    for (const [payerId, maxPayment] of maxAmountPerPayer)
      mandatoryPayments.set(payerId, maxPayment);
    return mandatoryPayments;
  }

  // there is more than one payer and there is a surplus
  // how much do payers pay over 100%? (surplus)
  const surplus = sumOfPossiblePayments.minus(price);
  if (surplus.gte(price)) {
    // surplus of 100% means that even a payer of 100% does not have to pay anything (because it is possible others will cover his 100%)
    for (const [payerId] of maxAmountPerPayer) {
      mandatoryPayments.set(payerId, ZERO);
    }
    return mandatoryPayments;
  }
  // each payer has to pay part of their share that is above surplus (meaning the part cannot be covered by others)
  for (const [payerId, maxPayment] of maxAmountPerPayer) {
    const mandatoryPayment = maxPayment.minus(surplus); // maximumPayment - surplus
    if (mandatoryPayment.gt(0))
      mandatoryPayments.set(payerId, mandatoryPayment);
  }
  return mandatoryPayments;
}

/**
 * Represents a payment of a given amount performed by a payer.
 */
export interface Payment {
  // payer
  payerId: string;
  // how much is paid
  amount: Big;
}

/**
 * Holds payments for one item.
 */
export class ItemPayments {
  private paymentsPerPayer = new Map<string, Big>();
  private paidTotal = ZERO;

  constructor(
    public readonly itemPaidForId: string,
    public readonly itemPrice: Big
  ) {}

  isComplete(): boolean {
    return this.paidTotal.round(8).gte(this.itemPrice);
  }

  getAmountPaidBy(payerId: string): Big | undefined {
    return this.paymentsPerPayer.get(payerId);
  }

  getPayers(): string[] {
    const payers: string[] = [];
    for (const [payerId, amount] of this.paymentsPerPayer) {
      if (amount.gt(0)) payers.push(payerId);
    }
    return payers;
  }

  getAmountPaid(): Big {
    return this.paidTotal;
  }

  getAmountUnpaid(): Big {
    return this.itemPrice.minus(this.paidTotal); // itemPrice-amountPaid
  }

  addPayment(payerId: string, amount: Big): Payment | undefined {
    if (amount.lte(0)) {
      // not a payment
      console.warn(
        `Rejecting payment by '${payerId}' of wrong amount '${amount}'. Amount must be greater than 0`
      );
      return undefined;
    }
    const amountNeeded = this.getAmountUnpaid();
    if (amountNeeded.lte(0)) {
      // already paid completely
      console.debug(
        `Ignoring payment by '${payerId}' of '${amount}'. Item '${this.itemPaidForId}' is already completely paid`
      );
      return undefined;
    }

    const amountPaid = min(amountNeeded, amount);

    // add to payer's payments
    const newPayersTotal = amountPaid.plus(
      this.paymentsPerPayer.get(payerId) || ZERO
    );
    this.paymentsPerPayer.set(payerId, newPayersTotal);

    // update item's total
    this.paidTotal = this.paidTotal.plus(amountPaid);
    return { payerId, amount: amountPaid };
  }

  equals(other: ItemPayments): boolean {
    if (
      other.itemPaidForId !== this.itemPaidForId ||
      this.paymentsPerPayer.size !== other.paymentsPerPayer.size
    )
      return false;
    for (const [payerId, amount] of this.paymentsPerPayer) {
      if (!other.paymentsPerPayer.get(payerId)?.eq(amount)) return false;
    }
    return true;
  }
}

/**
 * Holds payments for a set of items.
 */
export class PaymentPlan {
  readonly itemPayments: Map<string, ItemPayments> = new Map();

  equals(other: PaymentPlan): boolean {
    if (this.itemPayments.size !== other.itemPayments.size) return false;

    for (const [itemId, payment] of this.itemPayments) {
      const otherPayment = other.itemPayments.get(itemId);
      if (!otherPayment || !payment.equals(otherPayment)) return false;
    }
    return true;
  }

  /**
   * @returns total price of all items of all payments of this plan
   */
  getTotalPrice(): number {
    let priceTotal = ZERO;

    this.itemPayments.forEach((payment) => {
      priceTotal = priceTotal.plus(payment.itemPrice);
    });
    return priceTotal.toNumber();
  }

  getPayers(): string[] {
    const payers = new Set<string>();
    for (const [, payment] of this.itemPayments) {
      payment.getPayers().forEach((payer) => payers.add(payer));
    }
    return [...payers];
  }

  /**
   * @returns total amount paid in all payments of this plan
   */
  getTotalAmountPaid(): number {
    let paidTotal = ZERO;
    this.itemPayments.forEach((payment) => {
      paidTotal = paidTotal.plus(payment.getAmountPaid());
    });
    return paidTotal.toNumber();
  }

  getAmountPaidBy(payerId: string): number {
    let amountPaidByPayer = ZERO;
    for (const [, payment] of this.itemPayments) {
      const amountForItem = payment.getAmountPaidBy(payerId);
      if (amountForItem) {
        amountPaidByPayer = amountPaidByPayer.plus(amountForItem);
      }
    }
    return amountPaidByPayer.toNumber();
  }

  add(itemId: string, itemPrice: Big, payment: Payment): Payment | undefined {
    let item = this.itemPayments.get(itemId);
    if (item === undefined) {
      item = new ItemPayments(itemId, itemPrice);
      this.itemPayments.set(itemId, item);
    }
    return item.addPayment(payment.payerId, payment.amount);
  }

  isComplete(): boolean {
    for (const [, itemPayment] of this.itemPayments) {
      if (!itemPayment.isComplete()) return false;
    }
    return true;
  }

  isCompleteFor(items: string[]): boolean {
    for (let index = 0; index < items.length; index++) {
      if (!this.itemPayments.get(items[index])?.isComplete()) return false;
    }
    return true;
  }
}
