import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { NamedValue } from "../model/CaseModel";
import { WeightedValuesEditor } from "./WeightedValuesEditor";

export const AssetEditorDialog = ({
  asset,
  open,
  onClose,
}: {
  asset: NamedValue;
  open: boolean;
  onClose: (updatedAsset?: NamedValue) => void;
}) => {
  const [name, setName] = useState(asset.name);
  const [values, setValues] = useState(asset.values);

  const isNameEmpty = name === undefined || name.trim().length === 0;
  const isValueMissing = values === undefined || values.length === 0;

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.parties.assets.editDialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={1}>
          <Trans i18nKey="case.parties.assets.editDialog.description" />
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label={`${t("case.parties.assets.editDialog.tip.name")}`}
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          // pass name update
          onChange={(e) => setName(e.target.value)}
          error={isNameEmpty}
          helperText={
            isNameEmpty
              ? t("case.parties.assets.editDialog.helper.emptyName")
              : undefined
          }
        />
        <WeightedValuesEditor
          values={values}
          // pass value update
          onChange={setValues}
          min={0}
        />
        {isValueMissing && (
          <FormHelperText error>
            {t("case.parties.assets.editDialog.helper.valueMissing")}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          <Trans i18nKey="case.parties.assets.editDialog.cancel" />
        </Button>
        <Button
          disabled={isNameEmpty || isValueMissing}
          onClick={() => {
            onClose({ ...asset, name, values });
          }}
          variant="contained"
        >
          <Trans i18nKey="case.parties.assets.editDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
