import TreeIcon from "@mui/icons-material/AccountTree";
import OutcomesIcon from "@mui/icons-material/ContentCopy";
import ClaimsIcon from "@mui/icons-material/Forum";
import SettlementIcon from "@mui/icons-material/Handshake";
import NetResultIcon from "@mui/icons-material/Iso";
import BargainingIcon from "@mui/icons-material/LinearScale";
import ValueIcon from "@mui/icons-material/Money";
import RevenueIcon from "@mui/icons-material/Moving";
import PartiesIcon from "@mui/icons-material/People";
import FeeStructureIcon from "@mui/icons-material/PieChart";
import RecoveriesIcon from "@mui/icons-material/Replay";
import VarIcon from "@mui/icons-material/ScatterPlot";
import CostsIcon from "@mui/icons-material/StackedBarChart";
import OverviewIcon from "@mui/icons-material/Summarize";
import SensitivityIcon from "@mui/icons-material/Tornado";
import ApportionmentsIcon from "@mui/icons-material/Tune";
import RiskIcon from "@mui/icons-material/Warning";
import {
  Box,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { useAuth } from "./auth/ParseAuthContext";

function createListItem(
  label: string,
  to: string,
  icon: JSX.Element,
  theme: Theme,
  isXsDisplay: boolean
): JSX.Element {
  return (
    <NavLink
      key={"section_" + to}
      end
      to={to}
      style={({ isActive }) => {
        return {
          color: isActive
            ? theme.palette.primary.contrastText
            : theme.palette.text.secondary,
          textDecoration: "none",
          backgroundColor: isActive
            ? theme.palette.primary.light
            : theme.palette.background.paper,
        };
      }}
    >
      <ListItem sx={{ p: 0, backgroundColor: "inherit", border: "inherit" }}>
        <ListItemButton title={label}>
          <ListItemIcon sx={{ minWidth: "24px", color: "inherit" }}>
            {icon}
          </ListItemIcon>
          {!isXsDisplay && (
            <ListItemText primary={label} sx={{ ml: theme.spacing(2) }} />
          )}
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
}

const CaseDrawer = () => {
  const { currentUser } = useAuth();

  const configurationSections: { path: string; icon: JSX.Element }[] = [
    { path: "parties", icon: <PartiesIcon /> },
    { path: "claims", icon: <ClaimsIcon /> },
    { path: "costs", icon: <CostsIcon /> },
    { path: "costStructure", icon: <FeeStructureIcon /> },
    { path: "recoveries", icon: <RecoveriesIcon /> },
    { path: "apportionments", icon: <ApportionmentsIcon /> },
  ];
  const analyticsSections: { path: string; icon: JSX.Element }[] = [
    { path: "outcomes", icon: <OutcomesIcon /> },
    { path: "netResults", icon: <NetResultIcon /> },
    { path: "risk", icon: <RiskIcon /> },
    { path: "value", icon: <ValueIcon /> },
    { path: "sensitivity", icon: <SensitivityIcon /> },
    { path: "revenue", icon: <RevenueIcon /> },
    { path: "settlement", icon: <SettlementIcon /> },
    { path: "bargaining", icon: <BargainingIcon /> },
    { path: "var", icon: <VarIcon /> },
  ];

  if (currentUser?.canViewDecisionTree) {
    analyticsSections.push({ path: "tree", icon: <TreeIcon /> });
  }

  const theme = useTheme();
  const isNarrowDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = isNarrowDisplay ? 24 + 32 : 200;

  return (
    <Drawer
      variant="permanent"
      PaperProps={{ elevation: 3 }}
      sx={{
        flexShrink: 0,
        overflow: "auto",
        height: "fit-content",
        width: drawerWidth,
        maxHeight: "100vh",
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
        },
      }}
    >
      <Toolbar sx={{ mb: theme.spacing(1) }} />
      {createListItem("Overview", "", <OverviewIcon />, theme, isNarrowDisplay)}
      {isNarrowDisplay ? (
        <Divider />
      ) : (
        <Typography
          variant="overline"
          mt={theme.spacing(1)}
          ml={theme.spacing(2)}
        >
          <Trans i18nKey={"case.details.label.configuration"} />
        </Typography>
      )}
      <List>
        {configurationSections.map((section) =>
          createListItem(
            t(
              "case.details.section.configuration." + section.path,
              section.path
            ),
            "configuration/" + section.path,
            section.icon,
            theme,
            isNarrowDisplay
          )
        )}
      </List>
      {isNarrowDisplay ? (
        <Divider />
      ) : (
        <Typography
          variant="overline"
          mt={theme.spacing(1)}
          ml={theme.spacing(2)}
        >
          <Trans i18nKey={"case.details.label.analytics"} />
        </Typography>
      )}
      <List>
        {analyticsSections.map((section) =>
          createListItem(
            t("case.details.section.analytics." + section.path, section.path),
            "analytics/" + section.path,
            section.icon,
            theme,
            isNarrowDisplay
          )
        )}
      </List>
    </Drawer>
  );
};

export const CasePage = () => {
  const theme = useTheme();
  return (
    <Box display="flex">
      <CaseDrawer />
      <Container
        maxWidth="lg"
        sx={{
          mt: theme.spacing(2),
          overflow: "auto",
        }}
      >
        <Outlet />
      </Container>
    </Box>
  );
};
