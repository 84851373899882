import { TableCell } from "@mui/material";
import { Currency } from "../i18n/Currency";
import { CurrencyTextField } from "./general/CurrencyTextField";

export const EditableCurrencyTableCell = ({
  currency,
  value,
  onValueChange,
  min,
  max,
  align,
  editable,
  label,
}: {
  currency: Currency;
  value?: number;
  onValueChange: (newValue: number) => void;
  min?: number;
  max?: number;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  editable: boolean;
  label?: string | null;
}) => {
  return (
    <TableCell align={align} sx={{ minWidth: 120 }}>
      {editable ? (
        <CurrencyTextField
          value={value}
          label={label !== null ? label : undefined}
          onValueChange={onValueChange}
          currency={currency}
          min={min}
          max={max}
          size="small"
        />
      ) : (
        currency.format(value || 0)
      )}
    </TableCell>
  );
};
