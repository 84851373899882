import { Box, Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { t } from "i18next";
import { IssueRelation } from "../../model/CaseModel";

export interface RelationItem {
  relation: IssueRelation;
  hint: string;
  disabled?: boolean;
}

const relations: string[] = [
  IssueRelation[IssueRelation.And],
  IssueRelation[IssueRelation.Or],
  IssueRelation[IssueRelation.Xor],
];

export const IssueRelationSelector = ({
  selected,
  disabled = [],
  onRelationClicked,
}: {
  selected: IssueRelation;
  disabled?: IssueRelation[];
  onRelationClicked: (relation: IssueRelation) => void;
}) => {
  const disabledStrings = disabled.map((r) => IssueRelation[r]);
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <ToggleButtonGroup
        value={IssueRelation[selected]}
        exclusive
        onChange={(_, newValue: string) => {
          if (typeof newValue !== "string") {
            console.warn(
              "Ignoring new relation type value not being a string",
              newValue
            );
            return;
          }
          if (newValue === undefined) return;
          const relation =
            IssueRelation[newValue as keyof typeof IssueRelation];
          if (relation === undefined) {
            console.warn("Ignoring unknown issue relation", newValue);
            return;
          }
          onRelationClicked(relation);
        }}
        aria-label="Platform"
        size="small"
        color="success"
      >
        {relations.map((relation, index) => (
          <ToggleButton
            key={index}
            value={relation}
            sx={{ textTransform: "none", p: "0.25rem" }}
            title={t(`case.claims.issues.relations.hint.${relation}`)}
            disabled={disabledStrings.includes(relation)}
          >
            {t(`case.claims.issues.relations.name.${relation}`, relation)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Divider
        sx={{
          flexGrow: 1,
        }}
      />
    </Box>
  );
};
