import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Big from "big.js";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { Currency, gbp } from "../../i18n/Currency";
import { OptionalBig } from "../../model/CostModel";
import { EditableCurrencyTableCell } from "../EditableCurrencyTableCell";

export interface CostsPerStage {
  stage: Stage;
  incurredLawyerCosts: number;
  incurredFees: number;
  estimatedLawyerCosts: number;
  estimatedFees: number;
}

const getTotal = (costs: CostsPerStage) =>
  new Big(costs.incurredFees)
    .add(costs.incurredLawyerCosts)
    .add(costs.estimatedFees)
    .add(costs.estimatedLawyerCosts);

export const EditableCostsPerStageTable = ({
  editable = true,
  costs,
  updateCosts,
  currency = gbp,
}: {
  costs: CostsPerStage[];
  updateCosts: (update: CostsPerStage) => void;
  editable?: boolean;
  currency?: Currency;
}) => {
  const totalIncurredLawyerCosts = new OptionalBig();
  const totalIncurredFees = new OptionalBig();
  const totalEstimatedLawyerCosts = new OptionalBig();
  const totalEstimatedFees = new OptionalBig();
  const total = new OptionalBig();

  return (
    <TableContainer sx={{ mt: 2, mb: 2, userSelect: "none" }}>
      <Table size="small" aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ minWidth: 154, pt: 2, pb: 2 }}>
              <Trans i18nKey="case.budget.table.header.stage" />
            </TableCell>
            <TableCell align="right">
              <Trans i18nKey="case.budget.stage.header.incurredLawyerCosts" />
            </TableCell>
            <TableCell align="right">
              <Trans i18nKey="case.budget.stage.header.incurredFees" />
            </TableCell>
            <TableCell align="right">
              <Trans i18nKey="case.budget.stage.header.estimatedLawyerCosts" />
            </TableCell>
            <TableCell align="right">
              <Trans i18nKey="case.budget.stage.header.estimatedFees" />
            </TableCell>
            <TableCell align="right">
              <Trans i18nKey="case.budget.stage.header.total" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costs.map((costsOfStage, index) => {
            totalIncurredLawyerCosts.addNumber(
              costsOfStage.incurredLawyerCosts
            );
            totalIncurredFees.addNumber(costsOfStage.incurredFees);
            totalEstimatedLawyerCosts.addNumber(
              costsOfStage.estimatedLawyerCosts
            );
            totalEstimatedFees.addNumber(costsOfStage.estimatedFees);
            const totalOfStage = getTotal(costsOfStage);
            total.addBig(totalOfStage);
            return (
              <TableRow key={"timeCosts_" + index} hover>
                <TableCell align="center" sx={{ pt: 2, pb: 2 }}>
                  <Trans i18nKey={"case.stages." + Stage[costsOfStage.stage]} />
                </TableCell>
                <EditableCurrencyTableCell
                  editable={editable}
                  align="right"
                  currency={currency}
                  label={t("case.budget.stage.header.incurredLawyerCosts")}
                  value={costsOfStage.incurredLawyerCosts}
                  onValueChange={(value) =>
                    updateCosts({ ...costsOfStage, incurredLawyerCosts: value })
                  }
                />
                <EditableCurrencyTableCell
                  editable={editable}
                  align="right"
                  label={t("case.budget.stage.header.incurredFees")}
                  currency={currency}
                  value={costsOfStage.incurredFees}
                  onValueChange={(value) =>
                    updateCosts({ ...costsOfStage, incurredFees: value })
                  }
                />
                <EditableCurrencyTableCell
                  editable={editable}
                  align="right"
                  currency={currency}
                  label={t("case.budget.stage.header.estimatedLawyerCosts")}
                  value={costsOfStage.estimatedLawyerCosts}
                  onValueChange={(value) =>
                    updateCosts({
                      ...costsOfStage,
                      estimatedLawyerCosts: value,
                    })
                  }
                />
                <EditableCurrencyTableCell
                  editable={editable}
                  align="right"
                  label={t("case.budget.stage.header.estimatedFees")}
                  currency={currency}
                  value={costsOfStage.estimatedFees}
                  onValueChange={(value) =>
                    updateCosts({ ...costsOfStage, estimatedFees: value })
                  }
                />
                <TableCell align="right" sx={{ minWidth: 130 }}>
                  {currency.format(totalOfStage.toNumber())}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow key={"total"}>
            <TableCell align="center" variant="head" sx={{ pt: 2, pb: 2 }}>
              <Trans i18nKey="case.budget.stage.header.total" />
            </TableCell>
            <TableCell align="right" variant="head">
              {currency.format(totalIncurredLawyerCosts.toNumber() || 0)}
            </TableCell>
            <TableCell align="right" variant="head">
              {currency.format(totalIncurredFees.toNumber() || 0)}
            </TableCell>
            <TableCell align="right" variant="head">
              {currency.format(totalEstimatedLawyerCosts.toNumber() || 0)}
            </TableCell>
            <TableCell align="right" variant="head">
              {currency.format(totalEstimatedFees.toNumber() || 0)}
            </TableCell>
            <TableCell align="right" variant="head">
              {currency.format(total.toNumber() || 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
