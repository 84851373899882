import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";

export const CaseTitleDialog = ({
  initialTitle,
  open,
  saveTitle,
  onClose,
}: {
  initialTitle: string;
  open: boolean;
  saveTitle: (newTitle: string) => Promise<void>;
  onClose: () => void;
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const isTitleEmpty = title === undefined || title.trim().length === 0;

  const close = () => {
    // is there anything to save?
    if (isTitleEmpty) {
      onClose();
      return;
    }
    const toSave = title;
    setSaving(true);
    saveTitle(toSave)
      .then(() => {
        setSaving(false);
        setError(undefined);
      })
      .catch((error) => {
        setSaving(false);
        console.warn("Failed to save title", error);
        setError(error);
      });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.title.editDialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={1}>
          <Trans i18nKey="case.title.editDialog.description" />
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          label={`${t("case.title.editDialog.tip.title")}`}
          type="text"
          fullWidth
          variant="outlined"
          value={title}
          // pass name update
          onChange={(e) => setTitle(e.target.value)}
          error={isTitleEmpty}
          disabled={saving}
          helperText={
            isTitleEmpty
              ? t("case.title.editDialog.helper.emptyTitle")
              : undefined
          }
        />
        {error && (
          <Alert sx={{ mt: 2 }} severity="error">
            <AlertTitle>
              <Trans i18nKey="case.title.editDialog.error.save" />
            </AlertTitle>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} disabled={saving}>
          <Trans i18nKey="case.title.editDialog.cancel" />
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<SaveIcon />}
          disabled={isTitleEmpty || saving}
          loading={saving}
          onClick={close}
        >
          <Trans i18nKey="case.budget.table.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
