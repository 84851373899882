import { Alert, Chip, TableCell, TableRow, Typography } from "@mui/material";
import { currency } from "../../analytics/model/CaseConfiguration";
import { Quantum, getAwardedValue } from "../../model/CaseModel";
import { percentage } from "./Claims";
import { Party } from "./IssueModel";
import { QuantumCell, QuantumProps } from "./QuantumCell";

export interface QuantumRowCallbacks {
  /**
   * @param quantum quantum to edit or undefined if a new quantum shall be created
   */
  onQuantumEditorRequested: (quantum?: Quantum) => void;
  onLiabilityUpdate: (
    quantum: Quantum,
    defendantId: string,
    newLiability: number
  ) => void;
  onQuantumDelete: (quantum: Quantum) => void;
}

export const QuantumRow = ({
  issueTitle,
  issueOrdinal,
  quantum,
  defendants,
  callbacks,
  indent,
  summaryColumn = false,
}: {
  issueTitle: string;
  issueOrdinal: string;
  quantum: Quantum;
  defendants: Party[];
  callbacks: QuantumRowCallbacks;
  indent: boolean;
  summaryColumn?: boolean;
}) => {
  return (
    <TableRow key={"quantum_" + quantum.id}>
      <TableCell sx={{ borderBottom: "none", pl: 4 }} colSpan={indent ? 1 : 2}>
        <Chip
          label={
            <Typography variant="inherit" paddingTop={0.8} paddingBottom={0.8}>
              {quantum.name + " " + currency.format(getAwardedValue(quantum))}
            </Typography>
          }
          onClick={() => callbacks.onQuantumEditorRequested(quantum)}
          onDelete={() => callbacks.onQuantumDelete(quantum)}
          variant="outlined"
          clickable
          sx={{
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
            },
          }}
        />
      </TableCell>
      {defendants.map((defendant) => {
        const liability =
          (quantum.liabilityShares && quantum.liabilityShares[defendant.id]) ||
          0;
        const quantumCellProps: QuantumProps = {
          issueTitle,
          issueOrdinal,
          quantumTitle: quantum.name,
          quantumAmount: getAwardedValue(quantum),
        };
        return (
          <TableCell
            align="center"
            key={quantum.id + " " + defendant.id}
            sx={{ borderBottom: "none" }}
          >
            <QuantumCell
              quantum={quantumCellProps}
              defendant={defendant.name}
              onLiabilityUpdate={(value) =>
                callbacks.onLiabilityUpdate(quantum, defendant.id, value)
              }
              liability={liability}
            />
          </TableCell>
        );
      })}
      {summaryColumn && getQuantumCoverage(quantum, defendants, issueOrdinal)}
    </TableRow>
  );
};

function getQuantumCoverage(
  quantum: Quantum,
  defendants: Party[],
  issueOrdinal: string
) {
  if (quantum.value === 0) return <Typography variant="caption">--</Typography>;

  let vAny, vAll;
  const insufficientlyLiableDefendants: string[] = [];
  if (defendants.length === 0) {
    vAny = 0;
    vAll = 0;
  } else {
    if (quantum.liabilityShares === undefined) {
      // all are equally liable
      vAny = 1;
      vAll = 1;
    } else {
      // must start with 1 as we are searching for minimum (starting with 0 would always remain at 0)
      vAny = 1;
      // v all is sum of liability shares of all defendants, thus start at 0
      vAll = 0;
      for (let i = 0; i < defendants.length; i++) {
        const defendant = defendants[i];
        const liability = quantum.liabilityShares[defendant.id] ?? 0;
        if (liability < 1) insufficientlyLiableDefendants.push(defendant.name);

        vAll = Math.min(vAll + liability, 1);
        vAny = Math.min(vAny, liability, 1);
      }
    }
  }

  return (
    <>
      <TableCell
        align="center"
        key={"coverage_vAll"}
        sx={{
          borderBottom: "none",
          pb: 0,
        }}
      >
        <Alert
          key="vAll"
          sx={{ justifyContent: "center" }}
          severity={vAll < 1 ? "warning" : "success"}
          title={
            vAll < 1
              ? `Winning issue ${issueOrdinal} against all liable defendants does not fully cover quantum '${quantum.name}'`
              : `Winning issue ${issueOrdinal} against all liable defendants fully covers quantum '${quantum.name}'`
          }
        >
          {percentage.format(vAll)}
        </Alert>
      </TableCell>
      <TableCell
        align="center"
        key={"coverage_vAny"}
        sx={{
          borderBottom: "none",
          pb: 0,
        }}
      >
        <Alert
          key="vAny"
          sx={{ justifyContent: "center" }}
          severity={vAny < 1 ? "warning" : "success"}
          title={
            vAny < 1
              ? `Winning issue ${issueOrdinal} against ${insufficientlyLiableDefendants.join(
                  ", "
                )} does not fully cover quantum '${quantum.name}'`
              : `Winning issue ${issueOrdinal} against any liable defendant fully covers quantum '${quantum.name}'`
          }
        >
          {percentage.format(vAny)}
        </Alert>
      </TableCell>
    </>
  );
}
