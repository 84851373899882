import Big from "big.js";
import { ZERO, min } from "../big/bigUtil";

export class Quantum {
  constructor(
    private readonly quantumId: string,
    private readonly claimant: string,
    private readonly liableDefendants: { [defendantId: string]: Big },
    private amount: Big
  ) {}

  isLiableDefendant = (partyId: string) =>
    this.liableDefendants[partyId] !== undefined;

  isClaimant = (partyId: string) => this.claimant === partyId;

  getQuantumId = () => this.quantumId;

  getClaimant = () => this.claimant;

  getLiableDefendants = () => {
    const percentages: { [defendantId: string]: Big } = {};

    for (const defendantId in this.liableDefendants) {
      if (this.amount.eq(0)) percentages[defendantId] = ZERO;
      else
        percentages[defendantId] = this.liableDefendants[defendantId].div(
          this.amount
        );
    }
    return percentages;
  };

  getAmount = () => this.amount;

  increaseLiability = (partyId: string, amount: Big) => {
    const currentLiability = this.liableDefendants[partyId] ?? ZERO;
    if (currentLiability.gte(amount)) return;
    this.liableDefendants[partyId] = min(amount, this.amount);
  };

  increaseLiabilities = (liabilities: { [defendantId: string]: Big }) => {
    for (const defendantId in liabilities) {
      this.increaseLiability(defendantId, liabilities[defendantId]);
    }
  };

  increaseAmountTo = (newAmount: Big) => {
    if (this.amount.lt(newAmount)) this.amount = newAmount;
  };
}
