import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useNavigation } from "react-router";

import { Balance, QueryStats } from "@mui/icons-material";
import { Trans } from "react-i18next";
import { useAuth } from "../components/auth/ParseAuthContext";
import { Copyright } from "../components/Copyright";
import Loading from "../components/general/Loading";

const SectionCard = ({
  title,
  subTitle,
  icon,
  href,
}: {
  title: string;
  subTitle: string;
  icon: JSX.Element;
  href: string;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Card elevation={3} sx={{ minWidth: "fit-content" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1),
            alignItems: "center",
            userSelect: "none",
            cursor: "pointer",
          }}
          onClick={() => navigate(href)}
        >
          <Typography variant="h3">{icon}</Typography>
          <Box>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body1">{subTitle}</Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "end",
          userSelect: "none",
        }}
      >
        <Tooltip title={<Trans i18nKey={title} />}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => navigate(href)}
          >
            <Trans i18nKey="case.list.card.open" />
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

const Home = () => {
  const { currentUser } = useAuth();
  const navigation = useNavigation();
  const theme = useTheme();

  if (navigation.state === "loading") {
    return <Loading />;
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: theme.spacing(4),
      }}
    >
      <Typography variant="h4" mt={theme.spacing(4)}>
        {`Hello ${currentUser?.username}, welcome to CaseSolv.`}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Available features:
      </Typography>
      <SectionCard
        title="Cases"
        subTitle="Define and solve cases"
        href="/cases"
        icon={<Balance fontSize="inherit" />}
      />
      <SectionCard
        title="Portfolio"
        subTitle="Analyze your case portfolio"
        href="/portfolio"
        icon={<QueryStats fontSize="inherit" />}
      />
      <Copyright />
    </Container>
  );
};

export default Home;
