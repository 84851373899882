import { Alert, Box, Divider, Typography, useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../../context/CaseContext";
import {
  CostApportionment,
  CostStructure,
  FeeStructure,
} from "../../model/CaseModel";
import { backgroundColor } from "../analytics/RevenueColors";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "../issues/Claims";
import { CostApportionmentSlider } from "./CostApportionmentsOfParty";

export interface CostApportionmentCallbacks {
  update(partyId: string, newValue: CostApportionment): Promise<void>;
}

export type CostApportionmentOfParty = {
  readonly partyId: string;
  partyName: string;
  apportionment?: CostApportionment;
  opponents: string[];
};

export const LawFirmFees = () => {
  const caseContext = useCase();

  const submit = useSubmit();

  const updateClientFeeStructure = (feeStructure: FeeStructure) => {
    const costStructure: CostStructure =
      caseContext.getCase().costStructure ?? {};
    costStructure.clientFeeStructure = feeStructure;
    return Promise.resolve(
      submit(
        { costStructure: JSON.stringify(costStructure) },
        { method: "put" }
      )
    );
  };

  return (
    <LawFirmFeesComponent
      feeStructure={
        caseContext.getCase().costStructure?.clientFeeStructure ?? {
          indirectCostsPercentage: 0.33,
          directCostsPercentage: 0.33,
        }
      }
      updateFeeStructure={updateClientFeeStructure}
    />
  );
};

const LawFirmFeesComponent = ({
  feeStructure,
  updateFeeStructure,
}: {
  feeStructure: FeeStructure;
  updateFeeStructure: (newStructure: FeeStructure) => Promise<void>;
}) => {
  const theme = useTheme();
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const indirectCostsPercentage = feeStructure.indirectCostsPercentage ?? 0;
  const directCostsPercentage = feeStructure.directCostsPercentage ?? 0;

  const costsTotal = indirectCostsPercentage + directCostsPercentage;
  const margin = costsTotal < 1 ? 1 - costsTotal : 0;
  const [marginDelta, setMarginDelta] = useState(0);
  const save = (newStructure: FeeStructure) => {
    setSaving(true);

    updateFeeStructure(newStructure)
      .then(() => {
        setSaving(false);
        setError(undefined);
        setMarginDelta(0);
        console.debug("Updated cost structure");
      })
      .catch((error) => {
        setSaving(false);
        console.warn("Failed to update cost structure", error);
        setError(error);
      });
  };

  const data: ChartData<"pie"> = {
    labels: [
      t("case.feeStructure.margin"),
      t("case.feeStructure.indirectCosts"),
      t("case.feeStructure.directCosts"),
    ],

    datasets: [
      {
        backgroundColor: [
          backgroundColor("margin", theme),
          backgroundColor("indirectCosts", theme),
          backgroundColor("directCosts", theme),
        ],
        data: [
          Math.abs(margin + marginDelta),
          indirectCostsPercentage,
          directCostsPercentage,
        ],
        hoverOffset: 4,
      },
    ],
  };
  const font = {
    family: theme.typography.fontFamily,
  };

  return (
    <Box sx={{ userSelect: "none" }}>
      {error && (
        <Alert severity="error">
          <Trans
            i18nKey={"case.apportionments.error.updateFailed"}
            values={{ error }}
          />
        </Alert>
      )}
      <Typography mb={1}>
        <Trans i18nKey={"case.feeStructure.description"} />
      </Typography>
      <TwoLiner
        primary={percentage.format(Math.abs(margin + marginDelta))}
        secondary={t("case.feeStructure.margin")}
      />

      <CostApportionmentSlider
        key={"indirectCostsPercentage_slider"}
        label={t("case.feeStructure.indirectCosts")}
        secondaryLabel={t("case.feeStructure.secondaryLabel")}
        value={indirectCostsPercentage}
        max={Math.min(1, indirectCostsPercentage + margin)}
        onUpdate={(newValue) => {
          if (newValue === indirectCostsPercentage) return;
          save({
            indirectCostsPercentage: newValue,
            directCostsPercentage,
          });
        }}
        onSliding={(sliderValue) => {
          const genericDelta = indirectCostsPercentage - sliderValue;
          setMarginDelta(genericDelta);
        }}
        disabled={saving}
      />
      <CostApportionmentSlider
        key={"directCostsPercentage_slider"}
        label={t("case.feeStructure.directCosts")}
        secondaryLabel={t("case.feeStructure.secondaryLabel")}
        value={directCostsPercentage}
        max={Math.min(1, directCostsPercentage + margin)}
        onUpdate={(newValue) => {
          if (newValue === directCostsPercentage) return;
          save({
            directCostsPercentage: newValue,
            indirectCostsPercentage,
          });
        }}
        onSliding={(sliderValue) => {
          const genericDelta = directCostsPercentage - sliderValue;
          setMarginDelta(genericDelta);
        }}
        disabled={saving}
      />
      <Divider />
      <Box maxWidth={500}>
        <Pie
          data={data}
          options={{
            plugins: {
              legend: {
                labels: {
                  font,
                },
              },
              tooltip: {
                bodyFont: font,
                titleFont: font,
                footerFont: font,
                callbacks: {
                  label: (item) => percentage.format(item.parsed),
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
