import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";

export const ResponsiveButton = ({
  label,
  title,
  onClick,
  icon,
}: {
  label: string;
  title: string;
  onClick: () => void;
  icon: React.ReactNode;
}) => {
  const theme = useTheme();
  const isXsDisplay = useMediaQuery(theme.breakpoints.down("sm"));
  return isXsDisplay ? (
    <IconButton onClick={onClick} title={title} size="small">
      {icon}
    </IconButton>
  ) : (
    <Button
      startIcon={icon}
      onClick={onClick}
      color="inherit"
      title={title}
      size="small"
    >
      {label}
    </Button>
  );
};
