import { useTheme } from "@mui/material";
import { BarElement, ChartData } from "chart.js";
import { t } from "i18next";
import { Bar } from "react-chartjs-2";

export type PartyValue = {
  partyId: string;
  partyName: string;
  client: boolean;
  value: number;
};

export const ApportionmentBarChart = ({
  data,
  formatValue,
  selected,
  onSelected,
  genericCosts,
}: {
  data: PartyValue[];
  formatValue: (value: number) => string;
  selected?: string;
  onSelected: (index: string) => void;
  genericCosts: number;
}) => {
  const theme = useTheme();

  // get all clients into the chart
  // then, get defendants in, sorted by EV
  const chartData: ChartData<"bar", number[]> = {
    labels: data.map((party) =>
      t(
        party.client
          ? "case.analytics.name.client"
          : "case.analytics.name.noClient",
        { name: party.partyName }
      )
    ),
    datasets: [
      {
        label: t("case.apportionments.chart.label.apportionedCosts"),
        data: data.map((party) => party.value),
        backgroundColor: data.map((party) =>
          party.value < 0
            ? theme.palette.error.main +
              (party.partyId !== selected ? "50" : "99")
            : theme.palette.success.main +
              (party.partyId !== selected ? "50" : "99")
        ),
        borderColor: data.map((party) =>
          party.value < 0
            ? theme.palette.error.dark
            : theme.palette.success.dark
        ),
        borderWidth: 2,
      },
    ],
  };

  if (genericCosts > 0) {
    chartData.datasets.unshift({
      label: t("case.apportionments.chart.label.genericCosts"),
      data: data.map(() => genericCosts),
      backgroundColor: data.map((party) =>
        party.value < 0
          ? theme.palette.error.dark +
            (party.partyId !== selected ? "70" : "99")
          : theme.palette.success.dark +
            (party.partyId !== selected ? "70" : "99")
      ),
      borderColor: data.map((party) =>
        party.value < 0 ? theme.palette.error.dark : theme.palette.success.dark
      ),
      borderWidth: 2,
    });
  }

  const font = {
    family: theme.typography.fontFamily,
  };

  return (
    <Bar
      data={chartData}
      options={{
        onClick: (event, elements) => {
          if (elements === undefined || elements.length === 0) return;
          const selectedElement = elements[0];
          if (!(selectedElement.element instanceof BarElement)) return;
          onSelected(data[selectedElement.index].partyId);
        },
        animation: false,
        interaction: {
          mode: "index",
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (item) =>
                item.dataset.label + ": " + formatValue(item.parsed.y),
              footer: (item) =>
                t("case.apportionments.chart.totalValue", {
                  value: formatValue(
                    item
                      .map((i) => i.parsed.y)
                      .reduce((sum, next) => sum + next, 0)
                  ),
                }),
            },
            bodyFont: font,
            titleFont: font,
            footerFont: font,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              font,
              callback: function (value) {
                if (typeof value === "number") return formatValue(value);
                return value;
              },
            },
            grid: {
              // make 0 line thicker
              lineWidth: (context) => (context.tick.value == 0 ? 2 : 1),
            },
            stacked: true,
          },
          x: {
            grid: {
              color: () => undefined,
            },
            ticks: {
              font,
            },
            stacked: true,
          },
        },
      }}
    />
  );
};
