import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { useCase } from "../../../context/CaseContext";
import { useCurrency } from "../../../context/CurrencyContext";
import {
  Claim,
  OfferStatus,
  SettlementData,
  SettlementOffer,
} from "../../../model/CaseModel";
import { getQuantums } from "../../../model/CaseModelUtility";
import randomId from "../../../utils/randomId";
import { CollapsibleBox } from "../../general/CollapsibleBox";
import { TabPanel } from "../../general/TabPanel";
import { TwoLiner } from "../../general/TwoLiner";
import { byName, clientsFirst, sort } from "../AnalyticsUtility";
import { SettlementChart } from "./SettlementChart";
import { SettlementColors, asChartData } from "./SettlementChartData";
import { Offer } from "./SettlementOfferDialog";
import SettlementOffers from "./SettlementOffers";
import SettlementSummary from "./SettlementSummary";

export const SettlementAnalytics = () => {
  const caseContext = useCase();
  const parties = caseContext.getPartyDescriptors();
  const settlementData = caseContext.getSettlementData();

  if (settlementData.length === 0) {
    return (
      <Typography>
        <Trans i18nKey={"case.analytics.settlement.noOutcomes"} />
      </Typography>
    );
  }
  // tab controls
  const [value, setValue] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const sortedParties: string[] = useMemo(
    () => sort(parties, clientsFirst, byName),
    [parties]
  );

  const theme = useTheme();
  const chartColors: SettlementColors = {
    evSettlingParty: theme.palette.warning.light,
    evOtherParty: theme.palette.secondary.dark,
    bargainingRange: theme.palette.secondary.main + "70",
    midpoints: theme.palette.primary.main,
    netResult: theme.palette.error.light,
    worstEnforcement: theme.palette.primary.light,
    bestEnforcement: theme.palette.success.light,
  };

  return (
    <Box sx={{ userSelect: "none" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="settlements per party"
      >
        {sortedParties.map((partyId, index) => {
          const party = parties[partyId];
          return (
            <Tab
              key={"settlement_tab_" + index}
              label={t(
                party?.isClient
                  ? "case.parties.name.client"
                  : "case.parties.name.noClient",
                { name: party.name }
              )}
              id={"settlement_party_" + index}
            />
          );
        })}
      </Tabs>

      {sortedParties.map((partyId, index) => {
        const { name } = parties[partyId];
        const opponents = caseContext.getClaimMatrix().getOpponentsOf(partyId);

        if (Object.keys(opponents).length === 0)
          return (
            // party has no opponents
            <TabPanel
              key={"tab_" + index}
              currentTabIndex={value}
              index={index}
            >
              <Typography>
                <Trans i18nKey="case.analytics.settlement.noOpponents" />
              </Typography>
            </TabPanel>
          );
        const settlementDataOfParty = settlementData.find(
          (data) => data.partyId === partyId
        );

        return (
          <TabPanel key={"tab_" + index} currentTabIndex={value} index={index}>
            <Typography>
              <Trans
                i18nKey="case.analytics.settlement.description"
                values={{ name }}
              />
            </Typography>

            <Box pb={theme.spacing(2)}>
              <SettlementSummary partyId={partyId} />
            </Box>

            {sortedParties.map((opponentId, index) => {
              // ignore self and non-opponents
              if (opponentId === partyId || opponents[opponentId] === undefined)
                return undefined;

              const data = settlementDataOfParty?.data.find(
                (data) => data.opponentId === opponentId
              );

              return (
                <SettlementAnalyticsOfParty
                  settlingParty={{ id: partyId, name }}
                  opponent={{
                    id: opponentId,
                    name: parties[opponentId].name,
                  }}
                  settlementData={data}
                  chartColors={chartColors}
                  key={"settlement_" + index}
                />
              );
            })}
          </TabPanel>
        );
      })}
    </Box>
  );
};

const SettlementAnalyticsOfParty = ({
  settlementData,
  settlingParty,
  opponent,
  chartColors,
}: {
  settlementData?: SettlementData;
  settlingParty: {
    id: string;
    name: string;
  };
  opponent: {
    id: string;
    name: string;
  };
  chartColors: SettlementColors;
}) => {
  const theme = useTheme();

  const caseContext = useCase();
  const c = caseContext.getCase();
  const claimMatrix = caseContext.getClaimMatrix();
  const parties = caseContext.getPartyDescriptors();

  const claims: Claim[] = [];
  const opponentsOfOfferor = claimMatrix.getOpponentsOf(settlingParty.id);
  (opponentsOfOfferor[opponent.id] ?? []).map((claimId) => {
    const claim = c.claims?.find((claim) => claim.id === claimId);
    if (claim) claims.push(claim);
  });
  const allQuantums = getQuantums(claims);

  const costs = caseContext.getCostSummaryOf(settlingParty.id);
  const costsTowardsOpponent = Object.values(costs.incurred).reduce(
    (previous, current) => previous + (current.perParty[opponent.id] ?? 0),
    0
  );

  const offers: Offer[] = useMemo(
    () =>
      (c.settlements ?? [])
        // consider settlements only between the two parties
        .filter(
          ({ offerorId, offereeId }) =>
            (offerorId === settlingParty.id && offereeId === opponent.id) ||
            (offereeId === settlingParty.id && offerorId === opponent.id)
        )
        .map((settlement) => {
          // gather unique quantums of all claims
          const quantums: Offer["quantums"] = allQuantums.map((quantum) => {
            const apportioned =
              settlement.settledQuantums.find((q) => q.id === quantum.id)
                ?.apportioned ?? 0;
            return {
              id: quantum.id,
              title: quantum.name,
              value: quantum.value,
              apportioned,
            };
          });

          // gather costs
          const offer: Offer = {
            id: settlement.id,
            offeror: {
              id: settlement.offerorId,
              name: parties[settlement.offerorId].name ?? settlement.offerorId,
            },
            offeree: {
              id: settlement.offereeId,
              name: parties[settlement.offereeId].name ?? settlement.offereeId,
            },
            payment: settlement.payment,
            stage: settlement.stage,
            status: settlement.status,
            claims: claims.map((claim) => ({
              id: claim.id,
              title: claim.title,
              settledByOffer: settlement.settledClaims.includes(claim.id),
              quantumIds: (claim.quantums ?? []).map((q) => q.id),
            })),
            quantums,
            costs: {
              settlement: settlement.costs.settlement,
              towardsPayer: {
                incurred: costsTowardsOpponent,
                apportioned: settlement.costs.towardsPayer.apportioned,
              },
              other: {
                incurred: costs.incurredTotal
                  .minus(costsTowardsOpponent)
                  .toNumber(),
                apportioned: settlement.costs.other.apportioned,
              },
            },
          };
          return offer;
        }),
    [c]
  );

  const submit = useSubmit();

  return (
    <Paper sx={{ mb: theme.spacing(2), p: theme.spacing(2) }} elevation={4}>
      <Typography mb={1} variant="h6" color="textSecondary">
        <Trans
          i18nKey="case.analytics.settlement.titleSettlementChart"
          values={{
            name: settlingParty.name,
            other: opponent.name,
          }}
        />
      </Typography>
      <Typography gutterBottom variant="overline">
        Settlement offers
      </Typography>
      <SettlementOffers
        partyId={settlingParty.id}
        create={() => ({
          id: randomId(),
          status: OfferStatus.Draft,
          stage: Stage.CMC,
          claims: claims.map(({ id, title, quantums }) => ({
            id,
            title,
            quantumIds: (quantums ?? []).map((q) => q.id),
            settledByOffer: false,
          })),
          offeror: settlingParty,
          offeree: opponent,
          quantums: allQuantums.map(({ id, name, value }) => ({
            id,
            value,
            title: name,
          })),
          costs: {
            settlement: { offeror: 0, offeree: 0 },
            towardsPayer: {
              incurred: costsTowardsOpponent,
            },
            other: {
              incurred: costs.incurredTotal
                .minus(costsTowardsOpponent)
                .toNumber(),
            },
          },
          payment: { amount: 0, offereePays: true },
        })}
        offers={offers}
        remove={async (offerId) =>
          await submit({ offerId }, { method: "DELETE" })
        }
        save={async (offer) => {
          const method = offers.some(({ id }) => id === offer.id)
            ? // update
              "PUT"
            : // add
              "POST";
          await submit({ offer: JSON.stringify(convert(offer)) }, { method });
        }}
        stages={caseContext.getStages()}
      />
      {settlementData && (
        <CollapsibleBox title="Settlement range">
          <SettlementChartPanel
            settlementData={settlementData}
            chartColors={chartColors}
            opponent={opponent}
            settlingParty={settlingParty}
          />
        </CollapsibleBox>
      )}
    </Paper>
  );
};

const SettlementChartPanel = ({
  settlementData,
  settlingParty,
  opponent,
  chartColors,
}: {
  settlementData: SettlementData;
  settlingParty: {
    id: string;
    name: string;
  };
  opponent: {
    id: string;
    name: string;
  };
  chartColors: SettlementColors;
}) => {
  const theme = useTheme();

  const settlementChartData: ChartData<"line", number[]> = asChartData(
    settlementData,
    settlingParty.name,
    opponent.name,
    chartColors
  );

  // tab controls
  const [stageIndex, setStageIndex] = useState(0);

  const stages: string[] =
    settlementChartData.labels?.map((label) => "" + label) || [];

  const currency = useCurrency();

  return (
    <>
      <Box mb={theme.spacing(2)}>
        <Typography gutterBottom variant="overline">
          {"Settlement values at " + stages[stageIndex]}
        </Typography>
        <Stack spacing={theme.spacing(2)} direction="row">
          <TwoLiner
            primary={settlingParty.name + "'s case value"}
            secondary={currency.format(
              settlementData.perStage[stageIndex]?.range.partyEv || 0
            )}
          />
          <TwoLiner
            primary={opponent.name + "'s inverse case value"}
            secondary={currency.format(
              settlementData.perStage[stageIndex]?.range.opponentEv || 0
            )}
          />
          <TwoLiner
            primary={"Midpoint"}
            secondary={currency.format(
              settlementData.perStage[stageIndex]?.range.mid || 0
            )}
          />
        </Stack>
      </Box>
      <SettlementChart
        chartData={settlementChartData}
        index={stageIndex}
        onIndexSelect={setStageIndex}
      />
    </>
  );
};

export function convert(offer: Offer): SettlementOffer {
  return {
    id: offer.id,
    offerorId: offer.offeror.id,
    offereeId: offer.offeree.id,
    costs: {
      settlement: offer.costs.settlement,
      towardsPayer: { apportioned: offer.costs.towardsPayer.apportioned },
      other: { apportioned: offer.costs.other.apportioned },
    },
    payment: offer.payment,
    stage: offer.stage,
    status: offer.status,
    settledClaims: offer.claims
      .filter(({ settledByOffer }) => settledByOffer)
      .map(({ id }) => id),
    settledQuantums: offer.quantums.map(({ id, apportioned }) => ({
      id,
      apportioned,
    })),
  };
}
