import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import {
  EnforcementPlan,
  Outcome,
  getCost,
} from "../../analytics/api/Analytics";
import { Stage, percentage } from "../../analytics/model/CaseConfiguration";
import { ZERO } from "../../big/bigUtil";
import { TwoLiner } from "../general/TwoLiner";
import { OutcomeCard, OutcomeCardProps } from "./OutcomeCard";

function generateOutcomeCards(outcome: Outcome): OutcomeCardProps[] {
  const trialStage = outcome.stageResults[Stage.Trial];
  const costsToTrial = trialStage
    ? trialStage.netResult - trialStage.estimated.total
    : 0;

  const judgmentsByNetResult = new Map<number, OutcomeCardProps>();
  outcome.judgments.forEach((judgement) => {
    const worstEnforcement: EnforcementPlan | undefined =
      judgement.enforcementVariants[0];
    const netResult = worstEnforcement
      ? worstEnforcement.balance + costsToTrial
      : costsToTrial;

    let outcomeProps = judgmentsByNetResult.get(netResult);
    if (outcomeProps !== undefined) {
      // add to judgments
      outcomeProps.numberOfJudgments++;
      outcomeProps.probability += judgement.probability;
      return;
    }
    // create a new one
    outcomeProps = {
      judgementValue: judgement.value,
      netResult,
      numberOfJudgments: 1,
      probability: judgement.probability,
    };
    if (worstEnforcement !== undefined) {
      outcomeProps.enforcement = {
        worstAmount: worstEnforcement.value,
        costs: getCost(worstEnforcement),
      };
    }
    judgmentsByNetResult.set(netResult, outcomeProps);
  });

  // sort by net result
  return [...judgmentsByNetResult.values()].sort(
    (o1, o2) => o1.netResult - o2.netResult
  );
}

export const CaseOutcome = ({
  outcome,
  partyName,
}: {
  outcome: Outcome;
  partyName: string;
}) => {
  const outcomeProps = useMemo(() => generateOutcomeCards(outcome), [outcome]);

  if (outcomeProps.length === 0)
    return (
      <Typography key="stage-label">
        <Trans
          i18nKey="case.analytics.outcomes.description"
          values={{ count: 0 }}
        />
      </Typography>
    );

  let negativeJudgments = 0;
  let negativeJudgmentsProbability = ZERO;
  let negativeNetOutcomes = 0;
  let negativeNetOutcomesProbability = ZERO;
  outcomeProps.forEach((judgment) => {
    if (judgment.judgementValue < 0) {
      negativeJudgments += judgment.numberOfJudgments;
      negativeJudgmentsProbability = negativeJudgmentsProbability.add(
        judgment.probability
      );
    }
    if (judgment.netResult < 0) {
      negativeNetOutcomes++;
      negativeNetOutcomesProbability = negativeNetOutcomesProbability.add(
        judgment.probability
      );
    }
  });

  const allOutcomesNegative = negativeNetOutcomes >= outcomeProps.length;

  return (
    <Box>
      <Typography>
        <Trans
          i18nKey="case.analytics.outcomes.description"
          values={{ name: partyName }}
        />
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <TwoLiner
                  key="judgments_total"
                  primary={t("case.analytics.outcomes.summary.judgments.total")}
                  secondary={outcome.judgments.length}
                />
              </TableCell>
              <TableCell>
                <TwoLiner
                  key="judgments_negative"
                  primary={t(
                    "case.analytics.outcomes.summary.judgments.negative"
                  )}
                  secondary={negativeJudgments}
                />
              </TableCell>
              <TableCell>
                {" "}
                <TwoLiner
                  key="judgments_negative_probability"
                  primary={t(
                    "case.analytics.outcomes.summary.judgments.negativeProbability"
                  )}
                  secondary={percentage.format(
                    negativeJudgmentsProbability.toNumber()
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TwoLiner
                  key="judgments_total"
                  primary={t("case.analytics.outcomes.summary.outcomes.total")}
                  secondary={outcomeProps.length}
                />
              </TableCell>
              <TableCell>
                <TwoLiner
                  key="judgments_negative"
                  primary={t(
                    "case.analytics.outcomes.summary.outcomes.negative"
                  )}
                  secondary={negativeNetOutcomes}
                />
              </TableCell>
              <TableCell>
                <TwoLiner
                  key="judgments_negative_probability"
                  primary={t(
                    "case.analytics.outcomes.summary.outcomes.negativeProbability"
                  )}
                  secondary={percentage.format(
                    negativeNetOutcomesProbability.toNumber()
                  )}
                />
              </TableCell>
            </TableRow>
            {allOutcomesNegative && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Alert sx={{ width: "fit-content" }} severity="error">
                    <Trans
                      i18nKey="case.analytics.outcomes.summary.alerts.allNegative"
                      values={{ name: partyName }}
                    />
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {!allOutcomesNegative && negativeNetOutcomesProbability.gt(0.5) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Alert sx={{ width: "fit-content" }} severity="warning">
                    <Trans
                      i18nKey="case.analytics.outcomes.summary.alerts.mostNegative"
                      values={{
                        name: partyName,
                        probability: percentage.format(
                          negativeNetOutcomesProbability.toNumber()
                        ),
                      }}
                    />
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography mt={2}>
        <Trans i18nKey="case.analytics.outcomes.judgments.description" />
      </Typography>
      <Box
        mt={2}
        flexWrap="wrap"
        display={"flex"}
        justifyContent="space-evenly"
      >
        {outcomeProps.map((outcome, index) => {
          return <OutcomeCard key={"outcome_card_" + index} {...outcome} />;
        })}
      </Box>
    </Box>
  );
};
