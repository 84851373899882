import {
  Case,
  Claim,
  Configuration,
  CostStructure,
  Party,
  RecoveryType,
  SettlementOffer,
} from "../../model/CaseModel";
import { Budget } from "../../model/CostModel";
import randomId from "../../utils/randomId";
import { initializeBudget } from "../initializeBudget";
import { CaseApi } from "./CaseApi";

export class BudgetInitializingCaseApiDecorator implements CaseApi {
  constructor(private readonly api: CaseApi) {}

  queryAllCases(): Promise<Case[]> {
    return this.api.queryAllCases();
  }

  queryCase(caseId: string): Promise<Case> {
    return this.api.queryCase(caseId);
  }

  addCase(c: Case): Promise<Case> {
    return this.api.addCase(c);
  }

  removeCase(caseId: string): Promise<void> {
    return this.api.removeCase(caseId);
  }

  setTitle(caseId: string, title: string): Promise<void> {
    return this.api.setTitle(caseId, title);
  }

  addParty(caseId: string, party: Party): Promise<Party[]> {
    // initialize cost recoveries
    if (party.recoveries === undefined || party.recoveries.length === 0)
      party.recoveries = [
        {
          id: randomId(),
          type: RecoveryType.LawyerCosts,
          percentage: 0,
        },
        {
          id: randomId(),
          type: RecoveryType.CourtFees,
          percentage: 0,
        },
      ];

    return this.api.addParty(caseId, party).then((parties) => {
      // initialize budget
      return this.api
        .queryCase(caseId)
        .then((c) => {
          if (
            c.budgets &&
            c.budgets.find((budget) => budget.partyId === party.id) ===
              undefined
          ) {
            const budget = initializeBudget(party);
            this.api.addBudget(caseId, budget);
          }
        })
        .then(() => parties);
    });
  }

  updateParty(caseId: string, prototype: Party): Promise<void> {
    return this.api.updateParty(caseId, prototype);
  }

  removeParty(caseId: string, partyId: string): Promise<void> {
    return this.api.removeParty(caseId, partyId).then(() =>
      // clean-up budgets
      this.api.removeBudget(caseId, partyId)
    );
  }

  addClaim(caseId: string, claim: Claim): Promise<Claim[]> {
    return this.api.addClaim(caseId, claim);
  }

  updateClaim(caseId: string, claim: Claim): Promise<void> {
    return this.api.updateClaim(caseId, claim);
  }

  removeClaim(caseId: string, claimId: string): Promise<void> {
    return this.api.removeClaim(caseId, claimId);
  }

  addBudget(caseId: string, budget: Budget): Promise<Budget[]> {
    return this.api.addBudget(caseId, budget);
  }

  updateBudget(caseId: string, budget: Budget): Promise<void> {
    return this.api.updateBudget(caseId, budget);
  }

  removeBudget(caseId: string, partyId: string): Promise<void> {
    return this.api.removeBudget(caseId, partyId);
  }

  addOffer(caseId: string, offer: SettlementOffer): Promise<SettlementOffer[]> {
    return this.api.addOffer(caseId, offer);
  }

  updateOffer(caseId: string, offer: SettlementOffer): Promise<void> {
    return this.api.updateOffer(caseId, offer);
  }

  removeOffer(caseId: string, offerId: string): Promise<void> {
    return this.api.removeOffer(caseId, offerId);
  }

  setBargainingVariant(caseId: string, variant?: Configuration): Promise<void> {
    return this.api.setBargainingVariant(caseId, variant);
  }

  updateCostStructure(caseId: string, structure: CostStructure): Promise<void> {
    return this.api.updateCostStructure(caseId, structure);
  }
}
