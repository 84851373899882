import Big from "big.js";
import { Stage } from "../analytics/model/CaseConfiguration";
import { Case, Claim, Issue, Party, Quantum } from "./CaseModel";
import { Budget, Costs, Disbursements, HourlyCosts } from "./CostModel";
import { ZERO, getValue } from "./WeightedValue";

export function getValueOfAssets(party: Party): number | undefined {
  if (party.assets === undefined || party.assets.length < 1) return;

  let totalValue = ZERO;
  party.assets.forEach(
    (asset) => (totalValue = totalValue.add(getValue(asset.values)))
  );
  return totalValue.toNumber();
}

export class CostTotals {
  constructor(
    public incurredLawyerCosts: Big,
    public incurredFees: Big,
    public estimatedLawyerCosts: Big,
    public estimatedFees: Big
  ) {}

  add(other: CostTotals): CostTotals {
    this.incurredLawyerCosts = this.incurredLawyerCosts.add(
      other.incurredLawyerCosts
    );
    this.incurredFees = this.incurredFees.add(other.incurredFees);
    this.estimatedLawyerCosts = this.estimatedLawyerCosts.add(
      other.estimatedLawyerCosts
    );
    this.estimatedFees = this.estimatedFees.add(other.estimatedFees);
    return this;
  }

  addIncurredLawyerCosts(amount: number) {
    this.incurredLawyerCosts = this.incurredLawyerCosts.add(amount);
  }
  addIncurredFees(amount: number) {
    this.incurredFees = this.incurredFees.add(amount);
  }
  addEstimatedLawyerCosts(amount: number) {
    this.estimatedLawyerCosts = this.estimatedLawyerCosts.add(amount);
  }
  addEstimatedFees(amount: number) {
    this.estimatedFees = this.estimatedFees.add(amount);
  }

  getEstimated(): Big {
    return this.estimatedFees.add(this.estimatedLawyerCosts);
  }
  getIncurred(): Big {
    return this.incurredFees.add(this.incurredLawyerCosts);
  }
  getTotal(): Big {
    return this.getEstimated().add(this.getIncurred());
  }
}

export const zero = () => new CostTotals(ZERO, ZERO, ZERO, ZERO);

export function getCostsTotal(costs: Costs): CostTotals {
  const totals = zero();
  costs.disbursements.forEach((disbursements) => {
    totals.add(getDisbursementTotals(disbursements));
  });
  costs.hourlyCosts.forEach((hourlyCosts) => {
    totals.add(getHourlyCostsTotals(hourlyCosts));
  });
  return totals;
}

export type StageCosts = {
  stage: Stage;
  incurred: number;
  estimated: number;
};

export function getCostsPerStage(
  budget: Budget,
  lawyerFeesOnly?: boolean
): StageCosts[] {
  const valuesPerStage = new Map<Stage, StageCosts>();
  budget.costs.forEach((costsOfStage) => {
    const costs = getCostsTotal(costsOfStage);
    const estimated = lawyerFeesOnly
      ? costs.estimatedLawyerCosts
      : costs.getEstimated();
    const incurred = lawyerFeesOnly
      ? costs.incurredLawyerCosts
      : costs.getIncurred();

    const existingValues = valuesPerStage.get(costsOfStage.stage);
    if (existingValues) {
      existingValues.estimated = estimated
        .add(existingValues.estimated)
        .toNumber();

      existingValues.incurred = incurred
        .add(existingValues.incurred)
        .toNumber();
    } else {
      valuesPerStage.set(costsOfStage.stage, {
        incurred: incurred.toNumber(),
        estimated: estimated.toNumber(),
        stage: costsOfStage.stage,
      });
    }
  });
  return Array.from(valuesPerStage.values());
}

export function getQuantums(claims?: (Claim | undefined)[]): Quantum[] {
  if (!claims || claims.length === 0) return [];
  const quantums: { [id: string]: Quantum } = {};
  claims
    .flatMap((claim) => claim?.quantums ?? [])
    .forEach((quantum) => (quantums[quantum.id] = quantum));
  return Object.values(quantums);
}

export function getDisbursementTotals(costs: Disbursements): CostTotals {
  const totals = zero();

  costs.entries?.forEach((entry) => {
    if (entry.incurred) {
      if (entry.courtFee) totals.addIncurredFees(entry.amount);
      else totals.addIncurredLawyerCosts(entry.amount);
      return;
    }
    // estimated
    if (entry.courtFee) totals.addEstimatedFees(entry.amount);
    else totals.addEstimatedLawyerCosts(entry.amount);
  });
  return totals;
}

export function getHourlyCostsTotals(costs: HourlyCosts): CostTotals {
  const totals = zero();
  let estimatedHours = ZERO;
  let incurredHours = ZERO;
  costs.entries?.forEach((entry) => {
    if (entry.incurred) {
      incurredHours = incurredHours.add(entry.hours);
    } else {
      estimatedHours = estimatedHours.add(entry.hours);
    }
  });
  totals.addIncurredLawyerCosts(
    incurredHours.mul(costs.ratePerHour).toNumber()
  );
  totals.addEstimatedLawyerCosts(
    estimatedHours.mul(costs.ratePerHour).toNumber()
  );
  return totals;
}

export function isDefendantOf(partyId: string, issue: Issue): boolean {
  return (
    issue.defendants !== undefined &&
    issue.defendants.find((defendant) => defendant.id === partyId) !== undefined
  );
}

/**
 * @param claims to search
 * @param issueId id of issue to find
 * @returns issue or undefined if no issue with given id is found
 */
export function findIssue(
  claims: Claim[] | undefined,
  issueId: string
): Issue | undefined {
  if (claims === undefined) return undefined;
  for (let index = 0; index < claims.length; index++) {
    const claim = claims[index];
    if (claim.id === issueId) return claim;
    const child = claim.issues?.find((issue) => issue.id === issueId);
    if (child !== undefined) return child;
  }
  return undefined;
}

export function getOpponents(
  claims: Claim[] | undefined,
  partyId: string
): string[] {
  if (claims === undefined || claims.length === 0) return [];
  const opponents = new Set<string>();
  claims?.forEach((claim) => {
    // claimant?
    if (claim.claimant.id === partyId) {
      // all defendants of claim and its issues are opponents
      claim.defendants?.forEach((defendant) => opponents.add(defendant.id));
      claim.issues
        ?.flatMap((issue) => issue.defendants || [])
        .forEach((defendant) => opponents.add(defendant.id));
      return;
    }
    // defendant?
    if (claim.defendants?.find((d) => d.id === partyId) !== undefined) {
      opponents.add(claim.claimant.id);
      return;
    }
    claim.issues?.forEach((issue) => {
      if (issue.defendants?.find((d) => d.id === partyId)) {
        opponents.add(claim.claimant.id);
        return;
      }
    });
  });
  return [...opponents];
}

// get leaf issues/claims in which both parties are fighting
/**
 *
 * @param c case to check
 * @param partyId party
 * @param opponentId opponent of party
 * @returns all issue leafs in which party and opponent are fighting each other
 */
export function getLeafIssues(
  c: Case,
  partyId: string,
  opponentId: string
): Issue[] {
  const leafs: Issue[] = [];
  c.claims?.forEach((claim) => {
    if (claim.claimant.id === opponentId) {
      // opponent is claimant
      // is party defendant?
      if (isDefendantOf(partyId, claim)) {
        // add claim or its issues
        if (claim.issues !== undefined && claim.issues.length > 0)
          claim.issues.forEach((issue) => leafs.push(issue));
        else leafs.push(claim);
        return;
      }
      // party is not defendant of root claim. is it defendant of any leafs?
      claim.issues?.forEach((issue) => {
        if (isDefendantOf(partyId, issue)) leafs.push(issue);
      });
      return;
    }
    // opponent is not claimant
    // is party claimant?
    if (claim.claimant.id === partyId) {
      // party is claimant
      // is opponent defendant?
      if (isDefendantOf(opponentId, claim)) {
        // add claim or its issues
        if (claim.issues !== undefined && claim.issues.length > 0)
          claim.issues.forEach((issue) => leafs.push(issue));
        else leafs.push(claim);
        return;
      }
      // party is not defendant of root claim. is it defendant of any leafs?
      claim.issues?.forEach((issue) => {
        if (isDefendantOf(opponentId, issue)) leafs.push(issue);
      });
      return;
    }
  });
  return leafs;
}
