import {
  Autocomplete,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useCurrency } from "../context/CurrencyContext";
import { Quantum } from "../model/CaseModel";
import randomId from "../utils/randomId";
import { WeightedAwardsEditor } from "./WeightedAwardsEditor";
import { CurrencyTextField } from "./general/CurrencyTextField";

export interface QuantumEditorProps {
  quantum?: Quantum;
  // holds names of quantums that are already defined in other claims and can be re-used. Their IDs must be re-used to assure the algorithm treats those quantums as same
  // (while the users use the names for unique quantum identification, the algorithm needs a unique ID)
  availableQuantumNames: { [quantumId: string]: string };
  // holds names of quantums that are already defined in the claim and thus cannot be re-used (because the claim can't have two quantums with the same name).
  forbiddenQuantumNames: string[];
  open: boolean;
  onClose: (quantum?: Quantum) => void;
}

export const QuantumEditorDialog = ({
  quantum,
  availableQuantumNames = {},
  forbiddenQuantumNames = [],
  open,
  onClose,
}: QuantumEditorProps) => {
  const currency = useCurrency();
  const [name, setName] = useState(quantum?.name);
  const [value, setValue] = useState(quantum?.value);

  const [awardedShares, setAwardedShares] = useState(
    quantum?.awardedShares || []
  );

  const isNameEmpty = name === undefined || name.trim().length === 0;
  const isNameForbidden =
    name !== undefined && forbiddenQuantumNames.includes(name);

  const isValueEmpty = value === undefined || Number.isNaN(value);
  const isValueNegative = value !== undefined && value < 0;

  const uniqueOptions = new Set<string>();
  Object.values(availableQuantumNames).forEach((name) =>
    uniqueOptions.add(name)
  );

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans i18nKey="case.claims.quantums.editDialog.title" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="button">
          <Trans i18nKey="case.claims.quantums.editDialog.sections.name.title" />
        </Typography>
        <DialogContentText mb={2}>
          <Trans i18nKey="case.claims.quantums.editDialog.sections.name.description" />
        </DialogContentText>
        <FormControl
          key={"claimant-selection"}
          fullWidth
          sx={{ mt: 1, minWidth: 220 }}
          required
          error={isNameEmpty || isNameForbidden}
        >
          <Autocomplete
            disabled={quantum !== undefined}
            freeSolo
            options={[...uniqueOptions]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t(
                  "case.claims.quantums.editDialog.sections.name.label.name"
                )}`}
                error={isNameEmpty || isNameForbidden}
              />
            )}
            onSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            getOptionDisabled={(quantumName) =>
              forbiddenQuantumNames.includes(quantumName)
            }
            value={quantum?.name}
          />
          {isNameEmpty && (
            <FormHelperText>
              {t(
                "case.claims.quantums.editDialog.sections.name.helperText.emptyName"
              )}
            </FormHelperText>
          )}
          {isNameForbidden && (
            <FormHelperText>
              {t(
                "case.claims.quantums.editDialog.sections.name.helperText.nameAlreadyUsed"
              )}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Typography variant="button">
          <Trans i18nKey="case.claims.quantums.editDialog.sections.value.title" />
        </Typography>
        <DialogContentText mb={2}>
          <Trans i18nKey="case.claims.quantums.editDialog.sections.value.description" />
        </DialogContentText>
        <FormControl
          key={"value-section"}
          fullWidth
          sx={{ mt: 1, minWidth: 220 }}
          required
          error={isValueEmpty || isValueNegative}
        >
          <CurrencyTextField
            currency={currency}
            label={`${t(
              "case.claims.quantums.editDialog.sections.value.label.value"
            )}`}
            onValueChange={setValue}
            required
            error={isValueEmpty || isValueNegative}
            variant="outlined"
            min={0}
            value={value}
          />
          {isValueEmpty && (
            <FormHelperText>
              {t(
                "case.claims.quantums.editDialog.sections.value.helperText.valueEmpty"
              )}
            </FormHelperText>
          )}
          {isValueNegative && (
            <FormHelperText>
              {t(
                "case.claims.quantums.editDialog.sections.value.helperText.valueNegative"
              )}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <Divider />
      <Collapse in={value !== undefined && !Number.isNaN(value) && value !== 0}>
        <DialogContent>
          <Typography variant="button">
            <Trans i18nKey="case.claims.quantums.editDialog.sections.award.title" />
          </Typography>
          <DialogContentText>
            <Trans i18nKey="case.claims.quantums.editDialog.sections.award.description" />
          </DialogContentText>

          <WeightedAwardsEditor
            values={awardedShares}
            // pass value update
            onChange={setAwardedShares}
            currency={currency}
            maxAward={value || 0}
          />
          {awardedShares.length < 1 && (
            <FormHelperText error>
              {t("case.parties.assets.editDialog.helper.valueMissing")}
            </FormHelperText>
          )}
        </DialogContent>
        <Divider />
      </Collapse>
      <DialogActions>
        <Button onClick={() => onClose()}>
          <Trans i18nKey="case.claims.quantums.editDialog.cancel" />
        </Button>
        <Button
          disabled={
            isNameEmpty ||
            isNameForbidden ||
            isValueEmpty ||
            isValueNegative ||
            awardedShares.length < 1
          }
          onClick={() => {
            if (name === undefined || value === undefined) {
              onClose();
              return;
            }

            let id = randomId();
            if (quantum !== undefined) id = quantum.id;
            else {
              // creating new quantum, check if existing quantum's name was selected
              const existing = availableQuantumNames[id];
              if (existing !== undefined) id = existing;
            }

            onClose({
              id,
              name,
              value,
              awardedShares,
            });
          }}
          variant="contained"
          autoFocus
        >
          <Trans i18nKey="case.claims.quantums.editDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
