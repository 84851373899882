import { Evs, ValueOfStage } from "../../model/CaseModel";

export class EvMatrix {
  constructor(private evs: { [partyId: string]: Evs }) {}

  getEvs(partyId: string, other: string): ValueOfStage[] | undefined {
    return this.evs[partyId]?.vsOthers[other];
  }

  getOwnEvs(partyId: string): ValueOfStage[] | undefined {
    return this.evs[partyId]?.own;
  }

  getPartyIds(): string[] {
    return Object.keys(this.evs);
  }
}
