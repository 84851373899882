import {
  Gavel,
  Handshake,
  Iso,
  Money,
  Moving,
  StackedBarChart,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useLoaderData, useNavigate } from "react-router";
import { WeighedAverage } from "../../analytics/Value";
import { ZERO } from "../../big/bigUtil";
import { useCurrency } from "../../context/CurrencyContext";
import { Case, CaseStats } from "../../model/CaseModel";
import { OptionalBig } from "../../model/CostModel";
import CumulativeValueAtRiskChart from "../analytics/CumulativeValueAtRiskChart";
import { histogram, monteCarlo, Sample } from "../analytics/MonteCarlo";
import ValueAtRiskChart from "../analytics/ValueAtRiskChart";
import { Copyright } from "../Copyright";
import { percentage } from "../issues/Claims";

type PartyValueInCase = {
  caseId: string;
  partyId: string;
  value: number;
};

type Stat = {
  avg: WeighedAverage;
  total: OptionalBig;
  max?: PartyValueInCase;
  min?: PartyValueInCase;
};

function update(stat: Stat, value: number, caseId: string, partyId: string) {
  stat.avg.add({ value });
  stat.total.addNumber(value);
  if (!stat.max || value > stat.max.value)
    stat.max = { caseId, partyId, value };
  if (!stat.min || value < stat.min.value)
    stat.min = { caseId, partyId, value };
}

const StatCard = ({
  title,
  subTitle,
  value,
  valueVariant = "h4",
  color,
  href,
}: {
  title: string;
  subTitle: string;
  color?: string;
  value: string;
  valueVariant?: Variant;
  href?: string;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Card
      elevation={3}
      sx={{
        width: "fit-content",
        userSelect: "none",
        borderTop: "solid 6px",
        borderTopColor: color ?? theme.palette.text.secondary,
        cursor: href ? "pointer" : "auto",
      }}
      onClick={href ? () => navigate(href) : undefined}
    >
      <CardContent>
        <Typography variant="overline">{title}</Typography>
        <Typography
          textAlign="center"
          variant={valueVariant}
          fontWeight={theme.typography.fontWeightBold}
        >
          {value}
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {subTitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const PortfolioPage = () => {
  const theme = useTheme();
  const { cases } = useLoaderData() as { cases: Case[] };
  const currency = useCurrency();

  const clientStats = new Map<string, CaseStats>();
  const allCases = new Map<string, Case>();
  const allParties = new Set<string>();
  const winChance: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // quantums claimed by clients
  let totalQuantumsClaimed = 0;
  const quantumsClaimed: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // quantums claimed against by clients
  let totalQuantumsClaimedAgainst = 0;
  const quantumsClaimedAgainst: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // costs
  const estimatedCosts: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const estimatedLawFees: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const incurredCosts: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const incurredLawFees: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // net results & evs
  const netResults: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const evs: Stat = { avg: new WeighedAverage(), total: new OptionalBig() };

  // revenue
  const revenueIncurred: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const revenueEstimated: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const marginIncurred: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const marginEstimated: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // settlements
  let totalAcceptedSettlements = 0;
  let totalSettledOpponents = 0;
  let totalSettledClaims = 0;
  const settlementPaymentsToParty: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };
  const settlementPaymentsByParty: Stat = {
    avg: new WeighedAverage(),
    total: new OptionalBig(),
  };

  // sample array per case
  const clientOutcomes: Sample[][] = [];
  cases.forEach((c) => {
    allCases.set(c.id, c);
    c.parties?.forEach(({ id, client }) => {
      allParties.add(id);
      if (!client) return;

      // party is client
      const judgments = c.results?.outcomes
        .filter((o) => o.partyId === id)
        .flatMap((o) => o.judgments);
      if (judgments && judgments.length > 1) clientOutcomes.push(judgments);

      const partyStats = c.results?.partyStats?.find(
        (stats) => stats.partyId === id
      );
      if (!partyStats) return;
      clientStats.set(id, partyStats.stats);

      // win chance
      update(
        winChance,
        1 - partyStats.stats.probabilityOfNegativeJudgement,
        c.id,
        id
      );

      // quantums claimed by clients
      totalQuantumsClaimed += partyStats.stats.quantumsClaimed.amount;
      update(
        quantumsClaimed,
        partyStats.stats.quantumsClaimed.totalValue,
        c.id,
        id
      );

      // quantums claimed against clients
      totalQuantumsClaimedAgainst +=
        partyStats.stats.quantumsClaimedAgainst.amount;
      update(
        quantumsClaimedAgainst,
        partyStats.stats.quantumsClaimedAgainst.totalValue,
        c.id,
        id
      );

      // costs
      const { disbursements, lawFirmFees } = partyStats.stats.costs;
      update(
        estimatedCosts,
        disbursements.estimated + lawFirmFees.estimated,
        c.id,
        id
      );
      update(estimatedLawFees, lawFirmFees.estimated, c.id, id);
      update(
        incurredCosts,
        disbursements.incurred + lawFirmFees.incurred,
        c.id,
        id
      );
      update(incurredLawFees, lawFirmFees.incurred, c.id, id);

      // net results & EVs
      update(netResults, partyStats.stats.netResult.average, c.id, id);
      update(evs, partyStats.stats.ev, c.id, id);

      // revenue
      update(
        revenueIncurred,
        (c.results?.revenue?.total.incurred.directCosts ?? 0) +
          (c.results?.revenue?.total.incurred.indirectCosts ?? 0) +
          (c.results?.revenue?.total.incurred.margin ?? 0),
        c.id,
        id
      );
      update(
        revenueEstimated,
        (c.results?.revenue?.total.estimated.directCosts ?? 0) +
          (c.results?.revenue?.total.estimated.indirectCosts ?? 0) +
          (c.results?.revenue?.total.estimated.margin ?? 0),
        c.id,
        id
      );
      update(
        marginIncurred,
        c.results?.revenue?.total.incurred.margin ?? 0,
        c.id,
        id
      );
      update(
        marginEstimated,
        c.results?.revenue?.total.estimated.margin ?? 0,
        c.id,
        id
      );

      // settlements
      const {
        accepted,
        paymentsToParty,
        paymentsByParty,
        settledClaims,
        settledOpponents,
      } = partyStats.stats.settlements;
      if (accepted > 0) {
        totalAcceptedSettlements += accepted;
        totalSettledClaims += settledClaims;
        totalSettledOpponents += settledOpponents;
        update(settlementPaymentsToParty, paymentsToParty, c.id, id);
        update(settlementPaymentsByParty, paymentsByParty, c.id, id);
      }
    });
  });

  // basic case stats
  // number of cases, number of clients, average win-chance, best/worst case links
  const numberOfCases = cases.length;
  const numberOfClients = clientStats.size;

  if (numberOfCases === 0) {
    return (
      <Typography variant="h6" mt={theme.spacing(4)}>
        No cases defined
      </Typography>
    );
  }
  if (numberOfClients === 0) {
    return (
      <Typography variant="h6" mt={theme.spacing(4)}>
        No clients
      </Typography>
    );
  }

  // get var data of clients

  // run monte carlo
  const result = monteCarlo(clientOutcomes, 10000);
  const hist = histogram(result, 100);

  return (
    <Container
      maxWidth="lg"
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Typography variant="h4" my={theme.spacing(2)} gutterBottom>
        Portfolio analytics
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Gavel />
        <Typography>{`You work for ${numberOfClients} clients in ${allCases.size} cases`}</Typography>
      </Stack>
      <Stack flexWrap="wrap" direction="row" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_win_chance"}
          title="Average win chance"
          value={percentage.format(winChance.avg.getOr(0))}
          subTitle={`in ${allCases.size} cases`}
        />
        {winChance.max && (
          <StatCard
            key={"best_win_chance"}
            href={`/cases/${winChance.max.caseId}/analytics/outcomes`}
            title="Best win chance"
            value={percentage.format(winChance.max.value)}
            subTitle={`${allCases.get(winChance.max.caseId)?.results?.partyDescriptors[winChance.max.partyId].name} in '${allCases.get(winChance.max.caseId)?.name}'`}
            color={theme.palette.success.main}
          />
        )}
        {winChance.min && (
          <StatCard
            key={"worst_win_chance"}
            href={`/cases/${winChance.min.caseId}/analytics/outcomes`}
            title="Lowest win chance"
            value={percentage.format(winChance.min.value)}
            subTitle={`${allCases.get(winChance.min.caseId)?.results?.partyDescriptors[winChance.min.partyId].name} in '${allCases.get(winChance.min.caseId)?.name}'`}
            color={theme.palette.error.main}
          />
        )}
      </Stack>
      <Divider />

      {/* net results */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Iso />
        <Typography>Net results</Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_netResults"}
          title="Average net result"
          value={currency.format(netResults.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {netResults.max && (
          <StatCard
            key={"max_netResults"}
            title="Highest net result"
            href={`/cases/${netResults.max.caseId}/analytics/netResults`}
            value={currency.format(netResults.max.value)}
            subTitle={`${allCases.get(netResults.max.caseId)?.results?.partyDescriptors[netResults.max.partyId].name} in '${allCases.get(netResults.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {netResults.min && (
          <StatCard
            key={"min_netResults"}
            title="Lowest net result"
            href={`/cases/${netResults.min.caseId}/analytics/netResults`}
            value={currency.format(netResults.min.value)}
            subTitle={`${allCases.get(netResults.min.caseId)?.results?.partyDescriptors[netResults.min.partyId].name} in '${allCases.get(netResults.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* evs  */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Money />
        <Typography>Estimated value</Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"total_ev"}
          title="Total value"
          value={currency.format(evs.total.or(0))}
          subTitle={`of all cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avg_ev"}
          title="Average case value"
          value={currency.format(evs.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        {evs.max && (
          <StatCard
            key={"max_evs"}
            title="Highest value case"
            href={`/cases/${evs.max.caseId}/analytics/value`}
            value={currency.format(evs.max.value)}
            subTitle={`${allCases.get(evs.max.caseId)?.results?.partyDescriptors[evs.max.partyId].name} in '${allCases.get(evs.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {evs.min && (
          <StatCard
            key={"min_evs"}
            title="Lowest value case"
            href={`/cases/${evs.min.caseId}/analytics/value`}
            value={currency.format(evs.min.value)}
            subTitle={`${allCases.get(evs.min.caseId)?.results?.partyDescriptors[evs.min.partyId].name} in '${allCases.get(evs.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* costs */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <StackedBarChart />
        <Typography>Costs of your clients</Typography>
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={theme.spacing(2)}
        rowGap={theme.spacing(2)}
      >
        <StatCard
          key={"avg_incurredLawFees"}
          title="Average incurred law fees"
          value={currency.format(incurredLawFees.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {incurredLawFees.max && (
          <StatCard
            key={"max_incurredLawFees"}
            title="Highest law fees incurred"
            href={`/cases/${incurredLawFees.max.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.max.value)}
            subTitle={`${allCases.get(incurredLawFees.max.caseId)?.results?.partyDescriptors[incurredLawFees.max.partyId].name} in '${allCases.get(incurredLawFees.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {incurredLawFees.min && (
          <StatCard
            key={"min_incurredLawFees"}
            title="Lowest law fees incurred"
            href={`/cases/${incurredLawFees.min.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.min.value)}
            subTitle={`${allCases.get(incurredLawFees.min.caseId)?.results?.partyDescriptors[incurredLawFees.min.partyId].name} in '${allCases.get(incurredLawFees.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={theme.spacing(2)}
        rowGap={theme.spacing(2)}
      >
        <StatCard
          key={"avg_incurredLawFees"}
          title="Average incurred law fees"
          value={currency.format(incurredLawFees.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {incurredLawFees.max && (
          <StatCard
            key={"max_incurredLawFees"}
            title="Highest law fees incurred"
            href={`/cases/${incurredLawFees.max.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.max.value)}
            subTitle={`${allCases.get(incurredLawFees.max.caseId)?.results?.partyDescriptors[incurredLawFees.max.partyId].name} in '${allCases.get(incurredLawFees.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {incurredLawFees.min && (
          <StatCard
            key={"min_incurredLawFees"}
            title="Lowest law fees incurred"
            href={`/cases/${incurredLawFees.min.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.min.value)}
            subTitle={`${allCases.get(incurredLawFees.min.caseId)?.results?.partyDescriptors[incurredLawFees.min.partyId].name} in '${allCases.get(incurredLawFees.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* revenue */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Moving />
        <Typography>Your revenue</Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"totalEstimatedRevenue"}
          title="Expected remaining revenue"
          value={currency.format(revenueEstimated.total.or(0))}
          subTitle={`across all cases`}
          valueVariant="h5"
          color={
            revenueEstimated.total.or(0) > 0
              ? theme.palette.success.main
              : theme.palette.text.secondary
          }
        />
        <StatCard
          key={"totalIncurredRevenue"}
          title="Incurred revenue"
          value={currency.format(revenueIncurred.total.or(0))}
          subTitle={`across all cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avgIncurredRevenue"}
          title="Incurred revenue"
          value={currency.format(revenueIncurred.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"totalEstimatedMargin"}
          title="Expected remaining margin"
          value={currency.format(revenueEstimated.total.or(0))}
          subTitle={`across all cases`}
          valueVariant="h5"
          color={
            marginEstimated.total.or(0) > 0
              ? theme.palette.success.main
              : theme.palette.text.secondary
          }
        />
        <StatCard
          key={"totalIncurredMargin"}
          title="Incurred margin"
          value={currency.format(marginIncurred.total.or(0))}
          subTitle={`across all cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avgIncurredMargin"}
          title="Incurred margin"
          value={currency.format(marginIncurred.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        {marginIncurred.max && (
          <StatCard
            key={"max_estimatedMargin"}
            title="Highest margin incurred"
            href={`/cases/${marginIncurred.max.caseId}/analytics/revenue`}
            value={currency.format(marginIncurred.max.value)}
            subTitle={`${allCases.get(marginIncurred.max.caseId)?.results?.partyDescriptors[marginIncurred.max.partyId].name} in '${allCases.get(marginIncurred.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {marginIncurred.min && (
          <StatCard
            key={"min_marginIncurred"}
            title="Lowest margin incurred"
            href={`/cases/${marginIncurred.min.caseId}/analytics/revenue`}
            value={currency.format(marginIncurred.min.value)}
            subTitle={`${allCases.get(marginIncurred.min.caseId)?.results?.partyDescriptors[marginIncurred.min.partyId].name} in '${allCases.get(marginIncurred.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        {marginEstimated.max && (
          <StatCard
            key={"max_estimatedMargin"}
            title="Highest remaining margin estimate"
            href={`/cases/${marginEstimated.max.caseId}/analytics/revenue`}
            value={currency.format(marginEstimated.max.value)}
            subTitle={`${allCases.get(marginEstimated.max.caseId)?.results?.partyDescriptors[marginEstimated.max.partyId].name} in '${allCases.get(marginEstimated.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {marginEstimated.min && (
          <StatCard
            key={"min_marginEstimated"}
            title="Lowest remaining margin estimate"
            href={`/cases/${marginEstimated.min.caseId}/analytics/revenue`}
            value={currency.format(marginEstimated.min.value)}
            subTitle={`${allCases.get(marginEstimated.min.caseId)?.results?.partyDescriptors[marginEstimated.min.partyId].name} in '${allCases.get(marginEstimated.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* settlements */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Handshake />
        <Typography>
          {totalAcceptedSettlements === 0
            ? "Your clients reached no settlements"
            : `Your clients reached ${totalAcceptedSettlements} settlement(s) to settle ${totalSettledClaims} claim(s) with ${totalSettledOpponents} opponent(s)`}
        </Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_settlementPaymentsToCLients"}
          title="Settlement payments to clients"
          value={currency.format(settlementPaymentsToParty.avg.getOr(0))}
          subTitle={`average per settlement`}
          valueVariant="h5"
          color={
            settlementPaymentsToParty.avg.getOr(0) > 0
              ? theme.palette.success.main
              : theme.palette.text.secondary
          }
        />
        {settlementPaymentsByParty.avg.getOr(0) > 0 && (
          <StatCard
            key={"avg_settlementPaymentsByClients"}
            title="Settlement payments by clients"
            subTitle={`average per settlement`}
            value={currency.format(settlementPaymentsByParty.avg.getOr(0))}
            valueVariant="h5"
            color={theme.palette.error.main}
          />
        )}
        {settlementPaymentsToParty.max &&
          settlementPaymentsToParty.max.value > 0 && (
            <StatCard
              key={"max_settlementPaymentsToParty"}
              title="Highest settlement received"
              subTitle={`${allCases.get(settlementPaymentsToParty.max.caseId)?.results?.partyDescriptors[settlementPaymentsToParty.max.partyId].name} in '${allCases.get(settlementPaymentsToParty.max.caseId)?.name}'`}
              href={`/cases/${settlementPaymentsToParty.max.caseId}/analytics/settlement`}
              value={currency.format(settlementPaymentsToParty.max.value)}
              valueVariant="h5"
              color={theme.palette.success.main}
            />
          )}
        {settlementPaymentsByParty.max &&
          settlementPaymentsByParty.max.value > 0 && (
            <StatCard
              key={"max_settlementPaymentsByParty"}
              title="Highest settlement to pay"
              subTitle={`${allCases.get(settlementPaymentsByParty.max.caseId)?.results?.partyDescriptors[settlementPaymentsByParty.max.partyId].name} in '${allCases.get(settlementPaymentsByParty.max.caseId)?.name}'`}
              href={`/cases/${settlementPaymentsByParty.max.caseId}/analytics/settlement`}
              value={currency.format(settlementPaymentsByParty.max.value)}
              valueVariant="h5"
              color={theme.palette.error.main}
            />
          )}
      </Stack>
      <Divider />

      {/* quantums */}
      <Typography>Quantum claims of your clients</Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_quantumsClaimed"}
          title="Average claimed per case"
          value={currency.format(quantumsClaimed.avg.getOr(0))}
          subTitle={`across ${allCases.size} cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avg_quantumAmount"}
          title="Average quantum amount"
          value={currency.format(
            totalQuantumsClaimed === 0
              ? 0
              : quantumsClaimed.avg
                  .getOrBig(ZERO)
                  .div(totalQuantumsClaimed)
                  .toNumber()
          )}
          subTitle={`across ${totalQuantumsClaimed} quantums`}
          valueVariant="h5"
        />
        {quantumsClaimed.max && (
          <StatCard
            key={"max_quantumsClaimed"}
            href={`/cases/${quantumsClaimed.max.caseId}/configuration/claims`}
            title="Highest quantums claimed"
            value={currency.format(quantumsClaimed.max.value)}
            subTitle={`by ${allCases.get(quantumsClaimed.max.caseId)?.results?.partyDescriptors[quantumsClaimed.max.partyId].name} in '${allCases.get(quantumsClaimed.max.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
        {quantumsClaimed.min && (
          <StatCard
            key={"min_quantumsClaimed"}
            href={`/cases/${quantumsClaimed.min.caseId}/configuration/claims`}
            title="Lowest quantums claimed"
            value={currency.format(quantumsClaimed.min.value)}
            subTitle={`by ${allCases.get(quantumsClaimed.min.caseId)?.results?.partyDescriptors[quantumsClaimed.min.partyId].name} in '${allCases.get(quantumsClaimed.min.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />
      <Typography>Quantum claims against your clients</Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_quantumsClaimed"}
          title="Average claimed per case"
          value={currency.format(quantumsClaimedAgainst.avg.getOr(0))}
          subTitle={`across ${allCases.size} cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avg_quantumAmount"}
          title="Average quantum amount"
          value={currency.format(
            totalQuantumsClaimedAgainst === 0
              ? 0
              : quantumsClaimedAgainst.avg
                  .getOrBig(ZERO)
                  .div(totalQuantumsClaimedAgainst)
                  .toNumber()
          )}
          subTitle={`across ${totalQuantumsClaimedAgainst} quantums`}
          valueVariant="h5"
        />
        {quantumsClaimedAgainst.max && (
          <StatCard
            key={"max_quantumsClaimedAgainst"}
            href={`/cases/${quantumsClaimedAgainst.max.caseId}/configuration/claims`}
            title="Highest quantums claimed"
            value={currency.format(quantumsClaimedAgainst.max.value)}
            subTitle={`against ${allCases.get(quantumsClaimedAgainst.max.caseId)?.results?.partyDescriptors[quantumsClaimedAgainst.max.partyId].name} in '${allCases.get(quantumsClaimedAgainst.max.caseId)?.name}'`}
            color={theme.palette.error.main}
            valueVariant="h5"
          />
        )}
        {quantumsClaimedAgainst.min && (
          <StatCard
            key={"min_quantumsClaimedAgainst"}
            href={`/cases/${quantumsClaimedAgainst.min.caseId}/configuration/claims`}
            title="Lowest quantums claimed"
            value={currency.format(quantumsClaimedAgainst.min.value)}
            subTitle={`against ${allCases.get(quantumsClaimedAgainst.min.caseId)?.results?.partyDescriptors[quantumsClaimedAgainst.min.partyId].name} in '${allCases.get(quantumsClaimedAgainst.min.caseId)?.name}'`}
            color={theme.palette.success.main}
            valueVariant="h5"
          />
        )}
      </Stack>

      <Divider />
      <Typography variant="h6">Value at risk</Typography>
      <ValueAtRiskChart data={hist} />
      <CumulativeValueAtRiskChart data={result} />
      <Copyright />
    </Container>
  );
};
