import { EnforcementPlan, Judgement } from "./Analytics";

const precision = Number.EPSILON;
export function worseEnforcementBalanceFirst(
  j1: Judgement,
  j2: Judgement
): number {
  const j1Balance =
    j1.enforcementVariants.length === 0 ? 0 : j1.enforcementVariants[0].balance;
  const j2Balance =
    j2.enforcementVariants.length === 0 ? 0 : j2.enforcementVariants[0].balance;
  const delta = j1Balance - j2Balance;
  return Math.abs(delta) < precision ? 0 : delta;
}

export function lessValueFirst(j1: Judgement, j2: Judgement): number {
  const delta = j1.value - j2.value;
  return Math.abs(delta) < precision ? 0 : delta;
}

export function lessBalanceFirst(
  e1: EnforcementPlan,
  e2: EnforcementPlan
): number {
  const delta = e1.balance - e2.balance;
  return Math.abs(delta) < precision ? 0 : delta;
}

export function morePaymentsFirst(
  e1: EnforcementPlan,
  e2: EnforcementPlan
): number {
  // less payments is "more" (worst plans are put in front)
  return Object.keys(e2.payments).length - Object.keys(e1.payments).length;
}
