import { Box, Typography } from "@mui/material";
import { Trans } from "react-i18next";

const Loading = () => (
  <Box
    m={2}
    pt="auto"
    pb={2}
    display="flex"
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h5" color="textSecondary">
      <Trans i18nKey="loading.headline" />
    </Typography>
  </Box>
);

export default Loading;
