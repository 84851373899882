import Big from "big.js";
import { ZERO, max } from "../big/bigUtil";
import { CostGroups, CostType, Share } from "./model/CaseConfiguration";

class MaximumPerPayer {
  private generalMax = ZERO;
  private readonly perPayerMax: { [payerId: string]: Big } = {};

  process(percentage: number, payers?: Share[]) {
    const percentageBig = new Big(Math.max(0, Math.min(1, percentage)));
    this.generalMax = max(this.generalMax, percentageBig);

    payers?.forEach((payerShare) => {
      const current = this.perPayerMax[payerShare.partyId];
      // scale payer-specific share
      const shareBig = percentageBig.mul(
        Math.max(0, Math.min(1, payerShare.percentage ?? 1))
      );
      this.perPayerMax[payerShare.partyId] =
        current === undefined ? shareBig : max(shareBig, current);
    });
  }

  getRecoveryShares(): RecoveryShares {
    return { maxRecovery: this.generalMax, payerShares: this.perPayerMax };
  }
}

class CostGroupRecoveries {
  private courtFees = new MaximumPerPayer();
  private lawyerCosts = new MaximumPerPayer();

  process(costGroup: CostGroups, percentage: number, payers?: Share[]) {
    switch (costGroup) {
      case CostGroups.CourtFees:
        this.courtFees.process(percentage, payers);
        return;
      case CostGroups.LawyerCosts:
        this.lawyerCosts.process(percentage, payers);
        return;
      case CostGroups.CourtFeesAndLawyerCosts:
        this.courtFees.process(percentage, payers);
        this.lawyerCosts.process(percentage, payers);
        return;
      default:
        console.error(`Unknown cost group: ${costGroup}`);
        return;
    }
  }

  getRecoveryShares(costType: CostType): RecoveryShares {
    switch (costType) {
      case CostType.CourtFees:
        return this.courtFees.getRecoveryShares();
      case CostType.LawyerCosts:
        return this.lawyerCosts.getRecoveryShares();
      default:
        console.error(`Unknown cost type: ${costType}`);
        return { maxRecovery: ZERO, payerShares: {} };
    }
  }
}
export interface RecoveryShares {
  payerShares: { [payerId: string]: Big };
  maxRecovery: Big;
}
/**
 * Utility class that helps process recovery configurations. It tracks the recoveries assigned to cost groups and specific costs. It also processes the payers and tracks their maximum recovery share.
 * This utility does not consider the recipient of the recoveries, nor the 'when' scenarios of the recoveries. In other words, use it to process recoveries valid in a given issue scenario and paid out to one recipient.
 */
export class RecoverySharesCalculator {
  private generalRecoveries = new CostGroupRecoveries();
  private perCostRecoveries: { [costId: string]: MaximumPerPayer } = {};

  addGeneralRecovery(
    type: CostGroups,
    percentage: number,
    payers: Share[]
  ): void {
    this.generalRecoveries.process(type, percentage, payers);
  }

  addSpecificRecovery(
    costs: string[],
    percentage: number,
    payers: Share[]
  ): void {
    costs.forEach((costId) => {
      let costRecoveries = this.perCostRecoveries[costId];
      if (costRecoveries === undefined) {
        costRecoveries = new MaximumPerPayer();
        this.perCostRecoveries[costId] = costRecoveries;
      }
      costRecoveries.process(percentage, payers);
    });
  }

  /**
   * If a cost-specific recovery has been configured, it is returned. Otherwise the general recovery configuration is used to determine what share the payer is liable for.
   *
   * @param costId ID of the cost
   * @param costType type of cost
   * @param payerId payer
   * @returns maximum recovery per payer
   */
  getRecoveryShares(costId: string, costType: CostType): RecoveryShares {
    // is there a cost-specific recovery?
    const costSpecificRecovery = this.perCostRecoveries[costId];
    if (costSpecificRecovery) {
      return costSpecificRecovery.getRecoveryShares();
    }
    return this.generalRecoveries.getRecoveryShares(costType);
  }
}
