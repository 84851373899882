import { Container, Paper, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export const ErrorPage = () => (
  <Container maxWidth="sm">
    <Paper elevation={1} sx={{ mt: 3, p: 2 }}>
      <ErrorIcon color="error" fontSize="large" />
      <Typography variant="h5" color="textSecondary">
        <Trans i18nKey="error.header" />
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        <Trans i18nKey="error.message" />
      </Typography>
      {getMessage(useRouteError())}
    </Paper>
  </Container>
);

export default ErrorPage;

function getMessage(error: unknown) {
  if (isRouteErrorResponse(error)) {
    return (
      <>
        <Typography color="textSecondary" gutterBottom>
          <Trans
            i18nKey="error.status"
            values={{ status: error.status, text: error.statusText }}
          />
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {error.data.message ? error.data.message : error.data}
        </Typography>
      </>
    );
  }
  return (
    <Typography color="textSecondary" gutterBottom>
      {error instanceof Error ? error.message : "" + error}
    </Typography>
  );
}
