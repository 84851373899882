import { useTheme } from "@mui/material";
import { ChartDataset } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCurrency } from "../../context/CurrencyContext";
import { getTail, Sample } from "./MonteCarlo";

export const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 2,
});

function createTailDataset(
  data: Sample[],
  tailCutOff: number
): ChartDataset<
  "bar",
  {
    x: number;
    y: number;
  }[]
> {
  const tail: Sample[] = getTail(data, tailCutOff);
  const tailTotalProbability = tail
    .map(({ probability }) => probability)
    .reduce((prev, curr) => prev + curr, 0);
  return {
    label: `Within ${percentage.format(tailTotalProbability)} of worst outcomes`,
    data: tail.map(({ value }) => ({
      x: value,
      y: tailCutOff,
    })),
    stepped: true,
    type: "line",
    fill: true,
    borderWidth: 0,
    pointRadius: 0,
  };
}

const ValueAtRiskChart = ({ data }: { data: Sample[] }) => {
  const theme = useTheme();
  const currency = useCurrency();
  const font = {
    family: theme.typography.fontFamily,
  };
  if (data.length < 2) return <p>Not enough data</p>;

  const onePercentTail = createTailDataset(data, 0.01);
  onePercentTail.backgroundColor = theme.palette.warning.light + "30";
  onePercentTail.borderColor = theme.palette.warning.light + "30";

  const fivePercentTail = createTailDataset(data, 0.05);
  fivePercentTail.backgroundColor = theme.palette.warning.light + "15";
  fivePercentTail.borderColor = theme.palette.warning.light + "15";

  return (
    <Bar
      data={{
        datasets: [
          onePercentTail,
          fivePercentTail,
          {
            data: data
              .filter(({ probability }) => probability > 0)
              .filter(({ value }) => value < 0)
              .map((result) => ({
                x: result.value,
                y: result.probability,
              })),
            backgroundColor: theme.palette.error.dark + "90",
            borderColor: theme.palette.error.dark,
            barPercentage: 2.3,
          },
          {
            data: data
              .filter(({ probability }) => probability > 0)
              .filter(({ value }) => value >= 0)
              .map((result) => ({
                x: result.value,
                y: result.probability,
              })),
            backgroundColor: theme.palette.success.dark + "90",
            borderColor: theme.palette.success.dark,
            barPercentage: 2.3,
          },
        ],
      }}
      options={{
        interaction: {
          intersect: false,
          mode: "x",
        },

        plugins: {
          legend: { display: false },
          tooltip: {
            bodyFont: font,
            titleFont: font,
            footerFont: font,
            callbacks: {
              title: () => "",
              label: (item) => {
                if (item.datasetIndex < 2) return item.dataset.label;
                return `${percentage.format(item.parsed.y)} for ${currency.format(item.parsed.x)}`;
              },
            },
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          },
        },
        scales: {
          y: {
            min: 0,
            ticks: {
              callback: function (value) {
                return typeof value === "number"
                  ? percentage.format(value)
                  : value;
              },
              font,
            },
            grid: {
              // make 0 line thicker
              lineWidth: (context) => (context.tick.value == 0 ? 2 : 1),
            },
          },
          x: {
            type: "linear",
            ticks: {
              callback: function (value) {
                return typeof value === "number"
                  ? currency.format(value)
                  : value;
              },
              font,
            },
          },
        },
      }}
    />
  );
};

export default ValueAtRiskChart;
