import { Box, Typography, TypographyOwnProps } from "@mui/material";

export const TwoLiner = ({
  primary,
  primaryColor,
  secondary,
  secondaryColor,
  onClick,
}: {
  primary: string | number | null;
  primaryColor?: TypographyOwnProps["color"];
  secondary: string | number | null;
  secondaryColor?: TypographyOwnProps["color"];
  onClick?: () => void;
}) => {
  return (
    <Box onClick={onClick}>
      <Typography color={primaryColor}>{primary}</Typography>
      <Typography variant="body2" color={secondaryColor ?? "textSecondary"}>
        {secondary}
      </Typography>
    </Box>
  );
};
