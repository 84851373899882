import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import CumulativeValueAtRiskChart from "./CumulativeValueAtRiskChart";
import { histogram, monteCarlo, Sample } from "./MonteCarlo";
import ValueAtRiskChart from "./ValueAtRiskChart";

export const ValueAtRiskAnalytics = () => {
  const currency = useCurrency();
  const caseContext = useCase();
  const outcomes = caseContext.getOutcomes();
  const theme = useTheme();

  const charts: {
    partyName: string;
    data: Sample[];
  }[] = [];
  caseContext.getCase().parties?.forEach((party) => {
    const judgments = outcomes
      .filter((o) => o.partyId === party.id)
      .flatMap((o) => o.judgments);
    if (judgments.length < 2) return;
    // run monte carlo
    const results = monteCarlo([judgments], 100000);
    const data = histogram(results, 200).filter(
      ({ probability }) => probability > 0
    );
    charts.push({
      partyName: party.name,
      data,
    });
  });

  return (
    <Stack
      direction="column"
      gap={theme.spacing(3)}
      sx={{ userSelect: "none" }}
    >
      {charts.map(({ partyName, data }, index) => (
        <Fragment key={index}>
          <Divider />
          <Typography variant="h6">{`Value at risk of ${partyName}`}</Typography>
          <ValueAtRiskChart data={data} />
          <CumulativeValueAtRiskChart data={data} />
        </Fragment>
      ))}
    </Stack>
  );
};
