import { Box, Button, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Currency, gbp } from "../i18n/Currency";
import { WeightedValue } from "../model/WeightedValue";
import { WeightedAwardEditor } from "./WeightedAwardEditor";

export const WeightedAwardsEditor = ({
  maxAward,
  values,
  currency = gbp,
  onChange,
}: {
  maxAward: number;
  values: WeightedValue[];
  currency: Currency;
  onChange: (newValues: WeightedValue[]) => void;
}) => {
  let totalWeight = 0;
  let sum = 0;
  values.forEach((value) => {
    totalWeight += value.weight;
    sum += value.value * value.weight;
  });

  const theme = useTheme();

  return (
    <Box
      pt={2}
      pb={0}
      sx={{
        userSelect: "none",
      }}
    >
      {totalWeight > 0 && (
        <>
          <Typography
            key="result-label"
            variant="caption"
            color="textSecondary"
          >
            <Trans i18nKey={"weightedAwards.label.result"} />
          </Typography>
          <Typography key="result">
            {currency.format((sum / totalWeight) * maxAward)}
          </Typography>
        </>
      )}
      {values.map((value, index) => {
        const share = totalWeight > 0 ? value.weight / totalWeight : undefined;
        return (
          <Box
            key={"weighted-value-" + index}
            sx={{
              display: "flex",
              alignItems: "end",
              userSelect: "none",
            }}
          >
            <Box mr={2} minWidth={100}>
              <Typography variant="body2" color="textSecondary">
                {`${t("weightedAwards.label.award", { index: index + 1 })}`}
              </Typography>
              <Typography key="result" variant="body2" color="textSecondary">
                {currency.format(value.value * maxAward)}
              </Typography>
            </Box>
            <WeightedAwardEditor
              {...value}
              weight={values.length > 1 ? value.weight : undefined}
              share={share}
              onValueChange={(newValue) =>
                onChange(
                  values.map((v) =>
                    v === value ? { ...value, value: newValue } : v
                  )
                )
              }
              onWeightChange={(newWeight) =>
                onChange(
                  values.map((v) =>
                    v === value ? { ...value, weight: newWeight } : v
                  )
                )
              }
              onDelete={
                values.length > 1
                  ? () => onChange(values.filter((v) => v !== value))
                  : undefined
              }
            />
          </Box>
        );
      })}

      <Button
        onClick={() => onChange(values.concat({ value: 1, weight: 1 }))}
        title={`${t("weightedAwards.tip.button.add")}`}
        sx={{ mt: theme.spacing(2) }}
        variant="outlined"
      >
        <Trans i18nKey="weightedAwards.label.button.add" />
      </Button>
    </Box>
  );
};
