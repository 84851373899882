import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { Copyright } from "../Copyright";

const LockAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  margin: ${(props) => props.theme.spacing(1)};
`;

export default function SignIn({
  login,
}: {
  login: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    setError("");
    setLoading(true);
    try {
      await login(username, password, remember);
      console.debug("Login successful, redirecting to app");
      setLoading(false);
    } catch (err) {
      console.log("Failed to login", err);
      setError("Failed to sign in");
      setLoading(false);
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 10,
      }}
    >
      <LockAvatar>
        <LockOutlinedIcon />
      </LockAvatar>
      <Typography component="h1" variant="h5">
        <Trans i18nKey="login.title" />
      </Typography>
      <form noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          disabled={loading}
          required
          fullWidth
          id="email"
          label={<Trans i18nKey="login.label.email" />}
          name="email"
          autoComplete="email"
          autoFocus
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          disabled={loading}
          required
          fullWidth
          name="password"
          label={<Trans i18nKey="login.label.password" />}
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              value="remember"
              color="primary"
              checked={remember}
              onChange={(e) => setRemember(e.target.checked)}
              disabled={loading}
            />
          }
          label={<Trans i18nKey="login.label.remember" />}
        />
        {error !== "" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            <AlertTitle>
              <Trans i18nKey="login.error.loginFailed" />
            </AlertTitle>
            {error}
          </Alert>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={
            loading || username.trim().length < 1 || password.trim().length < 1
          }
          onClick={(e) => handleSubmit(e)}
        >
          <Trans i18nKey="login.submit" />
        </Button>
        {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
      </form>
      <Container
        maxWidth="sm"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      />
      <Copyright />
    </Container>
  );
}
