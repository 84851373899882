import { Box, useTheme } from "@mui/material";

import { ChartData, Point } from "chart.js/auto";
import { t } from "i18next";
import { Line } from "react-chartjs-2";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { useCurrency } from "../../../context/CurrencyContext";
import {
  SettlementRangeOfStage,
  SettlementRanges,
} from "../../../model/CaseModel";
import { intersection } from "./LineIntersection";

function getMinMax(range: SettlementRangeOfStage): {
  min: number;
  max: number;
} {
  if (range.range.partyEv > range.range.opponentEv) {
    return { min: range.range.opponentEv, max: range.range.partyEv };
  }
  return { min: range.range.partyEv, max: range.range.opponentEv };
}

function getRange(
  range1: SettlementRangeOfStage,
  range2: SettlementRangeOfStage
): { min: number; max: number } {
  const r1 = getMinMax(range1);
  const r2 = getMinMax(range2);
  return { min: Math.max(r1.min, r2.min), max: Math.min(r1.max, r2.max) };
}

export const BargainingLineChart = ({
  rangeOne,
  rangeOneLabel,
  rangeTwo,
  rangeTwoLabel,
  onClick,
}: {
  rangeOne: SettlementRanges;
  rangeOneLabel: string;
  rangeTwo: SettlementRanges;
  rangeTwoLabel: string;
  onClick?: (x: Stage, y: number) => void;
}) => {
  const theme = useTheme();

  const currency = useCurrency();

  const zopa_max: Point[] = [];
  const zopa_mid: Point[] = [];
  const zopa_min: Point[] = [];
  let previousWasZopa = false;
  for (
    let i = 0;
    i < Math.min(rangeOne.perStage.length, rangeTwo.perStage.length);
    i++
  ) {
    const element = getRange(rangeOne.perStage[i], rangeTwo.perStage[i]);
    if (element.min < element.max) {
      zopa_min.push({ x: i, y: element.min });
      zopa_mid.push({ x: i, y: element.min + (element.max - element.min) / 2 });
      zopa_max.push({ x: i, y: element.max });
      previousWasZopa = true;
    } else {
      if (previousWasZopa) {
        const previousMin = zopa_min[i - 1];
        const previousMax = zopa_max[i - 1];
        const zopaEnd =
          previousMin !== undefined && previousMax !== undefined
            ? intersection(
                { x1: previousMin.y, x2: element.min },
                { x1: previousMax.y, x2: element.max }
              )
            : undefined;
        if (zopaEnd !== undefined) {
          const zopaEndPoint = { y: zopaEnd.x, x: zopaEnd.y + i - 1 };
          zopa_min.push(zopaEndPoint);
          zopa_mid.push(zopaEndPoint);
          zopa_max.push(zopaEndPoint);
        }
      }
      previousWasZopa = false;
    }
  }

  const labels = rangeOne.perStage.map(({ stage }) =>
    t("case.stages." + Stage[stage], Stage[stage])
  );
  const chartData: ChartData<"line", (number | Point)[]> = {
    datasets: [
      {
        label: "Agreement zone maximum",
        data: zopa_max,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 1,
      },
      {
        label: "Agreement zone minimum",
        data: zopa_min,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light + "80",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 1,
        fill: "-1",
      },
      {
        label: rangeOneLabel,
        data: rangeOne.perStage.map(({ range }, index) => ({
          x: index,
          y: range.partyEv,
        })),
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main + "80",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
      },
      {
        label: rangeOneLabel,
        data: rangeOne.perStage.map(({ range }, index) => ({
          x: index,
          y: range.opponentEv,
        })),
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main + "80",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
        fill: "-1",
      },
      {
        label: rangeOneLabel + " mid",
        data: rangeOne.perStage.map(({ range }, index) => ({
          x: index,
          y: range.mid,
        })),
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main + "80",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        borderDash: [8, 8],
        borderWidth: 1,
        order: 2,
      },
      {
        label: rangeTwoLabel,
        data: rangeTwo.perStage.map(({ range }, index) => ({
          x: index,
          y: range.partyEv,
        })),
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main + "70",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
      },
      {
        label: rangeTwoLabel,
        data: rangeTwo.perStage.map(({ range }, index) => ({
          x: index,
          y: range.opponentEv,
        })),
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main + "70",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 2,
        fill: "-1",
      },
      {
        label: rangeTwoLabel + " mid",
        data: rangeTwo.perStage.map(({ range }, index) => ({
          x: index,
          y: range.mid,
        })),
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main + "70",
        pointHoverRadius: 6,
        pointHitRadius: 8,
        borderDash: [8, 8],
        borderWidth: 1,
        order: 2,
      },
      {
        label: "Agreement zone midpoint",
        data: zopa_mid,
        borderColor: theme.palette.success.dark,
        backgroundColor: theme.palette.success.main + "70",
        borderWidth: 2,
        borderDash: [8, 8],
        pointHoverRadius: 6,
        pointHitRadius: 8,
        order: 0,
      },
    ],
  };

  return (
    <Box sx={{ userSelect: "none" }} mt={theme.spacing(2)}>
      <Line
        data={chartData}
        options={{
          onClick: (e, _, chart) => {
            if (!onClick) return;
            if (e.x === null || e.y === null) return;

            // get coordinate values in the scales of chart
            const x = chart.scales.x.getValueForPixel(e.x);
            if (x === undefined) return;
            const y = chart.scales.y.getValueForPixel(e.y);
            if (y === undefined) return;

            // add 10% tolerance for clicking near the stage line
            const stageIndex = Math.floor(x + 0.1);
            const stage = rangeOne.perStage[stageIndex].stage;
            onClick(stage, y);
          },
          interaction: {
            mode: "point",
          },
          plugins: {
            legend: {
              onClick: () => {
                // do nothing
              },
              position: "bottom",
              labels: {
                filter: (item) => {
                  return item.datasetIndex === 1;
                },
                font: {
                  family: theme.typography.fontFamily,
                },
              },
            },
            tooltip: {
              callbacks: {
                title: () => "",
                label: (item) =>
                  item.dataset.label + ": " + currency.format(item.parsed.y),
              },
              bodyFont: {
                family: theme.typography.fontFamily,
              },
            },
          },
          scales: {
            x: {
              type: "linear",
              ticks: {
                stepSize: 1,
                font: {
                  family: theme.typography.fontFamily,
                },
                callback: function (value) {
                  if (typeof value === "number") return labels[value];
                  return value;
                },
              },
            },
            y: {
              ticks: {
                callback: function (value) {
                  if (typeof value === "number") return currency.format(value);
                  return value;
                },
                font: {
                  family: theme.typography.fontFamily,
                },
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </Box>
  );
};
