import { Box, Stack, Typography, useTheme } from "@mui/material";
import Big from "big.js";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { max, ZERO } from "../../big/bigUtil";
import { useCase } from "../../context/CaseContext";
import { useCurrency } from "../../context/CurrencyContext";
import { Case } from "../../model/CaseModel";
import { getCostsPerStage } from "../../model/CaseModelUtility";
import { OptionalBig } from "../../model/CostModel";
import { TwoLiner } from "../general/TwoLiner";
import { RevenueBarChart, StageRevenue } from "./RevenueBarChart";

export const RevenueAnalytics = () => {
  const currency = useCurrency();
  const caseContext = useCase();
  const c: Case = caseContext.getCase();
  const parties = caseContext.getPartyDescriptors();

  const feeStructure = c.costStructure?.clientFeeStructure;
  if (!feeStructure) return;
  <Box sx={{ userSelect: "none" }}>
    <Typography>
      <Trans i18nKey="case.analytics.revenue.noCostStructure" />
    </Typography>
  </Box>;

  const directCostsPercentage = new Big(feeStructure.directCostsPercentage);
  const indirectCostsPercentage = new Big(feeStructure.indirectCostsPercentage);
  const marginPercentage = max(
    directCostsPercentage.plus(indirectCostsPercentage).neg().plus(1),
    ZERO
  );

  const stages = caseContext.getStages();
  const incurredRevenue: StageRevenue[] = stages.map((stage) => ({
    stage,
    directCosts: 0,
    indirectCosts: 0,
    margin: 0,
  }));
  const estimatedRevenue: StageRevenue[] = stages.map((stage) => ({
    stage,
    directCosts: 0,
    indirectCosts: 0,
    margin: 0,
  }));

  const incurredTotal = new OptionalBig();
  const estimatedTotal = new OptionalBig();
  for (const budget of c.budgets ?? []) {
    const party = parties[budget.partyId];
    if (!party || !party.isClient) continue;

    const lawyersFeesPerStage = getCostsPerStage(budget, true);
    for (
      let stageIndex = 0;
      stageIndex < incurredRevenue.length;
      stageIndex++
    ) {
      const incurred = incurredRevenue[stageIndex];
      const estimated = estimatedRevenue[stageIndex];
      const lawyersFees = lawyersFeesPerStage.find(
        ({ stage }) => stage === incurred.stage
      );
      if (!lawyersFees) continue;

      incurredTotal.addNumber(lawyersFees.incurred);
      estimatedTotal.addNumber(lawyersFees.estimated);

      incurred.directCosts = directCostsPercentage
        .mul(lawyersFees.incurred)
        .plus(incurred.directCosts)
        .toNumber();
      incurred.indirectCosts = indirectCostsPercentage
        .mul(lawyersFees.incurred)
        .plus(incurred.indirectCosts)
        .toNumber();
      incurred.margin = marginPercentage
        .mul(lawyersFees.incurred)
        .plus(incurred.margin)
        .toNumber();
      estimated.directCosts = directCostsPercentage
        .mul(lawyersFees.estimated)
        .plus(estimated.directCosts)
        .toNumber();
      estimated.indirectCosts = indirectCostsPercentage
        .mul(lawyersFees.estimated)
        .plus(estimated.indirectCosts)
        .toNumber();
      estimated.margin = marginPercentage
        .mul(lawyersFees.estimated)
        .plus(estimated.margin)
        .toNumber();
    }
  }

  const theme = useTheme();

  const total = new OptionalBig();
  total.add(incurredTotal);
  total.add(estimatedTotal);
  return (
    <Box sx={{ userSelect: "none" }}>
      <Typography variant="h6">
        <Trans i18nKey="case.analytics.revenue.description" />
      </Typography>
      <Stack direction="row" spacing={3}>
        <TwoLiner
          key={"total_cost"}
          primary={t("case.analytics.revenue.total")}
          secondary={currency.format(total.or(0))}
        />
        <TwoLiner
          key={"indirectCosts"}
          primary={t("case.analytics.revenue.indirectCosts")}
          secondary={currency.format(
            incurredRevenue.reduce(
              (prev, current) => prev + current.indirectCosts,
              0
            ) +
              estimatedRevenue.reduce(
                (prev, current) => prev + current.indirectCosts,
                0
              )
          )}
        />
        <TwoLiner
          key={"directCosts"}
          primary={t("case.analytics.revenue.directCosts")}
          secondary={currency.format(
            incurredRevenue.reduce(
              (prev, current) => prev + current.directCosts,
              0
            ) +
              estimatedRevenue.reduce(
                (prev, current) => prev + current.directCosts,
                0
              )
          )}
        />
        <TwoLiner
          key={"margin"}
          primary={t("case.analytics.revenue.margin")}
          secondary={currency.format(
            incurredRevenue.reduce(
              (prev, current) => prev + current.margin,
              0
            ) +
              estimatedRevenue.reduce(
                (prev, current) => prev + current.margin,
                0
              )
          )}
        />
      </Stack>
      <Box mt={2} sx={{ userSelect: "none" }}>
        <Typography variant="h6">
          <Trans i18nKey="case.analytics.revenue.incurred.title" />
        </Typography>
        <Stack direction="row" spacing={theme.spacing(3)} mb={theme.spacing(2)}>
          <TwoLiner
            key={"incurred_cost"}
            primary={t("case.analytics.revenue.incurred.label")}
            secondary={currency.format(incurredTotal.or(0))}
          />
          <TwoLiner
            key={"indirectCosts"}
            primary={t("case.analytics.revenue.incurred.indirectCosts")}
            secondary={currency.format(
              incurredRevenue.reduce(
                (prev, current) => prev + current.indirectCosts,
                0
              )
            )}
          />
          <TwoLiner
            key={"directCosts"}
            primary={t("case.analytics.revenue.incurred.directCosts")}
            secondary={currency.format(
              incurredRevenue.reduce(
                (prev, current) => prev + current.directCosts,
                0
              )
            )}
          />
          <TwoLiner
            key={"margin"}
            primary={t("case.analytics.revenue.incurred.margin")}
            secondary={currency.format(
              incurredRevenue.reduce(
                (prev, current) => prev + current.margin,
                0
              )
            )}
          />
        </Stack>
        <Typography variant="overline">
          <Trans i18nKey="case.analytics.revenue.incurred.byStage" />
        </Typography>
        <RevenueBarChart data={incurredRevenue} currency={currency} />
      </Box>
      <Box mt={2} sx={{ userSelect: "none" }}>
        <Typography variant="h6">
          <Trans i18nKey="case.analytics.revenue.estimated.title" />
        </Typography>
        <Stack direction="row" spacing={theme.spacing(3)} mb={theme.spacing(2)}>
          <TwoLiner
            key={"estimated"}
            primary={t("case.analytics.revenue.estimated.label")}
            secondary={currency.format(estimatedTotal.or(0))}
          />
          <TwoLiner
            key={"indirectCosts"}
            primary={t("case.analytics.revenue.estimated.indirectCosts")}
            secondary={currency.format(
              estimatedRevenue.reduce(
                (prev, current) => prev + current.indirectCosts,
                0
              )
            )}
          />
          <TwoLiner
            key={"directCosts"}
            primary={t("case.analytics.revenue.estimated.directCosts")}
            secondary={currency.format(
              estimatedRevenue.reduce(
                (prev, current) => prev + current.directCosts,
                0
              )
            )}
          />
          <TwoLiner
            key={"margin"}
            primary={t("case.analytics.revenue.estimated.margin")}
            secondary={currency.format(
              estimatedRevenue.reduce(
                (prev, current) => prev + current.margin,
                0
              )
            )}
          />
        </Stack>
        <Typography variant="overline">
          <Trans i18nKey="case.analytics.revenue.estimated.byStage" />
        </Typography>
        <RevenueBarChart data={estimatedRevenue} currency={currency} />
      </Box>
    </Box>
  );
};
