import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { useAuth } from "./ParseAuthContext";

export const UserAvatar = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  async function handleLogout() {
    console.log("Logging out");
    // Note that these values come from state variables that we've declared before
    try {
      await logout().then(() => {
        console.debug("Logged out, redirecting to login page");
        navigate("/", { replace: true });
      });
    } catch (error) {
      // Error can be caused by wrong parameters or lack of Internet connection
      console.warn("Failed to log out", error);
    }
  }

  if (currentUser === undefined) {
    return null;
  }

  return (
    <Button
      variant="text"
      color="inherit"
      size="large"
      onClick={() => handleLogout()}
      endIcon={<ExitToAppIcon />}
    >
      {!useMediaQuery(theme.breakpoints.down("sm")) && (
        <Trans i18nKey="appBar.logout" />
      )}
    </Button>
  );
};
