import DeleteIcon from "@mui/icons-material/DeleteForever";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { Currency, gbp } from "../i18n/Currency";
import { CurrencyTextField } from "./general/CurrencyTextField";

const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export type WeightedValueEditorProps = {
  value: number;
  currency?: Currency;
  onValueChange: (newValue: number) => void;
  weight?: number;
  onWeightChange?: (newValue: number) => void;
  share?: number;
  onDelete?: () => void;
  min?: number;
  max?: number;
};

export const WeightedValueEditor = ({
  value,
  currency = gbp,
  weight,
  share,
  min,
  max,
  onValueChange,
  onWeightChange,
  onDelete,
}: WeightedValueEditorProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        userSelect: "none",
        mt: 2,
      }}
    >
      <CurrencyTextField
        currency={currency}
        label={`${t("weightedValue.label.value")}`}
        value={value}
        onValueChange={(newValue) => {
          if (Number.isNaN(newValue)) onValueChange(0);
          if (newValue >= 0) onValueChange(newValue);
        }}
        min={min}
        max={max}
      />
      {weight !== undefined && (
        <TextField
          sx={{ width: "15ch", ml: 4 }}
          label={t("weightedValue.label.weight")}
          variant="standard"
          type="number"
          value={weight}
          onChange={(e) => {
            if (onWeightChange === undefined) return;
            const newWeight = Number.parseFloat(e.target.value);
            if (newWeight > 0) onWeightChange(newWeight);
          }}
          title={`${t("weightedValue.tip.weight")}`}
          InputProps={
            share
              ? {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      title={`${t("weightedValue.tip.share")}`}
                    >
                      {percentage.format(share)}
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      )}
      {onDelete !== undefined && (
        <IconButton
          onClick={() => onDelete()}
          aria-label={`${t("weightedValue.tip.button.delete")}`}
          title={`${t("weightedValue.tip.button.delete")}`}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};
