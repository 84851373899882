import {
  Box,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { percentage } from "../../analytics/model/CaseConfiguration";
import { YesNoDialog } from "../general/YesNoDialog";
import { IssueCallbacks, createEditMenu } from "./IssueEditorRows";
import { Issue, Party, getIssueWinChance } from "./IssueModel";
import { WinChanceCell } from "./WinChanceCell";
import { WinChanceVertical } from "./WinChanceVertical";

export interface IssueDefendant extends Party {
  // defendant's win chance in an issue may be limited (e.g. in XOR issue groups the win chance of all issues must sum to 1)
  maxWinChance?: number;
}

export const IssueRows = ({
  issue,
  ordinal,
  callbacks,
  defendants,
  isFirst = false,
  isLast = false,
  indentRows = 0,
  summaryColumn = false,
}: {
  issue: Issue;
  ordinal: string;
  callbacks: IssueCallbacks;
  defendants: IssueDefendant[];
  isFirst?: boolean;
  isLast?: boolean;
  indentRows?: number;
  summaryColumn?: boolean;
}) => {
  const [issueToDelete, setIssueToDelete] = useState<Issue | undefined>();

  const callbacksWithDialog: IssueCallbacks = {
    ...callbacks,
    onDelete: (issue) => setIssueToDelete(issue),
  };

  const againstAny = getIssueWinChance(issue, defendants, false);
  const againstAll = getIssueWinChance(issue, defendants, true);

  const liableDefendants = Math.min(
    defendants.length,
    Object.keys(issue.winChanceAgainst).length
  );
  const enableSwitchToGeneric = issue.generic || liableDefendants > 1;
  return (
    <>
      <YesNoDialog
        onYes={() => {
          if (issueToDelete !== undefined) callbacks.onDelete(issueToDelete);
          setIssueToDelete(undefined);
        }}
        onNo={() => setIssueToDelete(undefined)}
        open={issueToDelete !== undefined}
        title={t("case.claims.issues.deleteDialog.title")}
        description={t("case.claims.issues.deleteDialog.description", {
          issueOrdinal: ordinal,
        })}
      />
      <TableRow key={"issue_header_" + issue.id}>
        {indentRows > 0 && (
          <TableCell rowSpan={indentRows + 1} sx={{ borderBottom: "none" }} />
        )}
        <TableCell
          align="left"
          colSpan={indentRows ? 1 : 2}
          sx={{
            borderBottom: "none",
            maxWidth: indentRows ? "360px" : "408px",
            minWidth: "260px",
            pb: 0,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              <Trans
                i18nKey="case.claims.issues.header.issue"
                values={{ ...issue, ordinal }}
                components={{ bold: <b /> }}
              />
            </Typography>
            {createEditMenu(issue, callbacksWithDialog, isFirst, isLast)}
          </Box>
        </TableCell>
        {enableSwitchToGeneric && (
          <TableCell
            align="center"
            colSpan={defendants.length}
            sx={{
              borderBottom: "none",
              pb: 0,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip title="Defendant-specific issues must be won per-defendant. Winning against one of the defendants is independent from winning against others.">
                <Typography variant="caption">Defendant-specific</Typography>
              </Tooltip>
              <Tooltip
                title={`Issue ${ordinal} is currently configured as a ${
                  issue.generic ? "generic" : "defendant-specific"
                } issue`}
              >
                <Switch
                  size="small"
                  onChange={(e) => {
                    if (e.target.checked) {
                      callbacks.onSwitchToGeneric(issue);
                    } else {
                      callbacks.onSwitchToSpecific(issue);
                    }
                  }}
                  checked={issue.generic !== undefined && issue.generic}
                />
              </Tooltip>
              <Tooltip title="Generic issues represent general facts, not dependant upon individual defendants. They are won once against all liable defendants.">
                <Typography variant="caption">Generic</Typography>
              </Tooltip>
            </Stack>
          </TableCell>
        )}
      </TableRow>
      <TableRow key={"issue_winChances_" + issue.id}>
        <TableCell
          align="left"
          colSpan={indentRows ? 1 : 2}
          sx={{
            borderBottom: "none",
          }}
        >
          <Typography variant="caption">{issue.title}</Typography>
        </TableCell>

        {issue.generic ? (
          <TableCell
            colSpan={defendants.length}
            align="center"
            sx={{
              borderBottom: "none",
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box maxWidth={200} minWidth={200}>
                <WinChanceVertical
                  onChanceChange={(newChance) =>
                    callbacks.onGenericWinChanceUpdated(issue, newChance)
                  }
                  chance={issue.genericWinChance ?? 0}
                  limit={defendants
                    .map((d) => d.maxWinChance)
                    .filter((value) => value !== undefined)
                    .reduce(
                      (previous, current) =>
                        Math.min(previous ?? 1, current ?? 1),
                      1
                    )}
                />
              </Box>
            </Box>
          </TableCell>
        ) : (
          defendants.map((defendant) => (
            <WinChanceCell
              defendant={defendant}
              winChance={issue.winChanceAgainst[defendant.id]}
              onWinChanceUpdated={(defendantId: string, newChance: number) =>
                callbacks.onWinChanceUpdated(issue, defendantId, newChance)
              }
              // enforce slider updates when maxWinChance changes (component holds its own position state and will not react otherwise)
              key={"defendant_" + defendant.id + "_" + defendant.maxWinChance}
              limit={defendant.maxWinChance}
            />
          ))
        )}

        {summaryColumn && (
          <IssueWinChanceSummaryCell
            key={"vAll"}
            issueOrdinal={ordinal}
            value={againstAll?.toNumber()}
          />
        )}
        {summaryColumn && !issue.generic && (
          <IssueWinChanceSummaryCell
            key={"vAny"}
            issueOrdinal={ordinal}
            vAny
            value={againstAny?.toNumber()}
          />
        )}
      </TableRow>
    </>
  );
};

export const IssueWinChanceSummaryCell = ({
  issueOrdinal,
  value,
  vAny = false,
}: {
  issueOrdinal?: string;
  value?: number;
  vAny?: boolean;
}) => {
  return (
    <TableCell
      key={"vAny"}
      align="center"
      sx={{
        borderBottom: "none",
      }}
    >
      <Tooltip
        title={t(
          vAny
            ? "case.claims.issues.summary.issueWinChance.tooltip.vAny"
            : "case.claims.issues.summary.issueWinChance.tooltip.vAll",
          { issueOrdinal }
        )}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">
            {t(
              vAny
                ? "case.claims.issues.summary.issueWinChance.label.vAny"
                : "case.claims.issues.summary.issueWinChance.label.vAll",
              { issueOrdinal }
            )}
          </Typography>
          <Typography variant="body2">
            {value !== undefined ? percentage.format(value) : "--"}
          </Typography>
        </Box>
      </Tooltip>
    </TableCell>
  );
};
