import ArrowDown from "@mui/icons-material/ArrowDropDown";
import ArrowUp from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { percentage } from "../../analytics/model/CaseConfiguration";
import { IssueRelation, Quantum } from "../../model/CaseModel";
import { EditAndDeleteMenu } from "../general/EditAndDeleteMenu";
import { MenuItem } from "../general/ZoomOutButtonMenu";
import { IssueGroupHeaderRow } from "./IssueGroupHeaderRow";
import {
  Issue,
  IssueGroup,
  Party,
  getIssueGroupWinChance,
  getIssuesWinChance,
  getOverallWinChance,
  getWinChance,
  isGroup,
} from "./IssueModel";
import { IssueRelationSelector } from "./IssueRelationSelector";
import { IssueDefendant, IssueRows } from "./IssueRows";

const aCode = "A".charCodeAt(0);

export interface IssueEditorRowsProps {
  issues: (Issue | IssueGroup)[];
  defendants: Party[];
  callbacks: IssueCallbacks;
}

export const IssueEditorRows = ({
  issues,
  defendants,
  callbacks,
}: IssueEditorRowsProps) => {
  let ordinalNumber = 1;

  const againstAll = getIssuesWinChance(issues, defendants, true);
  const againstAny = getIssuesWinChance(issues, defendants);

  const summaryColumn = defendants.length > 1;
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell align="left" colSpan={2} rowSpan={summaryColumn ? 2 : 1}>
            <Typography>
              <b>Issues</b>
            </Typography>
          </TableCell>
          {defendants.map((defendant, index) => (
            <TableCell
              align="center"
              key={"defendant_" + index}
              rowSpan={summaryColumn ? 2 : 1}
              sx={{ minWidth: 160 }}
            >
              <Typography>
                <b>{defendant.name}</b>
              </Typography>
            </TableCell>
          ))}
          {summaryColumn && (
            <TableCell align="center" key={"summary_total"} colSpan={2}>
              <Typography>
                <b>Total</b>
              </Typography>
            </TableCell>
          )}
        </TableRow>
        {summaryColumn && (
          <TableRow>
            <TableCell align="center" key={"summary_all"}>
              <Typography>
                <b>Against all</b>
              </Typography>
            </TableCell>
            <TableCell align="center" key={"summary_any"}>
              <Typography>
                <b>Against any</b>
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {issues.map((issue, index) => {
          const isFirst = index === 0;
          const isLast = index === issues.length - 1;
          const ordinal = `${ordinalNumber++}.`;

          if (isGroup(issue))
            return createIssueGroupRows(
              issue,
              ordinal,
              callbacks,
              defendants,
              isFirst,
              isLast
            );

          return (
            <Fragment key={index}>
              <IssueRows
                key={index}
                issue={issue}
                ordinal={ordinal}
                callbacks={callbacks}
                defendants={defendants}
                isFirst={isFirst}
                isLast={isLast}
                summaryColumn={summaryColumn}
              />

              <TableRow key={"issue_relation_" + issue.id}>
                <TableCell
                  colSpan={(summaryColumn ? 4 : 2) + defendants.length}
                  // keep bottom border in last row to separate from summary
                  sx={isLast ? undefined : { borderBottom: "none" }}
                >
                  {!isLast && (
                    <IssueRelationSelector
                      onRelationClicked={(relation) =>
                        callbacks.onRelationSelected(issue, relation)
                      }
                      selected={issue.relation}
                    />
                  )}
                </TableCell>
              </TableRow>
            </Fragment>
          );
        })}
        <TableRow key="summary">
          <TableCell colSpan={2}>
            <Typography>
              <b>Issue Summary</b>
            </Typography>
            <Typography>Claimant's win chance</Typography>
          </TableCell>
          {defendants.map((defendant) => {
            const winChanceVDefendant = getOverallWinChance(
              issues,
              defendant.id
            );
            return (
              <TableCell key={"defendant_" + defendant.id} align="center">
                <Box display="flex" flexDirection="column">
                  <Typography>
                    <b>{`v ${defendant.name}`}</b>
                  </Typography>
                  <Typography>
                    {winChanceVDefendant === undefined
                      ? "--"
                      : percentage.format(winChanceVDefendant.toNumber())}
                  </Typography>
                </Box>
              </TableCell>
            );
          })}
          {summaryColumn && (
            <>
              <TableCell key={"claim_total_all"} align="center">
                <Box display="flex" flexDirection="column">
                  <Typography>
                    <b>Against all</b>
                  </Typography>
                  <Typography>
                    <b>{againstAll ? percentage.format(againstAll) : "--"}</b>
                  </Typography>
                </Box>
              </TableCell>
              <TableCell key={"claim_total_any"} align="center">
                <Box display="flex" flexDirection="column">
                  <Typography>
                    <b>Against any</b>
                  </Typography>
                  <Typography>
                    <b>{againstAny ? percentage.format(againstAny) : "--"}</b>
                  </Typography>
                </Box>
              </TableCell>
            </>
          )}
        </TableRow>
      </TableBody>
    </>
  );
};

function getXorWinChanceLimits(
  issueGroup: IssueGroup,
  defendant: Party
): { [issueId: string]: number } {
  let totalWinChance = 0;
  const winChances: { [issueId: string]: number } = {};
  issueGroup.subIssues.forEach((subIssue) => {
    const winChance = getWinChance(subIssue, defendant.id)?.toNumber();
    if (winChance !== undefined) {
      totalWinChance += winChance;
      winChances[subIssue.id] = winChance;
    }
  });

  const remaining = 1 - totalWinChance;
  for (const issueId in winChances) {
    winChances[issueId] = winChances[issueId] + remaining;
  }
  return winChances;
}

function createIssueGroupRows(
  issueGroup: IssueGroup,
  groupOrdinal: string,
  callbacks: IssueCallbacks,
  defendants: Party[],
  isFirst = false,
  isLast = false
) {
  let subIssueOrdinal = aCode;
  const groupedOrdinals: string[] = [];

  issueGroup.subIssues.forEach(() => {
    const subIssueOrdinalString = `${String.fromCharCode(subIssueOrdinal)}.`;
    groupedOrdinals.push(`${groupOrdinal}${subIssueOrdinalString}`);

    subIssueOrdinal++;
  });

  // restart sub-issue ordinal for the loop. -1 to make the ++ work in return
  subIssueOrdinal = aCode;

  const againstAll = getIssueGroupWinChance(issueGroup, defendants, true);
  const againstAny = getIssueGroupWinChance(issueGroup, defendants, false);
  const summaryColumn = defendants.length > 1;

  return (
    <Fragment key={"group_" + issueGroup.id}>
      <IssueGroupHeaderRow
        ordinal={groupOrdinal}
        relation={issueGroup.relation}
        key={"group_header_row" + issueGroup.id}
        groupedOrdinals={groupedOrdinals}
      />
      {issueGroup.subIssues.map((subIssue, index) => {
        const isFirstInGroup = index === 0;
        const isLastInGroup = index === issueGroup.subIssues.length - 1;

        // limit max win chances of defendants in XOR groups
        const issueDefendants =
          issueGroup.relation === IssueRelation.Xor
            ? defendants.map((d) => {
                const issueDefendant: IssueDefendant = { ...d };
                issueDefendant.maxWinChance = getXorWinChanceLimits(
                  issueGroup,
                  d
                )[subIssue.id];
                return issueDefendant;
              })
            : defendants;
        return (
          <Fragment key={index}>
            <IssueRows
              key={"issue_row_" + index}
              issue={subIssue}
              ordinal={`${groupOrdinal}${String.fromCharCode(
                subIssueOrdinal++
              )}.`}
              callbacks={callbacks}
              defendants={issueDefendants}
              isFirst={isFirst && isFirstInGroup}
              isLast={isLast && isLastInGroup}
              indentRows={isLastInGroup ? 1 : 2}
              summaryColumn={summaryColumn}
            />
            {isLastInGroup && (
              <>
                <TableRow>
                  <TableCell sx={{ borderBottom: "none" }} rowSpan={2} />
                  <TableCell
                    colSpan={(summaryColumn ? 2 : 1) + defendants.length}
                  />
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="caption">
                        <b>Group summary</b>
                      </Typography>
                      <Typography variant="caption">Win chance</Typography>
                    </Box>
                  </TableCell>
                  {defendants.map((defendant) => {
                    const winChance = getOverallWinChance(
                      issueGroup.subIssues,
                      defendant.id
                    );
                    return (
                      <TableCell
                        key={"defendant_" + defendant.id}
                        sx={{ borderBottom: "none" }}
                        align="center"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography variant="caption">
                            <b>{`v ${defendant.name}`}</b>
                          </Typography>
                          <Typography variant="caption">
                            {winChance === undefined
                              ? "--"
                              : percentage.format(winChance.toNumber())}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                  {summaryColumn && (
                    <>
                      <TableCell
                        key="issue_vAll"
                        sx={{ borderBottom: "none" }}
                        align="center"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography variant="caption">
                            <b>{`Issue ${groupOrdinal} v all`}</b>
                          </Typography>
                          <Typography variant="caption">
                            {againstAll
                              ? percentage.format(againstAll.toNumber())
                              : "--"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        key="issue_vAny"
                        sx={{ borderBottom: "none" }}
                        align="center"
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography variant="caption">
                            <b>{`Issue ${groupOrdinal} v any`}</b>
                          </Typography>
                          <Typography variant="caption">
                            {againstAny
                              ? percentage.format(againstAny.toNumber())
                              : "--"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </>
            )}
            <TableRow key={"issue_relation_" + subIssue.id}>
              <TableCell
                colSpan={(summaryColumn ? 3 : 2) + defendants.length}
                // keep bottom border in last row to separate from summary
                sx={
                  isLast && isLastInGroup ? undefined : { borderBottom: "none" }
                }
              >
                {!(isLast && isLastInGroup) && (
                  <IssueRelationSelector
                    onRelationClicked={(relation) =>
                      callbacks.onRelationSelected(subIssue, relation)
                    }
                    selected={subIssue.relation}
                  />
                )}
              </TableCell>
            </TableRow>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

export function createEditMenu(
  issue: Issue,
  callbacks: IssueCallbacks,
  isFirst: boolean,
  isLast: boolean
) {
  const additionalItems: MenuItem[] = [];
  if (!isFirst)
    additionalItems.push({
      title: "Move up",
      icon: <ArrowUp fontSize="inherit" />,
      onClick: () => callbacks.onMoveUp(issue),
    });
  if (!isLast)
    additionalItems.push({
      title: "Move down",
      icon: <ArrowDown fontSize="inherit" />,
      onClick: () => callbacks.onMoveDown(issue),
    });
  return (
    <EditAndDeleteMenu
      openToTheLeft
      onDelete={() => callbacks.onDelete(issue)}
      onEdit={() => callbacks.onIssueEditorRequested(issue)}
      additionalItems={additionalItems}
    />
  );
}

export interface QuantumCallbacks {
  /**
   * @param issue with the quantum
   * @param quantum quantum to edit or undefined if a new quantum shall be created
   */
  onQuantumEditorRequested: (issue: Issue, quantum?: Quantum) => void;
  onLiabilityUpdate: (
    issue: Issue,
    quantum: Quantum,
    defendantId: string,
    newLiability: number
  ) => void;
  onQuantumDelete: (issue: Issue, quantum: Quantum) => void;
}

export interface IssueCallbacks {
  onIssueEditorRequested: (issue: Issue) => void;
  onDelete: (issue: Issue) => void;
  onMoveUp: (issue: Issue) => void;
  onMoveDown: (issue: Issue) => void;
  onRelationSelected: (issue: Issue, newRelation: IssueRelation) => void;
  onWinChanceUpdated: (
    issue: Issue,
    defendantId: string,
    newChance: number
  ) => void;
  onGenericWinChanceUpdated: (issue: Issue, newChance: number) => void;
  onSwitchToGeneric: (issue: Issue) => void;
  onSwitchToSpecific: (issue: Issue) => void;
}
