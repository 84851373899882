import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";

import { SettlementImpact } from "../../../model/CaseModel";

import OfferSummary from "./OfferSummary";
import { Offer } from "./SettlementOfferDialog";

export interface SettlementOfferDialogProps {
  title: string;
  offer: Offer;
  impact: SettlementImpact;
  // if positive, offeror pays offeree
  open: boolean;
  onClose: () => void;
}

const SettlementImpactInfoDialog = ({
  title,
  offer,
  impact,
  open,
  onClose,
}: SettlementOfferDialogProps) => {
  const close = () => onClose();

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        userSelect: "none",
      }}
      maxWidth="lg"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            width: theme.spacing(5),
            height: theme.spacing(5),
            mx: theme.spacing(2),
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
      >
        <OfferSummary offer={offer} {...impact} />
      </DialogContent>
    </Dialog>
  );
};

export default SettlementImpactInfoDialog;
