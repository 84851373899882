export interface Sample {
  probability: number;
  value: number;
}

export function getTail(data: Sample[], probabilityCutOff: number): Sample[] {
  const tail: Sample[] = [];
  let cumulativeProbability = 0;
  for (const sample of data) {
    cumulativeProbability += sample.probability;
    tail.push(sample);
    if (cumulativeProbability > probabilityCutOff) return tail;
  }
  return tail;
}

class SampleSet {
  private totalProbability: number;

  constructor(private samples: Sample[]) {
    this.totalProbability = samples.reduce(
      (sum, item) => sum + item.probability,
      0
    );
  }

  random(): Sample {
    const random = Math.random() * this.totalProbability;
    let p = 0;
    for (const sample of this.samples) {
      p += sample.probability;
      if (random <= p) {
        return sample;
      }
    }
    throw new Error("No outcomes");
  }
}

export function monteCarlo(
  portfolio: Sample[][],
  numberOfSimulations = 10000
): Sample[] {
  if (portfolio.length === 0) return [];
  const sets: SampleSet[] = portfolio.map((samples) => new SampleSet(samples));

  const results: Sample[] = [];
  for (let i = 0; i < numberOfSimulations; i++) {
    const result = sets
      .map((set) => set.random())
      .reduce(
        (previous, current) => ({
          value: current.value + previous.value,
          probability: current.probability * previous.probability,
        }),
        { value: 0, probability: 1 }
      );
    results.push(result);
  }
  return results;
}

export function histogram(samples: Sample[], numberOfBins = 500): Sample[] {
  if (numberOfBins < 1) return [];
  if (samples.length === 0) return [];
  if (samples.length === 1) return samples;

  let min = samples[0].value;
  let max = min;
  for (const { value } of samples) {
    min = Math.min(min, value);
    max = Math.max(max, value);
  }
  const binSize = (max - min) / numberOfBins;

  // initialize bins
  const bins: Sample[] = [];
  let value = min;
  for (let binIndex = 0; binIndex < numberOfBins; binIndex++) {
    bins.push({ value, probability: 0 });
    value += binSize;
  }

  for (const { value, probability } of samples) {
    const binIndex =
      value >= max ? numberOfBins - 1 : Math.floor((value - min) / binSize);
    bins[binIndex].probability++;
  }

  for (const bin of bins) bin.probability /= samples.length;

  return bins;
}

// const deppHeard: Sample[] = [
//   { probability: 0.0219, value: -60.1 },
//   { probability: 0.0219, value: -40.1 },
//   { probability: 0.0281, value: -37.6 },
//   { probability: 0.0656, value: -22.6 },
//   { probability: 0.0656, value: -17.6 },
//   { probability: 0.0375, value: -2.6 },
//   { probability: 0.1969, value: -0.1 },
//   { probability: 0.5625, value: 38.8 },
// ];

// const results = monteCarlo(
//   [deppHeard, deppHeard, deppHeard, deppHeard, deppHeard, deppHeard, deppHeard],
//   100000
// );
// // const results = monteCarlo(
// //   [
// //     [
// //       { probability: 0.4, value: 4 },
// //       { probability: 0.3, value: 10 },
// //       { probability: 0.3, value: 20 },
// //     ],
// //   ],
// //   10000
// // );

// const hist = histogram(results, 100);
// console.log(hist);

// console.log(
//   hist.map((s) => s.probability).reduce((prev, curr) => prev + curr, 0)
// );
