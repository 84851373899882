import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Trans } from "react-i18next";

export const YesNoDialog = ({
  open,
  onYes,
  onNo,
  title,
  description,
}: {
  open: boolean;
  onYes: () => void;
  onNo: () => void;
  title: string;
  description: string;
}) => {
  return (
    // delete asset confirmation
    <Dialog
      open={open}
      onClose={onNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} variant="outlined" autoFocus>
          <Trans i18nKey="dialog.yesNo.no" />
        </Button>
        <Button onClick={onYes} variant="outlined">
          <Trans i18nKey="dialog.yesNo.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
