import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useCurrency } from "../../context/CurrencyContext";
import { percentage } from "./Claims";
import { QuantumLiabilityEditorDialog } from "./QuantuLiabilityEditorDialog";

export interface QuantumProps {
  issueTitle: string;
  issueOrdinal: string;
  quantumTitle: string;
  quantumAmount: number;
}

export const QuantumCell = ({
  quantum,
  defendant,
  liability,
  onLiabilityUpdate,
}: {
  quantum: QuantumProps;
  defendant: string;
  liability: number;
  onLiabilityUpdate: (newChance: number) => void;
}) => {
  const currency = useCurrency();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  return (
    <Box minWidth={80}>
      <QuantumLiabilityEditorDialog
        quantum={quantum}
        defendant={defendant}
        liability={liability}
        onClose={(newLiability) => {
          setEditDialogOpen(false);
          if (newLiability !== undefined) onLiabilityUpdate(newLiability);
        }}
        open={editDialogOpen}
      />

      <Chip
        title={t("case.claims.quantums.liability.tooltip", {
          defendant,
          quantumTitle: quantum.quantumTitle,
        })}
        label={
          liability === 0
            ? t("case.claims.quantums.liability.none")
            : t("case.claims.quantums.liability.value", {
                percentage: percentage.format(liability),
                amount: currency.format(quantum.quantumAmount * liability),
              })
        }
        deleteIcon={<EditIcon fontSize="small" />}
        onClick={() => setEditDialogOpen(true)}
        onDelete={() => setEditDialogOpen(true)}
        variant="outlined"
        clickable
      />
    </Box>
  );
};
