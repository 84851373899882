import Big from "big.js";
import { max, ZERO } from "../../big/bigUtil";
import { Payer } from "./Payment";

export interface ManagedPayer {
  readonly id: string;

  allocate(itemId: string, amount: Big | undefined): void;

  /**
   * @param maximumNeeded maximum amount needed, returned number should not be greater
   * @returns remaining amount payable by this payer
   */
  getAvailableAmount(maximumNeeded: Big): Big;

  /**
   * @param amount to reduce the assets of this payer by. Must be greater than zero. If payer has less than the amount, the amount is reduced to zero.
   * @returns if the available amount of the payer was reduced by any amount
   */
  reduceAvailableAmountBy(amount: Big): boolean;

  /**
   * @returns true if this payer has means to pay anything, false otherwise
   */
  hasFunds(): boolean;

  /**
   * @returns true if this payer has means to pay anything, false otherwise
   */
  hasEnoughFunds(): boolean;
}

export function createManagedPayer(payer: Payer): ManagedPayer {
  if (payer.availableAmount !== undefined)
    return new PayerWithLimitedFunds(payer.payerId, payer.availableAmount);
  return new PayerWithUnlimitedFunds(payer.payerId);
}

/**
 * Represents a payer with a limited amount of funds to pay for items.
 */
class PayerWithLimitedFunds {
  private itemsToPay = new Map<string, Big>();
  private toPayTotal: Big | undefined = ZERO;

  constructor(
    readonly id: string,
    private availableAmount: Big
  ) {}

  allocate(itemId: string, amount: Big | undefined): void {
    if (amount === undefined || amount.lte(0)) {
      if (this.itemsToPay.delete(itemId)) this.toPayTotal = undefined;
      return;
    }
    this.itemsToPay.set(itemId, amount);
    // reset total amount, recalculated in getter on-demand
    this.toPayTotal = undefined;
  }

  /**
   * @returns remaining amount payable by this payer
   */
  getAvailableAmount(): Big {
    return this.availableAmount;
  }

  /**
   * @param amount to reduce the assets of this payer by. Must be greater than zero. If payer has less than the amount, the amount is reduced to zero.
   * @returns if the available amount of the payer was reduced by any amount
   */
  reduceAvailableAmountBy(amount: Big): boolean {
    if (!this.hasFunds()) {
      console.debug(
        `Cannot reduce by amount ${amount.toNumber()}, payer '${
          this.id
        }' has no funds`
      );
      return false;
    }
    if (amount.lte(0)) {
      console.debug(`Cannot reduce by negative amount ${amount.toNumber()}`);
      return false;
    }
    this.availableAmount = max(this.availableAmount.minus(amount), ZERO);
    console.debug(
      `Reduced available amount of payer '${
        this.id
      }' to ${this.availableAmount.toNumber()}`
    );
    return true;
  }

  /**
   * @returns true if this payer has means to pay anything, false otherwise
   */
  hasFunds(): boolean {
    return this.availableAmount.gt(0);
  }

  /**
   * @returns true if this payer has means to pay anything, false otherwise
   */
  hasEnoughFunds(): boolean {
    if (this.toPayTotal === undefined) {
      // recalculate total amount to pay for all items
      this.toPayTotal = ZERO;
      for (const [, amount] of this.itemsToPay) {
        this.toPayTotal = this.toPayTotal?.add(amount);
      }
    }
    return this.availableAmount.gte(this.toPayTotal);
  }
}

class PayerWithUnlimitedFunds implements ManagedPayer {
  constructor(readonly id: string) {}
  allocate(): void {
    // nothing to do
  }
  getAvailableAmount(maximumNeeded: Big): Big {
    // we have no limits
    return maximumNeeded;
  }
  reduceAvailableAmountBy(): boolean {
    return true;
  }
  hasFunds(): boolean {
    return true;
  }
  hasEnoughFunds(): boolean {
    return true;
  }
}
