import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { User } from "./User";
import parseAuth from "./parseAuth";

export function useAuth() {
  return useContext(AuthContext);
}

export const ParseAuthProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [currentUser, setCurrentUser] = useState<User>();

  function login(username: string, password: string, remember = false) {
    console.debug("Logging in", username);
    return parseAuth.login(username, password, remember);
  }

  function logout() {
    console.debug("Logging out");
    return parseAuth.logout().finally(() => {
      setCurrentUser(undefined);
      console.debug("Logged out");
    });
  }

  useEffect(() => {
    const unsubscribe = parseAuth.setCallback((user) => {
      console.log("Authenticated ", user);
      setCurrentUser(user);
    });
    // load session from storage
    parseAuth.checkSession();
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
