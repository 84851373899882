import HelpIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { IssueRelation } from "../../model/CaseModel";

export const IssueGroupHeaderRow = ({
  ordinal,
  relation,
  groupedOrdinals,
}: {
  ordinal: string;
  relation: IssueRelation;
  groupedOrdinals: string[];
}) => {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <TableRow>
      <Dialog open={infoOpen} onClose={() => setInfoOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          <Trans
            i18nKey={`case.claims.issues.groups.infoDialog.title.${IssueRelation[relation]}`}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans
              i18nKey={`case.claims.issues.groups.infoDialog.description.${IssueRelation[relation]}`}
              components={{ bold: <b /> }}
              values={{ groupedOrdinals: groupedOrdinals.join(", "), ordinal }}
              count={2}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoOpen(false)} autoFocus>
            <Trans i18nKey="case.claims.issues.groups.infoDialog.submit" />
          </Button>
        </DialogActions>
      </Dialog>
      <TableCell colSpan={2} sx={{ borderBottom: "none", pb: 0 }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography>
              <Trans
                i18nKey="case.claims.issues.header.group"
                values={{ ordinal }}
                components={{ bold: <b /> }}
              />
            </Typography>
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={() => setInfoOpen(true)}
            >
              <HelpIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};
