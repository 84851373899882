import { Typography } from "@mui/material";
import { t } from "i18next";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../../context/CaseContext";
import { CostRecovery, Party, RecoveryType } from "../../model/CaseModel";
import { CostTotals, getCostsTotal, zero } from "../../model/CaseModelUtility";
import { byName, clientsFirst, sort } from "../analytics/AnalyticsUtility";
import { Tabs } from "../general/Tabs";
import {
  CostRecoveryCallbacks,
  CostRecoveryDetails,
} from "./CostRecoveryDetails";

export interface CostRecoveriesCallbacks {
  add(partyId: string, newValue: CostRecovery): Promise<void>;
  update(partyId: string, newValue: CostRecovery): Promise<void>;
  remove(partyId: string, newValue: CostRecovery): Promise<void>;
}

export type RecoveriesOfParty = {
  readonly partyId: string;
  readonly partyName: string;
  client?: boolean;
  recoveries?: CostRecovery[];
};

export const CostRecoveries = () => {
  const caseContext = useCase();
  const c = caseContext.getCase();

  const sortedParties = useMemo(
    () =>
      sort(caseContext.getPartyDescriptors(), clientsFirst, byName).map(
        (partyId) => c.parties?.find((p) => p.id === partyId)
      ),
    [c.parties]
  );

  // sort recoveries consistently with other tabs
  const recoveries: RecoveriesOfParty[] = [];
  sortedParties.forEach(
    (p) =>
      p !== undefined &&
      recoveries.push({
        partyId: p.id,
        partyName: p.name,
        client: p.client,
        recoveries: p.recoveries,
      })
  );

  const totals: Map<string, CostTotals> = useMemo(() => {
    const totalsPerParty = new Map<string, CostTotals>();
    c.budgets?.forEach((budget) => {
      const total = zero();
      budget.costs.forEach((costs) => total.add(getCostsTotal(costs)));
      totalsPerParty.set(budget.partyId, total);
    });
    return totalsPerParty;
  }, [c]);

  const submit = useSubmit();
  const updateParty = (party: Party) =>
    Promise.resolve(
      submit({ party: JSON.stringify(party) }, { method: "put" })
    );

  const recoveriesCallbacks: CostRecoveriesCallbacks = {
    add: (partyId, recovery) => {
      const party = c.parties?.find((p) => p.id === partyId);
      if (party === undefined) {
        console.warn(
          "Cannot add cost recovery, no party exists with ID " + partyId
        );
        return Promise.resolve();
      }
      const recoveries = party.recoveries
        ? [...party.recoveries, recovery]
        : [recovery];
      return updateParty({ ...party, recoveries });
    },
    update: (partyId, recovery) => {
      const party = c.parties?.find((p) => p.id === partyId);
      if (party === undefined) {
        console.warn(
          "Cannot add cost recovery, no party exists with ID " + partyId
        );
        return Promise.resolve();
      }
      if (party.recoveries === undefined) {
        console.warn(
          "Cannot add cost recovery, no recoveries defined in party " + partyId
        );
        return Promise.resolve();
      }
      const recoveries = party.recoveries.map((r) =>
        r.id === recovery.id ? recovery : r
      );
      return updateParty({ ...party, recoveries });
    },
    remove: (partyId, recovery) => {
      const party = c.parties?.find((p) => p.id === partyId);
      if (party === undefined) {
        console.warn(
          "Cannot add cost recovery, no party exists with ID " + partyId
        );
        return Promise.resolve();
      }
      if (party.recoveries === undefined) {
        console.warn(
          "Cannot remove cost recovery, no recoveries defined in party " +
            partyId
        );
        return Promise.resolve();
      }
      const recoveries = party.recoveries.filter((r) => r.id !== recovery.id);
      if (recoveries.length === party.recoveries.length) {
        console.warn("Recovery was not among recoveries of party " + partyId);
        return Promise.resolve();
      }
      return updateParty({ ...party, recoveries });
    },
  };

  return (
    <CostRecoveriesComponent
      recoveries={recoveries}
      recoveriesCallbacks={recoveriesCallbacks}
      totals={totals}
    />
  );
};

export const CostRecoveriesComponent = ({
  recoveries,
  recoveriesCallbacks,
  totals,
}: {
  recoveries: RecoveriesOfParty[];
  recoveriesCallbacks: CostRecoveriesCallbacks;
  totals: Map<string, CostTotals>;
}) => {
  if (recoveries.length === 0) {
    return (
      <Typography>
        <Trans i18nKey={"case.recoveries.description"} values={{ count: 0 }} />
      </Typography>
    );
  }

  const tabs: { label: string; tabComponent: React.ReactNode }[] =
    recoveries.map((recoveryOfParty) => {
      const total = totals.get(recoveryOfParty.partyId) || zero();
      const courtFees = total.incurredFees.add(total.estimatedFees).toNumber();
      const lawyerCosts = total.incurredLawyerCosts
        .add(total.estimatedLawyerCosts)
        .toNumber();
      return {
        label: t(
          recoveryOfParty.client
            ? "case.parties.name.client"
            : "case.parties.name.noClient",
          { name: recoveryOfParty.partyName }
        ),
        tabComponent: (
          <>
            {recoveryOfParty.recoveries?.map((recovery, recoveryIndex) => {
              const recoveryCallbacks: CostRecoveryCallbacks = {
                onPercentageUpdate: (newPercentage) =>
                  recoveriesCallbacks.update(recoveryOfParty.partyId, {
                    ...recovery,
                    percentage: newPercentage,
                  }),
              };
              return (
                <CostRecoveryDetails
                  key={"recoveries_" + recoveryIndex}
                  recovery={recovery}
                  recoveryCallbacks={recoveryCallbacks}
                  costsTotal={
                    recovery.type === RecoveryType.CourtFees
                      ? courtFees
                      : lawyerCosts
                  }
                />
              );
            })}
          </>
        ),
      };
    });

  return (
    <>
      <Typography>
        <Trans
          i18nKey={"case.recoveries.description"}
          values={{ count: recoveries.length }}
        />
      </Typography>
      <Tabs tabs={tabs} />
    </>
  );
};
