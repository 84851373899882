import { List, ListItem, ListItemText } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Currency, gbp } from "../i18n/Currency";
import { NamedValue } from "../model/CaseModel";
import { getValue } from "../model/WeightedValue";
import { AssetEditorDialog } from "./AssetEditorDialog";
import { EditAndDeleteMenu } from "./general/EditAndDeleteMenu";
import { YesNoDialog } from "./general/YesNoDialog";

const AssetItem = ({
  asset,
  currency = gbp,
  onChange,
  onDelete,
}: {
  asset: NamedValue;
  currency: Currency;
  onChange: (updatedAsset: NamedValue) => void;
  onDelete: () => void;
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <ListItem>
      <ListItemText
        primary={asset.name}
        secondary={currency.format(getValue(asset.values).toNumber())}
      />
      <EditAndDeleteMenu
        onEdit={() => setEditDialogOpen(true)}
        onDelete={() => setDeleteDialogOpen(true)}
      />
      <YesNoDialog
        onYes={() => {
          onDelete();
          setDeleteDialogOpen(false);
        }}
        onNo={() => {
          setDeleteDialogOpen(false);
        }}
        open={deleteDialogOpen}
        title={t("case.parties.assets.deleteDialog.title")}
        description={t("case.parties.assets.deleteDialog.description", {
          name: asset.name,
        })}
      />
      <AssetEditorDialog
        asset={asset}
        open={editDialogOpen}
        onClose={(updatedAsset) => {
          if (updatedAsset) onChange(updatedAsset);
          setEditDialogOpen(false);
        }}
      />
    </ListItem>
  );
};

export const AssetList = ({
  assets,
  currency = gbp,
  onChange,
}: {
  assets: NamedValue[];
  currency: Currency;
  onChange: (updatedAssets: NamedValue[]) => void;
}) => {
  return (
    <List sx={{ pt: 0, pb: 0 }}>
      {assets.map((asset, index) => (
        <AssetItem
          key={"asset_" + index}
          asset={asset}
          currency={currency}
          onChange={(newAsset) =>
            // replace asset in assets list
            onChange(assets.map((a) => (a.id === newAsset.id ? newAsset : a)))
          }
          onDelete={() => onChange(assets.filter((a) => a !== asset))}
        />
      ))}
    </List>
  );
};
