import AddQuantumIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIssueIcon from "@mui/icons-material/PlaylistAdd";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import {
  Claim as CaseModelClaim,
  IssueRelation,
  Quantum,
} from "../../model/CaseModel";
import randomId from "../../utils/randomId";
import { IssueEditorDialog } from "../IssueEditorDialog";
import {
  QuantumEditorDialog,
  QuantumEditorProps,
} from "../QuantumEditorDialog";
import { ResponsiveButton } from "../general/ResponsiveButton";
import { YesNoDialog } from "../general/YesNoDialog";
import { ClaimEditorDialog } from "./ClaimEditorDialog";
import {
  ClaimWinChanceCallbacks,
  ClaimWinChanceEditor,
} from "./ClaimWinChanceEditor";
import { Claim, Issue, IssueGroup, Party, isGroup } from "./IssueModel";
import { QuantumEditorRows, QuantumTableCallbacks } from "./QuantumEditorRows";
import { TwoLevelIssueHierarchyEditor } from "./TwoLevelIssueHierarchyEditor";

export interface ClaimCallbacks {
  onClaimUpdate: (claim: Claim) => void;
  onClaimDelete: (claim: Claim) => void;
}

export interface ClaimCardProps {
  claim: Claim;
  ordinal: string;
  callbacks: ClaimCallbacks;
  // all case parties should be available to be added to claim
  parties: Party[];
  // quantums of other claims that can be re-used in issues
  availableQuantumNames?: { [quantumId: string]: string };
}

function addDefendant(defendantId: string, issues: (Issue | IssueGroup)[]) {
  issues.forEach((issue) => {
    if (isGroup(issue)) addDefendant(defendantId, issue.subIssues);
    else {
      const winChance = issue.winChanceAgainst[defendantId];
      if (winChance === undefined) issue.winChanceAgainst[defendantId] = 0;
    }
  });
}

export const ClaimCard = ({
  claim,
  ordinal,
  callbacks,
  parties,
  availableQuantumNames,
}: ClaimCardProps) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newIssueEditorOpen, setNewIssueEditorOpen] = useState(false);
  const [editedIssue, setEditedIssue] = useState<Issue | undefined>();
  const [quantumEditorProps, setQuantumEditorProps] =
    useState<QuantumEditorProps>();

  const getDefendantsOfIssue = (issue: Issue) => {
    const issueDefendantIds = Object.keys(issue.winChanceAgainst);
    return claim.defendants.filter((d) => issueDefendantIds.includes(d.id));
  };

  const onQuantumEditorRequested = (quantum?: Quantum) => {
    const onClose: (quantum?: Quantum) => void =
      quantum === undefined
        ? (newQuantum) => {
            // new quantum
            if (newQuantum) {
              // initialize quantum liability at 100% per claim defendant
              if (newQuantum.liabilityShares === undefined)
                newQuantum.liabilityShares = {};
              for (let i = 0; i < claim.defendants.length; i++) {
                const defendant = claim.defendants[i];
                newQuantum.liabilityShares[defendant.id] = 1;
              }

              if (!claim.quantums) claim.quantums = [newQuantum];
              else claim.quantums?.push(newQuantum);
              callbacks.onClaimUpdate(claim);
            }
            setQuantumEditorProps(undefined);
          }
        : (newQuantum) => {
            // edited existing quantum
            if (newQuantum) {
              const mergedQuantum = { ...quantum, ...newQuantum };
              claim.quantums = claim.quantums?.map((q) =>
                q.id === newQuantum.id ? mergedQuantum : q
              );

              callbacks.onClaimUpdate(claim);
            }
            setQuantumEditorProps(undefined);
          };

    const editorProps: QuantumEditorProps = {
      forbiddenQuantumNames:
        claim.quantums
          ?.filter((q) => q.id !== quantum?.id)
          .map((q) => q.name) ?? [],
      open: true,
      availableQuantumNames: availableQuantumNames ?? {},
      onClose,
      quantum,
    };
    setQuantumEditorProps(editorProps);
    return;
  };

  const quantumCallbacks: QuantumTableCallbacks = {
    onQuantumDelete: (quantum) => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      claimCopy.quantums = claimCopy.quantums?.filter(
        (q) => q.id !== quantum.id
      );

      callbacks.onClaimUpdate(claimCopy);
    },
    onQuantumEditorRequested,
    onQuantumUpdate: (quantum) => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      claimCopy.quantums = claimCopy.quantums?.map((q) =>
        q.id === quantum.id ? quantum : q
      );
      callbacks.onClaimUpdate(claimCopy);
    },
  };
  const hasIssues: boolean =
    claim.issues !== undefined && claim.issues.length > 0;
  const hasQuantums: boolean =
    claim.quantums !== undefined && claim.quantums.length > 0;

  const onIssuesUpdate = (newIssues: (Issue | IssueGroup)[]) => {
    claim.issues = newIssues;
    callbacks.onClaimUpdate(claim);
  };
  const parentClaim: CaseModelClaim = {
    id: claim.id,
    title: claim.title,
    claimant: claim.claimant,
    defendants: claim.defendants,
  };

  const createClaimCallbacks: (claim: Claim) => ClaimWinChanceCallbacks = (
    claim
  ) => ({
    onWinChanceUpdated: (defendantId: string, newChance: number) => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      if (claimCopy.winChanceAgainst === undefined)
        claimCopy.winChanceAgainst = {};
      claimCopy.winChanceAgainst[defendantId] = newChance;
      callbacks.onClaimUpdate(claimCopy);
    },
    onSwitchToGeneric: () => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      claimCopy.generic = true;
      callbacks.onClaimUpdate(claimCopy);
    },
    onSwitchToSpecific: () => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      claimCopy.generic = false;
      callbacks.onClaimUpdate(claimCopy);
    },
    onGenericWinChanceUpdated: (newChance: number) => {
      const claimCopy: Claim = JSON.parse(JSON.stringify(claim));
      claimCopy.genericWinChance = newChance;
      callbacks.onClaimUpdate(claimCopy);
    },
  });

  return (
    <Card
      elevation={3}
      sx={{
        marginBottom: 2,
        userSelect: "none",
      }}
    >
      <ClaimEditorDialog
        title={claim.title}
        claimant={claim.claimant}
        defendants={claim.defendants || []}
        parties={parties}
        open={editDialogOpen}
        onClose={(update?) => {
          setEditDialogOpen(false);
          if (update === undefined) {
            return;
          }
          const updatedClaim = { ...claim, ...update };
          // make sure that all new defendants are set in issues
          const newDefendants = updatedClaim.defendants.filter(
            (d) => !claim.defendants.some((cd) => cd.id === d.id)
          );
          newDefendants.forEach((newDefendant) => {
            addDefendant(newDefendant.id, claim.issues);
          });
          callbacks.onClaimUpdate(updatedClaim);
        }}
      />
      {newIssueEditorOpen && (
        <IssueEditorDialog
          onClose={(issue?) => {
            setNewIssueEditorOpen(false);
            if (!issue) return;

            const newIssue: Issue = {
              ...issue,
              id: randomId(),
              relation: IssueRelation.And,
              winChanceAgainst: {},
            };
            issue.defendants.forEach((defendant) =>
              addDefendant(defendant.id, [newIssue])
            );

            claim.issues.push(newIssue);
            callbacks.onClaimUpdate(claim);
          }}
          parentClaim={parentClaim}
          defendants={claim.defendants}
          parties={claim.defendants}
          open={newIssueEditorOpen}
        />
      )}
      {!newIssueEditorOpen && editedIssue !== undefined && (
        <IssueEditorDialog
          parentClaim={parentClaim}
          title={editedIssue.title}
          generic={editedIssue.generic}
          defendants={getDefendantsOfIssue(editedIssue)}
          open
          onClose={(issueTemplate) => {
            if (issueTemplate) {
              const newDefendantIds = issueTemplate.defendants.map((d) => d.id);

              editedIssue.title = issueTemplate.title;
              editedIssue.generic = issueTemplate.generic;

              // make sure new defendants are in the issue
              newDefendantIds.forEach((defendantId) =>
                addDefendant(defendantId, [editedIssue])
              );

              // make sure only selected defendants are involved in the issue
              for (const defendantId in editedIssue.winChanceAgainst) {
                if (!newDefendantIds.includes(defendantId))
                  delete editedIssue.winChanceAgainst[defendantId];
              }

              callbacks.onClaimUpdate(claim);
            }
            setEditedIssue(undefined);
          }}
          parties={claim.defendants}
        />
      )}
      {quantumEditorProps && <QuantumEditorDialog {...quantumEditorProps} />}
      <YesNoDialog
        onYes={() => {
          callbacks.onClaimDelete(claim);
          setDeleteDialogOpen(false);
        }}
        onNo={() => {
          setDeleteDialogOpen(false);
        }}
        open={deleteDialogOpen}
        title={t("case.claims.deleteDialog.title")}
        description={t("case.claims.deleteDialog.description", {
          claim: claim.title,
        })}
      />
      <CardHeader
        title={t("case.claims.header", {
          claimOrdinal: ordinal,
          title: claim.title,
        })}
        subheader={t("case.claims.claimant", { ...claim.claimant })}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="body1">
          <Trans
            i18nKey="case.claims.defendants"
            values={{ names: claim.defendants.map((d) => d.name).join(" & ") }}
            count={claim.defendants.length}
          />
        </Typography>

        <Box sx={{ overflowX: "auto" }}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ userSelect: "none" }}
            width="fit-content"
          >
            <TableContainer>
              <Table size="small" aria-label="sticky table">
                {hasIssues ? (
                  <TwoLevelIssueHierarchyEditor
                    issues={claim.issues}
                    defendants={claim.defendants}
                    onIssuesUpdate={onIssuesUpdate}
                    onIssueEditorRequested={setEditedIssue}
                  />
                ) : (
                  <ClaimWinChanceEditor
                    claim={claim}
                    defendants={claim.defendants}
                    callbacks={createClaimCallbacks(claim)}
                  />
                )}
                {hasQuantums && (
                  <QuantumEditorRows
                    claim={{ title: claim.title, ordinal }}
                    defendants={claim.defendants}
                    callbacks={quantumCallbacks}
                    quantums={claim.quantums ?? []}
                    firstColSpan={hasIssues ? 2 : 1}
                  />
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" spacing={1}>
            <ResponsiveButton
              icon={<EditIcon fontSize="inherit" />}
              onClick={() => setEditDialogOpen(true)}
              title={`${t("case.claims.tip.edit")}`}
              label="Edit"
            />
            <ResponsiveButton
              icon={<AddIssueIcon fontSize="inherit" />}
              onClick={() => setNewIssueEditorOpen(true)}
              title={`${t("case.claims.issues.tip.add")}`}
              label="Add issue"
            />
            <ResponsiveButton
              icon={<AddQuantumIcon fontSize="inherit" />}
              onClick={() => onQuantumEditorRequested()}
              title={`${t("case.claims.quantums.tip.add")}`}
              label="Add quantum"
            />
          </Stack>
          <ResponsiveButton
            icon={<DeleteIcon fontSize="inherit" />}
            onClick={() => setDeleteDialogOpen(true)}
            title={`${t("case.claims.tip.delete")}`}
            label="Delete"
          />
        </Box>
      </CardActions>
    </Card>
  );
};
