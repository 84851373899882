import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { ComponentProps } from "react";
import CostApportionmentRadioButtonGroup from "./CostApportionmentRadioButtonGroup";

export interface Props
  extends ComponentProps<typeof CostApportionmentRadioButtonGroup> {
  title: string;
  secondaryTitle?: string;
  disableGutters?: ComponentProps<typeof Accordion>["disableGutters"];
  elevation?: ComponentProps<typeof Accordion>["elevation"];
  disabled?: ComponentProps<typeof Accordion>["disabled"];
}

const CostApportionmentAccordion = (props: Props) => {
  const theme = useTheme();
  const { title, secondaryTitle, disableGutters, disabled, elevation } = props;
  return (
    <Accordion
      disableGutters={disableGutters}
      disabled={disabled}
      elevation={elevation}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {secondaryTitle ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>{title}</Typography>
            <Typography sx={{ color: "text.secondary", pr: theme.spacing(1) }}>
              {secondaryTitle}
            </Typography>
          </Box>
        ) : (
          <Typography>{title}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <CostApportionmentRadioButtonGroup {...props} />
      </AccordionDetails>
    </Accordion>
  );
};

export default CostApportionmentAccordion;
