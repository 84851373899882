export interface EquidistantSegment {
  x1: number;
  x2: number;
}

export function intersection(
  segment1: EquidistantSegment,
  segment2: EquidistantSegment
): { x: number; y: number } | undefined {
  if (segment1.x1 === segment2.x1) return { x: segment1.x1, y: 0 };
  if (segment1.x2 === segment2.x2) return { x: segment1.x2, y: 1 };
  if (segment1.x1 > segment2.x1) {
    if (segment1.x2 > segment2.x2) return;
    // there is intersection
    // segment1.x1 is greater than segment2.x1 but segment1.x2 is less than segment2.x2
    const x1Distance = segment1.x1 - segment2.x1;

    // delta x when moving 1 on y
    const segment1Gain = segment1.x2 - segment1.x1;
    const segment2Gain = segment2.x2 - segment2.x1;
    // this is actually impossible
    if (segment1Gain === segment2Gain) return;

    // how much do we move on y to travel x1Distance (i.e. from being apart to intersect at 0 x distance)?
    const y = Math.abs(x1Distance / (segment1Gain - segment2Gain));

    return { x: segment1.x1 + segment1Gain * y, y };
  }
  // segment1.x1 is less or equal segment2.x1
  if (segment1.x2 < segment2.x2) return;
  // there is intersection
  // segment1.x1 is less than segment2.x1 but segment1.x2 is greater than segment2.x2
  const x1Distance = segment1.x1 - segment2.x1;

  // delta x when moving 1 on y
  const segment1Gain = segment1.x2 - segment1.x1;
  const segment2Gain = segment2.x2 - segment2.x1;
  // how much do we move on y to travel x1Distance (i.e. from being apart to intersect at 0 x distance)?
  const y = Math.abs(x1Distance / (segment1Gain - segment2Gain));

  return { x: segment1.x1 + segment1Gain * y, y };
}
