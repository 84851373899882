import { Theme } from "@mui/material";

export function backgroundColor(
  revenue: "margin" | "directCosts" | "indirectCosts",
  theme: Theme
): string {
  switch (revenue) {
    case "margin":
      return theme.palette.info.main + "90";
    case "directCosts":
      return theme.palette.secondary.main + "90";
    case "indirectCosts":
      return theme.palette.primary.main + "90";
  }
}

export function borderColor(
  revenue: "margin" | "directCosts" | "indirectCosts",
  theme: Theme
): string {
  switch (revenue) {
    case "margin":
      return theme.palette.info.dark;
    case "directCosts":
      return theme.palette.secondary.dark;
    case "indirectCosts":
      return theme.palette.primary.dark;
  }
}
