import AddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useSubmit } from "react-router-dom";
import { useCase } from "../context/CaseContext";
import { NamedValue, Party } from "../model/CaseModel";
import randomId from "../utils/randomId";
import { PartyDetails } from "./PartyDetails";
import { PartyEditorDialog } from "./PartyEditorDialog";

export interface PartyCallbacks {
  setName: (party: Party, newName: string) => void;
  setClient: (party: Party) => void;
  setNonClient: (party: Party) => void;
  setAssets: (party: Party, assets: NamedValue[]) => void;
  add: (party: Party) => void;
  delete: (party: Party) => void;
}

export const Parties = () => {
  const caseContext = useCase();

  const submit = useSubmit();
  const updateParty = (party: Party) =>
    submit({ party: JSON.stringify(party) }, { method: "put" });

  const partyCallbacks: PartyCallbacks = {
    setName: function (party: Party, name: string): void {
      updateParty({ ...party, name });
    },
    setClient: function (party: Party): void {
      updateParty({ ...party, client: true });
    },
    setNonClient: function (party: Party): void {
      updateParty({ ...party, client: false });
    },
    setAssets: function (party: Party, assets: NamedValue[]): void {
      updateParty({ ...party, assets });
    },
    add: function (party: Party): void {
      submit(
        {
          partyId: party.id,
          name: party.name,
          client: party.client ? "true" : "false",
        },
        { method: "post" }
      );
    },
    delete: function (party: Party): void {
      submit({ partyId: party.id }, { method: "delete" });
    },
  };

  return (
    <PartiesComponent
      parties={caseContext.getCase().parties || []}
      partyCallbacks={partyCallbacks}
    />
  );
};

export const PartiesComponent = ({
  parties,
  partyCallbacks,
}: {
  parties: Party[];
  partyCallbacks: PartyCallbacks;
}) => {
  const [newParty, setNewParty] = useState<Party>();
  return (
    <Box>
      {parties.map((party, index) => (
        <PartyDetails
          key={"party_" + index}
          party={party}
          partyCallbacks={partyCallbacks}
        />
      ))}
      <Button
        onClick={() => {
          // create & add new party
          const newParty: Party = {
            id: randomId(),
            name: ``,
          };
          setNewParty(newParty);
        }}
        title={`${t("case.parties.tip.add")}`}
        startIcon={<AddIcon />}
      >
        <Trans i18nKey="case.parties.add" />
      </Button>

      {newParty && (
        <PartyEditorDialog
          party={newParty}
          onClose={(party) => {
            if (party) partyCallbacks.add(party);
            setNewParty(undefined);
          }}
          open={newParty !== undefined}
        />
      )}
    </Box>
  );
};
