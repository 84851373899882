import LeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import RightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Chip, useTheme } from "@mui/material";
import { t } from "i18next";
import { Fragment } from "react";
import { Stage } from "../../../analytics/model/CaseConfiguration";
import { OfferStatus } from "../../../model/CaseModel";
import SettlementOfferCard, { Offer } from "./SettlementOfferCard";

const SettlementTimeline = ({
  partyId,
  offers = [],
  onStatusChange,
  onDeleteRequested,
  onEditRequested,
  onInfoRequested,
}: {
  partyId: string;
  offers?: Offer[];
  onStatusChange?: (offerId: string, newStatus: OfferStatus) => void;
  onDeleteRequested?: (offerId: string) => void;
  onEditRequested?: (offerId: string) => void;
  onInfoRequested?: (offerId: string) => void;
}) => {
  const theme = useTheme();

  let lastStage: Stage;
  return (
    <Timeline>
      <TimelineItem key={"start"} position="left">
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent />
      </TimelineItem>
      {(!offers || offers.length === 0) && (
        <TimelineItem key={"no_offers"} position="left">
          <TimelineSeparator
            sx={{
              display: "flex",
              alignSelf: "center",
              userSelect: "none",
            }}
          >
            <TimelineConnector />
            <Chip label="No offers" sx={{ boxShadow: 4 }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent />
        </TimelineItem>
      )}
      {offers.map((offer, offerIndex) => {
        const onLeft = offer.offeror.id === partyId;
        const isLast = offerIndex === offers.length - 1;

        // check if stage shall be shown
        const currentStage: Stage | undefined =
          lastStage !== offer.stage ? offer.stage : undefined;
        lastStage = offer.stage;

        const timelineItem = (
          <TimelineItem key={offerIndex} position={onLeft ? "left" : "right"}>
            <TimelineSeparator>
              <TimelineConnector />
              <Box sx={{ color: theme.palette.text.secondary }}>
                {offer.offeror.id === partyId ? <RightIcon /> : <LeftIcon />}
              </Box>
              {!isLast ? <TimelineConnector /> : <Box flexGrow={1} />}
            </TimelineSeparator>
            <TimelineContent
              display="flex"
              justifyContent={onLeft ? "flex-end" : "flex-start"}
            >
              <SettlementOfferCard
                offer={offer}
                onDeleteRequested={
                  onDeleteRequested
                    ? () => onDeleteRequested(offer.id)
                    : undefined
                }
                onEditRequested={
                  onEditRequested ? () => onEditRequested(offer.id) : undefined
                }
                onInfoRequested={
                  onInfoRequested ? () => onInfoRequested(offer.id) : undefined
                }
                onStatusChange={
                  onStatusChange
                    ? (newStatus) => onStatusChange(offer.id, newStatus)
                    : undefined
                }
              />
            </TimelineContent>
          </TimelineItem>
        );

        return currentStage ? (
          <Fragment key={offerIndex}>
            <TimelineItem
              key={"stage_" + offerIndex}
              position={onLeft ? "left" : "right"}
            >
              <TimelineSeparator
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  userSelect: "none",
                }}
              >
                <TimelineConnector />
                <Chip
                  label={t(`case.stages.${Stage[offer.stage]}`)}
                  sx={{ boxShadow: 4 }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent />
            </TimelineItem>
            {timelineItem}
          </Fragment>
        ) : (
          timelineItem
        );
      })}
    </Timeline>
  );
};

export default SettlementTimeline;
