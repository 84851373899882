import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useCurrency } from "../../../context/CurrencyContext";
import { CurrencyTextField } from "../../general/CurrencyTextField";

const CostApportionmentRadioButtonGroup = ({
  value,
  max,
  description,
  suggestedValue,
  onValueChange,
  disabled = false,
  costLabel,
}: {
  costLabel: string;
  max?: number;
  description?: string;
  value: number;
  suggestedValue: { label: string; value: number; selected?: boolean };
  onValueChange: (newValue: number) => void;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const currency = useCurrency();
  const [customValue, setCustomValue] = useState(value);
  const [selectedType, setSelectedType] = useState<
    "suggested" | "custom" | "none"
  >(suggestedValue.selected ? "suggested" : "custom");

  return (
    <Box
      component="fieldset"
      border={1}
      borderRadius={1}
      borderColor={theme.palette.divider}
      width="fit-content"
    >
      <legend>
        <Typography
          variant="body2"
          color={disabled ? theme.palette.text.disabled : undefined}
        >
          {costLabel}
        </Typography>
      </legend>
      {description && (
        <Typography
          variant="body2"
          color={disabled ? theme.palette.text.disabled : undefined}
        >
          {description}
        </Typography>
      )}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={selectedType}
        name="radio-buttons-group"
        onChange={(e) => {
          switch (e.target.value) {
            case "none": {
              setSelectedType("none");
              onValueChange(0);
              return;
            }
            case "suggested": {
              setSelectedType("suggested");
              onValueChange(suggestedValue.value);
              return;
            }
            case "custom": {
              setSelectedType("custom");
              onValueChange(customValue);
              return;
            }
          }
        }}
      >
        <FormControlLabel
          disabled={disabled}
          value={"suggested"}
          control={<Radio size="small" />}
          label={`${suggestedValue.label}: ${currency.format(suggestedValue.value)}`}
        />
        <FormControlLabel
          disabled={disabled}
          value={"custom"}
          control={<Radio size="small" />}
          label="Custom amount"
        />
        <CurrencyTextField
          currency={currency}
          onValueChange={(newValue) => {
            setCustomValue(newValue);
            if (selectedType === "custom") onValueChange(newValue);
          }}
          disabled={disabled || suggestedValue.selected}
          variant="outlined"
          label={max ? `Max ${currency.format(max)}` : undefined}
          min={0}
          max={max}
          sx={{
            ml: theme.spacing(4),
            maxWidth: theme.spacing(24),
            my: theme.spacing(0.5),
          }}
          value={customValue}
          size="small"
        />
        <FormControlLabel
          disabled={disabled}
          value={"none"}
          control={<Radio size="small" />}
          label="None"
        />
      </RadioGroup>
    </Box>
  );
};

export default CostApportionmentRadioButtonGroup;
