import { Box, Typography, useTheme } from "@mui/material";
import { useCurrency } from "../context/CurrencyContext";

export const MonetaryDeltaThreeLiner = ({
  label,
  value,
  delta,
  onClick,
}: {
  label: string | number | null;
  value: number;
  delta: number;
  onClick?: () => void;
}) => {
  const currency = useCurrency();
  const theme = useTheme();
  return (
    <Box onClick={onClick}>
      <Typography>{label}</Typography>
      <Typography variant="body2" color="textSecondary">
        {delta === 0
          ? currency.format(value)
          : delta > 0
          ? `${currency.format(value)} ↑`
          : `${currency.format(value)} ↓`}
      </Typography>
      <Typography
        variant="body2"
        color={
          delta === 0
            ? theme.palette.text.secondary
            : delta < 0
            ? theme.palette.error.main
            : theme.palette.success.main
        }
      >
        {delta === 0
          ? `(±${currency.format(0)})`
          : delta > 0
          ? `(+${currency.format(delta)})`
          : `(${currency.format(delta)})`}
      </Typography>
    </Box>
  );
};
