import {
  getResultingApportionment,
  isCounterClaim,
  isCovered,
} from "./CaseConfigurationUtility";
import {
  Case,
  CaseVariant,
  CostType,
  LiabilityIssue,
  LiabilityScenario,
  QuantumIssue,
  Recovery,
  Share,
} from "./model/CaseConfiguration";
import Scenario from "./Scenario";

import Big from "big.js";
import { max, min, ZERO } from "../big/bigUtil";
import { LiabilityOutcome } from "./liability/LiabilityOutcome";
import { getPossibleScenarios } from "./liability/LiabilityScenarios";
import { Payer } from "./payment/Payment";
import { merge, PaymentObligation } from "./payment/PaymentObligation";
import { PaymentPool } from "./payment/PaymentPool";
import { Quantum } from "./Quantum";
import { QuantumLiabilities } from "./QuantumLiabilities";
import { RecoveryPaymentCalculator } from "./RecoveryPaymentCalculator";

enum ClaimType {
  Regular,
  CounterClaim,
  SetOff,
}

class QuantumClaimWin {
  constructor(
    readonly quantum: QuantumIssue,
    readonly value: Big,
    readonly liabilityId: string,
    // defendants mapped to amounts they are liable for
    readonly defendants: { [defendantId: string]: Big },
    readonly type = ClaimType.Regular
  ) {}

  isSetOff = () => this.type === ClaimType.SetOff;

  isDefendant = (partyId: string) => this.defendants[partyId] !== undefined;

  isClaimant = (partyId: string) => this.quantum.claimant === partyId;
  isAnyClaimant = (partyIds: string[]) =>
    partyIds.some((partyId) => this.quantum.claimant === partyId);
}

/**
 * Encapsulates a possible judgement outcome of a case, for a given case configuration variant.
 */
export class ScenarioOutcome extends Scenario {
  readonly wonQuantums = new Map<string, QuantumClaimWin[]>();

  private issueOutcome: LiabilityOutcome;

  public readonly quantumLiabilities: {
    [quantumId: string]: QuantumLiabilities;
  } = {};

  // holds won quantums of this issue outcome scenario
  private readonly wins: Quantum[] = [];

  // recoveries matching this outcome scenario (based on who won/lost what)
  private readonly applicableRecoveries: Recovery[] = [];

  private readonly recoveryPayments: {
    [recipientId: string]: PaymentObligation[];
  } = {};

  constructor(
    c: Case,
    variant: CaseVariant,
    public readonly when: LiabilityScenario
  ) {
    super(c, variant);

    this.issueOutcome = new LiabilityOutcome(this.c, when);

    this.initQuantumLiabilities();

    // get won & lost quantums
    this.c.quantumIssues?.forEach((quantumIssue) => {
      const wonClaims = this.getWonQuantums(quantumIssue);
      if (wonClaims.length > 0)
        this.wonQuantums.set(quantumIssue.id, wonClaims);
    });

    // process set-off defense liability issues
    // split won quantums into set-offs and non-set-offs
    // filter out set-offs of lost claims
    // consider each set-off claimant
    // filter out set-offs of won claims but with no successful defendant claim against claimant
    // sum value of non-set-off quantums against claimant of all set-offs defendants.
    // if a defendant's non-set-off quantum is set-off by claimant, break and report set-off cycle error between claimant and defendant!
    // cap value of set-offs of claimant at the minimum of either their quantum value or sum of defendant's quantums against claimant
    const setOffWins: QuantumClaimWin[] = [];
    const nonSetOffWins: QuantumClaimWin[] = [];
    for (const [, quantumWin] of this.wonQuantums) {
      const perClaimant = new Map<string, Quantum>();
      quantumWin.forEach((win) => {
        if (win.isSetOff()) {
          setOffWins.push(win);
          return;
        }
        nonSetOffWins.push(win);
        // non-set-off quantums are trivial and can be created immediately in this loop

        const existing = perClaimant.get(win.quantum.claimant);
        if (existing) {
          existing.increaseAmountTo(win.value);
          existing.increaseLiabilities(win.defendants);
        } else {
          perClaimant.set(
            win.quantum.claimant,
            new Quantum(
              win.quantum.id,
              win.quantum.claimant,
              win.defendants,
              win.value
            )
          );
        }
      });

      for (const [, quantum] of perClaimant) {
        this.wins.push(quantum);
      }
    }

    setOffWins.forEach((setOff) => {
      const perClaimant = new Map<string, Quantum>();
      // create per-claimant quantums
      // cap set-off value at value of successful defendants' claims
      let maxSetOff = ZERO;
      nonSetOffWins.forEach((nonSetOff) => {
        // is it against claimant?
        if (!nonSetOff.isDefendant(setOff.quantum.claimant)) return;
        // is any defendant claiming against claimant?
        if (!nonSetOff.isAnyClaimant(Object.keys(setOff.defendants))) return;

        maxSetOff = max(maxSetOff, nonSetOff.value);
      });
      // is there anything to set-off?
      if (maxSetOff.lte(0)) {
        // no successful claim by defendants, nothing to set-off
        console.debug(
          `'${setOff.quantum.claimant}' won set-off quantum '${setOff.quantum.id}', but has nothing to set-off`
        );
        return;
      }
      const setOffValue = min(setOff.value, maxSetOff);
      const existing = perClaimant.get(setOff.quantum.claimant);
      if (existing) {
        existing.increaseAmountTo(setOff.value);
        existing.increaseLiabilities(setOff.defendants);
      } else {
        perClaimant.set(
          setOff.quantum.claimant,
          new Quantum(
            setOff.quantum.id,
            setOff.quantum.claimant,
            setOff.defendants,
            setOffValue
          )
        );
      }

      for (const [, quantum] of perClaimant) {
        this.wins.push(quantum);
      }
    });

    // calculate recoveries based on who won/lost what
    const debtorsPerParty = new Map<string, Set<string>>();

    // calculate recoveries
    // decide recovery payments based on won/lost heads of claims
    this.c.liabilityIssues?.forEach((headOfClaim) => {
      if (headOfClaim.claimant === undefined) {
        console.error("No claimant set in head of claim", headOfClaim);
        return;
      }
      if (this.issueOutcome.isIssueWon(headOfClaim.id)) {
        // claim won, defendants are debtors of claimant
        let debtorsOfClaimant = debtorsPerParty.get(headOfClaim.claimant);
        if (debtorsOfClaimant === undefined) {
          debtorsOfClaimant = new Set();
          debtorsPerParty.set(headOfClaim.claimant, debtorsOfClaimant);
        }
        headOfClaim.defendants?.forEach((defendantId) =>
          debtorsOfClaimant?.add(defendantId)
        );
        return;
      }

      // claim lost, claimant is debtor of defendants
      headOfClaim.defendants?.forEach((defendantId) => {
        let debtorsOfDefendant = debtorsPerParty.get(defendantId);
        if (debtorsOfDefendant === undefined) {
          debtorsOfDefendant = new Set();
          debtorsPerParty.set(defendantId, debtorsOfDefendant);
        }
        debtorsOfDefendant.add(headOfClaim.claimant);
      });
    });
    // check for each recovery if a payment shall be awarded
    this.variant.costRecoveries?.forEach((recovery) => {
      // filter out recoveries not matching issue outcome
      if (recovery.when && !isCovered(this.c, recovery.when, this.when)) {
        return;
      }
      if (recovery.payers) {
        // payers specified explicitly, must pay
        this.applicableRecoveries.push(recovery);
        return;
      }

      // either recipient won quantums, then all defendants of those quantums are liable for recoveries
      // or recipient successfully defended a claim, then claimants are liable for recoveries
      const debtors = debtorsPerParty.get(recovery.recipient);
      if (debtors === undefined || debtors.size === 0) {
        // nobody is liable for recoveries of recipient
        return;
      }

      this.applicableRecoveries.push({
        ...recovery,
        payers: [...debtors].map((partyId) => ({ partyId })),
      });
    });

    // finally, calculate the recovery payments
    const recoveryCalculators: {
      [recipientId: string]: RecoveryPaymentCalculator;
    } = {};
    this.getParties().forEach((party) => {
      const debtors = debtorsPerParty.get(party.id);
      if (debtors === undefined || debtors.size === 0) {
        // nobody pays party
        return;
      }

      const lawyersCostsApportionment = this.getApportionmentAmongDebtors(
        party.id,
        CostType.LawyerCosts,
        debtors
      );
      const courtFeesApportionment = this.getApportionmentAmongDebtors(
        party.id,
        CostType.CourtFees,
        debtors
      );

      recoveryCalculators[party.id] = new RecoveryPaymentCalculator(
        lawyersCostsApportionment.length > 0
          ? lawyersCostsApportionment
          : [...debtors].map((partyId) => ({ partyId })),
        courtFeesApportionment.length > 0
          ? courtFeesApportionment
          : [...debtors].map((partyId) => ({ partyId }))
      );
    });
    // process recoveries per recipient (i.e. cost payer)
    this.applicableRecoveries.forEach((recovery) => {
      const calculator = recoveryCalculators[recovery.recipient];
      if (calculator === undefined) {
        return;
      }
      calculator.addRecovery(recovery);
    });
    // now find out what was paid towards which payer, based on cost apportionment
    this.getCosts().forEach((cost) => {
      const calculator = recoveryCalculators[cost.payerId];
      if (calculator === undefined) {
        // no payer for the recovery means recipient has no debtors in this outcome
        return;
      }
      const recoveryPayment = calculator.getRecoveryPayment(cost);
      if (recoveryPayment === undefined) {
        // nobody pays
        return;
      }

      const recipientsRecoveries = this.recoveryPayments[cost.payerId];
      if (recipientsRecoveries) {
        recipientsRecoveries.push(recoveryPayment);
        return;
      }
      // first recovery for recipient, store in new array
      this.recoveryPayments[cost.payerId] = [recoveryPayment];
    });
  }

  /**
   * This method retrieves the globally configured apportionment of a party and applies it to a set of debtors (which may be a sub- or super-set of the apportioned parties).
   * Shares of apportioned parties not among the debtors are not returned. Non-apportioned debtors are assigned the generic costs, if there is a generic part.
   *
   * @param partyId to get the apportionment of
   * @param costType type of apportioned costs
   * @param debtors of party
   * @returns shares of the debtors according to the globally configured apportionment or equal shares in case no apportionment is configured
   */
  private getApportionmentAmongDebtors(
    partyId: string,
    costType: CostType,
    debtors: Set<string>
  ): Share[] {
    const apportionment = this.getGlobalApportionment(costType, partyId);
    return getResultingApportionment([...debtors], apportionment);
  }

  private getWonQuantums(quantumIssue: QuantumIssue): QuantumClaimWin[] {
    const quantumLiability = this.quantumLiabilities[quantumIssue.id];
    if (quantumLiability === undefined) {
      console.warn(`Ignoring quantum '${quantumIssue.id}' with no value`);
      return [];
    }

    const quantumClaimWins: QuantumClaimWin[] = [];
    // go through liability claims of quantum
    quantumIssue.liabilities.forEach((liabilityId) => {
      const liability = this.issueOutcome.getLiability(liabilityId);
      if (liability === undefined) {
        console.warn(
          `Quantum issue '${quantumIssue.id}' references liability '${liabilityId}', not existing in the issue outcome`
        );
        return;
      }
      // check if we deal with counter claim or not
      if (!isCounterClaim(quantumIssue, liability.issue)) {
        // not a counter-claim is the easier case
        // check if claim was won?
        if (!liability.isBranchWon()) return;

        // claim won, thus quantum is won
        quantumClaimWins.push(
          new QuantumClaimWin(
            quantumIssue,
            quantumLiability.max(),
            liability.issue.id,
            quantumLiability.perDefendant(),
            // don't forget to check set-off
            liability.isSetOff() ? ClaimType.SetOff : ClaimType.Regular
          )
        );
        return;
      }
      // issue cannot be a counter-claim and set-off at the same time
      if (liability.isSetOff()) {
        console.warn(
          `'${quantumIssue.id}' references counter-claim liability '${liabilityId}', which is also marked as set-off`
        );
        return;
      }
      // check if liability is won from the quantum's perspective (quantum can be counter-claim)
      // quantum of counter claim, check if liability was lost => quantum is won
      if (liability.isBranchWon()) {
        // counter-claim not successful, quantum not won
        return;
      }

      quantumClaimWins.push(
        new QuantumClaimWin(
          quantumIssue,
          quantumLiability.max(),
          liability.issue.id,
          quantumLiability.perDefendant(),
          ClaimType.CounterClaim
        )
      );
    });

    return quantumClaimWins;
  }

  /**
   * Goes through quantums and configured quantum amounts of this scenario outcome.
   */
  private initQuantumLiabilities() {
    if (!this.c.quantumIssues) return;

    // filter quantum amounts matching this scenario and re-use for all quantums
    const quantumsOfScenario = this.variant.quantumAmounts?.filter(
      // filter out amounts not matching this scenario
      (q) => q.when === undefined || isCovered(this.c, q.when, this.when)
    );

    // initialize quantum amounts for each quantum of the case
    this.c.quantumIssues.forEach((quantumIssue) => {
      const maxAmounts = new QuantumLiabilities(
        quantumIssue.value,
        this.getLiableDefendants(quantumIssue.id)
      );
      this.quantumLiabilities[quantumIssue.id] = maxAmounts;
      // process quantum amounts configured for issue, if any are defined
      quantumsOfScenario
        ?.filter((q) => q.quantumId === quantumIssue.id)
        .forEach((quantum) => {
          maxAmounts.increaseMaxAmount(quantum.amount);
          if (quantum.perDefendant)
            maxAmounts.increaseDefendantMaxAmounts(quantum.perDefendant);
        });
    });
  }

  getQuantumsWonBy(partyId: string): Quantum[] {
    return this.wins.filter((quantum) => quantum.isClaimant(partyId));
  }

  getQuantumsLostBy(partyId: string): Quantum[] {
    return this.wins.filter((quantum) => quantum.isLiableDefendant(partyId));
  }

  getLiabilitiesWonBy(partyId: string): LiabilityIssue[] {
    return this.issueOutcome.getLiabilitiesWonBy(partyId);
  }

  getLiabilitiesLostBy(partyId: string): LiabilityIssue[] {
    return this.issueOutcome.getLiabilitiesLostBy(partyId);
  }

  getLiabilitiesWon(): LiabilityIssue[] {
    return this.issueOutcome.getIssuesWon();
  }

  getLiabilitiesLost(): LiabilityIssue[] {
    return this.issueOutcome.getIssuesLost();
  }

  getAsPayer(partyId: string): Payer {
    const limit = this.getAssetLimitOfParty(partyId);
    const name = this.getParty(partyId)?.name;
    if (limit === undefined) {
      return { payerId: partyId, name };
    }
    return { payerId: partyId, name, availableAmount: limit };
  }

  getLiableDefendants(quantumIssueId: string): string[] {
    const quantumIssue = this.getQuantumIssue(quantumIssueId);
    if (quantumIssue === undefined) {
      console.warn("Unknown quantum issue " + quantumIssueId);
      return [];
    }
    const defendantIds = new Set<string>();
    quantumIssue.liabilities?.forEach((liabilityIssueId) => {
      const liability = this.issueOutcome.getLiability(liabilityIssueId);
      if (liability === undefined) {
        console.warn(
          `Unknown liability issue '${liabilityIssueId}' referenced in quantum issue '${quantumIssue}'`
        );
        return;
      }
      if (isCounterClaim(quantumIssue, liability.issue)) {
        // defendants of liability have a quantum => counter claim
        // return all other claimants of all other quantums associated with this liability issue
        this.getQuantumIssuesOfLiability(liabilityIssueId).forEach(
          (quantumIssue) => {
            if (!liability.hasDefendant(quantumIssue.claimant))
              defendantIds.add(quantumIssue.claimant);
          }
        );
        return;
      }
      liability.getLiableDefendants().forEach((id) => defendantIds.add(id));
    });

    return [...defendantIds];
  }

  // cache expensive variable
  private paymentPool: PaymentPool<PaymentObligation> | undefined;
  getPaymentPool(): PaymentPool<PaymentObligation> {
    // lazy initialization
    if (this.paymentPool === undefined) {
      const allPayments: PaymentObligation[] = [];

      // gather awarded recoveries
      Object.values(this.recoveryPayments)
        .flatMap((r) => r)
        .forEach((recovery) => merge(allPayments, recovery));

      // gather quantums awarded
      this.getAwardedQuantumsAsPayments().forEach((quantum) =>
        merge(allPayments, quantum)
      );

      // gather all payers
      const payerIds = new Set<string>();
      const paymentsToPlan = allPayments.filter((payment) =>
        payment.price.gt(0)
      );
      paymentsToPlan.forEach((item) => {
        for (const [payerId, share] of item.maxSharePerPayer) {
          if (share.gt(0)) payerIds.add(payerId);
        }
      });

      const payers = [...payerIds].map((id) => this.getAsPayer(id));
      this.paymentPool = new PaymentPool(paymentsToPlan, payers);
    }
    return this.paymentPool;
  }

  private getAwardedQuantumsAsPayments(): PaymentObligation[] {
    const items: PaymentObligation[] = [];
    this.wins.forEach((quantum) => {
      const sharePerDefendant = new Map<string, Big>();
      const liableDefendants = quantum.getLiableDefendants();

      for (const defendantId in liableDefendants)
        sharePerDefendant.set(defendantId, liableDefendants[defendantId]);

      items.push(
        new PaymentObligation(
          quantum.getQuantumId(),
          quantum.getAmount(),
          sharePerDefendant,
          quantum.getClaimant()
        )
      );
    });
    return items;
  }

  // overwrite to make sure outcome returns its applicable recoveries
  getRecoveries(): Recovery[] {
    return this.applicableRecoveries;
  }

  /**
   * Returns recoveries awarded to a party, given this case outcome.
   *
   * @param partyId party
   * @returns all applicable recoveries
   */
  getRecoveriesAwardedTo(partyId: string): Recovery[] {
    return this.applicableRecoveries.filter(
      (recovery) => recovery.recipient === partyId
    );
  }

  /**
   * Returns recovery payments awarded to a party, given this case outcome.
   *
   * @param partyId party
   * @returns all applicable recovery payments
   */
  getRecoveriesPaidTo(partyId: string): PaymentObligation[] {
    return this.recoveryPayments[partyId] ?? [];
  }

  /**
   * Returns recoveries to by paid by a party, given this case outcome.
   *
   * @param partyId party
   * @returns all applicable recoveries
   */
  getRecoveriesPaidBy(partyId: string): PaymentObligation[] {
    const payments: PaymentObligation[] = [];
    for (const recipientId in this.recoveryPayments) {
      // skip received payments
      if (recipientId === partyId) continue;

      this.recoveryPayments[recipientId]
        .filter((payment) => payment.isPayer(partyId))
        .forEach((payment) => payments.push(payment));
    }
    return payments;
  }
}

/***
 * Generates all possible outcomes of this case scenario
 */
export function generateOutcomes(scenario: Scenario): ScenarioOutcome[] {
  if (scenario.variant.liabilityWinChances === undefined) {
    console.debug(
      `No liability issue probabilities provided for ${scenario.c.title} in configuration. Cannot generate scenario outcomes`,
      scenario.variant
    );
    return [];
  }

  if (scenario.leafIssueIds.length < 1) {
    console.debug(
      `No liability issues defined in ${scenario.c.title}. No scenario outcomes`,
      scenario.variant
    );
    return [];
  }

  const getWinChance = (issueId: string) => {
    const p = scenario.variant.liabilityWinChances?.find(
      (p) => p.liabilityId === issueId
    )?.winChance;
    return p ?? 1;
  };

  const outcomes = getPossibleScenarios(
    scenario.c.liabilityIssues ?? [],
    getWinChance
  );

  return outcomes.map(
    (outcome) => new ScenarioOutcome(scenario.c, scenario.variant, outcome)
  );
}
