import { Stage } from "./CaseConfiguration";

export function getStages() {
  return [
    Stage.PreAction,
    Stage.IssueStatementsOfCase,
    Stage.CMC,
    Stage.Disclosure,
    Stage.WitnessStatements,
    Stage.ExpertReports,
    Stage.PTR,
    Stage.TrialPreparation,
    Stage.Trial,
    Stage.Appeals,
    Stage.Enforcement,
  ];
}
