import Parse, { ACL } from "parse";
import { useContext } from "react";
import parseAuth from "../../../components/auth/parseAuth";
import {
  Case,
  Claim,
  Configuration,
  CostStructure,
  Party,
  SettlementOffer,
  Variants,
} from "../../../model/CaseModel";
import { Budget } from "../../../model/CostModel";
import { BudgetInitializingCaseApiDecorator } from "../BudgetInitializingCaseApiDecorator";
import { CaseApi, CaseApiContext } from "../CaseApi";

function asCase(object: Parse.Object<Parse.Attributes>): Case {
  const c: Case = { id: object.id, name: object.get("title") };
  c.parties = object.get("parties") || [];
  c.claims = object.get("claims") || [];
  c.budgets = object.get("budgets") || [];
  c.costStructure = object.get("costStructure") || {};
  c.settlements = object.get("settlements") || [];
  c.results = object.get("results");
  c.variants = object.get("variants") || {};
  c.variantResults = object.get("variantResults") || {};
  c.createdAt = object.createdAt?.toISOString();
  c.updatedAt = object.updatedAt?.toISOString();
  return c;
}

export class ParseCaseClient implements CaseApi {
  private readonly options;

  constructor(sessionToken: string) {
    this.options = {
      sessionToken,
    };
  }

  queryAllCases(): Promise<Case[]> {
    const casesQuery = new Parse.Query("Case");

    return casesQuery
      .find(this.options)
      .then((results) => results.map((c) => asCase(c)));
  }

  queryCase(caseId: string): Promise<Case> {
    const caseQuery = new Parse.Query("Case");
    return caseQuery.get(caseId, this.options).then((obj) => asCase(obj));
  }

  addCase(c: Case): Promise<Case> {
    const CaseType = Parse.Object.extend("Case");
    const parseCase = new CaseType();
    parseCase.set("title", c.name);

    // current users and owners should have access
    const acl = new ACL(Parse.User.current());
    acl.setRoleReadAccess("owners", true);
    acl.setRoleWriteAccess("owners", true);
    parseCase.setACL(acl);

    return parseCase.save(this.options).then((obj: typeof CaseType) => {
      // rewrite, as id is set by server
      return { id: obj.id, title: parseCase.get("name") };
    });
  }

  removeCase(caseId: string): Promise<void> {
    const caseQuery = new Parse.Query("Case");
    return caseQuery
      .get(caseId, this.options)
      .then((obj) => obj.destroy())
      .then();
  }

  setTitle(caseId: string, title: string): Promise<void> {
    return this.getCase(caseId).then((c) => {
      c.set("title", title);
      return c.save().then();
    });
  }

  add<E>(caseId: string, fieldId: keyof Case, element: E): Promise<E[]> {
    return this.getCase(caseId).then((c) => {
      const elements: E[] = c.get(fieldId) || [];
      const newElements = [...elements, element];
      c.set(fieldId, newElements);
      return c.save().then(() => newElements);
    });
  }

  private getCase(
    caseId: string
  ): ReturnType<Parse.Query<Parse.Object<Parse.Attributes>>["get"]> {
    const caseQuery = new Parse.Query("Case");
    return caseQuery.get(caseId, this.options);
  }

  update(
    caseId: string,
    fieldId: keyof Case,
    element: { id: string }
  ): Promise<void> {
    return this.getCase(caseId).then((c) => {
      const elements: { id: string }[] = c.get(fieldId) || [];
      if (elements === undefined || elements === null) {
        // nothing to remove
        return;
      }
      c.set(
        fieldId,
        elements.map((p) => (p.id === element.id ? element : p))
      );
      return c.save().then();
    });
  }

  remove(
    caseId: string,
    fieldId: keyof Case,
    elementId: string
  ): Promise<void> {
    return this.getCase(caseId).then((c) => {
      const elements: { id: string }[] = c.get(fieldId);
      if (elements === undefined || elements === null) {
        // nothing to remove
        return;
      }
      const newElements = elements.filter(
        (element) => element.id !== elementId
      );
      if (newElements.length === elements.length) {
        // nothing changed
        return;
      }
      c.set(fieldId, newElements);
      return c.save().then();
    });
  }

  addParty(caseId: string, party: Party): Promise<Party[]> {
    const PartyType = Parse.Object.extend("Party");
    const serverParty = new PartyType();
    serverParty.set("name", party.name);

    return this.getCase(caseId).then((c) => {
      const parties: Party[] = c.get("parties") || [];
      const newParties = [...parties, party];
      c.set("parties", newParties);
      return c.save().then(() => newParties);
    });
  }

  updateParty(caseId: string, prototype: Party): Promise<void> {
    return this.update(caseId, "parties", prototype);
  }

  removeParty(caseId: string, partyId: string): Promise<void> {
    return this.remove(caseId, "parties", partyId);
  }

  addClaim(caseId: string, claim: Claim): Promise<Claim[]> {
    return this.add(caseId, "claims", claim);
  }

  updateClaim(caseId: string, claim: Claim): Promise<void> {
    return this.update(caseId, "claims", claim);
  }

  removeClaim(caseId: string, claimId: string): Promise<void> {
    return this.remove(caseId, "claims", claimId);
  }

  addOffer(caseId: string, offer: SettlementOffer): Promise<SettlementOffer[]> {
    return this.add(caseId, "settlements", offer);
  }

  updateOffer(caseId: string, offer: SettlementOffer): Promise<void> {
    return this.update(caseId, "settlements", offer);
  }

  removeOffer(caseId: string, offerId: string): Promise<void> {
    return this.remove(caseId, "settlements", offerId);
  }

  addBudget(caseId: string, budget: Budget): Promise<Budget[]> {
    return this.add(caseId, "budgets", budget);
  }

  updateBudget(caseId: string, budget: Budget): Promise<void> {
    return this.getCase(caseId).then((c) => {
      const budgets: Budget[] = c.get("budgets") || [];
      if (budgets === undefined || budgets === null) {
        // nothing to remove
        return;
      }
      const newBudgets = budgets.map((b) =>
        b.partyId === budget.partyId ? budget : b
      );
      c.set("budgets", newBudgets);
      return c.save().then();
    });
  }

  removeBudget(caseId: string, partyId: string): Promise<void> {
    return this.getCase(caseId).then((c) => {
      const budgets: Budget[] = c.get("budgets");
      if (budgets === undefined || budgets === null) {
        // nothing to remove
        return;
      }
      const newBudgets = budgets.filter((b) => b.partyId !== partyId);
      if (newBudgets.length === budgets.length) {
        // nothing changed
        return;
      }
      c.set("budgets", newBudgets);
      return c.save().then();
    });
  }

  setBargainingVariant(caseId: string, variant: Configuration): Promise<void> {
    return this.getCase(caseId).then((c) => {
      const variants: Variants = c.get("variants") ?? {};
      variants.bargaining = variant;
      console.log(`Saving new bargaining variant`);
      c.set("variants", variants);
      return c.save().then();
    });
  }

  updateCostStructure(caseId: string, structure: CostStructure): Promise<void> {
    return this.getCase(caseId).then((c) => {
      c.set("costStructure", structure);
      return c.save().then();
    });
  }
}

export function useCaseApi() {
  return useContext(CaseApiContext);
}

export const ParseCaseApiProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const sessionToken = getParseSessionToken();
  const parseCaseApi: CaseApi = new ParseCaseClient(sessionToken);
  // make sure budgets are initialized and cleaned-up correctly
  const api: CaseApi = new BudgetInitializingCaseApiDecorator(parseCaseApi);
  return (
    <CaseApiContext.Provider value={api}>{children}</CaseApiContext.Provider>
  );
};

function getParseSessionToken() {
  const sessionToken = Parse.User.current()?.getSessionToken();
  if (sessionToken) {
    // parse client already logged in
    return sessionToken;
  }

  const storedToken = parseAuth.getToken();
  if (storedToken !== undefined) {
    return storedToken;
  }

  console.log("Missing session token for parse server");
  throw new Error("Missing session token for server. User sign-in is needed.");
}
