import Big from "big.js";
import { ZERO } from "../big/bigUtil";
import combineCompareFns from "../utils/combineCompareFns";
import Scenario from "./Scenario";
import { EnforcementPayment, EnforcementPlan } from "./api/Analytics";
import { lessBalanceFirst, morePaymentsFirst } from "./api/JudgmentCompareFns";
import { Cost, CostType, Share, Stage } from "./model/CaseConfiguration";
import { PaymentObligation } from "./payment/PaymentObligation";
import { PaymentPool } from "./payment/PaymentPool";

class ApportionedCosts {
  private readonly costs: { [partyId: string]: Big } = {};

  private readonly generic;

  constructor(
    private readonly total: Big,
    apportionment: Share[]
  ) {
    let sharesTotal = ZERO;
    apportionment.forEach((share) => {
      this.costs[share.partyId] = this.total.mul(
        share.percentage === undefined ? 1 : share.percentage
      );
      sharesTotal = sharesTotal.add(
        share.percentage === undefined ? 1 : share.percentage
      );
    });
    // generic=1-sharesTotal
    this.generic = sharesTotal.gte(1)
      ? ZERO
      : sharesTotal.neg().add(1).mul(total);
  }

  apportionedTowards(partyId: string): Big {
    return this.costs[partyId] || ZERO;
  }

  genericCosts(): Big {
    return this.generic;
  }
}

export class EnforcementCosts {
  // holds cost estimates that have their own apportionment (defined at the cost object)
  private readonly apportionedEstimates: Cost[] = [];

  private readonly courtFees: ApportionedCosts;
  private readonly layerCosts: ApportionedCosts;
  private readonly genericCosts: Big;

  constructor(
    scenario: Scenario,
    private readonly partyId: string
  ) {
    const enforcementEstimates = scenario
      .getCostsPaidBy(partyId)
      .filter(
        (cost) => cost.stage === Stage.Enforcement && cost.incurred !== true
      );

    let courtFeesTotal = ZERO;
    let lawyerCostsTotal = ZERO;
    enforcementEstimates.forEach((estimate) => {
      if (estimate.apportionment && estimate.apportionment.length > 0) {
        // cost-specific apportionment overwrites case's general apportionment
        this.apportionedEstimates.push(estimate);
        return;
      }
      // gather to total, by type
      switch (estimate.type) {
        case CostType.CourtFees:
          courtFeesTotal = courtFeesTotal.add(estimate.amount);
          break;
        case CostType.LawyerCosts:
          lawyerCostsTotal = lawyerCostsTotal.add(estimate.amount);
          break;
      }
    });

    this.courtFees = new ApportionedCosts(
      courtFeesTotal,
      scenario.getGlobalApportionment(CostType.CourtFees, partyId)
        ?.costApportionment || []
    );

    this.layerCosts = new ApportionedCosts(
      lawyerCostsTotal,
      scenario.getGlobalApportionment(CostType.LawyerCosts, partyId)
        ?.costApportionment || []
    );

    this.genericCosts = this.courtFees
      .genericCosts()
      .add(this.layerCosts.genericCosts());
  }

  private apportionedTowards(partyId: string): Big {
    let total = this.courtFees
      .apportionedTowards(partyId)
      .add(this.layerCosts.apportionedTowards(partyId));
    this.apportionedEstimates.forEach((estimate) => {
      // check apportionment
      if (estimate.apportionment?.includes(partyId))
        total = total.add(estimate.amount);
    });
    return total;
  }

  /**
   * @param payments payment plans to analyze
   * @returns enforcement plans with costs of enforcing payments towards party, sorted from worst to best in terms of balance
   */
  getEnforcements<X extends PaymentObligation>(
    payments: PaymentPool<X>
  ): EnforcementPlan[] {
    // we are interested in enforcing items for party
    const paymentsToParty = payments.items.filter(
      (payment) => payment.recipientId === this.partyId
    );

    // find plans with payments of and to party
    const enforcements: EnforcementPlan[] = payments
      .getPaymentPlans()
      .map((plan) => {
        const payments: { [partyId: string]: EnforcementPayment } = {};

        // what others pay towards party
        let paidToPartyTotal = ZERO;
        paymentsToParty.forEach((item) => {
          const paymentToParty = plan.itemPayments.get(item.id);
          if (paymentToParty === undefined) return;
          const paidToParty = paymentToParty.getAmountPaid();
          if (paidToParty.lte(0)) return;

          // there is a payment towards party. Get payers too
          paidToPartyTotal = paidToPartyTotal.add(paidToParty.toNumber());
          paymentToParty.getPayers().forEach((payer) => {
            const value = paymentToParty.getAmountPaidBy(payer)?.toNumber();
            if (value === undefined) return;
            // create new enforcement or add value to existing one
            if (payments[payer] === undefined) {
              payments[payer] = { value, cost: 0 };
            } else {
              payments[payer].value += value;
            }
          });
        });

        // got all payments of plan. get enforcement costs per payer
        let anythingEnforced = false;
        let totalCosts = ZERO;
        for (const [payerId, payment] of Object.entries(payments)) {
          if (payment.value > 0) {
            const paymentCost = this.apportionedTowards(payerId);
            payment.cost = paymentCost.toNumber();
            totalCosts = totalCosts.add(paymentCost);
            anythingEnforced = true;
          }
        }
        if (anythingEnforced) totalCosts = totalCosts.add(this.genericCosts);

        // value of enforcement is amount paid to party minus amount paid by party
        const value = paidToPartyTotal.minus(
          plan.getAmountPaidBy(this.partyId)
        );

        const enforcement: EnforcementPlan = {
          value: value.toNumber(),
          baseCost: anythingEnforced ? this.genericCosts.toNumber() : 0,
          balance: value.minus(totalCosts).toNumber(),
          payments,
        };
        return enforcement;
      });

    enforcements.sort(worseBalanceMorePaymentsFirst);
    return enforcements;
  }
}
const worseBalanceMorePaymentsFirst = combineCompareFns([
  lessBalanceFirst,
  morePaymentsFirst,
]);
