import Big from "big.js";
import { min, ONE, ZERO } from "../../big/bigUtil";
import randomId from "../../utils/randomId";
import { Item } from "./Payment";

export class PaymentObligation implements Item {
  public readonly maximumPaid: Big;

  constructor(
    public readonly id: string,
    public readonly price: Big,
    public readonly maxSharePerPayer: Map<string, Big>,
    public readonly recipientId: string
  ) {
    this.maximumPaid = this.getMaximumPaid();
  }

  public getMaximumPaidBy(payerId: string): Big {
    const shareOfPayer = this.maxSharePerPayer.get(payerId);
    return shareOfPayer === undefined ? ZERO : this.price.mul(shareOfPayer);
  }

  public isPayer(payerId: string): boolean {
    const shareOfPayer = this.maxSharePerPayer.get(payerId);
    return shareOfPayer !== undefined && shareOfPayer.gt(0);
  }

  /**
   * @returns maximum possible price paid, based on the shares of payers (in case share total is below 100%)
   */
  private getMaximumPaid(): Big {
    let shareTotal = ZERO;
    for (const [, shareOfPayer] of this.maxSharePerPayer) {
      shareTotal = shareTotal.add(shareOfPayer);
      if (shareTotal.gte(1)) return this.price;
    }
    return shareTotal.mul(this.price);
  }

  /**
   * Returns a maximum payment of this payment obligation.
   */
  public getMaximumPayment(): PaymentObligation {
    // covers price=0 (making divisions further down safe)
    if (this.maximumPaid.gte(this.price)) return this;

    const sharesOfMaximum = new Map<string, Big>();
    for (const [payerId, shareOfPrice] of this.maxSharePerPayer) {
      if (shareOfPrice !== undefined && shareOfPrice.gt(0)) {
        sharesOfMaximum.set(
          payerId,
          min(ONE, this.price.mul(shareOfPrice).div(this.maximumPaid))
        );
      } else {
        sharesOfMaximum.set(payerId, ZERO);
      }
    }
    return new PaymentObligation(
      this.id,
      this.maximumPaid,
      sharesOfMaximum,
      this.recipientId
    );
  }

  /**
   * Checks two payment obligations for equality.
   *
   * @param other payment obligation to compare to
   * @returns true if this element equals the given other
   */
  equals(other: PaymentObligation): boolean {
    if (other.id !== this.id) {
      return false;
    }
    if (!other.price.eq(this.price)) {
      return false;
    }
    if (other.maxSharePerPayer.size !== this.maxSharePerPayer.size) {
      return false;
    }
    for (const [payerId, maxShare] of this.maxSharePerPayer) {
      if (!other.maxSharePerPayer.get(payerId)?.eq(maxShare)) return false;
    }
    if (!this.hasSameRecipient(other)) {
      return false;
    }
    return true;
  }

  private hasEqualPayers(payers: string[]): boolean {
    let actuallyPayingPayers = 0;
    for (const [payerId, shareOfPayer] of this.maxSharePerPayer) {
      if (shareOfPayer.gt(0)) {
        actuallyPayingPayers++;
        if (!payers.includes(payerId)) return false;
      }
    }
    return payers.length === actuallyPayingPayers;
  }

  private hasSameRecipient(other: PaymentObligation): boolean {
    return this.recipientId === other.recipientId;
  }

  merge(other: PaymentObligation): PaymentObligation | undefined {
    if (this.maximumPaid.eq(0)) return other;
    if (other.maximumPaid.eq(0)) return this;

    if (this.hasSameRecipient(other)) {
      const totalPrice = this.price.add(other.price);
      // calculate proportions of this and other to easily weigh payer shares
      const thisWeight = totalPrice.eq(0) ? 0 : this.price.div(totalPrice);
      const otherWeight = totalPrice.eq(0) ? 0 : other.price.div(totalPrice);

      // add amounts and new shares of payers
      const mergedShares = new Map<string, Big>();
      // start with own shares
      for (const [payerId, shareOfPayer] of this.maxSharePerPayer) {
        const otherShareOfPayer = other.maxSharePerPayer.get(payerId);
        mergedShares.set(
          payerId,
          shareOfPayer
            .mul(thisWeight)
            .add(otherShareOfPayer ? otherShareOfPayer.mul(otherWeight) : ZERO)
        );
      }
      // check if other contains payer shares that this does not
      for (const [payerId, otherShareOfPayer] of other.maxSharePerPayer) {
        if (mergedShares.has(payerId)) continue;
        // share is only in other, weigh it and add to merged shares
        mergedShares.set(payerId, otherShareOfPayer.mul(otherWeight));
      }
      return new PaymentObligation(
        randomId(),
        totalPrice,
        mergedShares,
        this.recipientId
      );
    } else if (
      this.hasEqualPayers([other.recipientId]) &&
      other.hasEqualPayers([this.recipientId])
    ) {
      // payment in opposite direction so payments must be subtracted
      // which one is higher?
      const higher = this.price.gt(other.price) ? this : other;
      const lower = this.price.gt(other.price) ? other : this;

      // subtract lower payment from higher to assure non-negative result
      // payment in exactly opposite direction, subtract
      return new PaymentObligation(
        randomId(),
        higher.price.minus(lower.price),
        // lower payment gets annulled by higher so keep higher's payer shares
        higher.maxSharePerPayer,
        higher.recipientId
      );
    }

    // unrelated payments, cannot merge
    return undefined;
  }
}

export function merge(items: PaymentObligation[], newItem: PaymentObligation) {
  if (newItem.maxSharePerPayer.size === 0) return;
  for (let index = 0; index < items.length; index++) {
    const merged = items[index].merge(newItem);
    if (merged) {
      // replace
      items[index] = merged;
      // no need to add new item to items, it has already been merged into it
      return;
    }
  }
  // not merged with any existing item, so add at end
  items.push(newItem);
}
