import { SxProps, TextField, Theme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Currency } from "../../i18n/Currency";

interface State {
  rawValue: string;
}

export const CurrencyTextField = ({
  currency,
  value,
  label,
  onValueChange,
  required = false,
  error = false,
  variant = "standard",
  min,
  max,
  size = "medium",
  sx,
  disabled = false,
}: {
  currency: Currency;
  label?: string;
  value?: number;
  onValueChange: (newValue: number) => void;
  required?: boolean;
  error?: boolean;
  min?: number;
  max?: number;
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  disabled?: boolean;
}) => {
  const [values, setValues] = useState<State>({
    rawValue: value !== undefined ? value.toString() : "",
  });
  // use memoization to avoid re-creation of the numeric format on every prop/state change
  const format = useMemo(
    () => currency.createNumericFormat(min, max),
    [min, max]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (
      event.target.value === undefined ||
      event.target.value.trim().length === 0
    ) {
      onValueChange(0);
      return;
    }
    onValueChange(Number.parseFloat(event.target.value));
  };

  return (
    <TextField
      size={size}
      label={label}
      error={error}
      value={values.rawValue}
      onChange={handleChange}
      name="rawValue"
      id={`currencyInput_${label}`}
      sx={sx}
      disabled={disabled}
      InputProps={{
        inputComponent: format as any,

        // default implementation makes the field annoying to edit if it has 0.00 as value
        // if the cursor lands before the leading 0, entering e.g. a "3" results in "30" as value. Thus the 0 has to be deleted separately
        // if the cursor is placed just after the leading 0 and before the decimal separator ".", typing a number works fine but the leading zero is kept until the field is left
        // thus to facility ease of use, when landing focusing a field with 0.00 value, the value of the field is cleared and users can immediately type in numbers
        onFocus: (e) => {
          if (e.target?.selectionStart !== null) {
            const currentValue = Number.parseFloat(values.rawValue);
            // if current value is not a number then we are at end of textfield, but there is nothing in it
            // user is  not entering a decimal, don't interfere
            if (currentValue === undefined || Number.isNaN(currentValue))
              return;
            // only interfere if 0 is in field
            if (currentValue !== 0) return;
            // clear field
            setValues({ rawValue: "" });
          }
        },

        // default implementation prevents input of decimal numbers at the end of the textfield
        // e.g. given "22.56" as current number, one can't click behind the 6 and input anything
        // what we want is that if a decimal is input at end of textfield, decimals on the left are shifted
        // given "22.56", if one inputs a 3 right of the 6, the result should be "22.63" (6 moves up a place, 3 replaces it)
        onKeyDown: (e) => {
          // are we at the end of the textfield?
          if (
            e.currentTarget.selectionStart !== null &&
            e.currentTarget.selectionStart >= e.currentTarget.value.length
          ) {
            // is a number entered?
            const keyAsInt = Number.parseInt(e.key);
            if (keyAsInt === undefined || Number.isNaN(keyAsInt)) return;
            // number is entered, now check if a decimal is entered at end of current value of textfield
            const currentValue = Number.parseFloat(values.rawValue);
            // if current value is not a number then we are at end of textfield, but there is nothing in it
            // user is  not entering a decimal, don't interfere
            if (currentValue === undefined || Number.isNaN(currentValue))
              return;

            // shift current value by one decimal and append entered key as second decimal
            const currentInteger = Math.floor(currentValue);
            const currentDecimal = currentValue - currentInteger;
            // shift by * 10
            const shiftedDecimal =
              currentDecimal * 10 - Math.floor(currentDecimal * 10);
            const newValue = currentInteger + shiftedDecimal + keyAsInt / 100;
            setValues({ rawValue: newValue.toString() });
            onValueChange(newValue);
          }
        },
      }}
      variant={variant}
      required={required}
    />
  );
};
