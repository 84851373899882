import { Issue, IssueGroup, isGroup } from "./IssueModel";

export class IssuePointer {
  constructor(
    private readonly issues: (Issue | IssueGroup)[],
    private readonly primaryIndex: number,
    private readonly secondaryIndex?: number
  ) {}

  get(): Issue | undefined {
    if (this.primaryIndex >= this.issues.length) return;
    const primary = this.issues[this.primaryIndex];

    if (!isGroup(primary))
      return this.secondaryIndex === undefined ? primary : undefined;

    // group
    if (this.secondaryIndex === undefined) return undefined;
    if (this.secondaryIndex >= primary.subIssues.length) return;
    return primary.subIssues[this.secondaryIndex];
  }

  getGroup: () => IssueGroup | undefined = () => {
    const group = this.issues[this.primaryIndex];
    return isGroup(group) ? group : undefined;
  };

  set(issue: Issue): boolean {
    if (this.primaryIndex > this.issues.length - 1) return false;
    if (this.secondaryIndex === undefined) {
      this.issues[this.primaryIndex] = issue;
      return true;
    }

    const primary = this.issues[this.primaryIndex];
    if (!isGroup(primary)) return false;

    if (this.secondaryIndex > primary.subIssues.length - 1) return false;

    primary.subIssues[this.secondaryIndex] = issue;
    return true;
  }

  previous(): IssuePointer | undefined {
    if (this.secondaryIndex !== undefined) {
      if (this.secondaryIndex > 0)
        // stay in group, move up
        return new IssuePointer(
          this.issues,
          this.primaryIndex,
          this.secondaryIndex - 1
        );
    }
    if (this.primaryIndex === 0) return undefined;
    const previousPrimary = this.primaryIndex - 1;
    const primary = this.issues[previousPrimary];
    return isGroup(primary)
      ? new IssuePointer(
          this.issues,
          previousPrimary,
          primary.subIssues.length - 1
        )
      : new IssuePointer(this.issues, previousPrimary);
  }

  next(): IssuePointer | undefined {
    if (this.secondaryIndex !== undefined) {
      const primary = this.issues[this.primaryIndex];

      if (!isGroup(primary))
        // something went wrong, we should not have a secondary index
        return undefined;
      // is current pointer at the end of group?
      if (this.secondaryIndex < primary.subIssues.length - 1)
        // group's end not reached
        return new IssuePointer(
          this.issues,
          this.primaryIndex,
          this.secondaryIndex + 1
        );
    }
    // either no secondary index or end of group reached. increase primary index
    if (this.primaryIndex === this.issues.length - 1) return undefined;
    const nextPrimary = this.primaryIndex + 1;
    const primary = this.issues[nextPrimary];
    if (!isGroup(primary)) return new IssuePointer(this.issues, nextPrimary);
    return primary.subIssues.length > 0
      ? // first element of next group
        new IssuePointer(this.issues, nextPrimary, 0)
      : // no sub-issues
        undefined;
  }

  toString(): string {
    return `IssuePointer[primaryIndex=${this.primaryIndex},secondaryIndex='${this.secondaryIndex}']`;
  }
}

export function locate(
  issues: (Issue | IssueGroup)[],
  issueId: string
): IssuePointer | undefined {
  for (let primaryIndex = 0; primaryIndex < issues.length; primaryIndex++) {
    const issue = issues[primaryIndex];
    if (isGroup(issue)) {
      for (
        let secondaryIndex = 0;
        secondaryIndex < issue.subIssues.length;
        secondaryIndex++
      ) {
        const subIssue = issue.subIssues[secondaryIndex];
        if (subIssue.id === issueId)
          return new IssuePointer(issues, primaryIndex, secondaryIndex);
      }
    }
    if (issue.id === issueId) return new IssuePointer(issues, primaryIndex);
  }
}
