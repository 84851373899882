import { Box, Slider, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useCurrency } from "../../context/CurrencyContext";
import { RecoveryType } from "../../model/CaseModel";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "../issues/Claims";

export interface CostRecoveryCallbacks {
  onPercentageUpdate(newPercentage: number): void;
}

export const CostRecoveryDetails = ({
  recovery,
  recoveryCallbacks,
  costsTotal,
}: {
  recovery: {
    type: RecoveryType;
    percentage: number;
  };
  recoveryCallbacks: CostRecoveryCallbacks;
  costsTotal: number;
}) => {
  const [sliderValue, setSliderValue] = useState(recovery.percentage * 100);
  const currency = useCurrency();
  const theme = useTheme();
  return (
    <Box
      mt={theme.spacing(2)}
      mb={theme.spacing(4)}
      sx={{
        userSelect: "none",
      }}
    >
      <Typography variant="overline">
        <Trans
          i18nKey={
            "case.recoveries.details.type." + RecoveryType[recovery.type]
          }
        />
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <TwoLiner
          primary={percentage.format(sliderValue / 100)}
          secondary={t("case.recoveries.details.percentage")}
        />
        <Slider
          sx={{
            //  make sure value label does not stick out too much
            mt: theme.spacing(2),
            ml: theme.spacing(2),
            mb: theme.spacing(2),
            minWidth: 150,
            maxWidth: 200,
          }}
          color="primary"
          size="small"
          value={sliderValue}
          aria-label="Small"
          valueLabelFormat={(value) => percentage.format(value / 100)}
          valueLabelDisplay="auto"
          onChangeCommitted={(ignored, value) => {
            if (typeof value !== "number") return;
            setSliderValue(value);
            recoveryCallbacks.onPercentageUpdate(value / 100);
          }}
          onChange={(ignored, value) => {
            if (typeof value !== "number") return;
            setSliderValue(value);
          }}
        />
      </Box>
      <Stack direction="row" spacing={4}>
        <TwoLiner
          primary={currency.format(costsTotal)}
          secondary={t(
            "case.recoveries.details.total." + RecoveryType[recovery.type]
          )}
        />
        <TwoLiner
          primary={currency.format((costsTotal * sliderValue) / 100)}
          secondary={t("case.recoveries.details.result")}
        />
      </Stack>
    </Box>
  );
};
