import Big from "big.js";
import { ONE, ZERO } from "../../big/bigUtil";
import { IssueRelation } from "../../model/CaseModel";
import { LiabilityScenario, Relation } from "../model/CaseConfiguration";

export interface Issue {
  id: string;
  relation?: Relation | IssueRelation;
  subIssues?: Issue[];
}

export function getPossibleScenarios(
  issues: Issue[],
  getWinChance: (issueId: string) => number
): LiabilityScenario[] {
  const scenarios = new Or(issues.map((i) => createSet(i, getWinChance)));
  return scenarios.getLosses().concat(scenarios.getWins());
}

interface ScenarioSet {
  getWins: () => LiabilityScenario[];
  getLosses: () => LiabilityScenario[];
}

function createSet(
  issue: Issue,
  getWinChance: (issueId: string) => number
): ScenarioSet {
  if (issue.subIssues === undefined || issue.subIssues.length === 0)
    return new LeafIssue(issue.id, getWinChance(issue.id));

  const subIssues = issue.subIssues.map((subIssue) =>
    createSet(subIssue, getWinChance)
  );

  switch (issue.relation) {
    case IssueRelation.And:
    case Relation.And: {
      return new And(subIssues, issue.id);
    }
    case IssueRelation.Or:
    case Relation.Or: {
      return new Or(subIssues, issue.id);
    }
    case IssueRelation.Xor:
    case Relation.Xor: {
      return new Xor(subIssues, issue.id);
    }
  }

  // assume AND in old cases if no relation is set
  return new And(subIssues, issue.id);
}

class LeafIssue implements ScenarioSet {
  private readonly wins: LiabilityScenario[] = [];
  private readonly losses: LiabilityScenario[] = [];
  constructor(
    readonly issueId: string,
    winChance: number
  ) {
    if (winChance >= 1) {
      this.wins.push({ issuesWon: [issueId], probability: ONE });
      return;
    }
    if (winChance <= 0) {
      this.losses.push({ issuesLost: [issueId], probability: ONE });
      return;
    }
    this.wins.push({ issuesWon: [issueId], probability: new Big(winChance) });
    this.losses.push({
      issuesLost: [issueId],
      probability: ONE.minus(winChance),
    });
  }

  getWins() {
    return this.wins;
  }

  getLosses() {
    return this.losses;
  }
}

class And implements ScenarioSet {
  private wins: LiabilityScenario[] = [];
  private losses: LiabilityScenario[] = [];

  constructor(
    readonly issues: ScenarioSet[],
    readonly issueId?: string
  ) {
    // combine win scenarios of issues
    if (issues.length === 0) {
      return;
    }

    this.wins = issues[0].getWins();
    this.losses = issues[0].getLosses();
    let certainLoss = this.wins.length === 0;

    for (let i = 1; i < issues.length; i++) {
      const issue = issues[i];
      const issueWins = issue.getWins();
      const issueLosses = issue.getLosses();

      // in AND all must be won so combine previous wins with new wins
      // if this.wins is empty, combine will return an empty array
      // any issue with no wins must make the whole unwinnable
      if (issueWins.length === 0) {
        certainLoss = true;
        // since issueWins is empty, issueLosses is not empty
        this.losses = combine(issueLosses, this.wins.concat(this.losses));
        this.wins = [];
      } else {
        // in AND, combining issue's losses with any previous scenarios produces new losses
        // combine previous losses with new wins
        // combine all previous scenarios with new losses
        this.losses = combine(this.losses, issueWins).concat(
          combine(issueLosses, this.wins.concat(this.losses))
        );
        if (!certainLoss) this.wins = combine(this.wins, issueWins);
      }
    }

    if (issueId !== undefined) {
      this.wins.forEach((win) => win.issuesWon?.push(issueId));
      this.losses.forEach((loss) => loss.issuesLost?.push(issueId));
    }
  }

  getWins() {
    return this.wins;
  }

  getLosses() {
    return this.losses;
  }
}

class Or implements ScenarioSet {
  private wins: LiabilityScenario[] = [];
  private losses: LiabilityScenario[] = [];

  constructor(
    readonly issues: ScenarioSet[],
    readonly issueId?: string
  ) {
    // combine win scenarios of issues
    if (issues.length === 0) {
      return;
    }

    let certainWin = false;
    issues.forEach((issue) => {
      const issueLosses = issue.getLosses();
      if (issueLosses.length === 0) {
        certainWin = true;
        // in OR, combining this issue's wins with any previous scenarios produces new wins
        this.wins = combine(issue.getWins(), this.wins.concat(this.losses));
        // issue is not losable, so the whole OR cannot be lost
        this.losses = [];
        return;
      }

      // combine all previous scenarios with new wins, producing new wins
      // also combine previous wins with new losses. In OR those are still wins
      this.wins = combine(this.wins, issueLosses).concat(
        combine(
          // new wins
          issue.getWins(),
          // previous wins + previous losses
          this.wins.concat(this.losses)
        )
      );

      // to lose OR all issues must be lost
      if (!certainWin)
        this.losses =
          this.losses.length > 0
            ? combine(this.losses, issueLosses)
            : issueLosses;
    });

    if (issueId !== undefined) {
      this.wins.forEach((win) => win.issuesWon?.push(issueId));
      this.losses.forEach((loss) => loss.issuesLost?.push(issueId));
    }
  }

  getWins() {
    return this.wins;
  }

  getLosses() {
    return this.losses;
  }
}

export function getProbability(scenarios: LiabilityScenario[]): Big {
  return scenarios
    .map((s) => s.probability)
    .reduce((previous, current) => previous.add(current), ZERO);
}

class Xor implements ScenarioSet {
  private wins: LiabilityScenario[] = [];
  private losses: LiabilityScenario[] = [];

  constructor(
    readonly issues: ScenarioSet[],
    readonly issueId?: string
  ) {
    // combine win scenarios of issues
    if (issues.length === 0) {
      return;
    }

    let allLosses: LiabilityScenario[] = [];
    let totalWinChance = ZERO;
    issues.forEach((issue) => {
      const issueLosses = issue.getLosses();
      const issueWins = issue.getWins();
      const issueWinChance = getProbability(issueWins);

      // XOR wins are wins of each issue combined with losses of other issues
      // already existing wins need to be combined with losses of issue
      // win of issue needs to be combined with losses of all previous issues
      this.wins = combine(this.wins, issueLosses, true).concat(
        combine(issueWins, allLosses, true)
      );
      // store all losses
      allLosses =
        allLosses.length > 0 ? combine(allLosses, issueLosses) : issueLosses;

      totalWinChance = totalWinChance.add(issueWinChance);
    });

    if (totalWinChance.gt(1)) {
      // illegal XOR
      this.wins = [];
      this.losses = [];
      return;
    }

    if (issueId !== undefined) {
      this.wins.forEach((win) => win.issuesWon?.push(issueId));
      if (totalWinChance.lt(1)) {
        const lossProbability = totalWinChance.neg().add(1);
        this.losses = combine(
          [{ issuesLost: [issueId], probability: lossProbability }],
          allLosses,
          true
        );
      }
    }
  }

  getWins() {
    return this.wins;
  }

  getLosses() {
    return this.losses;
  }
}

function combine(
  existing: LiabilityScenario[],
  toAdd: LiabilityScenario[],
  keepProbability?: boolean
): LiabilityScenario[] {
  if (toAdd.length === 0) return [...existing];

  const result: LiabilityScenario[] = [];
  existing.forEach((sourceScenario) => {
    toAdd.forEach((scenario) => {
      const mergeResult = merge(sourceScenario, scenario, keepProbability);
      if (mergeResult) result.push(mergeResult);
    });
  });
  return result;
}

function merge(
  scenario1: LiabilityScenario,
  scenario2: LiabilityScenario,
  keepProbability?: boolean
): LiabilityScenario | undefined {
  // check for inconsistencies
  if (
    anyDuplicates(scenario1.issuesWon, scenario2.issuesLost) ||
    anyDuplicates(scenario2.issuesWon, scenario1.issuesLost)
  )
    // this may happen if an issue is reused in multiple claims.
    // only scenarios with consistent outcomes of each issue can be combined
    // inconsistent scenarios get discarded
    return undefined;
  return {
    issuesWon: concat(scenario1.issuesWon, scenario2.issuesWon),
    issuesLost: concat(scenario1.issuesLost, scenario2.issuesLost),
    probability: keepProbability
      ? scenario1.probability
      : scenario1.probability.mul(scenario2.probability),
  };
}

function concat(a1?: string[], a2?: string[]): string[] {
  if (a1) {
    if (a2 === undefined) return [...a1];

    const noDuplicates = new Set(a1);
    a2.forEach((a) => noDuplicates.add(a));
    return [...noDuplicates];
  }

  return a2 ? [...a2] : [];
}

function anyDuplicates(a1?: string[], a2?: string[]): boolean {
  return a1 !== undefined && a2 !== undefined && a1.some((a) => a2.includes(a));
}
