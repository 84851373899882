export enum Relation {
  And,
  Or,
  Xor,
}

/**
 * Base case types
 */
export interface Party {
  readonly id: string;
  name: string;
}

export interface Issue {
  readonly id: string;
  title: string;
}

export interface LiabilityIssue extends Issue {
  defendants?: string[];
  subIssues?: LiabilityIssue[];
  relation?: Relation;
  setOff?: boolean;
}

export interface Claim extends LiabilityIssue {
  claimant: string;
}

export interface QuantumIssue extends Issue {
  claimant: string;
  liabilities: string[];
  value: number;
}

export interface Case {
  id: string;
  title: string;
  claimType?: string;
  parties?: Party[];
  liabilityIssues?: Claim[];
  quantumIssues?: QuantumIssue[];
}

/**
 * Scenario types
 */
export interface Cost {
  costId: string;
  type: CostType;
  incurred?: boolean;
  amount: number;
  payerId: string;
  stage: Stage;
  date?: Date;
  title?: string;
  // cost-specific apportionment overwrites global apportionment of case configuration
  // cost is split equally among apportioned parties
  apportionment?: string[];
}

export enum CostType {
  CourtFees,
  LawyerCosts,
}

export interface AssetLimit {
  // who does this limit apply to
  partyId: string;
  // monetary value with probability of this asset to have the value at time of judgement
  amount: LikelyValue;
  // comment
  note?: string;
}

export interface QuantumAmount {
  // what quantum issue?
  quantumId: string;
  // refines when this amount applies
  when?: IssueScenario;
  // what is the amount of the quantum for the liability issue
  amount: number;
  // amount may differ per defendant if the issue has multiple liable defendants (e.g. a generic issue)
  perDefendant?: { [defendantId: string]: number };
}

export interface IssueConfiguration {
  liabilityId: string;
  winChance: number;
}

export interface IssueScenario {
  issuesLost?: string[];
  issuesWon?: string[];
}

export interface LiabilityScenario extends IssueScenario {
  readonly probability: Big;
}

export interface Share {
  partyId: string;
  percentage?: number; // 0.0-1.0
}

export interface CostApportionment {
  // payer
  payerId: string;
  // costs apportioned towards other parties. If shares do not add up to 1, the rest is generic cost
  costApportionment: Share[];
  // depending on whether court fees are apportioned or not, we may need this
  costGroup?: CostGroups;
}

export interface Recovery {
  recipient: string;
  type: CostGroups;
  percentage: number;
  // payer id mapped to % share of payer in this recovery's payment (applied to amount remaining after recovery percentage has been applied!)
  payers?: Share[];
  when?: IssueScenario;
  recoveredCosts?: string[];
  description?: string;
}

export enum CostGroups {
  CourtFees,
  LawyerCosts,
  CourtFeesAndLawyerCosts,
}

export enum Stage {
  PreAction,
  IssueStatementsOfCase,
  CMC,
  Disclosure,
  WitnessStatements,
  ExpertReports,
  PTR,
  TrialPreparation,
  Trial,
  Appeals,
  Enforcement,
}

export interface CaseVariant {
  stages: Stage[];
  costs?: Cost[];
  // how much are parties spending towards other parties?
  costApportionments?: CostApportionment[];
  costRecoveries?: Recovery[];
  assetLimits?: AssetLimit[];
  liabilityWinChances?: IssueConfiguration[];
  quantumAmounts?: QuantumAmount[];
}

export const currency: Intl.NumberFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 2,
});

export function equalShares(share1: Share, share2: Share) {
  if (share1 === share2) {
    return true;
  }
  if (share1 === undefined || share2 === undefined) {
    return false;
  }
  return (
    share1.partyId === share2.partyId && share1.percentage === share2.percentage
  );
}

/**
 * Enables the definition of a value as an aggregate of multiple values with a probability.
 * - A flat value is expressed as a LikelyValue with probability of 1 (which is the default, so the field can be left undefined)
 * - Use 'or' to build a chain of several likely values.
 */
export interface LikelyValue {
  readonly value: number;

  /**
   * Probability expressed as decimal [0..1]. 1 (100%) is assumed if undefined.
   */
  readonly probability?: number;

  /**
   * Possible other value.
   */
  readonly or?: LikelyValue;
}
