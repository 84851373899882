import Big from "big.js";

export type WeightedValue = {
  value: number;
  weight: number;
};

export const ZERO = Big(0);

export function getValue(values: WeightedValue[]): Big {
  if (values.length === 0) return ZERO;
  let totalWeight = ZERO;
  let sum = ZERO;
  values.forEach(({ value, weight }) => {
    totalWeight = totalWeight.add(weight);
    sum = sum.add(new Big(value).mul(weight));
  });
  return totalWeight.gt(0) ? sum.div(totalWeight) : ZERO;
}
