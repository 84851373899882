import Big from "big.js";
import { ZERO } from "../big/bigUtil";
import {
  getDefendantIds,
  getLiabilityIssue,
  getParty,
  getQuantumIssuesOfLiability,
} from "./CaseConfigurationUtility";
import IssueNode from "./IssueNode";
import { avg } from "./Value";
import {
  AssetLimit,
  Case,
  CaseVariant,
  Cost,
  CostApportionment,
  CostGroups,
  CostType,
  LiabilityIssue,
  Party,
  QuantumIssue,
  Recovery,
  Share,
  Stage,
} from "./model/CaseConfiguration";

/**
 * Represents a possible scenario of a case, as a combination of a Case and its CaseVariant.
 */
export default class Scenario {
  // IDs of leaf liability issues
  public readonly leafIssueIds: string[];

  private readonly allLiabilities = new Map<string, LiabilityIssue>();

  constructor(
    readonly c: Case,
    readonly variant: CaseVariant
  ) {
    if (c.liabilityIssues)
      this.fillLiabilitiesMap(c.liabilityIssues, this.allLiabilities);

    const leafs = new Set<string>();
    for (const [, issue] of this.allLiabilities) {
      if (issue.subIssues === undefined || issue.subIssues.length === 0)
        // issue is leaf
        leafs.add(issue.id);
    }
    this.leafIssueIds = [...leafs];
  }

  getTitle(): string {
    return this.c.title;
  }

  getCaseStages(): Stage[] {
    return this.variant.stages;
  }

  getCosts(): Cost[] {
    return this.variant.costs || [];
  }

  getCost(costId: string): Cost | undefined {
    return this.getCosts().find((cost) => cost.costId === costId);
  }

  getCostsPaidBy(partyId: string): Cost[] {
    return this.getCosts().filter((cost) => cost.payerId === partyId);
  }

  getAssetLimits(): AssetLimit[] {
    return this.variant.assetLimits || [];
  }

  getRecoveries(): Recovery[] {
    return this.variant.costRecoveries || [];
  }

  private fillLiabilitiesMap(
    issues: LiabilityIssue[],
    map: Map<string, LiabilityIssue>
  ) {
    issues.forEach((issue) => {
      map.set(issue.id, issue);
      if (issue.subIssues) this.fillLiabilitiesMap(issue.subIssues, map);
    });
  }

  getAssetLimitOfParty(partyId: string): Big | undefined {
    let limit = ZERO;
    let anyLimit = false;
    this.getAssetLimits().forEach((assetLimit) => {
      if (assetLimit.partyId !== partyId) return;
      anyLimit = true;
      limit = limit.add(avg(assetLimit.amount));
    });
    return anyLimit ? limit : undefined;
  }

  getParty(partyId: string): Party | undefined {
    return getParty(this.c, partyId);
  }

  getParties(): Party[] {
    return this.c.parties || [];
  }

  getLiabilityIssues(): LiabilityIssue[] {
    return this.c.liabilityIssues || [];
  }

  getLiabilityIssue(id: string): LiabilityIssue | undefined {
    return this.allLiabilities.get(id);
  }

  /**
   * Returns liability issues in which a party is the claimant.
   *
   * @param partyId party identifier
   * @returns identifiers of the liability issues
   */
  getLiabilityIssuesClaimedBy(partyId: string): string[] {
    const liabilityIssueIds = new Set<string>();
    this.c.quantumIssues
      ?.filter((quantumIssue) => quantumIssue.claimant === partyId)
      .forEach((issue) =>
        issue.liabilities.forEach((liabilityId) =>
          liabilityIssueIds.add(liabilityId)
        )
      );
    return [...liabilityIssueIds];
  }

  /**
   * Returns liability issues in which a party is the defendant.
   *
   * @param partyId party identifier
   * @returns identifiers of the liability issues
   */
  getLiabilityIssuesDefendedBy(partyId: string): string[] {
    const liabilityIssueIds = new Set<string>();

    this.c.quantumIssues
      ?.filter((quantumIssue) =>
        // get issues where party is the defendant
        getDefendantIds(this.c, quantumIssue.id).includes(partyId)
      )
      .forEach((issue) =>
        // add to set
        issue.liabilities.forEach((liabilityId) =>
          liabilityIssueIds.add(liabilityId)
        )
      );
    return [...liabilityIssueIds];
  }

  getQuantumIssue(quantumIssueId: string): QuantumIssue | undefined {
    return this.c.quantumIssues?.find((issue) => issue.id === quantumIssueId);
  }

  getQuantumIssuesOfLiability(liabilityIssueId: string): QuantumIssue[] {
    return getQuantumIssuesOfLiability(this.c, liabilityIssueId);
  }

  removeParty(partyId: string): Scenario {
    const caseCopy: Case = JSON.parse(JSON.stringify(this.c));
    const configurationCopy: CaseVariant = JSON.parse(
      JSON.stringify(this.variant)
    );

    // remove from parties
    caseCopy.parties = caseCopy.parties?.filter(
      (party) => party.id !== partyId
    );

    // remove party from liability issues of party
    const issuesWithNoDefendants: Set<LiabilityIssue> = new Set();
    if (caseCopy.liabilityIssues) {
      caseCopy.liabilityIssues.forEach((rootIssue) => {
        removePartyRecursively(rootIssue, partyId);
        // remove issues without defendants (i.e. issues that were exclusive to party)
        const rootLiabilityNode = new IssueNode(rootIssue, () => false);

        rootLiabilityNode.accept((liability) => {
          if (liability.getDefendants().length < 1)
            issuesWithNoDefendants.add(liability.issue);
        });
      });
      caseCopy.liabilityIssues = filterRecursively(
        caseCopy.liabilityIssues,
        (issue) => !issuesWithNoDefendants.has(issue)
      );
    }

    // remove quantums of party
    caseCopy.quantumIssues = caseCopy.quantumIssues?.filter(
      (issue) => issue.claimant !== partyId
    );

    // remove costs
    const newCosts: Cost[] = [];
    configurationCopy.costs?.forEach((cost) => {
      const newCost = this.removePartyFromCost(cost, partyId);
      if (newCost !== undefined) newCosts.push(newCost);
    });
    configurationCopy.costs = newCosts;
    // rescale global cost apportionment
    this.removePartyFromGlobalCostApportionment(configurationCopy, partyId);

    // clean-up global apportionments
    if (configurationCopy.costApportionments) {
      // remove apportionments of party
      configurationCopy.costApportionments =
        configurationCopy.costApportionments.filter(
          (apportionment) => apportionment.payerId !== partyId
        );
      // remove party's shares from apportionments of other parties
      configurationCopy.costApportionments.forEach((apportionment) => {
        apportionment.costApportionment =
          apportionment.costApportionment.filter(
            (share) => share.partyId !== partyId
          );
      });
    }

    // remove liability probabilities without corresponding issue
    configurationCopy.liabilityWinChances =
      configurationCopy.liabilityWinChances?.filter(
        (liabilityProbability) =>
          getLiabilityIssue(caseCopy, liabilityProbability.liabilityId) !==
          undefined
      );

    // 1. remove cost recoveries with party as recipient or only payer
    // 2. remove party from remaining recoveries
    if (configurationCopy.costRecoveries) {
      const recoveriesWithoutParty: Recovery[] = [];
      configurationCopy.costRecoveries?.forEach((recovery) => {
        if (recovery.recipient === partyId) return;
        if (recovery.payers?.find((payer) => payer.partyId === partyId)) {
          if (recovery.payers.length === 1)
            // party is only payer, get rid of recovery
            return;
          // remove party from payers
          recovery.payers = recovery.payers.filter(
            (payer) => payer.partyId !== partyId
          );
        }
        recoveriesWithoutParty.push(recovery);
      });
      configurationCopy.costRecoveries = recoveriesWithoutParty;
    }

    // remove asset limits
    configurationCopy.assetLimits = configurationCopy.assetLimits?.filter(
      (assetLimit) => assetLimit.partyId !== partyId
    );
    return new Scenario(caseCopy, configurationCopy);
  }
  removePartyFromGlobalCostApportionment(
    variant: CaseVariant,
    partyId: string
  ) {
    // remove party as payer
    variant.costApportionments = variant.costApportionments?.filter(
      (apportionment) => apportionment.payerId !== partyId
    );
    // rescale shares paid by party
    variant.costApportionments?.forEach((apportionment) => {
      let totalShares = ZERO;
      let foundParty = false;
      apportionment.costApportionment = apportionment.costApportionment.filter(
        (share) => {
          if (share.partyId === partyId) {
            foundParty = true;
            // filter out
            return false;
          }
          // sum others' shares
          totalShares = totalShares.add(share.percentage ?? 1);
          // retain share in array
          return true;
        }
      );
      if (foundParty) {
        // scale shares of other to new total
        apportionment.costApportionment = apportionment.costApportionment.map(
          (share) => ({
            ...share,
            percentage: new Big(share.percentage ?? 1)
              .div(totalShares)
              .toNumber(),
          })
        );
      }
    });
  }

  removePartyFromCost(cost: Cost, partyId: string): Cost | undefined {
    // check if party is paying
    if (cost.payerId === partyId) {
      // returning undefined means, cost should be removed
      // nobody else to pay for the cost, remove it
      return;
    }

    if (cost.apportionment) {
      // apportionment is configured, check what is allocated towards party
      if (!cost.apportionment.includes(partyId)) return cost;
      // apportionment includes party
      // if cost is apportioned exclusively, remove it
      if (cost.apportionment.length === 1) return;
      // cost is apportioned towards multiple parties, save new apportionment

      return {
        ...cost,
        apportionment: cost.apportionment.filter((p) => p !== partyId),
      };
    }

    // check global apportionment of cost's payer towards party. Check if an amount of cost apportioned towards party should be subtracted
    const payerApportionment = this.getGlobalApportionment(
      cost.type,
      cost.payerId
    );
    if (payerApportionment === undefined) return cost;

    // return apportionment
    const apportionmentTowardsParty = this.sumSharesOfParty(
      payerApportionment.costApportionment,
      partyId
    );

    // if cost is not apportioned towards party, do nothing
    if (apportionmentTowardsParty.lte(0)) return cost;
    // if 100% of cost are apportioned towards party, remove cost completely
    if (apportionmentTowardsParty.gte(1)) return undefined;
    // reduce cost by amount apportioned towards party
    return {
      ...cost,
      // amount = cost - (share * cost)
      amount: apportionmentTowardsParty
        .mul(cost.amount)
        .neg()
        .add(cost.amount)
        .toNumber(),
    };
  }

  private sumSharesOfParty(shares: Share[], partyId: string): Big {
    let sharesOfParty = ZERO;
    shares.forEach((share) => {
      if (share.partyId === partyId) {
        sharesOfParty = sharesOfParty.add(share.percentage ?? 1);
      }
    });
    return sharesOfParty;
  }

  /**
   * @param costType type of costs for which to get the payer's global apportionment
   * @param payerId of which perspective the apportionment is returned
   * @returns cost shares as allocated towards each party configured globally or undefined if no apportionment is configured
   */
  getGlobalApportionment(
    costType: CostType,
    payerId: string
  ): CostApportionment | undefined {
    // global case apportionment is second-best
    // make sure to get apportionment matching cost type
    let foundApportionment: CostApportionment | undefined;

    this.variant.costApportionments?.forEach((apportionment) => {
      if (apportionment.payerId !== payerId)
        // not for this party, ignore
        return;
      if (
        costType === CostType.CourtFees &&
        apportionment.costGroup === CostGroups.LawyerCosts
      )
        // not matching
        return;
      if (
        costType === CostType.LawyerCosts &&
        apportionment.costGroup === CostGroups.CourtFees
      )
        // not matching
        return;

      // is apportionment more specific?
      if (
        foundApportionment === undefined ||
        (foundApportionment.costGroup !== CostGroups.CourtFees &&
          foundApportionment.costGroup !== CostGroups.LawyerCosts)
      ) {
        // foundApportionment was not more specific
        foundApportionment = apportionment;
      }
    });
    return foundApportionment;
  }

  getOpponentsOf(partyId: string) {
    const opponents: Set<string> = new Set();
    this.c.quantumIssues?.forEach((quantumIssue) => {
      const defendants = getDefendantIds(this.c, quantumIssue.id);
      if (quantumIssue.claimant === partyId) {
        defendants.forEach((defendantId) => opponents.add(defendantId));
      } else if (defendants.includes(partyId))
        opponents.add(quantumIssue.claimant);
    });
    opponents.delete(partyId);
    return [...opponents];
  }
}

/**
 * Removes a party from the defendants array of an issue and its sub-issues (recursively)
 */
function removePartyRecursively(issue: LiabilityIssue, partyId: string) {
  issue.subIssues?.forEach((subIssue) =>
    removePartyRecursively(subIssue, partyId)
  );
  issue.defendants = issue.defendants?.filter(
    (defendantId) => defendantId !== partyId
  );
}

function filterRecursively<I extends LiabilityIssue>(
  issues: I[],
  predicate: (issue: LiabilityIssue) => boolean
): I[] {
  const newIssues: I[] = [];
  issues.forEach((issue) => {
    if (!predicate(issue)) return;
    newIssues.push(issue);
    if (issue.subIssues)
      issue.subIssues = filterRecursively<LiabilityIssue>(
        issue.subIssues,
        predicate
      );
  });
  return newIssues;
}
