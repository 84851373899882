import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/PersonSearch";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useCase } from "../../context/CaseContext";
import { Currency, gbp } from "../../i18n/Currency";
import { CostCopyDialog } from "./CostCopyDialog";
import {
  CostsPerStage,
  EditableCostsPerStageTable,
} from "./EditableCostsPerStageTable";

export const EditableCostsPerStage = ({
  costs,
  currency = gbp,
  partyId,
  updateCosts,
  getCosts,
}: {
  costs: CostsPerStage[];
  currency?: Currency;
  partyId: string;
  updateCosts: (update: CostsPerStage[]) => Promise<void>;
  getCosts?: (partyId: string) => CostsPerStage[] | undefined;
}) => {
  const [editedCosts, setEditedCosts] = useState<CostsPerStage[]>();
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [costTimestamp, setCostTimestamp] = useState(Date.now());

  const save = () => {
    const toSave = editedCosts;
    // is there anything to save?
    if (toSave === undefined) return;
    setSaving(true);
    setSaving(true);
    updateCosts(toSave)
      .then(() => {
        setEditedCosts(undefined);
        setSaving(false);
        setError(undefined);
      })
      .catch((error) => {
        setEditedCosts(undefined);
        setSaving(false);
        console.warn("Failed to save costs", error);
        setError(error);
      });
  };

  const caseContext = useCase();
  const parties =
    caseContext
      .getCase()
      .parties?.sort((p1, p2) => p1.name.localeCompare(p2.name)) || [];

  return (
    <Box>
      {error && (
        <Alert severity="error">
          <AlertTitle>Saving costs failed</AlertTitle>
          {error}
        </Alert>
      )}
      <EditableCostsPerStageTable
        key={"costs_" + costTimestamp}
        costs={editedCosts !== undefined ? editedCosts : costs}
        editable={editedCosts !== undefined && !saving}
        currency={currency}
        updateCosts={(update) =>
          setEditedCosts(
            editedCosts?.map((costs) =>
              costs.stage === update.stage ? update : costs
            )
          )
        }
      />
      <Box
        mb={2}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent={
          editedCosts === undefined ? "flex-end" : "space-between"
        }
      >
        {editedCosts !== undefined && getCosts && (
          <Button
            disabled={saving || copyDialogOpen}
            variant="outlined"
            startIcon={<CopyIcon />}
            sx={{ mr: 2 }}
            onClick={() => setCopyDialogOpen(true)}
          >
            <Trans i18nKey="case.budget.table.copy" />
          </Button>
        )}
        {editedCosts !== undefined ? (
          <Box display="flex">
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              disabled={saving || copyDialogOpen}
              onClick={() => setEditedCosts(undefined)}
            >
              <Trans i18nKey="case.budget.table.cancel" />
            </Button>
            <LoadingButton
              variant="contained"
              endIcon={<SaveIcon />}
              disabled={saving || copyDialogOpen}
              loading={saving}
              onClick={save}
            >
              <Trans i18nKey="case.budget.table.save" />
            </LoadingButton>
          </Box>
        ) : (
          <Button
            disabled={saving || copyDialogOpen}
            variant="contained"
            endIcon={<EditIcon />}
            sx={{ margin: 0 }}
            onClick={() => setEditedCosts(costs)}
          >
            <Trans i18nKey="case.budget.table.edit" />
          </Button>
        )}
      </Box>
      <CostCopyDialog
        open={copyDialogOpen}
        onClose={(copy) => {
          setCopyDialogOpen(false);
          if (copy === undefined || getCosts === undefined) return;
          const newCosts = getCosts(copy.sourcePartyId);
          if (newCosts === undefined)
            console.warn("Got no costs for party " + copy.sourcePartyId);
          console.debug(
            "Replacing costs of '" +
              partyId +
              "' with costs of '" +
              copy.sourcePartyId +
              "'"
          );
          setEditedCosts(newCosts);
          // needed so the edit table is rerendered with new costs (cells hold each cost in their state during editing)
          setCostTimestamp(Date.now());
        }}
        sources={parties.filter((party) => party.id !== partyId)}
        target={parties.find((party) => party.id === partyId)?.name || partyId}
      />
    </Box>
  );
};
