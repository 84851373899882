import { useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { Line } from "react-chartjs-2";
import { useCurrency } from "../../../context/CurrencyContext";

export const SettlementChart = ({
  chartData,
  index = 0,
  onIndexSelect,
}: {
  chartData: ChartData<"line", number[]>;
  index?: number;
  onIndexSelect: (newIndex: number) => void;
}) => {
  const currency = useCurrency();
  const theme = useTheme();

  return (
    <Line
      data={chartData}
      options={{
        onClick: (_, elements) => {
          if (elements === undefined || elements.length === 0) return;
          onIndexSelect(elements[0].index);
        },
        interaction: {
          mode: "point",
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              font: {
                family: theme.typography.fontFamily,
              },
            },
          },
          tooltip: {
            callbacks: {
              title: () => "",
              label: (item) =>
                item.dataset.label + ": " + currency.format(item.parsed.y),
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            itemSort: (a, b) =>
              chartData.datasets[b.datasetIndex].data[b.dataIndex] -
              chartData.datasets[a.datasetIndex].data[a.dataIndex],
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: theme.typography.fontFamily,
                weight: (ctx) => {
                  return ctx.index === index ? "bold" : "normal";
                },
              },
            },
            grid: {
              lineWidth: (ctx) => {
                return ctx.index === index ? 2 : 1;
              },
              color: (ctx) => {
                return ctx.index === index
                  ? theme.palette.primary.main + "70"
                  : "rgba(0, 0, 0, 0.1)";
              },
            },
            border: {
              dash: (ctx) => {
                return ctx.index === index ? [8, 4] : undefined;
              },
            },
          },
          y: {
            ticks: {
              callback: function (value) {
                if (typeof value === "number") return currency.format(value);
                return value;
              },
              font: {
                family: theme.typography.fontFamily,
              },
            },
            beginAtZero: true,
          },
        },
      }}
    />
  );
};
