import {
  Box,
  Stack,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Claim, Party, getIssuesWinChance } from "./IssueModel";
import { IssueWinChanceSummaryCell } from "./IssueRows";
import { WinChanceCell } from "./WinChanceCell";
import { WinChanceVertical } from "./WinChanceVertical";

export interface ClaimWinChanceCallbacks {
  onWinChanceUpdated: (defendantId: string, newChance: number) => void;
  onSwitchToGeneric?: () => void;
  onSwitchToSpecific?: () => void;
  onGenericWinChanceUpdated: (newChance: number) => void;
}

export const ClaimWinChanceEditor = ({
  claim,
  defendants,
  callbacks,
  hideSummary = false,
}: {
  claim: Claim;
  defendants: Party[];
  callbacks: ClaimWinChanceCallbacks;
  hideSummary?: boolean;
}) => {
  const againstAll = getIssuesWinChance([claim], defendants, true);
  const againstAny = getIssuesWinChance([claim], defendants);

  const summaryColumn = !hideSummary && defendants.length > 1;

  const liableDefendants = Math.min(
    defendants.length,
    claim.winChanceAgainst ? Object.keys(claim.winChanceAgainst).length : 0
  );
  const enableSwitchToGeneric = claim.generic || liableDefendants > 1;
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell align="left" colSpan={1} rowSpan={summaryColumn ? 2 : 1} />
          {defendants.map((defendant, index) => (
            <TableCell
              align="center"
              key={"defendant_" + index}
              rowSpan={summaryColumn ? 2 : 1}
              sx={{ minWidth: 160 }}
            >
              <Typography>
                <b>{defendant.name}</b>
              </Typography>
            </TableCell>
          ))}
          {summaryColumn && (
            <TableCell align="center" key={"summary_total"} colSpan={2}>
              <Typography>
                <b>Total</b>
              </Typography>
            </TableCell>
          )}
        </TableRow>
        {summaryColumn && (
          <TableRow>
            <TableCell align="center" key={"summary_all"}>
              <Typography>
                <b>Against all</b>
              </Typography>
            </TableCell>
            <TableCell align="center" key={"summary_any"}>
              <Typography>
                <b>Against any</b>
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        <TableRow key={"header"}>
          <TableCell
            align="left"
            colSpan={1}
            sx={{
              borderBottom: "none",
              maxWidth: "360px",
              minWidth: "260px",
              pb: 0,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                <b>{claim.title}</b>
              </Typography>
            </Box>
          </TableCell>
          {enableSwitchToGeneric && (
            <TableCell
              align="center"
              colSpan={defendants.length}
              sx={{
                borderBottom: "none",
                pb: 0,
              }}
            >
              {callbacks.onSwitchToGeneric && callbacks.onSwitchToSpecific ? (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip title="Defendant-specific claims must be won per-defendant. Winning against one of the defendants is independent from winning against others.">
                    <Typography variant="caption">
                      Defendant-specific
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={`Claim is currently configured as ${
                      claim.generic ? "generic" : "defendant-specific"
                    }`}
                  >
                    <Switch
                      size="small"
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (callbacks.onSwitchToGeneric)
                            callbacks.onSwitchToGeneric();
                        } else {
                          if (callbacks.onSwitchToSpecific)
                            callbacks.onSwitchToSpecific();
                        }
                      }}
                      checked={claim.generic !== undefined && claim.generic}
                    />
                  </Tooltip>
                  <Tooltip title="Generic claims represent general facts, not dependant upon individual defendants. They are won once against all liable defendants.">
                    <Typography variant="caption">Generic</Typography>
                  </Tooltip>
                </Stack>
              ) : claim.generic ? (
                <Tooltip title="Generic claims represent general facts, not dependant upon individual defendants. They are won once against all liable defendants.">
                  <Typography variant="caption">Generic</Typography>
                </Tooltip>
              ) : (
                <Tooltip title="Defendant-specific claims must be won per-defendant. Winning against one of the defendants is independent from winning against others.">
                  <Typography variant="caption">Defendant-specific</Typography>
                </Tooltip>
              )}
            </TableCell>
          )}
        </TableRow>
        <TableRow key="winChances">
          <TableCell
            align="left"
            colSpan={1}
            sx={{
              borderBottom: "none",
            }}
          />

          {claim.generic ? (
            <TableCell
              colSpan={defendants.length}
              align="center"
              sx={{
                borderBottom: "none",
              }}
            >
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Box maxWidth={200} minWidth={200}>
                  <WinChanceVertical
                    onChanceChange={(newChance) =>
                      callbacks.onGenericWinChanceUpdated(newChance)
                    }
                    chance={claim.genericWinChance ?? 0}
                  />
                </Box>
              </Box>
            </TableCell>
          ) : (
            defendants.map((defendant) => (
              <WinChanceCell
                defendant={defendant}
                winChance={
                  claim.winChanceAgainst === undefined
                    ? undefined
                    : claim.winChanceAgainst[defendant.id]
                }
                onWinChanceUpdated={(defendantId, newChance) =>
                  callbacks.onWinChanceUpdated(defendantId, newChance)
                }
                key={"defendant_winChance_" + defendant.id}
              />
            ))
          )}

          {summaryColumn && (
            <IssueWinChanceSummaryCell key={"vAll"} value={againstAll} />
          )}
          {summaryColumn && !claim.generic && (
            <IssueWinChanceSummaryCell key={"vAny"} vAny value={againstAny} />
          )}
        </TableRow>
      </TableBody>
    </>
  );
};
