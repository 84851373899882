import {
  EnforcementPayment,
  EnforcementPlan,
  Judgement,
} from "../../analytics/api/Analytics";
import { WeighedAverage } from "../../analytics/Value";
import { PartyDescriptor, PartyDescriptors } from "../../model/CaseModel";
import combineCompareFns from "../../utils/combineCompareFns";

export function clientsFirst(
  a: [string, PartyDescriptor],
  b: [string, PartyDescriptor]
): number {
  if (a[1].isClient) {
    return b[1].isClient ? 0 : -1;
  }
  return b[1].isClient ? 1 : 0;
}

export function byName(
  a: [string, PartyDescriptor],
  b: [string, PartyDescriptor]
): number {
  return a[1].name.localeCompare(b[1].name);
}

/**
 * @param parties to sort
 * @returns array of party IDs sorted according to the order of the parties - clients first, defendants behind, all sorted by their names
 */
export function sort(
  parties: PartyDescriptors,
  ...comparisons: ((
    a: [string, PartyDescriptor],
    b: [string, PartyDescriptor]
  ) => number)[]
): string[] {
  const compareFunction =
    combineCompareFns<[string, PartyDescriptor]>(comparisons);
  const sortedEntries = Object.entries(parties)
    .sort((e1, e2) => compareFunction(e1, e2))
    .map((entry) => entry[0]);
  return sortedEntries;
}

/**
 * Returns per paying party the highest balance payment.
 * @param judgement to analyze enforcements of
 * @returns highest balance enforcement payments per paying party
 */
export function getMaxBalanceEnforcementVsParties(
  enforcements: EnforcementPlan[]
): {
  [partyId: string]: { value: number; cost: number; baseCost: number };
} {
  // maximum enforceable per party (not of the whole enforcement plan, as it may be paid by multiple parties)
  const maxPerParty: {
    [partyId: string]: { value: number; cost: number; baseCost: number };
  } = {};
  enforcements.forEach((enforcement) => {
    for (const [payerId, payment] of Object.entries(enforcement.payments)) {
      const balance = payment.value - payment.cost - enforcement.baseCost;
      if (balance > 0) {
        const other = maxPerParty[payerId];
        if (other === undefined)
          maxPerParty[payerId] = { ...payment, baseCost: enforcement.baseCost };
        // compare payment balance
        else if (balance > other.value - other.cost - other.baseCost)
          maxPerParty[payerId] = { ...payment, baseCost: enforcement.baseCost };
      }
    }
  });
  return maxPerParty;
}

/**
 *
 * @param judgments to analyze
 * @returns average maximum enforceable amount per party across given judgments, taking enforcement costs per party into account
 */
export function getAvgMaxEnforcementVsParties(judgments: Judgement[]): {
  [partyId: string]: EnforcementPayment;
} {
  const avgValues = new Map<
    string,
    { value: WeighedAverage; cost: WeighedAverage }
  >();
  judgments.forEach((judgement) => {
    const maxEnforcements = getMaxBalanceEnforcementVsParties(
      judgement.enforcementVariants
    );
    // average max enforcements per party according to the probability of judgement
    for (const [payerId, maxEnforcement] of Object.entries(maxEnforcements)) {
      if (maxEnforcement.value > 0) {
        let avgValue = avgValues.get(payerId);
        if (avgValue === undefined) {
          avgValue = {
            value: new WeighedAverage(),
            cost: new WeighedAverage(),
          };
          avgValues.set(payerId, avgValue);
        }
        // add enforcement parameters
        avgValue.value.add({
          value: maxEnforcement.value,
          probability: judgement.probability,
        });
        avgValue.cost.add({
          value: maxEnforcement.baseCost + maxEnforcement.cost,
          probability: judgement.probability,
        });
      }
    }
  });
  // convert map indexed type
  const result: { [partyId: string]: EnforcementPayment } = {};
  for (const [payerId, avg] of avgValues) {
    const value = avg.value.get();
    if (value === undefined) continue;

    // avg must be normalized to the 100% of all judgments
    // for non-winning judgments add 0 with probability of 100%-(probability of gathered enforcement values)
    result[payerId] = {
      value: normalizeToOneAndGet(avg.value),
      cost: normalizeToOneAndGet(avg.cost),
    };
  }
  return result;
}

function normalizeToOneAndGet(avg: WeighedAverage): number {
  const value = avg.get();
  if (value === undefined) return 0;
  // avg must be normalized to the 100% of all judgments
  // for non-winning judgments add 0 with probability of 100%-(probability of gathered enforcement values)
  avg.add({
    value: 0,
    probability: avg.getWeight().neg().add(1).toNumber(),
  });
  return avg.get()?.toNumber() || 0;
}
