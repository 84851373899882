import { Tabs as MaterialTabs, Tab } from "@mui/material";
import { useState } from "react";
import { TabPanel } from "./TabPanel";

export const Tabs = ({
  tabs,
}: {
  tabs: { label: string; tabComponent: React.ReactNode }[];
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  return (
    <>
      <MaterialTabs value={tabIndex} onChange={handleChange}>
        {tabs.map(({ label }, index) => {
          return (
            <Tab
              key={"outcome_tab_" + index + "_" + label}
              label={label}
              id={"tab_" + index}
            />
          );
        })}
      </MaterialTabs>

      {tabs.map(({ tabComponent }, index) => (
        <TabPanel
          key={"tab_component_" + index}
          currentTabIndex={index}
          index={tabIndex}
        >
          {tabComponent}
        </TabPanel>
      ))}
    </>
  );
};
