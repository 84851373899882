import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useCurrency } from "../../context/CurrencyContext";
import { CurrencyTextField } from "../general/CurrencyTextField";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "./Claims";
import { QuantumProps } from "./QuantumCell";

const QuantumLiabilityEditor = ({
  quantum,
  defendant,
  liability = 0,
  onLiabilityChange,
}: {
  quantum: QuantumProps;
  defendant: string;
  liability: number;
  onLiabilityChange: (newLiability: number) => void;
}) => {
  const theme = useTheme();
  const currency = useCurrency();

  const [sliderValue, setSliderValue] = useState(liability * 100);
  // use to force rerender of currency field
  const [sliderTs, setSliderTs] = useState(Date.now());

  return (
    <Box>
      <Typography variant="subtitle2">
        {t("case.claims.quantums.liability.editDialog.subTitle", {
          ...quantum,
          defendant,
        })}
      </Typography>
      <Typography>
        {t("case.claims.quantums.liability.editDialog.hint", {
          ...quantum,
          defendant,
        })}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          mt: theme.spacing(2),
        }}
      >
        <TwoLiner
          primary={percentage.format(sliderValue / 100)}
          secondary={t("case.claims.quantums.liability.editDialog.sliderLabel")}
        />
        <Slider
          sx={{
            //  make sure value label does not stick out too much
            mt: theme.spacing(1),
            ml: theme.spacing(2),
            mb: theme.spacing(2),
            minWidth: 140,
            maxWidth: 200,
          }}
          color="primary"
          size="small"
          value={sliderValue}
          aria-label="Small"
          valueLabelFormat={(value) => percentage.format(value / 100)}
          valueLabelDisplay="auto"
          onChangeCommitted={(ignored, value) => {
            if (typeof value !== "number") return;
            setSliderValue(value);
            setSliderTs(Date.now());
            onLiabilityChange(value / 100);
          }}
          onChange={(ignored, value) => {
            if (typeof value !== "number") return;
            setSliderTs(Date.now());
            setSliderValue(value);
          }}
        />
      </Box>
      <CurrencyTextField
        key={"liability_" + sliderTs}
        currency={currency}
        label={`${t(
          "case.claims.quantums.liability.editDialog.textFieldLabel"
        )}`}
        onValueChange={(newValue) => {
          setSliderValue((newValue / quantum.quantumAmount) * 100);
          onLiabilityChange(newValue / quantum.quantumAmount);
        }}
        min={0}
        max={quantum.quantumAmount}
        value={(quantum.quantumAmount * sliderValue) / 100}
      />
    </Box>
  );
};

export const QuantumLiabilityEditorDialog = ({
  quantum,
  defendant,
  liability = 0,
  open,
  onClose,
}: {
  quantum: QuantumProps;
  defendant: string;
  liability: number;
  open: boolean;
  onClose: (newLiability?: number) => void;
}) => {
  const currency = useCurrency();
  const [newLiability, setNewLiability] = useState(liability);

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ userSelect: "none" }}
      scroll="body"
    >
      <DialogTitle id="alert-dialog-title">
        {t("case.claims.quantums.liability.editDialog.title", {
          ...quantum,
          defendant,
        })}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle2">
          {t("case.claims.quantums.liability.editDialog.issueLabel", {
            ...quantum,
            defendant,
          })}
        </Typography>
        <i>
          <Typography gutterBottom>
            {t("case.claims.quantums.liability.editDialog.issueDescription", {
              ...quantum,
            })}
          </Typography>
        </i>
        <Typography variant="subtitle2">
          {t("case.claims.quantums.liability.editDialog.quantumLabel", {
            ...quantum,
          })}
        </Typography>
        <Typography gutterBottom>
          {t("case.claims.quantums.liability.editDialog.quantumDescription", {
            ...quantum,
            quantumAmount: currency.format(quantum.quantumAmount),
          })}
        </Typography>
        <QuantumLiabilityEditor
          defendant={defendant}
          quantum={quantum}
          liability={liability}
          onLiabilityChange={(value) => setNewLiability(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          <Trans i18nKey="case.claims.quantums.liability.editDialog.cancel" />
        </Button>
        <Button
          onClick={() => onClose(newLiability)}
          variant="contained"
          autoFocus
        >
          <Trans i18nKey="case.claims.quantums.liability.editDialog.submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
