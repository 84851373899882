import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { Scatter } from "react-chartjs-2";
import { useCurrency } from "../../context/CurrencyContext";
import { Sample } from "./MonteCarlo";

export const percentage = new Intl.NumberFormat("en-GB", {
  style: "percent",
  maximumFractionDigits: 2,
});

const CumulativeValueAtRiskChart = ({ data }: { data: Sample[] }) => {
  const cumulativeData: Sample[] = useMemo(() => {
    const result2: Sample[] = [];
    const sorted = data
      .filter(({ probability }) => probability > 0)
      .sort((a, b) => a.value - b.value);
    let cumulativeProbability = 0;
    for (const { value, probability } of sorted) {
      cumulativeProbability += probability;
      result2.push({ value, probability: cumulativeProbability });
    }
    if (cumulativeProbability > 0)
      for (const result of result2) result.probability /= cumulativeProbability;
    return result2;
  }, [data]);

  const theme = useTheme();
  const currency = useCurrency();
  const font = {
    family: theme.typography.fontFamily,
  };
  return (
    <Scatter
      data={{
        datasets: [
          {
            data: cumulativeData
              .filter(({ value }) => value < 0)
              .map((result) => ({
                x: result.probability,
                y: result.value,
              })),
            fill: true,
            backgroundColor: theme.palette.error.dark + "10",
            borderColor: theme.palette.error.light,
          },
          {
            data: cumulativeData
              .filter(({ value }) => value >= 0)
              .map((result) => ({
                x: result.probability,
                y: result.value,
              })),
            fill: true,
            backgroundColor: theme.palette.success.dark + "10",
            borderColor: theme.palette.success.light,
          },
        ],
      }}
      options={{
        interaction: {
          mode: "nearest",
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            bodyFont: font,
            titleFont: font,
            footerFont: font,
            callbacks: {
              label: (item) =>
                `${percentage.format(1 - item.parsed.x)} chance of at least ${currency.format(item.parsed.y)}`,
            },
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          },
        },
        scales: {
          x: {
            min: 0,
            ticks: {
              callback: function (value) {
                return typeof value === "number"
                  ? percentage.format(value)
                  : value;
              },
              font,
            },
            grid: {
              // make 0 line thicker
              lineWidth: (context) => (context.tick.value == 0 ? 2 : 1),
            },
          },
          y: {
            ticks: {
              callback: function (value) {
                return typeof value === "number"
                  ? currency.format(value)
                  : value;
              },
              font,
            },
          },
        },
      }}
    />
  );
};

export default CumulativeValueAtRiskChart;
