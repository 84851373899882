import { Opponents } from "../../model/CaseModel";

export class ClaimMatrix {
  constructor(public readonly opponents: Opponents) {}

  public getOpponentsOf(partyId: string): { [opponentId: string]: string[] } {
    return this.opponents[partyId] ?? {};
  }

  public getOpponentsInClaim(partyId: string, claimId: string): string[] {
    const opponents = this.getOpponentsOf(partyId);

    const opponentsInClaim = new Set<string>();
    Object.entries(opponents).forEach(([opponentId, claims]) => {
      if (claims.some((id) => id === claimId)) opponentsInClaim.add(opponentId);
    });
    return [...opponentsInClaim];
  }
}
